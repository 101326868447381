import { render, staticRenderFns } from "./BaseAdvertise.vue?vue&type=template&id=b770f024&scoped=true&"
import script from "./BaseAdvertise.vue?vue&type=script&lang=js&"
export * from "./BaseAdvertise.vue?vue&type=script&lang=js&"
import style0 from "../assets/assets-CSS/baseAdvertise.css?vue&type=style&index=0&id=b770f024&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b770f024",
  null
  
)

export default component.exports