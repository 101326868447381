<template>
<!-- This component is used to show an image on the left and some texts related to that on the right. We used it in the "About.vue" view for showing certifications. The data of this component are from parent component and also has some sliding effect that is running in the parent component. -->
  <div class="container-fluid">
    <div class="row">

        <!-- text part -->

      <section class="col-md-6">
        <div class="descPar d-flex align-items-center justify-content-center">
          <section class="col-lg-9 col-11 mx-auto">
            <div class="mb-2 d-flex align-items-center justify-content-between">
              <p>صادر کننده: </p>
              <p class="dynamicP">{{ imgInfo.sader }}</p>
            </div>

            <div class="mb-2 d-flex align-items-center justify-content-between">
              <p>عنوان دوره: </p>
              <p class="dynamicP">{{ imgInfo.title }}</p>
            </div>

            <div class="mb-2 d-flex align-items-center justify-content-between">
              <p>محتوای دوره: </p>
              <ul class="w-50">
                <li>{{ imgInfo.mohtava[0] }}</li>
                <li>{{ imgInfo.mohtava[1] }}</li>
                <li>{{ imgInfo.mohtava[2] }}</li>
              </ul>
            </div>

            <div class="mb-2 d-flex align-items-center justify-content-between">
              <p>زمان اخذ مدرک: </p>
              <p class="dynamicP">{{ imgInfo.timeMad }}</p>
            </div>
          </section>
        </div>
      </section>

        <!-- image part -->

      <section class="col-md-6 d-flex align-items-center justify-content-center">
        <img class="img-fluid img-thumbnail" :src=imgInfo.srcImg :alt=imgInfo.srcAlt>
      </section>
    </div>
  </div>
</template>

<script>
    export default {
        name: "ImgContainer",
        props: {
            imgInfo: Object
        }
    }
</script>

<style scoped src="../assets/assets-CSS/imgCont.css"></style>
