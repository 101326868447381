<template>
<!--
This is the main navigation of the site that is used in "App.vue". It has "router-link" to route to the pages of the site. Also it has another part below links that change according to the data of each route.
-------------------------------------------------------------
Important Tip: this component does not work properly if you use lazy-loading in your vue-router.
-->
<section>
    <div class="row">
      <div class="col-md-12">
        <nav id="nav" class="navbar navbar-expand-sm">
          <button @click="menuLogo" class="navbar-toggler mt-3" type="button" data-toggle="collapse" data-target="#nav1" aria-controls="nav1" aria-expanded="false" aria-label="Toggle navigation">
            <i class="far fa-bars icon-style"></i>
          </button>
          <div class="collapse navbar-collapse" id="nav1">

            <ul class="navbar-nav ml-auto mt-2 mt-lg-0">
              <!-- navigation links -->
              <li
                  class="nav-item mx-lg-4 mx-sm-2 mx-1"
              >
                <router-link :class="[navBoot, aStyle]" :to="{name: 'Articles'}">مقالات
                  <i class="far fa-file-signature"></i>
                </router-link>
              </li>
              <li
                  class="nav-item mx-lg-4 mx-sm-2 mx-1"
              >
                <router-link :class="[navBoot, aStyle]" :to="{name: 'About'}">درباره من
                  <i class="far fa-id-card-alt"></i>
                </router-link>
              </li>
              <li
                  class="nav-item mx-lg-4 mx-sm-2 mx-1"
              >
                <router-link :class="[navBoot, aStyle]" :to="{name: 'Samples'}">نمونه کارها
                  <i class="far fa-code"></i>
                </router-link>
              </li>
              <li
                  class="nav-item mx-lg-4 mx-sm-2 mx-1"
              >
                <router-link :class="[navBoot, aStyle]" :to="{name: 'Contact'}">تماس با من
                  <i class="far fa-envelope-open-text"></i>
                </router-link>
              </li>
              <!-- end of navigation links -->
            </ul>
          </div>
          <!-- logo of the site -->
          <div>
          <router-link :class="[navBootBrand]" :to="{name: 'Articles'}">
            <img class="img-fluid logo" src="../assets/img/logo.png" alt="لوگوی سایت حمید داودی">
          </router-link>
          </div>
        </nav>

      </div>
    </div>

    <!-- below the navigation link -->

    <div class="row p-sm-2">
      <div class="col-md-5">
        <div class="head-img d-flex flex-column mt-md-auto mt-5">
          <img v-bind:src = this.message.src1 :alt = this.message.alt1 class="img-fluid">
          <h1 class="py-3">
            {{ this.message.messH1 }}
          </h1>
          <p>
            {{ this.message.messP1 }}
          </p>
        </div>


      </div>

      <div class="col-md-7">
        <div class="parent-left">
            <h2 class="pb-3">
              {{ this.message.messH2 }}
            </h2>
            <div>
              <img class="img-fluid my-5 my-sm-auto" v-bind:src = this.message.src2 :alt = this.message.alt2>
            </div>
        </div>
      </div>

    </div>
    <!-- end of below the navigation link -->
</section>
</template>

<script>
    export default {
        name: "TheNavigation",
        data() {
            return {
                navBoot: "nav-link",
                aStyle: "astyles",
                navBootBrand: "navbar-brand",
                message: {
                    /* this is the data that changes according to each route */
                    messH1: 'مقالات در زمینه وب',
                    messP1: 'آموزش و تمرین مهارتها',
                    messH2: 'شاخه های کلی مقالات',
                    src1: require('../assets/img/nav-imgs/article-img.png'),
                    src2: require('../assets/img/nav-imgs/article-left.png'),
                    alt1: 'تصویر نمایشی مقالات',
                    alt2: 'تصویر شاخه های کلی مقالات'
                }
            }
        },
        
        computed: {
            routeName: function() {
                return this.$route.name;
            }
        },
        
        watch: {
            routeName: function (newrouteName, oldrouteName) {
                this.rouFunc();
            }
        },

        methods: {
            menuLogo: function () {
                /* This function is used to position the logo when clicking the "menu icon" below 575px */
                $('.logo').addClass('logo755');
            },

            rouFunc: function () {
                /* This function changes the text and images according to "route name" */

                switch (this.routeName) {
                    case "Articles":
                        this.message.messH1 = 'مقالات در زمینه وب';
                        this.message.messP1 = 'آموزش و تمرین مهارتها';
                        this.message.messH2 = 'شاخه های کلی مقالات';
                        this.message.src1 = require('../assets/img/nav-imgs/article-img.png');
                        this.message.src2 = require('../assets/img/nav-imgs/article-left.png');
                        this.message.alt1 = 'تصویر نمایشی مقالات';
                        this.message.alt2 = 'تصویر شاخه های کلی مقالات';
                        break;
                    case "ArticlesId":
                        this.message.messH1 = 'مقالات در زمینه وب';
                        this.message.messP1 = 'آموزش و تمرین مهارتها';
                        this.message.messH2 = 'شاخه های کلی مقالات';
                        this.message.src1 = require('../assets/img/nav-imgs/article-img.png');
                        this.message.src2 = require('../assets/img/nav-imgs/article-left.png');
                        this.message.alt1 = 'تصویر نمایشی مقالات';
                        this.message.alt2 = 'تصویر شاخه های کلی مقالات';
                        break;
                    case "About":
                        this.message.messH1 = 'حمید داودی';
                        this.message.messP1 = 'طراح و توسعه دهنده وب سایت';
                        this.message.messH2 = 'مهارتهای مهم و تخصصی';
                        this.message.src1 = require('../assets/img/nav-imgs/about-img.png');
                        this.message.src2 = require('../assets/img/nav-imgs/about-left.png');
                        this.message.alt1 = 'تصویر حمید داودی';
                        this.message.alt2 = 'تصویر مهارتهای تخصصی';
                        break;
                    case "Samples":
                        this.message.messH1 = 'پروژه های انجام شده';
                        this.message.messP1 = 'نشان دهنده کیفیت مهارتها';
                        this.message.messH2 = 'موضوعات کلی نمونه کارها ';
                        this.message.src1 = require('../assets/img/nav-imgs/sample-img.png');
                        this.message.src2 = require('../assets/img/nav-imgs/sample-left.png');
                        this.message.alt1 = 'تصویر نمایشی برای نمونه کارها';
                        this.message.alt2 = 'تصویر عنوان برخی پروژه های انجام شده';
                        break;
                    case "Contact":
                        this.message.messH1 = 'ارتباط با طراح سایت';
                        this.message.messP1 = 'ارسال نظرات و درخواست ها';
                        this.message.messH2 = 'راه های مهم ارتباطی';
                        this.message.src1 = require('../assets/img/nav-imgs/contact-img.png');
                        this.message.src2 = require('../assets/img/nav-imgs/contact-left.png');
                        this.message.alt1 = 'تصویر نمایشی ارتباط با طراح سایت';
                        this.message.alt2 = 'تصویر راه های مهم ارتباطی';
                        break;
                    case "DonateMe":
                        this.message.messH1 = 'کمک مالی به سایت';
                        this.message.messP1 = 'انتقال وجه از درگاه بانکی';
                        this.message.messH2 = 'از همیاری شما سپاسگزاریم';
                        this.message.src1 = require('../assets/img/nav-imgs/donate-img.png');
                        this.message.src2 = require('../assets/img/nav-imgs/donate-left.png');
                        this.message.alt1 = 'تصویر نمایشی مربوط به کمک مالی';
                        this.message.alt2 = 'تصویر مربوط به قدردانی از کمک';
                        break;
                    default:
                        this.message.messH1 = 'صفحه مورد نظر یافت نشد!';
                        this.message.messP1 = 'به صفحه اصلی سایت بروید';
                        this.message.messH2 = 'آدرس اینترنتی اشتباه وارد کرده اید';
                        this.message.src1 = require('../assets/img/nav-imgs/four-o-four-img.png');
                        this.message.src2 = require('../assets/img/nav-imgs/four-o-four-left.png');
                        this.message.alt1 = 'تصویر صفحه مورد نظر یافت نشد!';
                        this.message.alt2 = 'تصویر صفحه مورد نظر یافت نشد!';
                } // end of switch

            },


        }, // end of methods


        created() {
            window.addEventListener('beforeunload', this.rouFunc() );
        }, // end of "created" hook


    }
</script>

<style scoped src="../assets/assets-CSS/navig.css"></style>
