<template>
<!-- this is the texts and contents of "article number 3" of our database. for more info and list of all options available for each article please see this file:
H:/web-codes/websites/portfolio-folder/design-and-assets/sample article/BaseArticle.vue  -->
        <div class="col-md-10 mx-auto">

            <div class="text-right mt-5">

                <!-- intro text -->
                <h2 class="subTitle">مقدمه: </h2>
                <blockquote class="blockquote">
                    <p class="text-justify textPara">
                        معمولا توسعه&zwnj;دهندگان وب&zwnj;سایت برای بررسی کدهای نوشته شده و تغییراتی که می&zwnj;توانند به آنها اعمال کنند تا طراحی بهتری داشته باشند، از ابزارهای توسعه وب در مرورگرها (<span class="engliSpan">DevTools</span>) استفاده می&zwnj;کنند. این ابزارها همچنین به توسعه&zwnj;دهندگان کمک می&zwnj;کنند تا وب&zwnj;سایت&zwnj;های ساخته شده توسط دیگر توسعه&zwnj;دهندگان را بررسی کرده و به چگونگی طراحی و کدهای به کار رفته در آن وب&zwnj;سایت&zwnj;ها واقف شوند. گرچه اغلب طراحان وب&zwnj;سایت کم یا زیاد با این ابزارها آشنایی دارند، اما همانند سایر تکنولوژی&zwnj;های موجود، این ابزارها نیز ممکن است نکات ناشناخته&zwnj;ای برای برخی توسعه&zwnj;دهندگان داشته باشند. لذا در این مقاله بر آن شدیم تا شما را با برخی از این ویژگی&zwnj;ها و نحوه استفاده از آنها <span class="farsiSpan">بیشتر</span> آشنا کنیم.
                    </p>
                    <p class="text-justify textPara">
                        قبل از شروع بررسی مواردی که می&zwnj;خواهیم به آن اشاره کنیم، لازم است برای مخاطبانی که کمتر با این ابزارها آشنایی دارند توضیح داده شود که برای دسترسی به این ابزارها در مرورگرهای مختلف راه&zwnj;های میانبر (shortcut) گوناگونی هم وجود دارد. اما معمولا با کلیک راست کردن بر روی صفحه وب&zwnj;سایت مربوطه و انتخاب گزینه&zwnj;هایی مانند <span class="engliSpan">inspect</span> و <span class="engliSpan">inspect element</span> می&zwnj;توانید به این ابزارها دسترسی داشته باشید.
                    </p>

                    <!-- imgs (png,jpg or gif) that are not related to codes -->
                    <div class="col-md-10 mt-5 mx-auto">
                        <img class="img-fluid mx-auto d-block img-thumbnail shadow-lg" src="./imgFolder/img1.png" alt="تصویر مربوط به inspect گرفتن از صفحه وب سایت">
                    </div>

                    <p class="text-justify textPara py-5">
                        معمولا در اکثر مرورگرها با کلید میانبر <code>Ctrl+Shift+I</code> هم می&zwnj;توان به این ابزارها دسترسی پیدا کرد. نکته دیگر اینکه برخی از این ابزارها در تمامی مرورگرها در دسترس هستند و برخی دیگر به طور خاص فقط در <strong>بعضی</strong> از مرورگرها قابلیت استفاده دارند.
                    </p>

                </blockquote>

                <!-- paragraphs -->
                <h2 class="subTitle mt-5">ویژگی designMode :</h2>
                <p class="text-justify textPara">
                    این ابزار که در تمامی مرورگرها قابل استفاده است، به این شکل عمل می&zwnj;کند که کافی است پس از inspect گرفتن از صفحه مورد نظر، در تب <span class="engliSpan">console</span> عبارت زیر را تایپ کنید:
                </p>

                <div class="col-md-10 mt-5 mx-auto">
                        <img class="img-fluid mx-auto d-block img-thumbnail shadow-lg" src="./imgFolder/img2.png" alt="تصویر مربوط به نحوه استفاده از designMode در تب console">
                </div>

                <p class="text-justify textPara pt-5">
                    در این صورت تمامی محتویات صفحه مورد نظر به حالت <span class="farsiSpan">قابل ویرایش</span> در می&zwnj;آیند. یعنی مثلا می&zwnj;توانید متن موجود در سایت را تغییر دهید و ... . این مسئله به یک طراح کمک می&zwnj;کند تا بتواند طراحی بهتری در خصوص المان&zwnj;های سایت داشته باشد.
                </p>

                <div class="col-md-10 mt-5 mx-auto">
                        <img class="img-fluid mx-auto d-block img-thumbnail shadow-lg" src="./imgFolder/design-mode1.gif" alt="تصویر مربوط به قابل ویرایش بودن سایت در حالت designMode ">
                </div>

                <!-- paragraphs -->
                <h2 class="subTitle mt-5">ویژگی filtering :</h2>
                <p class="text-justify textPara">
                    این قابلیت که بیشتر در مرورگر فایرفاکس (<span class="engliSpan">Firefox</span>) در اینجا مورد بررسی قرار می&zwnj;گیرد، به شما این امکان را می&zwnj;دهد که با انتخاب کردن یک تگ در تب <span class="engliSpan">inspector</span> بررسی کنید که یک ویژگی سی&zwnj;اس&zwnj;اس (<span class="engliSpan">CSS property</span>) خاص در چه قسمت&zwnj;هایی از استایل این تگ استفاده شده است. به عنوان مثال در تصویر زیر تگ <code>&lt;ul&gt;</code> در تب inspector انتخاب شده است و سپس در ستون وسط با نوشتن <span class="engliSpan">color</span> در قسمت filter styles می&zwnj;توان مشاهده کرد که در فایل CSS این ویژگی (color)، در چه بخش&zwnj;هایی به کار رفته است:
                </p>

                <div class="col-md-10 mt-5 mx-auto">
                        <img class="img-fluid mx-auto d-block img-thumbnail shadow-lg" src="./imgFolder/filter-video.gif" alt="تصویر مربوط به فیلتر کردن استایل ها">
                </div>


                <!-- paragraphs -->
                <p class="text-justify textPara pt-5">
                    همچنین از قابلیت&zwnj;های دیگر مرورگر <strong>فایرفاکس</strong> در بخش فیلتر نمودن تگ&zwnj;ها این است که با انتخاب آیکون مربوطه در ستون وسطی تب inspector، می&zwnj;توانید تمامی تگ&zwnj;هایی که در فایل HTML این استایل&zwnj;ها را گرفته&zwnj;اند، شناسایی کنید.
                </p>

                <!-- imgs (png,jpg or gif) that are not related to codes -->
                <div class="col-md-10 mt-5 mx-auto">
                        <img class="img-fluid mx-auto d-block img-thumbnail shadow-lg" src="./imgFolder/highlight.gif" alt="تصویر مربوط به انتخاب تمامی المان های دارای استایل inspect گرفته شده">
                </div>


                <!-- paragraphs -->
                <h2 class="subTitle mt-5">بررسی حالت&zwnj;های hover و focus و ... :</h2>
                <p class="text-justify textPara">
                    این ویژگی در اغلب مرورگرها یافت می&zwnj;شود. هدف آن است که حالت&zwnj;های مختلف یک المان HTML (مانند تگ <code>&lt;button&gt;</code>) را بررسی کنیم. چنین حالت&zwnj;هایی که در <span class="engliSpan">CSS</span> معمولا به عنوان <span class="engliSpan">pseudo state</span> شناخته می&zwnj;شوند (حالت&zwnj;های hover، focus، visit و ...)، در مرورگرها قابل شناسایی هستند. برای استفاده از این قابلیت ابزارهای توسعه مرورگرها کافی است که در تب <strong>inspector</strong> (یا تب Elements در مرورگر <span class="engliSpan">Google Chrome</span>) در پنجره styles روی آیکون <span dir="ltr" class="engliSpan">:hov</span> کلیک کنیم تا قابلیت انتخاب حالت&zwnj;های مختلف تگ و استایل&zwnj;های آن به وجود آید:
                </p>

                <!-- imgs (png,jpg or gif) that are not related to codes -->
                <div class="col-md-10 mt-5 mx-auto">
                        <img class="img-fluid mx-auto d-block img-thumbnail shadow-lg" src="./imgFolder/hover.gif" alt="تصویر مربوط به بررسی حالت های مختلف یک المان">
                </div>


                <!-- paragraphs -->
                <h2 class="subTitle mt-5">بررسی ساختارهای تو در توی HTML :</h2>
                <p class="text-justify textPara">
                    گاهی اوقات در ساختار فایل&zwnj;های HTML مربوط به وب&zwnj;سایت&zwnj;ها تگ مورد نظر که قرار است به بررسی آن بپردازیم، درون تعداد زیادی تگ دیگر قرار گرفته است (در اصطلاح <span class="engliSpan">parent</span> های فراوانی دارد). در چنین حالت&zwnj;هایی شاید برای پیدا کردن این تگ نیاز به جستجوی زیادی در تب <span class="engliSpan">inspector</span> داشته باشیم. لذا گزینه&zwnj;ای در اغلب مرورگرها برای این منظور تبیین شده است. مثلا در مرورگر فایرفاکس با انتخاب المان parent مورد نظر و راست کلیک و سپس گزینه <span class="engliSpan">Expand All</span> می&zwnj;توانیم به تمامی فرزندان آن المان (المان&zwnj;های <span class="farsiSpan">داخلی</span> در ساختار HTML) دسترسی پیدا کنیم. این گزینه در مرورگر Chrome تحت عنوان <span class="engliSpan">Expand recursively</span> موجود است. همچنین کلید میانبر انجام این کار نگه داشتن <code>Ctrl + Alt</code> و کلیک بر روی فلش سمت چپ المان مورد نظر است:
                </p>

                <!-- imgs (png,jpg or gif) that are not related to codes -->
                <div class="col-md-10 mt-5 mx-auto">
                        <img class="img-fluid mx-auto d-block img-thumbnail shadow-lg" src="./imgFolder/Expand.gif" alt="تصویر مربوط به بررسی ساختارهای تو در توی HTML">
                </div>

                <h2 class="subTitle mt-5">بررسی CSS-animations :</h2>
                <p class="text-justify textPara">
                    چنانچه در طراحی سایت خود به المانی از طریق CSS انیمیشن داده باشید، می&zwnj;توانید با ابزارهای توسعه مرورگرها به بررسی این انیمیشن و زمان بندی آن بپردازید. به عنوان مثال در مرورگر فایرفاکس این ابزار در تب <span class="engliSpan">inspector</span> در ستون <strong>سمت راستی</strong> واقع شده است که اگر المان دارای انیمیشن در حالت <span class="farsiSpan">انتخاب</span> باشد، با اجرای انیمیشن اطلاعات آن در این قسمت مشاهده می&zwnj;شود. حتی می&zwnj;توانید با عقب و جلو بردن خط زمانی (Time Line) به بررسی دقیق&zwnj;تر انیمیشن بپردازید.
                </p>

                <!-- imgs (png,jpg or gif) that are not related to codes -->
                <div class="col-md-10 mt-5 mx-auto">
                        <img class="img-fluid mx-auto d-block img-thumbnail shadow-lg" src="./imgFolder/animation.gif" alt="تصویر مربوط به بررسی animation از طریق تب inspector">
                </div>

                <h2 class="subTitle mt-5">کار با تب Network :</h2>
                <p class="text-justify textPara">
                    همان&zwnj;طور که می&zwnj;دانیم این تب عمدتا مخصوص درخواست&zwnj;هایی (<span class="engliSpan">request</span>) است که توسط سایت صورت می&zwnj;گیرد. مثلاً فایل&zwnj;ها یا تصاویری که در سایت قرار می&zwnj;گیرند، در این بخش ذکر می&zwnj;شوند. در واقع به کمک <strong>کدهایی</strong> که برای هر یک ذکر می&zwnj;شود، نوع و نتیجه درخواست مشخص می&zwnj;شود. مثلا چنانچه کد 200 در ستون <span class="engliSpan">status</span> مشاهده شود، نشان&zwnj;دهنده <strong>موفقیت آمیز بودن</strong> درخواست به سمت سرور سایت است. نکته&zwnj;ای که در این تب وجود دارد آن است که در قسمت بالای آن مکانی برای وارد کردن متن (یک <span class="engliSpan">input</span>) وجود دارد که می&zwnj;توانید از طریق آن به <span class="farsiSpan">فیلتر</span> کردن اطلاعات بپردازید. موارد زیر از جمله فیلترهایی هستند که می&zwnj;توانید در این قسمت تایپ کنید:
                </p>

                <BaseList :listData = "listText"></BaseList>

                <!-- imgs (png,jpg or gif) that are not related to codes -->
                <div class="col-md-10 mt-5 mx-auto">
                        <img class="img-fluid mx-auto d-block img-thumbnail shadow-lg" src="./imgFolder/mime.gif" alt="تصویر مربوط به فیلتر کردن از طریق تب Network">
                </div>

                <h2 class="subTitle mt-5">ویژگی Live Expression :</h2>
                <p class="text-justify textPara">
                    این ویژگی مخصوص مرورگر <span class="engliSpan">Google Chrome</span> است. نحوه کار آن به این صورت است که تغییراتی را که بر روی المان inspect گرفته شده اعمال می&zwnj;کنید، به صورت همزمان در console نشان می&zwnj;دهد. مثلا فرض نمایید که یک <code>&lt;textarea&gt;</code> با id به نام <span class="engliSpan">textAR</span> داشته باشید. چنانچه در حالت <span class="farsiSpan">عادی</span> این المان را در console با دستور <strong>getElementById</strong> انتخاب کرده و مقدار value آن را ارزیابی کنید و سپس در داخل <code>&lt;textarea&gt;</code> متنی تایپ کنید، دیگر مقداری که قبلا از دستور فوق دریافت کرده بودید در console تغییری <span class="farsiSpan">نمی&zwnj;کند</span>:
                </p>

                <!-- imgs (png,jpg or gif) that are not related to codes -->
                <div class="col-md-10 mt-5 mx-auto">
                        <img class="img-fluid mx-auto d-block img-thumbnail shadow-lg" src="./imgFolder/live1.gif" alt="تصویر مربوط به فعال نبودن Live Expression">
                </div>

                <p class="text-justify textPara pt-5">
                    اما اگر از آیکون مشابه <span class="farsiSpan">چشم</span> که در قسمت console وجود دارد، استفاده کنید (در واقع Live Expression را فعال کنید)، آنگاه با تغییر دادن متن درون <code>&lt;textarea&gt;</code> متن درون console هم تغییر می&zwnj;کند:
                </p>

                <!-- imgs (png,jpg or gif) that are not related to codes -->
                <div class="col-md-10 mt-5 mx-auto">
                        <img class="img-fluid mx-auto d-block img-thumbnail shadow-lg" src="./imgFolder/live2.gif" alt="تصویر مربوط به فعال بودن Live Expression">
                </div>

                <h2 class="subTitle mt-5">کار با تب Lighthouse :</h2>
                <p class="text-justify textPara">
                    یکی دیگر از تب&zwnj;هایی که در مرورگر Google Chrome موجود است، تب <span class="engliSpan">Lighthouse</span> است. در صورت <span class="farsiSpan">اتصال</span> به اینترنت، این تب سایت مورد نظر را بررسی می&zwnj;کند و با زدن دکمه <span class="engliSpan">Generate Report</span> گزارشی از وضعیت سایت در زمینه&zwnj;هایی چون <span class="engliSpan">performance</span> ، <span class="engliSpan">accessibility</span> ، <span class="engliSpan">best practices</span> و <span class="engliSpan">SEO</span> در اختیار می&zwnj;گذارد. همچنین در قسمت&zwnj;هایی که سایت مورد نظر عملکرد <span class="farsiSpan">ضعیفی</span> داشته باشد، راه حل&zwnj;هایی را نیز پیشنهاد می&zwnj;کند. در ادامه نحوه تولید اطلاعات برای یک سایت مفروض را مشاهده می&zwnj;کنید:
                </p>

                <!-- imgs (png,jpg or gif) that are not related to codes -->
                <div class="col-md-10 mt-5 mx-auto">
                        <img class="img-fluid mx-auto d-block img-thumbnail shadow-lg" src="./imgFolder/lighthouse.gif" alt="تصویر مربوط به نحوه کارکرد تب lighthouse">
                </div>

                <!-- conclusion part -->
                <h2 class="subTitle pb-3 mt-5">جمع بندی: </h2>
                <blockquote class="blockquote conclu">
                    <p class="text-justify textPara pt-3">
                        در این مقاله به برخی ابزارها و ویژگی&zwnj;هایی که در مرورگرهای رایج برای <strong>بررسی بهتر</strong> وضعیت سایت در اختیار توسعه&zwnj;دهندگان قرار گرفته است، اشاره شد. گرچه قابلیت&zwnj;ها و ویژگی&zwnj;های مرورگرها فراتر از موارد ذکر شده است، اما در این مقاله به همین مقدار بسنده می&zwnj;شود. هر یک از تب&zwnj;هایی که در اثر inspect گرفتن از یک وب&zwnj;سایت مشاهده می&zwnj;شود، <span class="farsiSpan">کارایی خاص</span> خود را دارند که اکثر توسعه&zwnj;دهندگان به منظور بررسی و بهتر کردن کدهای خود، معمولا با توجه به نیاز خود و به مرور زمان با این تب&zwnj;ها آشنایی پیدا می&zwnj;کنند.
                    </p>
                </blockquote>

            </div>



            <!-- ways number 1 of loading imgs  -->
            <!--
            <img :src='`${this.publicPath}articles/${this.$route.params.id}/first.png`' alt="تصویر نمونه">
-->

                <!-- ways number 2 of loading imgs  -->
                <!--
               <img src='./imgFolder/img1.png' alt="تصویر نمونه">
               -->
        </div>
</template>

<script>
import BaseList from '../../BaseList.vue';

export default {
                name: "BaseArticle",
                components: {
                    BaseList
                },
                data() {
                    return {
                        listText: [
                            /* this is the data used for BaseList.vue. the "strongText" data is optional and may be present in some list and not in other lists. */
                            {
                                icon: "fad fa-angle-left",
                                strongText: "is:from-cache ",
                                liText: "تمامی فایل&zwnj;ها و درخواست&zwnj;هایی را که از طریق <span class = engliSpan>cache</span> مرورگر صورت گرفته است، نشان می&zwnj;دهد. این درخواست&zwnj;ها معمولاً با کد 304 نشان داده می&zwnj;شوند. "
                            },

                            {
                                icon: "fad fa-angle-left",
                                strongText: "is:running ",
                                liText: "درخواست&zwnj;هایی را که کامل نشده یا <span class = farsiSpan >بی پاسخ</span> مانده است، نشان می&zwnj;دهد."
                            },

                            {
                                icon: "fad fa-angle-left",
                                strongText: "larger-than:<span class = engl>25KB</span> ",
                                liText: "درخواست&zwnj;ها یا فایل&zwnj;هایی را که حجم بیشتر از <span class = engliSpan>25KB</span> دارند، گزینش می&zwnj;کند و نشان می&zwnj;دهد."
                            },

                            {
                                icon: "fad fa-angle-left",
                                strongText: "method:get ",
                                liText: "تمامی درخواست&zwnj;هایی را که از طریق متود GET به سمت سرور ارسال شده باشد، نمایش می&zwnj;دهد."
                            },

                            {
                                icon: "fad fa-angle-left",
                                strongText: "mime-type ",
                                liText: "این فیلتر در واقع نوع فایل&zwnj;هایی را که در درخواست&zwnj;ها وجود دارد، نشان می&zwnj;دهد. به عنوان مثال چنانچه <span class = engliSpan>mime-type:image/jpeg</span> را تایپ کنید، تمامی تصاویر با پسوند jpg نمایش داده می&zwnj;شوند و بقیه <span class = farsiSpan>فیلتر</span> می&zwnj;شوند."
                            }

                        ],  // end of liText

                    }
                }, // end of data

        }
</script>

<style scoped></style>
