<template>
<!-- This is the component that is used to show "radio-buttons" in the site, but with special design that we made and also the functionality to send data selected to the parent component to send for example to the back-end of the site. -->
    <div>
        <section
            id="radioPar"
            class="col-12 mx-auto p-3 my-3 my-sm-5"
        >
            <p class="text-center mt-3">
                <slot></slot>
            </p>
            <div class="rarioWrapper">

                <div
                    v-for = "item in itemOptions"
                    :key="item.value"
                    class="option text-center my-3"
                >
                    <input
                        type="radio"
                        :id="`radio${item.value}`"
                        :name="radioName"
                        :value="item.value"
                        v-model="defaultPick"
                        @change="changeFunc"
                    >
                    <label :for="`radio${item.value}`">
                        {{ item.label }}
                    </label>
                </div>

            </div>
        </section>
    </div>
</template>

<script>

        export default {

                name: "BaseRadio",

                data() {
                    return {
                        picked: ""
                    }
                }, // end of data


                computed: {
                    defaultPick:{

                        // getter
                        get: function() {
                             if (this.checkVal == "nparam") {
                                return ""
                            } else {
                                return this.itemOptions[0].value;
                            }
                        },
                        // setter
                        set: function(valRadio) {
                            this.picked = valRadio;
                            return valRadio;
                        }

                    }

                }, // end of computed data

                props: {
                    radioName: String, /* This prop is used to set the name of radio inputs */
                    /* ------------------ */
                    itemOptions: Array, /* This prop generate the number of radio inputs and also the data needed for them like the label and .... */
                    /* ------------------ */
                    checkVal: String /* This prop is used to determine at the begin of loading the page if any radio must be checked or not. */
                }, // end of props


                methods: {
                    changeFunc: function () {
                        /* This function sends the selected radio input to the parent component. */
                        this.$emit('changeRadio', this.picked);
                    }
                }


        }
</script>

<style scoped src="../assets/assets-CSS/BaseRadio.css"></style>
