<template>
    <!-- for article id = 11 -->
        <div class="col-md-10 mx-auto">

            <div class="text-right mt-5">

                <!-- **************** -->
                <!-- intro text -->
                <!-- **************** -->
                <h2 class="subTitle">مقدمه: </h2>
                <blockquote class="blockquote">
                    <p class="text-justify textPara">
                        <span class="engliSpan">Tooltip</span> ها یا همان توضیحات مختصری را که در برخی وب&zwnj;سایت&zwnj;ها یا نرم&zwnj;افزارها مشاهده می&zwnj;کنیم، می&zwnj;توان این&zwnj;گونه تعریف کرد:
                    </p>

                    <blockquote class="blockquote mainPart text-center pr-3">
                        پیام&zwnj;های مختصری که در <strong>اثر ارتباط</strong> کاربر با المان&zwnj;های وب&zwnj;سایت یا نرم&zwnj;افزار برای ارائه توضیح یا اطلاعات اضافه&zwnj;تر در خصوص آن المان به نمایش در می&zwnj;آیند.
                    </blockquote>

                    <p class="text-justify textPara">
                        به عنوان مثال وقتی کاربر بر روی یک آیکون در سایتی کلیک می&zwnj;کند یا موس را بر روی آن می&zwnj;برد (اصطلاحاً آن المان <span class="engliSpan">hover</span> می&zwnj;شود)، پیامی در کنار آیکون به نمایش در می&zwnj;آید که توضیح اضافه&zwnj;تر در خصوص کاربرد آن بخش سایت به کاربر ارائه می&zwnj;کند.
                    </p>

                    <div class="col-md-10 mt-5 mx-auto">
                        <img class="img-fluid mx-auto d-block img-thumbnail shadow-lg" src="./imgFolder/img-1.png" alt="تصویر مربوط به نمایش tooltip هنگام رفتن موس بر روی آیکون">
                    </div>

                    <p class="text-justify textPara mt-5 pb-5">
                        <span class="engliSpan">Tooltip</span> ها معمولا به صورت پیش فرض نمایش داده <strong>نمی شوند</strong> و تنها در صورتی که کاربر سایت <span class="farsiSpan">اقدامی</span> انجام دهد (مثلاً روی دکمه&zwnj;ای کلیک کند و ...)، به نمایش در می&zwnj;آیند. گرچه استفاده از آنها و به کارگیری آنها در طراحی وب&zwnj;سایت چندان <span class="farsiSpan">پیچیده</span> به نظر نمی&zwnj;آید، اما گاهی اوقات در برخی سایت&zwnj;ها یا نرم افزارها شاهد استفاده نامناسب از این ابزار هستیم. در این مقاله بر آن شدیم تا برخی نکات ضروری و راهنمایی&zwnj;هایی را که باید در به کارگیری tooltip ها به آنها توجه داشته باشیم، بررسی نماییم.
                    </p>
                </blockquote>

                <!-- **************** -->
                <!-- part one -->
                <!-- **************** -->
                <h2 class="subTitle mt-5">نکته اول: از tooltip در خصوص اطلاعاتی که دانستن آنها برای کاربر ضروری و لازم است، استفاده نشود</h2>
                <p class="text-justify textPara">
                    از آنجایی&zwnj;که ماهیت tooltip به این صورت است که تا زمانی که کاربر اقدامی نکند (مثلا موس را روی المانی نبرد) پیام مربوط به آن ظاهر نمی&zwnj;شود، بهتر آن است که از tooltip برای اطلاعات <strong>ضروری</strong> کاربر که بدون دانستن آن، فرد دچار سردرگمی می&zwnj;شود، استفاده <strong>نشود</strong>. چرا که در آن صورت کاربران <span class="farsiSpan">جدید</span> ممکن است به وجود tooltip پی نبرند و کاربران قبلی سایت نیز باید روند دستیابی به آن اطلاعات را به حافظه بسپارند که این امر از لحاظ UX (<span class="engliSpan">User Experience</span>) مناسب <span class="farsiSpan">نیست</span>.
                </p>

                <p class="text-justify textPara">
                    به عنوان نمونه <span class="farsiSpan">نامناسب</span> در شکل زیر مشاهده می&zwnj;شود که وقتی کاربر موس را روی کلمه <span class="engliSpan">password</span> در بالای <span class="engliSpan">input</span> می&zwnj;برد، شرایطی که برای صحیح بودن password ضروری است، نشان داده می&zwnj;شود:
                </p>

                <div class="col-md-10 mt-5 mx-auto">
                        <img class="img-fluid mx-auto d-block img-thumbnail shadow-lg" src="./imgFolder/password_wrong.gif" alt="تصویر مربوط به استفاده نامناسب tooltip در نمایش اطلاعات ضروری کاربر">
                </div>

                <p class="text-justify textPara mt-5">
                    در حالی که نمونه <span class="farsiSpan">صحیح</span> آن به این شکل است که نیازی به استفاده از tooltip ندارد. چرا که این اطلاعات از جمله اطلاعاتی است که کاربر برای انجام کار خود به شکل صحیح به آن نیاز دارد و در واقع توضیح <span class="farsiSpan">اضافه&zwnj;تر</span> محسوب نمی شود.
                </p>

                <div class="col-md-10 mt-5 mx-auto">
                        <img class="img-fluid mx-auto d-block img-thumbnail shadow-lg" src="./imgFolder/password_correct.png" alt="تصویر مربوط به عدم استفاده از tooltip در خصوص اطلاعات ضروری کاربر">
                </div>

                <!-- **************** -->
                <!-- part two -->
                <!-- **************** -->
                <h2 class="subTitle mt-5">نکته دوم: از tooltip به شکل مختصر و مفید استفاده شود</h2>
                <p class="text-justify textPara">
                    همانگونه که از نام tooltip مشخص است، این ابزار برای اطلاعات <span class="farsiSpan">کوتاه</span> و <span class="farsiSpan">سودمند</span> به کار می&zwnj;رود. اطلاعات طولانی، غیر مرتبط یا واضح و مشخص نباید در tooltip ها قرار داده شوند. برای مثال وقتی در یک سایت لینکی به صفحه دیگر موجود است و برای این لینک نیز از متن مناسب استفاده شده است، دیگر چندان نیازی به tooltip برای توضیح در خصوص این لینک احساس <span class="farsiSpan">نمی&zwnj;شود</span>:
                </p>

                <div class="col-md-10 mt-5 mx-auto">
                        <img class="img-fluid mx-auto d-block img-thumbnail shadow-lg" src="./imgFolder/brief-wrong.gif" alt="تصویر مربوط به استفاده نامناسب tooltip در نمایش اطلاعات به صورت مختصر و مفید">
                </div>

                <p class="text-justify textPara mt-5">
                    چنانچه بخواهیم نمونه فوق را به شکل درست&zwnj;تری پیاده سازی نماییم، شاید مورد زیر بتواند گزینه <span class="farsiSpan">مناسبی</span> باشد:
                </p>

                <div class="col-md-10 mt-5 mx-auto">
                        <img class="img-fluid mx-auto d-block img-thumbnail shadow-lg" src="./imgFolder/brief-correct.gif" alt="تصویر مربوط به استفاده درست tooltip در نمایش اطلاعات به صورت مختصر و مفید">
                </div>

                <p class="text-justify textPara mt-5">
                    در این حالت چون در گزینه&zwnj;ها از آیکون به جای متن استفاده شده است، ممکن است برای کاربر عملکرد آن گزینه چندان واضح نباشد و لذا استفاده از tooltip مناسب به نظر می&zwnj;آید.
                </p>

                <!-- **************** -->
                <!-- part three -->
                <!-- **************** -->
                <h2 class="subTitle mt-5">نکته سوم: پشتیبانی از هر دو مورد موس و کیبورد</h2>
                <p class="text-justify textPara">
                    گاهی اوقات در سایت&zwnj;ها دیده می&zwnj;شود که وقتی کاربر با <span class="farsiSpan">موس</span> بر روی گزینه&zwnj;ای می&zwnj;رود (اصطلاحاً hover انجام می&zwnj;دهد)، tooltip مورد نظر ظاهر می&zwnj;شود. اما اگر با دکمه <span class="engliSpan">Tab</span> در کیبورد خود بخواهد گزینه&zwnj;ها را بررسی کند، این tooltip ها ظاهر نمی&zwnj;شوند. برای دسترسی بهتر کاربران (با توجه به اینکه کاربران ممکن است دارای محدودیت&zwnj;های جسمی باشند) مناسب است که <strong>هر دو حالت</strong> استفاده از موس و کیبورد برای tooltip ها مدنظر قرار گرفته باشد.
                </p>

                <!-- showing code imgs in tabs -->
                <BaseTab
                    :head-tabs = "tabDatas"
                    @indexSend = "loadTabContent($event)"
                >
                    <template>
                        <div class="col-md-10 mt-5 mx-auto code-img">
                            <img class="img-fluid img-thumbnail" :src="require(`./imgFolder/${tabDatas[activeTab].data.imgName}`)" :alt="`${tabDatas[activeTab].data.altText}`">
                        </div>
                    </template>

                </BaseTab>

                <!-- **************** -->
                <!-- part four -->
                <!-- **************** -->
                <h2 class="subTitle mt-5">نکته چهارم: ایجاد تضاد رنگی بین متن و پس زمینه</h2>
                <!-- paragraphs -->
                <p class="text-justify textPara">
                    از آنجایی که tooltip ها معمولا با کلیک یا hover کردن روی المان&zwnj;های سایت به نمایش در می&zwnj;آیند، باید <span class="farsiSpan">تضاد رنگی مناسبی</span> بین متن آنها و رنگ پس&zwnj;زمینه وجود داشته باشد تا کاربران (به ویژه افرادی که از نظر بینایی محدودیت&zwnj;هایی دارند) بتوانند به آسانی آنها را تشخیص دهند.
                </p>

                <BaseTab
                    :head-tabs = "tabDatas2"
                    @indexSend = "loadTabContent2($event)"
                >
                    <template>
                        <div class="col-md-10 mt-5 mx-auto code-img">
                            <img class="img-fluid img-thumbnail" :src="require(`./imgFolder/${tabDatas2[activeTab2].data.imgName}`)" :alt="`${tabDatas2[activeTab2].data.altText}`">
                        </div>
                    </template>

                </BaseTab>

                <!-- **************** -->
                <!-- part five -->
                <!-- **************** -->
                <h2 class="subTitle mt-5">نکته پنجم: قرار دادن tooltip در مکان مناسب</h2>
                <!-- paragraphs -->
                <p class="text-justify textPara">
                    مسئله دیگری که در طراحی tooltip باید در نظر گرفته شود، <span class="farsiSpan">محل قرارگیری</span> آن است. به این معنا که وقتی کاربر موس را بر روی المان مورد نظر می&zwnj;برد و tooltip ظاهر می&zwnj;شود، متن و ابعاد آن نباید سایر محتویات سایت را محو کند و به عبارتی در جلوی آنها قرار بگیرد. چرا که در این صورت کاربر هر بار مجبور است برای مشاهده اطلاعات tooltip موس را روی المان مربوطه برده و سپس برای مشاهده اطلاعات سایت، موس را بردارد و این مسئله از لحاظ تجربه کاربری (UX) مناسب نیست. لذا بهتر است که در هنگام طراحی tooltip آن را امتحان کنیم تا نسبت به محل قرارگیری مناسب آن <span class="farsiSpan">مطمئن</span> شویم.
                </p>

                <BaseTab
                    :head-tabs = "tabDatas3"
                    @indexSend = "loadTabContent3($event)"
                >
                    <template>
                        <div class="col-md-10 mt-5 mx-auto code-img">
                            <img class="img-fluid img-thumbnail" :src="require(`./imgFolder/${tabDatas3[activeTab3].data.imgName}`)" :alt="`${tabDatas3[activeTab3].data.altText}`">
                        </div>
                    </template>

                </BaseTab>


                <!-- **************** -->
                <!-- conclusion part -->
                <!-- **************** -->
                <h2 class="subTitle pb-3 mt-5">جمع بندی: </h2>
                <blockquote class="blockquote conclu">
                    <p class="text-justify textPara pt-3">
                        در این مقاله سعی شد تا به برخی نکات در استفاده از tooltip اشاره شود. tooltip به عنوان ابزاری که قرار است راهنمایی&zwnj;هایی را در اختیار کاربران سایت قرار دهد، امروزه استفاده&zwnj;های رایج و متداولی در وب&zwnj;سایت&zwnj;ها دارد. نکته دارای اهمیت آن است که طراحان و توسعه&zwnj;دهندگان وب&zwnj;سایت&zwnj;ها بتوانند از این ابزار در <span class="farsiSpan">جای درست</span> و به <span class="farsiSpan">شکل مناسب</span> بهره گیرند تا ضمن راهنمایی کاربران سایت، طراحی مناسبی را نیز پیاده&zwnj;سازی کرده باشند.
                    </p>
                </blockquote>

            </div>

        </div>
</template>

<script>
import BaseTab from '../../BaseTab.vue';

export default {
                name: "BaseArticle",
                components: {
                    BaseTab
                },
                /* ----------------------------- */
                data() {
                    return {
                        activeTab: 0,
                        activeTab2: 0,
                        activeTab3: 0,

                        /* data for mouse and keyboard support */
                        tabDatas: [
                            {
                                tabHeders: "پشتیبانی از موس و کیبورد",
                                data:{
                                    imgName: "keboard.gif",
                                    altText: "حالت صحیح پشتیبانی از موس و کیبورد"
                                }
                            },

                            {
                                tabHeders: "پشتیبانی فقط از موس",
                                data:{
                                    imgName: "brief-correct.gif",
                                    altText: "حالت نامناسب مربوط به پشتیبانی فقط از موس"
                                }
                            }

                        ], /* end of data for mouse and keyboard support */

                        /* data for contrast support */
                        tabDatas2: [
                            {
                                tabHeders: "تضاد رنگی مناسب",
                                data:{
                                    imgName: "contrast2.png",
                                    altText: "تصویر مربوط به تضاد رنگی مناسب در tooltip"
                                }
                            },

                            {
                                tabHeders: "تضاد رنگی نامناسب",
                                data:{
                                    imgName: "contrast1.png",
                                    altText: "تصویر مربوط به تضاد رنگی نامناسب در tooltip"
                                }
                            }

                        ], /* end of data for contrast support */

                        /* data for correct placement */
                        tabDatas3: [
                            {
                                tabHeders: "محل قرارگیری مناسب",
                                data:{
                                    imgName: "placement-correct.gif",
                                    altText: "تصویر مربوط به محل قرارگیری مناسب tooltip"
                                }
                            },

                            {
                                tabHeders: "محل قرارگیری نامناسب",
                                data:{
                                    imgName: "placement-wrong.gif",
                                    altText: "تصویر مربوط به محل قرارگیری نامناسب tooltip"
                                }
                            }

                        ], /* end of data for correct placement */
                        
                        publicPath: process.env.BASE_URL,
                    }
                },
                /* ----------------------------- */
                methods: {
                    loadTabContent: function(indexNow) {
                        this.activeTab = indexNow;
                    },

                    loadTabContent2: function(indexNow) {
                        this.activeTab2 = indexNow;
                    },

                     loadTabContent3: function(indexNow) {
                        this.activeTab3 = indexNow;
                    }
                } /* end of methods */


        }
</script>

<style scoped></style>

