<template>
<!-- This is the component that is used to show information related to each sample work in "Samples.vue" view. It uses "named slot" to separate different part of the information. -->
    <section class="container-fluid">
      <div class="row">
        <div class="col-md-10 mx-auto col-sm-8 col-12 containPar">

            <div class="card shadow-lg">
              <div class="row no-gutters">

                <div class="col-md-7">
                  <div class="card-body">
                    <h2 class="card-title text-center nazOutClass pb-4">
                      <slot name="headText"></slot>
                    </h2>
                    <p class="card-text text-center">
                      <slot name="mainText"></slot>
                    </p>
                    <p class="card-text text-left">
                      <strong class="text-right mb-2">تکنولوژی های استفاده شده:</strong>
                      <slot name="techno"></slot>
                    </p>
                  </div>
                  <div class="card-footer btnParFoot">
                    <div class="d-flex justify-content-center align-items-center">
                      <BaseButton
                          type-prop="button"
                          color2-prop
                          linked="true"
                          targetProp="_blank"
                          :link-ref="linkBtn"
                          text="مشاهده آنلاین"
                      >
                      </BaseButton>
                    </div>
                  </div>
                </div>

                <div class="col-md-5 col-8 mx-auto my-2 d-flex justify-content-center align-items-center px-3">

                  <img
                      :src="imgPath"
                      alt="تصویر نمونه کار"
                      class="card-img img-fluid"
                  >

                </div>

              </div> <!-- end of inner "row" -->
            </div> <!-- end of "card" -->

        </div> <!-- end of ".containPar" -->
      </div> <!-- end of outer "row" -->

    </section>
</template>

<script>
    import BaseButton from "./BaseButton";
    export default {
        name: "ShowSample",
        components: {BaseButton},
        data() {
            return {
                imgPath: this.imgSrc
            }
        },
        props: {
            linkBtn: String,  /* link to the url of sample work. */
            imgSrc: String /* link to the address of img source */
        }
    }
</script>

<style scoped src="../assets/assets-CSS/showSample.css"></style>
