import { render, staticRenderFns } from "./OneButton.vue?vue&type=template&id=6585a264&scoped=true&"
import script from "./OneButton.vue?vue&type=script&lang=js&"
export * from "./OneButton.vue?vue&type=script&lang=js&"
import style0 from "../assets/assets-CSS/buttons.css?vue&type=style&index=0&id=6585a264&scoped=true&lang=css&"
import style1 from "./OneButton.vue?vue&type=style&index=1&module=true&lang=css&"




function injectStyles (context) {
  
  this["$style"] = (style1.locals || style1)

}

/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "6585a264",
  null
  
)

export default component.exports