<template>
<!-- This page(view) is used to show some information about me. It contains some parts like "an intro text", "a brief description", "some of my skills" and "some certification that I have". At the end of the page there is a button that used for downloading "pdf" file of my resume.  -->
  <div class="about">
    <div class="container-fluid">
      <div class="row">
        <!-- ******************* -->
        <!-- "an intro text" part -->
        <div class="col-md-12">
          <div class="introMess d-flex flex-sm-row flex-column align-items-center justify-content-between py-sm-0 py-2 px-2">
            <h2>سلام</h2>
            <h2>من حمید داودی هستم.</h2>
            <h2>طراح و توسعه دهنده وب سایت</h2>
          </div>
        </div>
        <!-- ******************* -->
        <!-- "a brief description" part -->
        <div class="col-md-12">
          <section class="col-md-9 col-sm-10 col-12 mx-auto pt-2">
            <h2 class="titleAbout text-right">معرفی کوتاه</h2>
            <p class="text-right p-3 p-sm-5 paraMe shadow-lg rounded border border-primary">
              همیشه حل کردن معماها و مسائل ریاضی برایم جالب بود. لذا وارد رشته مهندسی شدم. اما بعد از گذشت سالها متوجه شدم که حل کردن مسائل ریاضی بدون اینکه مشکلی از دیگران حل کند، فایده ای نخواهد داشت. لذا تصمیم گرفتم به سراغ طراحی وب بروم و مهارت های خود در حل مسئله و برنامه نویسی را در این حیطه و کد نویسی برای آن به کار بگیرم. اما نه کدهایی که صرفا یک مسئله ریاضی را حل کنند و بعد هم کنار گذاشته شوند، بلکه کدهایی که بتوانند مشکلی را حل کنند و دیده شوند. در این حیطه از طراحی یک وب سایت تک صفحه ای تا کار با فریم ورک های وب مانند vue و طراحی پایگاه داده و ... همگی از جمله مهارتهایی هستند که کم یا زیاد در آنها تجربه و آمادگی کسب نمودم و می توانند در امر ساخت و توسعه وب سایت و نرم افزارهای وب سودمند باشند.
            </p>
          </section>
        </div>
        <DividerIcon :divider-title="dataDivi"></DividerIcon>
        <!-- ******************* -->
        <!-- "some of my skills" part -->
        <div class="progPar col-md-12 mt-4">
          <section class="py-2 px-4">
            <div class="row">
            <div class="col-10 col-sm-4 mx-auto p-4 my-3 bg-temp even">

              <div dir="ltr" class="skill">
                <div class="skillBar mt-5 mx-auto">
                  <div class="skillPer" data-per="90"></div>
                </div>
                <div class="skillName mt-2 text-center">CSS</div>
              </div>

            </div>

            <div class="col-10 col-sm-4 mx-auto p-4 my-3 bg-temp">

              <div dir="ltr" class="skill">
                <div class="skillBar mt-5 mx-auto">
                  <div class="skillPer" data-per="85"></div>
                </div>
                <div class="skillName mt-2 text-center">bootstrap</div>
              </div>

            </div>

            <div class="col-10 col-sm-4 mx-auto p-4 bg-temp my-3 even">
              <div dir="ltr" class="skill">
                <div class="skillBar mt-5 mx-auto">
                  <div class="skillPer" data-per="75"></div>
                </div>
                <div class="skillName mt-2 text-center">JS</div>
              </div>

            </div>
            </div> <!-- end of row -->

            <div class="row">
              <div class="col-10 col-sm-4 mx-auto p-4 my-3 bg-temp">

                <div dir="ltr" class="skill">
                  <div class="skillBar mt-5 mx-auto">
                    <div class="skillPer" data-per="65"></div>
                  </div>
                  <div class="skillName mt-2 text-center">Vue</div>
                </div>

              </div>

              <div class="col-10 col-sm-4 mx-auto p-4 my-3 bg-temp even">

                <div dir="ltr" class="skill">
                  <div class="skillBar mt-5 mx-auto">
                    <div class="skillPer" data-per="75"></div>
                  </div>
                  <div class="skillName mt-2 text-center">jQuery</div>
                </div>

              </div>

              <div class="col-10 col-sm-4 mx-auto p-4 my-3 bg-temp">

                <div dir="ltr" class="skill">
                  <div class="skillBar mt-5 mx-auto">
                    <div class="skillPer" data-per="70"></div>
                  </div>
                  <div class="skillName mt-2 text-center">MySQL</div>
                </div>

              </div>
            </div> <!-- end of row -->

            <div class="row">
              <div class="col-10 col-sm-4 mx-auto p-4 my-3 bg-temp even">

                <div dir="ltr" class="skill">
                  <div class="skillBar mt-5 mx-auto">
                    <div class="skillPer" data-per="70"></div>
                  </div>
                  <div class="skillName mt-2 text-center">PHP</div>
                </div>

              </div>

              <div class="col-10 col-sm-4 mx-auto p-4 my-3 bg-temp">

                <div dir="ltr" class="skill">
                  <div class="skillBar mt-5 mx-auto">
                    <div class="skillPer" data-per="60"></div>
                  </div>
                  <div class="skillName mt-2 text-center">Node</div>
                </div>

              </div>

              <div class="col-10 col-sm-4 mx-auto p-4 my-3 bg-temp even">

                <div dir="ltr" class="skill">
                  <div class="skillBar mt-5 mx-auto">
                    <div class="skillPer" data-per="55"></div>
                  </div>
                  <div class="skillName mt-2 text-center">express</div>
                </div>

              </div>
            </div> <!-- end of row -->

          </section>
        </div>

        <div class="col-md-12">
        <DividerIcon :divider-title="dataCert"></DividerIcon>
        </div>
        <!-- ******************* -->
        <!-- "some certification that I have" part -->
        <div class="certiImgPar col-md-12">
          <ImgContainer :img-info="dataImg"></ImgContainer>
        </div>
        <!-- ******************* -->
        <!-- "downloading "pdf" file of my resume" part -->
        <OneButton></OneButton>

      </div>
    </div>
  </div>
</template>

<script>
/* importing components needed for this page */
/* ----------------------------- */
import DividerIcon from "../components/DividerIcon";
import ImgContainer from "../components/ImgContainer";
import OneButton from "../components/OneButton";
export default {
  metaInfo: {
    title: "درباره من",
  },
    
  data: () => ({
    error: '',
    messages: [],
      /* "theSet" is used for clearing interval */
    theSet: null,
      /* "dataDivi" is the necessary information for divider component */
    dataDivi: {
        title: "مهارتها در زمینه وب",
        icon: "far fa-badge-check"
    },
      /* "dataCert" is the necessary information for another divider component */
    dataCert: {
        title: "گواهی نامه های اخذ شده",
        icon: "far fa-id-card"
    },
      /* "dataImg" is the information needed for "ImgContainer" component that will be replaced */
      dataImg: {
          srcImg: require("../assets/img/certificates/udemyCerti.png"),
          srcAlt: "مدرک دوره یودمی",
          sader: "یودمی",
          title: "مفاهیم اولیه طراحی و توسعه پایگاه داده",
          mohtava: ["آشنایی با مفاهیم پایگاه داده", "کار با پایگاه داده mysql", "آشنایی با query های مربوط به داده ها"],
          timeMad: "پاییز 99"
      },
    dataImg1: {
        srcImg: require("../assets/img/certificates/udemyCerti.png"),
        srcAlt: "مدرک دوره یودمی",
        sader: "یودمی",
        title: "مفاهیم اولیه طراحی و توسعه پایگاه داده",
        mohtava: ["آشنایی با مفاهیم پایگاه داده", "کار با پایگاه داده mysql", "آشنایی با query های مربوط به داده ها"],
        timeMad: "پاییز 99"
    },
    dataImg2: {
        srcImg: require("../assets/img/certificates/photoshopCerti.png"),
        srcAlt: "مدرک دوره فتوشاپ",
        sader: "مجتمع فنی تهران",
        title: "فتوشاپ و ایلاستریتور",
        mohtava: ["یادگیری نرم افزار فتوشاپ", "یادگیری نرم افزار ایلاستریتور", "تمرینات مربوط به کار با نرم افزارها"],
        timeMad: "بهار 97"
    },
    dataImg3: {
        srcImg: require("../assets/img/certificates/webCerti.png"),
        srcAlt: "مدرک دوره طراحی وب",
        sader: "مجتمع فنی تهران",
        title: "پکیج طراحی وب",
        mohtava: ["یادگیری زبان HTML", "یادگیری CSS و بوتسترپ", "یادگیری جاوا اسکریپت و تمرینات آن"],
        timeMad: "پاییز 99"
    }
  }),
  /* ----------------------------- */
  components: {
    DividerIcon,
    ImgContainer,
    OneButton
  },
  /* ----------------------------- */
  methods: {
      displayAnimat: function () {
          /* This function at first detect the window scrolling to see when the user reaches to ".introMess" element. Then it uses some animation to show the text. */
          $(window).scroll(function() {
              if ( $(".introMess").length > 0 ) {
                  let offscroll = $(".introMess").offset().top;
                  let scroll = $(window).scrollTop();

                  if ( (offscroll - scroll) < 125 && (offscroll - scroll) > -125 ) {
                      $(".introMess").addClass("showIntro");

                      $(".introMess h2:nth-child(1)").addClass("animate__animated animate__fadeInRight");

                      $(".introMess h2:nth-child(2)").addClass("animate__animated animate__fadeInUp animate__delay-2s");

                      $(".introMess h2:nth-child(3)").addClass("animate__animated animate__fadeInLeft animate__delay-4s");
                  }
              } // end of if
          }); // end of "$(window)"
      },
      // end of "displayAnimat" ---------------------

      progressBar: function() {
          /* This function uses "animate" method of "jQuery" to give progressing effect to the "progress bars" that are showing the level of my skills. */
          let sarib = 0;
          let tekrarPar = 7; /* this parameter is used to do only "once" the function on window.scroll */
          window.onscroll = function() {
              if ( $(".progPar").length > 0 ) {
                  let offscroll = $(".progPar").offset().top;
                  let scroll = $(window).scrollTop();
                  /* second if */
                if ( (offscroll - scroll) < 155 && (offscroll - scroll) > -155 && tekrarPar === 7 ) {
                      let skillVar = $(".bg-temp");
                      skillVar.each(function (index, elem) {
                          sarib += 1;
                          let elemWid = $(elem).children(".skill").children(".skillBar").children(".skillPer");
                          let attrThis = elemWid.attr("data-per");
                          $(elem).fadeIn(500*sarib, function () {

                              $(elemWid).css("width", attrThis + "%" );
                              $({animValue:0}).animate(
                                  {
                                      animValue: attrThis},
                                  {
                                      duration: 1000,
                                      step: function(){
                                          elemWid.attr("data-per", Math.floor(this.animValue) + "%");
                                      },
                                      complete: function () {
                                          elemWid.attr("data-per", Math.floor(this.animValue) + "%");
                                      }
                                  }
                              );
                         }); // end of fade-in
                      }); // end of "each"
                      tekrarPar = 8;
                } // end of second if
            } // end of if
          }; // end of window scroll;

      },
      // end of "progressBar" ---------------------

      certiFade: function() {
          /* This function is responsible to make fading effect to "certification images and texts". It does that work with the help of "setInterval" js functionality and changing the value of "numImg" variable. */
          let vuasli = this;
          let vuInc = this.dataImg;
          vuasli.theSet = setInterval(funcFade, 7000);
          let numTotal = 3;
          let numImg = 1; // the number of certifications
          let vuRep = eval("vuasli."+ "dataImg" + numImg);
          let property1;
          /* the function that is called in "setInterval" */
          function funcFade() {
              $(".certiImgPar .img-fluid").removeClass("animate__animated animate__bounceInUp");
              $(".certiImgPar").fadeOut(700, function () {
                  for (property1 in vuInc) {
                      vuInc[property1] = vuRep[property1];
                  }
                  $(".certiImgPar .img-fluid").addClass("animate__animated animate__bounceInUp");
              }).fadeIn(700);
              numImg++;
              if(numImg > numTotal) {
                  numImg = 1;
              }
              vuRep = eval("vuasli."+ "dataImg" + numImg);
          } // end of "funcFade" function
      },
      // end of "certiFade" ---------------------
  },
  /* ----------------------------- */
  beforeDestroy () {
      /* This is necessary to clear "setInterval" functionality in "certiFade" method when the user routes between different pages of the site. */
      clearInterval(this.theSet);
  },
  /* ----------------------------- */
  mounted() {
      this.displayAnimat();
      this.progressBar();
      this.certiFade();
  }
};
</script>

<style scoped src="../assets/assets-CSS/about.css"></style>
