<template>
<!-- This component is used to show some preview information about each article in the "Articles.vue" page. It supports "title", "some intro text", "button for sending user to article page" and "an image" of the article. -->
        <div class="col-md-12">
            <div class="card my-3 p-2 parentCard">
                <div class="row no-gutters">

                    <div class="col-sm-7 d-flex align-items-center justify-content-center">
                    <!-- title and some brief text from article -->
                        <div class="card-body">
                            <h5 class="card-title text-center mb-4">
                                {{ infoArt.title }}
                            </h5>
                            <p class="card-text text-center">
                                {{ infoArt.head_text }}
                            </p>
                        </div>

                    </div>

                    <div class="col-sm-5 d-flex align-items-center justify-content-center">
                    <!-- preview image of article comes from "public" directory -->
                        <img
                            :src='`${publicPath}articles/${infoArt.id}/first.png`'
                            class="card-img px-3 px-sm-0"
                            alt="تصویر پیش نمایش مقاله"
                        >

                    </div>

                </div>

                <section class="row no-gutters my-2 secondRow">

                        <div class="col-xl-7 col-sm-8 my-4 my-sm-auto">
                            <div class="d-flex align-items-center justify-content-around flex-wrap badgePar">
                            <!-- tags comes here -->
                                <span v-for="tag in tags" class="badge my-2 p-2">
                                    {{ tag.tag_name }}
                                </span>

                            </div>
                        </div>

                        <div class="col-xl-5 col-sm-4">
                            <div class="d-flex align-items-center justify-content-around">
                            <!-- button for sending user to article page -->
                                <BaseButton
                                    @routing="route_call"
                                    type-prop="button"
                                    linked="false"
                                    text="مطالعه مقاله"
                                >
                                </BaseButton>

                            </div>
                        </div>
                </section>
            </div>
        </div>
</template>

<script>
import BaseButton from '../components/BaseButton.vue';
        export default {
                name: "ArticlePrev",
                /* ----------------------- */
                components: {
                    BaseButton
                },
                /* ----------------------- */
                data() {
                    return {
                        publicPath: process.env.BASE_URL,
                    }
                },
                /* ----------------------- */
                props: {
                    tags: Array, /* contains tag names related to the article */
                    infoArt: Object /* contains other info related to article. like "article id" */
                },
                /* ----------------------- */
                methods: {
                    route_call: function() {
                        /* This function is responsible to send user to the article page, it does this task with the help of "dynamic routing" of Vue-js. */
                        this.$router.push({ name: 'ArticlesId', params: { id: this.infoArt.id } });
                    }
                }
        }
</script>

<style scoped src="../assets/assets-CSS/artiPrev.css"></style>
