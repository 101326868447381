<template>
<!-- This is the component for showing un-ordered list of the articles. You can also set numbers as icons so that it is converted to the ordered list. The "strongText" data is the title of each list before ":" and is optional. -->
<section class="mt-1 pr-4">
    <ul class="fa-ul mr-sm-5 text-justify">

        <li
            class="my-4"
            :key = "index"
            v-for = "(item, index) in listData"
        >

            <span class="fa-li">
                <i :class="item.icon"></i>
            </span>

            <strong class="mainStrong" v-if = "item.strongText" v-html = "item.strongText"></strong>: <span v-html = "item.liText"></span>

        </li>

    </ul>
</section>
</template>

<script>
        export default {
                name: "BaseList",

                props: {
                    listData: Array
                }
        }
</script>

<style scoped src="../assets/assets-CSS/baseList.css"></style>
