<template>
    <!-- This is the component that used in articles or anywhere that we need to show multi-part info. For example to show "HTML" in one tab and "CSS" in another tab and "RESULT" in the third tab. The data come from "headTabs" prop and according to its length the number of tab is set automatically.  -->
<section id="parentTab" class="mt-5">
    <ul class="nav nav-tabs nav-fill" id="myTab" role="tablist">

        <li :key = "tabItem.tabHeders" v-for = "(tabItem, index) in headTabs" class="nav-item" role="presentation">
            <a :class="{ active: index == activeIndex }" class="nav-link" :id="tabItem.tabHeders" data-toggle="tab" :href="`#${tabItem.tabHeders}Id`" role="tab" :aria-controls="`${tabItem.tabHeders}Id`" aria-selected="true" @click="changeIndex(index)">
                {{ tabItem.tabHeders }}
            </a>
        </li>

    </ul>
    <div class="tab-content" id="myTabContent">

        <div :key = "tabItem.tabHeders" v-for = "(tabItem, index) in headTabs" :class="{ active: index == activeIndex, show: index == activeIndex }" class="tab-pane" :id="`${tabItem.tabHeders}Id`" role="tabpanel" :aria-labelledby="tabItem.tabHeders">
            <transition name="fademe">
                <div class="mt-5" v-if = "index == activeIndex">
                    <!-- The content of each tab comes here. -->
                    <slot></slot>
                </div>
            </transition>
        </div>

    </div>
</section>
</template>

<script>
        export default {
                name: "BaseTab",
                data() {
                        return {
                            activeIndex: 0 /* this data is used to change "active" and "show" classes on each tab */
                        }
                },
                props: {
                        headTabs: Array,  /* this prop contains the information of tab heads (the title of each tab and ...) */
                },
                methods: {
                    changeIndex: function(indexPara) {
                        /* This method is responsible for changing the "activeIndex" after clicking on each tab. Then it sends active index to the parent component to show the right info according to that. */
                        this.activeIndex = indexPara;
                        this.$emit('indexSend', this.activeIndex);
                    }
                }
        }
</script>

<style scoped src="../assets/assets-CSS/baseTab.css"></style>
