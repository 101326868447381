<template>
<!-- this is the texts and contents of "article number 13" of our database. for more info and list of all options available for each article please see this file:
H:/web-codes/websites/portfolio-folder/design-and-assets/sample article/BaseArticle.vue  -->
        <div class="col-md-10 mx-auto">

            <div class="text-right mt-5">
                <!-- **************** -->
                <!-- intro text -->
                <!-- **************** -->
                <h2 class="subTitle">مقدمه: </h2>
                <blockquote class="blockquote">
                    <p class="text-justify textPara pb-5">
                        فرم&zwnj;ها نقش مهمی در وب&zwnj;سایت&zwnj;ها دارند. شاید کمتر وب&zwnj;سایتی را بتوان در نظر گرفت که نیازی به وارد نمودن اطلاعات توسط کاربران نداشته باشد. برای این اطلاعات اغلب از تگ&zwnj;های <code>&lt;input&gt;</code> و <code>&lt;select&gt;</code> و ... در ساختارهای وب&zwnj;سایت&zwnj;ها استفاده می&zwnj;شود. اگر شما نیز یک توسعه&zwnj;دهنده وب&zwnj;سایت به کمک <a href="https://v2.vuejs.org/v2/guide/" target="_blank">فریم&zwnj;ورک ویو - جی&zwnj;اس</a> (<span class="engliSpan">vue-js</span>) هستید، می&zwnj;توانید از ابزارهای ساخته شده برای مدیریت کردن  فرم&zwnj;ها و اطلاعات کاربران در تولید وب&zwnj;سایت یا اپلیکیشن خود سود ببرید. یکی از این ابزارها <a href="https://vueformulate.com/guide/" target="_blank">ویو – فرمولیت</a> (<span class="engliSpan">Vue Formulate</span>) نام دارد. در این مقاله قرار است که نحوه مدیریت نمودن فرم&zwnj;ها و ورودی&zwnj;های کاربران سایت&zwnj;ها را به کمک این ابزار قدرتمند و مفید بررسی کنیم. لازم به ذکر است که این ابزار با نسخه <span class="farsiSpan">شماره 2</span> فریم&zwnj;ورک ویو - جی&zwnj;اس هماهنگی دارد و فرض ما در این مقاله بر آن است که مخاطبان از آشنایی کافی با فریم&zwnj;ورک مذکور برخوردار هستند.
                    </p>
                </blockquote>

                <!-- **************** -->
                <!-- part one -->
                <!-- **************** -->
                <h2 class="subTitle mt-5">آماده&zwnj;سازی&zwnj;های اولیه برای کار با ویو – فرمولیت:</h2>
                <p class="text-justify textPara">
                    برای شروع، ابتدا به پوشه&zwnj;ای دلخواه در محیط <span class="engliSpan">Command Prompt</span> در ویندوز (یا هر محیط اجرایی مشابه) رفته و دستور زیر را وارد می&zwnj;کنیم:
                </p>

                <div class="text-center">
                    <code>&lt;vue create &lt;project-name</code>
                </div>

                <p class="text-justify textPara mt-4">
                    در دستور فوق باید به جای <code>&lt;project-name&gt;</code> نام دلخواه پروژه خود را قرار دهید. با این کار در اصل یک پروژه ویو - جی&zwnj;اس را آغازکرده&zwnj;ایم:
                </p>



                <!-- imgs (png,jpg or gif) that are not related to codes -->
                <div class="col-md-10 mt-5 mx-auto">
                    <img class="img-fluid mx-auto d-block img-thumbnail shadow-lg" src="./imgFolder/vue-create.png" alt="تصویر مربوط به آغاز کردن یک پروژه ویو – جی اس">
                </div>

                <p class="text-justify textPara mt-5">
                    از آنجا که ما در این مقاله از ویژگی&zwnj;های فریم&zwnj;ورک <a href="https://getbootstrap.com" target="_blank">بوتسترپ</a> برای برخی قسمت&zwnj;ها سود برده&zwnj;ایم، در صورتی&zwnj;که بخواهید دقیقاً طبق این مقاله کدنویسی کنید، می&zwnj;توانید با اجرای دستور <code>npm i bootstrap @popperjs/core</code> این فریم&zwnj;ورک را به پروژه اضافه نمایید و سپس در فایل <span class="engliSpan">src/main.js</span> آن را <span class="engliSpan">import</span> کنید:
                </p>

                <BaseTab
                    :head-tabs = "tabDatas"
                    @indexSend = "loadTabContent($event)"
                >
                    <template>
                        <div class="col-md-10 mt-5 mx-auto code-img">
                            <img class="img-fluid img-thumbnail" :src="require(`./imgFolder/${tabDatas[activeTab].data.imgName}`)" :alt="`${tabDatas[activeTab].data.altText}`">
                        </div>
                    </template>

                </BaseTab>

                <p class="text-justify textPara mt-5">
                    بعد از طی مراحل فوق نوبت به اضافه نمودن ویو – فرمولیت به پروژه می&zwnj;رسد. برای این کار ابتدا در محیط <span class="engliSpan">Command Prompt</span> دستور <code>npm install @braid/vue-formulate</code> را اجرا نمایید:
                </p>

                <!-- imgs (png,jpg or gif) that are not related to codes -->
                <div class="col-md-10 mt-5 mx-auto">
                    <img class="img-fluid mx-auto d-block img-thumbnail shadow-lg" src="./imgFolder/add-formulate.png" alt="تصویر مربوط به نصب ویو – فرمولیت در پروژه">
                </div>

                <p class="text-justify textPara mt-5">
                    سپس در فایل <span class="engliSpan">src/main.js</span> موجود در پروژه آن را <span class="engliSpan">import</span> نمایید:
                </p>

                <!-- imgs (png,jpg or gif) that are not related to codes -->
                <div class="col-md-10 mt-5 mx-auto">
                    <img class="img-fluid mx-auto d-block img-thumbnail shadow-lg" src="./imgFolder/vue-formulate-main-js.png" alt="تصویر مربوط به اضافه کردن ویو – فرمولیت به پروژه">
                </div>


                <!-- **************** -->
                <!-- part two -->
                <!-- **************** -->
                <h2 class="subTitle mt-5">آشنایی با کامپوننت <code>&lt;FormulateInput&gt;</code> :</h2>
                <p class="text-justify textPara">
                    اکنون می&zwnj;توانیم کار خود را با این ابزار قدرتمند در مدیریت فرم&zwnj;ها آغاز نماییم. به طور کلی می&zwnj;توان گفت که طراحی ویو – فرمولیت به گونه&zwnj;ای صورت گرفته است که با دو کامپوننت عمده به نام&zwnj;های <code>&lt;FormulateInput&gt;</code> و <code>&lt;FormulateForm&gt;</code> می&zwnj;توانید به اکثر قابلیت&zwnj;های مربوطه دسترسی داشته و فرم&zwnj;های سایت خود را شکل دهید. در این بخش به بررسی کامپوننت <code>&lt;FormulateInput&gt;</code> می&zwnj;پردازیم. در یکی از صفحات دلخواه پروژه خود (ما در اینجا از صفحه <span class="engliSpan">About.vue</span> که به صورت پیش&zwnj;فرض با ایجاد پروژه ویو - جی&zwnj;اس نام&zwnj;گذاری شده است، استفاده کرده&zwnj;ایم) می&zwnj;توانید کلیه ویژگی&zwnj;های مربوط به این کامپوننت را تمرین کرده و با آنها آشنا شوید.
                </p>
                <!-- begin subtitles in indented paragraphs -->
                <div class="indentPara">

                    <h3 class="subTitle2 mt-5">تعیین نوع input :</h3>
                    <p class="text-justify textPara">
                        برای تعیین نوع <span class="engliSpan">input</span> مورد نظر (مثلا از نوع text باشد یا date باشد یا ...) از ویژگی type در این کامپوننت استفاده می&zwnj;شود. به کار بردن این ویژگی <strong>الزامی</strong> بوده و بدون آن ویو – فرمولیت نمی&zwnj;تواند نوع input مورد نظر را تشخیص دهد. در کد زیر می&zwnj;توانید نحوه به&zwnj;کارگیری این ویژگی و خروجی مربوط به آن را مشاهده کنید:
                    </p>

                    <BaseTab
                        :head-tabs = "tabDatas2"
                        @indexSend = "loadTabContent2($event)"
                    >
                        <template>
                            <div class="col-md-10 mt-5 mx-auto code-img">
                                <img class="img-fluid img-thumbnail" :src="require(`./imgFolder/${tabDatas2[activeTab2].data.imgName}`)" :alt="`${tabDatas2[activeTab2].data.altText}`">
                            </div>
                        </template>

                    </BaseTab>

                    <p class="text-justify textPara mt-5">
                        همان&zwnj;طور که در کد بالا قابل مشاهده است، به کمک کامپوننت <code>&lt;FormulateInput&gt;</code> و ویژگی type که در آن تعریف می&zwnj;شود، شما می&zwnj;توانید حتی برای المان&zwnj;هایی مانند <code>&lt;textarea&gt;</code>، که خود یک <span class="farsiSpan">تگ مجزا</span> در ساختار معمول HTML دارند، نیز کدنویسی انجام دهید. برای توضیحات بیشتر در خصوص برخی انواع input هایی که می&zwnj;توانید به کار بگیرید، به <a href="https://vueformulate.com/guide/inputs/types/text/#text" target="_blank">سایت اصلی مربوطه</a> مراجعه نمایید.
                    </p>

                    <h3 class="subTitle2 mt-5">استفاده از ویژگی&zwnj;های معمول تگ input :</h3>
                    <p class="text-justify textPara">
                        همان&zwnj;گونه که در قسمت قبل ملاحظه نمودید، برخی از ویژگی&zwnj;های معمول را که استفاده از آنها در تگ <code>&lt;input&gt;</code> یا ساختار فرم&zwnj;ها در HTML <span class="farsiSpan">رایج</span> است، می&zwnj;توانید در این کامپوننت به کار ببرید. این ویژگی&zwnj;ها به صورت <span class="engliSpan">props</span> به کامپوننت اضافه می&zwnj;شوند. برخی از آنها در زیر آورده شده است:
                    </p>

                    <BaseList :list-data = "listText"></BaseList>

                    <p class="text-justify textPara mt-3">
                        در کد زیر می&zwnj;توانید نحوه به&zwnj;کارگیری چنین ویژگی&zwnj;هایی را مشاهده کنید:
                    </p>

                    <BaseTab
                        :head-tabs = "tabDatas3"
                        @indexSend = "loadTabContent3($event)"
                    >
                        <template>
                            <div class="col-md-10 mt-5 mx-auto code-img">
                                <img class="img-fluid img-thumbnail" :src="require(`./imgFolder/${tabDatas3[activeTab3].data.imgName}`)" :alt="`${tabDatas3[activeTab3].data.altText}`">
                            </div>
                        </template>

                    </BaseTab>

                    <h3 class="subTitle2 mt-5">مقدار دادن به input ها:</h3>
                    <p class="text-justify textPara">
                        از آنجا که ویو – فرمولیت ابزاری طراحی شده بر پایه فریم&zwnj;ورک ویو - جی&zwnj;اس است، علاوه بر ویژگی <span class="engliSpan">value</span> که در ساختار متداول HTML برای مقداردهی به <code>&lt;input&gt;</code> ها از آن کمک گرفته می&zwnj;شود، می&zwnj;توان از ویژگی <span class="engliSpan">v-model</span> که در فریم&zwnj;ورک ویو - جی&zwnj;اس مطرح شده است، نیز برای مقداردهی به المان&zwnj;ها استفاده نمود. در کد زیر نحوه به&zwnj;کارگیری این روش را برای کامپوننت <code>&lt;FormulateInput&gt;</code> مشاهده می&zwnj;کنید:
                    </p>

                    <BaseTab
                        :head-tabs = "tabDatas4"
                        @indexSend = "loadTabContent4($event)"
                    >
                        <template>
                            <div class="col-md-10 mt-5 mx-auto code-img">
                                <img class="img-fluid img-thumbnail" :src="require(`./imgFolder/${tabDatas4[activeTab4].data.imgName}`)" :alt="`${tabDatas4[activeTab4].data.altText}`">
                            </div>
                        </template>

                    </BaseTab>

                </div>
                <!-- end subtitles in indented paragraphs -->

                <!-- **************** -->
                <!-- part three -->
                <!-- **************** -->
                <h2 class="subTitle mt-5">اعتبارسنجی فرم&zwnj;ها:</h2>
                <!-- begin subtitles in indented paragraphs -->
                <div class="indentPara">

                    <h3 class="subTitle2 mt-5">اضافه نمودن اعتبارسنجی به input ها:</h3>
                    <p class="text-justify textPara">
                        یکی از موضوعات مهم در مورد داده&zwnj;هایی که کاربران یک سایت وارد می&zwnj;کنند، مسئله بررسی <span class="farsiSpan">صحت و درستی</span> آن داده&zwnj;ها است. مثلا فرض کنید که در یک تگ <code>&lt;input&gt;</code> از کاربر خواسته باشید که آدرس ایمیل خود را وارد نماید. در چنین حالتی اگر ورودی کاربر یک <span class="farsiSpan">متن ساده</span> باشد، شما باید به کاربر اخطار دهید که آنچه وارد کرده است از شکل صحیح ایمیل (<span class="engliSpan">example@example.com</span>) تبعیت نمی&zwnj;کند. در واقع انجام چنین عملیاتی برای تمامی <code>&lt;input&gt;</code> ها و داده&zwnj;هایی که کاربران سایت وارد می&zwnj;کنند، <span class="farsiSpan">اعتبارسنجی</span> (validation) لقب گرفته است. ویو – فرمولیت نیز به این موضوع پرداخته است و برای انجام آن از ویژگی <span class="engliSpan">validation</span>، که می&zwnj;توان آن را به کامپوننت <code>&lt;FormulateInput&gt;</code> اضافه نمود، کمک گرفته است. در زیر نمونه&zwnj;ای از این اعتبارسنجی را برای ورودی&zwnj;های متفاوت کاربران مشاهده می&zwnj;کنید:
                    </p>

                    <BaseTab
                        :head-tabs = "tabDatas5"
                        @indexSend = "loadTabContent5($event)"
                    >
                        <template>
                            <div class="col-md-10 mt-5 mx-auto code-img">
                                <img class="img-fluid img-thumbnail" :src="require(`./imgFolder/${tabDatas5[activeTab5].data.imgName}`)" :alt="`${tabDatas5[activeTab5].data.altText}`">
                            </div>
                        </template>

                    </BaseTab>

                    <p class="text-justify textPara mt-5">
                        در کد بالا با اضافه کردن ویژگی <code>&rdquo;...&ldquo;=validation</code> به ویو – فرمولیت می&zwnj;فهمانیم که چه نوع اعتبارسنجی برای ما انجام دهد. به طور کلی می&zwnj;توان از دو نوع فرمت برای انجام این کار استفاده نمود: فرمت <span class="engliSpan">String</span> و فرمت <span class="engliSpan">Array</span>.
                    </p>

                    <p class="textPara">
                        <span data-before = "»" class="bginSpan">فرمت String :</span> این فرمت در <code>&lt;input&gt;</code>  های اول، دوم و چهارم استفاده شده است. در این فرمت هر قانون اعتبارسنجی با علامت <span class="engliSpan">|</span> از قانون&zwnj;های دیگر تفکیک می&zwnj;شود. مثلا در خصوص <span class="engliSpan">password</span> موجود در کد بالا از سه قانون <span class="engliSpan">required</span> (به معنای الزامی بودن پر کردن این مورد)، <span class="engliSpan">between: 8, 16, length</span> (به معنای این که طول متن وارد شده باید بین 8 تا 16 کاراکتر باشد) و <span class="engliSpan" dir="ltr">matches:/ [0-9] /</span> (به معنای این که عبارت وارد شده حداقل باید شامل یک عدد باشد) استفاده شده است. همان&zwnj;طور که ملاحظه می&zwnj;کنید برخی از قانون&zwnj;ها دارای <span class="farsiSpan">آرگومان&zwnj;هایی</span> هستند (مانند قانون between که دارای آرگومان&zwnj;های <span class="engliSpan">8</span>، <span class="engliSpan">16</span> و <span class="engliSpan">length</span> است) که باید بعد از علامت <span class="engliSpan">:</span> ذکر شوند. برای جداسازی آرگومان&zwnj;ها از یکدیگر از علامت <span class="engliSpan">,</span> استفاده شده است.
                    </p>

                    <p class="textPara">
                        <span data-before = "»" class="bginSpan">فرمت Array :</span> این فرمت در خصوص <span class="engliSpan">email</span> (<code>&lt;input&gt;</code>  سوم) به کار گرفته شده است. در این فرمت در داخل <code>&rdquo;...&ldquo;=validation</code> از یک آرایه استفاده می&zwnj;شود که قوانین مورد نظر در داخل آن ذکر می&zwnj;شوند. چنانچه قانونی دارای آرگومان باشد، آرگومان&zwnj;ها در داخل هر آرایه با علامت <span class="engliSpan">,</span> از یکدیگر تفکیک می&zwnj;شوند.
                    </p>

                    <p class="text-justify textPara">
                        برای مشاهده لیست کامل&zwnj;تر قوانینی که به صورت <span class="farsiSpan">پیش&zwnj;فرض</span> در ویو – فرمولیت تعریف شده&zwnj;اند، می&zwnj;توانید به <a href="https://vueformulate.com/guide/validation/#available-rules" target="_blank">آدرس سایت رسمی</a> آن مراجعه نمایید.
                    </p>

                    <h3 class="subTitle2 mt-5">استفاده از ویژگی Bail :</h3>
                    <p class="text-justify textPara">
                        ویو – فرمولیت دارای ویژگی خاصی است که خود آن را قانون <span class="engliSpan">Bail</span> نام گذاری کرده است. این ویژگی در مسئله اعتبارسنجی فرم&zwnj;ها به شما کمک می&zwnj;کند تا <span class="farsiSpan">نحوه نمایش</span> پیغام&zwnj;های خطا را تغییر دهید. ابتدا به کد زیر و نحوه استفاده از آن توجه کنید:
                    </p>

                    <BaseTab
                        :head-tabs = "tabDatas6"
                        @indexSend = "loadTabContent6($event)"
                    >
                        <template>
                            <div class="col-md-10 mt-5 mx-auto code-img">
                                <img class="img-fluid img-thumbnail" :src="require(`./imgFolder/${tabDatas6[activeTab6].data.imgName}`)" :alt="`${tabDatas6[activeTab6].data.altText}`">
                            </div>
                        </template>

                    </BaseTab>

                    <p class="text-justify textPara mt-5">
                        در این کد سه <code>&lt;input&gt;</code>  از نوع <span class="engliSpan">password</span> را به سه شکل مختلف به کار برده&zwnj;ایم. اولین <code>&lt;input&gt;</code>  به شکل معمول و نرمال (بدون قانون <span class="engliSpan">Bail</span>) قرار داده شده است. در دومین مورد در قسمت <code>&rdquo;...&ldquo;=validation</code> در <span class="farsiSpan">ابتدا</span> یک کلمه Bail اضافه شده است که  به ویو – فرمولیت می&zwnj;فهماند تمامی پیغام&zwnj;های خطا را به صورت یکجا به نمایش <span class="farsiSpan">نگذارد</span>. بلکه هر جا به اولین خطا برخورد کرد، آن را نمایش دهد. مثلا در اینجا تا زمانی که <code>&lt;input&gt;</code> خالی باشد، پیغام مربوط به پر کردن فیلد مورد نظر را نمایش می&zwnj;دهد. بعد از آن تا هنگامی که طول کاراکترهای وارد شده به 8 نرسد، پیغام مربوط به طول password را نشان می&zwnj;دهد. در نهایت تا وقتی که کاربر یک عدد به password خود اضافه نکند، پیغام مربوط به خطای سوم را نمایش می&zwnj;دهد.
                    </p>

                    <p class="text-justify textPara">
                        تفاوت دومین و سومین <code>&lt;input&gt;</code> در آن است که در مورد سوم از علامت <span class="engliSpan">^</span> در ابتدای قانون <span class="engliSpan">required</span> استفاده شده است. این علامت به معنای آن است که در هنگام نمایش پیام&zwnj;های خطا تا زمانی که <code>&lt;input&gt;</code> مورد نظر خالی باشد، <strong>فقط</strong> پیغام مربوط به پر کردن فیلد را نمایش می&zwnj;دهد. ولی بلافاصله بعد از وارد کردن اولین کاراکتر، دو پیغام دیگر به صورت <span class="farsiSpan">همزمان</span> نمایش داده می&zwnj;شوند تا در نهایت خطاهای مربوط به هر یک برطرف گردند.
                    </p>

                    <p class="text-justify textPara">
                        همچنین در پایان این بخش لازم است که متذکر شویم ویژگی <span class="engliSpan">error-behavior</span> که در کدهای فوق مشاهده کردید، در حقیقت <span class="farsiSpan">نحوه نمایش</span> پیغام&zwnj;های خطا را مدیریت می&zwnj;کند. به این صورت که در حالت پیش&zwnj;فرض ویو – فرمولیت پیغام&zwnj;های خطا را بعد از <span class="engliSpan">blur</span> کردن (کلیک کردن در محیط خارج <code>&lt;input&gt;</code> مورد نظر) کاربر نمایش می&zwnj;دهد. اما با قرار دادن <code>&rdquo;error-behavior=&ldquo;live</code> برای هر کامپوننت، این پیام&zwnj;ها در همان ابتدای بارگذاری صفحه به نمایش در می&zwnj;آیند تا کاربر با پر کردن هر فیلد به رفع آنها بپردازد.
                    </p>

                    <h3 class="subTitle2 mt-5">نمایش پیام خطا به صورت دلخواه:</h3>
                    <p class="text-justify textPara">
                        تاکنون تمامی پیغام&zwnj;های خطایی که در <code>&lt;input&gt;</code> ها و فرآیند اعتبارسنجی برای کاربر به نمایش گذاشتیم، همگی پیام&zwnj;های <span class="farsiSpan">پیش&zwnj;فرض</span> و تعریف شده توسط ویو – فرمولیت بوده&zwnj;اند. اما ممکن است که این پیام&zwnj;ها مناسب  وب&zwnj;سایت مورد نظر ما نباشند. مثلا ما بخواهیم پیام دیگری را برای قانون <span class="engliSpan">required</span> که در اعتبارسنجی&zwnj;ها به کار می&zwnj;رود، نمایش دهیم یا اصلا بخواهیم متنی به زبان دیگری در پیام&zwnj;ها گذاشته شود. برای رسیدن به چنین هدفی از ویژگی <span class="engliSpan">validation-messages</span> در کامپوننت <code>&lt;FormulateInput&gt;</code> کمک گرفته می&zwnj;شود:
                    </p>

                    <BaseTab
                        :head-tabs = "tabDatas7"
                        @indexSend = "loadTabContent7($event)"
                    >
                        <template>
                            <div class="col-md-10 mt-5 mx-auto code-img">
                                <img class="img-fluid img-thumbnail" :src="require(`./imgFolder/${tabDatas7[activeTab7].data.imgName}`)" :alt="`${tabDatas7[activeTab7].data.altText}`">
                            </div>
                        </template>

                    </BaseTab>

                    <p class="text-justify textPara mt-5">
                        همان&zwnj;گونه که در کد فوق مشاهده می&zwnj;شود، برای هر قانون اعتبارسنجی به کار رفته در <code>&lt;input&gt;</code> مورد نظر، یک المان درون یک <span class="engliSpan">object</span> در داخل ویژگی validation-messages <span class="farsiSpan">هم&zwnj;نام</span> با نام قانون اعتبارسنجی تعریف می&zwnj;شود. سپس در روبه&zwnj;روی آنها پیام&zwnj;های جایگزین مورد نظر ما قرار داده می&zwnj;شوند. در این قسمت از کد می&zwnj;توانید تنها از یک <span class="engliSpan">string</span> استفاده کنید (مانند آنچه در جلوی قانون required برای password در این مثال مشاهده می&zwnj;شود) یا آنکه از یک <span class="farsiSpan">تابع</span> کمک بگیرید (مانند آنچه در روبه&zwnj;روی قانون between در خصوص password ملاحظه می&zwnj;کنید). در حالت دوم می&zwnj;توانید <span class="farsiSpan">آرگومان&zwnj;هایی</span> را که توسط خود ویو – فرمولیت تعریف شده&zwnj;اند، به این تابع وارد کنید و از مقادیر آنها در پیام&zwnj;های شخصی&zwnj;سازی شده خود کمک بگیرید.
                    </p>

                    <BaseTable :ltrProp="false" styleProp="style-type1" :dataTableProp="tableData" widthMin="800px" captionText="جدول مربوط به آرگومان&zwnj;های قابل استفاده در <span class = engliSpan>validation-messages</span>"></BaseTable>

                    <p class="text-justify textPara mt-5">
                        در خصوص لیست بالا ذکر این نکته ضروری است که  ویژگی <span class="engliSpan">name</span> در <code>&lt;input&gt;</code> های تعریف شده به <span class="farsiSpan">شکل متغیر</span> عمل می&zwnj;کند. به این معنا که شما می&zwnj;توانید با اضافه کردن ویژگی <span class="engliSpan">validation-name</span> به هر یک از <code>&lt;input&gt;</code> ها و قرار دادن مقداری برای آن، همان مقدار را در پیام&zwnj;های خود استفاده کنید (مانند آنچه در خصوص password در مثال بالا دیده شد). در غیر این صورت ویو – فرمولیت <span class="farsiSpan">به ترتیب</span> از مقادیر مربوط به ویژگی&zwnj;های <span class="engliSpan">label</span> ، <span class="engliSpan">name</span> یا <span class="engliSpan">type</span> که در کامپوننت <code>&lt;FormulateInput&gt;</code> تعریف شده باشند، برای این آرگومان ورودی توابع اعتبارسنجی استفاده خواهد کرد. به عنوان تمرین می&zwnj;توانید آرگومان name را در پیام&zwnj;های قوانین min و after که در <code>&lt;input&gt;</code> های دوم و سوم تعریف شده&zwnj;اند، وارد کنید تا بهتر متوجه متغیر عمل کردن این آرگومان بشوید.
                    </p>

                </div>
                <!-- end subtitles in indented paragraphs -->



                <!-- **************** -->
                <!-- part four -->
                <!-- **************** -->
                <h2 class="subTitle mt-5">آشنایی با کامپوننت <code>&lt;FormulateForm&gt;</code> :</h2>
                <p class="text-justify textPara">
                    تاکنون با کامپوننت <code>&lt;FormulateInput&gt;</code> و نحوه اعتبارسنجی آن آشنا شدید. اما معمولا در ساخت وب&zwnj;سایت&zwnj;ها دسته&zwnj;ای از اطلاعات کاربران (<code>&lt;input&gt;</code> ها) در کنار یکدیگر قرار می&zwnj;گیرند و به اصطلاح یک <strong>فرم</strong> را تشکیل می&zwnj;دهند که بعد از ارسال آن، تمامی اطلاعات درون <code>&lt;input&gt;</code> ها به سرور سایت منتقل می&zwnj;شوند. برای ایجاد چنین فرم&zwnj;هایی در ویو – فرمولیت کامپوننت دیگری به نام <code>&lt;FormulateForm&gt;</code> طراحی شده است که به نوعی جایگزین تگ <code>&lt;form&gt;</code> در ساختار معمول HTML می&zwnj;شود و قرار است در این قسمت از مقاله بیشتر با آن آشنا شویم.
                </p>
                <!-- begin subtitles in indented paragraphs -->
                <div class="indentPara">

                    <h3 class="subTitle2 mt-5">نحوه به&zwnj;کارگیری و مقداردهی به فرم:</h3>
                    <p class="text-justify textPara">
                        استفاده از <code>&lt;FormulateForm&gt;</code> کار چندان دشواری نیست. کافی است که آن را به عنوان تگ <span class="engliSpan">parent</span> کامپوننت&zwnj;های <code>&lt;FormulateInput&gt;</code> در کد خود قرار دهیم. برای آن که <code>&lt;input&gt;</code> های درون این فرم دارای مقدار اولیه&zwnj;ای باشند، می&zwnj;توانید از ویژگی <code>&rdquo;...&ldquo;=values</code> برای <code>&lt;FormulateForm&gt;</code> استفاده کنید.
                    </p>

                    <BaseTab
                        :head-tabs = "tabDatas8"
                        @indexSend = "loadTabContent8($event)"
                    >
                        <template>
                            <div class="col-md-10 mt-5 mx-auto code-img">
                                <img class="img-fluid img-thumbnail" :src="require(`./imgFolder/${tabDatas8[activeTab8].data.imgName}`)" :alt="`${tabDatas8[activeTab8].data.altText}`">
                            </div>
                        </template>

                    </BaseTab>

                    <p class="text-justify textPara mt-5">
                        در واقع کد نوشته شده در بالا تنها یک مقدار اولیه به <code>&lt;input&gt;</code> های موجود در داخل <code>&lt;FormulateForm&gt;</code> می&zwnj;دهد. اما چنانچه کاربر قصد <span class="farsiSpan">تغییر</span> این مقادیر را در صفحه مرورگر داشته باشد (داخل <code>&lt;input&gt;</code> ها تایپ کند)، هیچ یک از مقادیری که در قسمت <code>&lt;script&gt;</code> کد خودمان برای داده <span class="engliSpan">formValues</span> تعریف کرده&zwnj;ایم، دچار تغییر نمی&zwnj;شوند. برای آن که یک ارتباط دو طرفه بین قسمت <code>&lt;script&gt;</code> و <code>&lt;template&gt;</code> برقرار شود، کافی است که به جای ویژگی values از ویژگی <span class="engliSpan">v-model</span> در کامپوننت <code>&lt;FormulateForm&gt;</code> استفاده کنیم.
                    </p>

                    <BaseTab
                        :head-tabs = "tabDatas9"
                        @indexSend = "loadTabContent9($event)"
                    >
                        <template>
                            <div class="col-md-10 mt-5 mx-auto code-img">
                                <img class="img-fluid img-thumbnail" :src="require(`./imgFolder/${tabDatas9[activeTab9].data.imgName}`)" :alt="`${tabDatas9[activeTab9].data.altText}`">
                            </div>
                        </template>

                    </BaseTab>

                    <p class="text-justify textPara mt-5">
                        همان&zwnj;گونه که مشاهده می&zwnj;کنید، چنانچه از صفحه مرورگر <span class="engliSpan">inspect</span> بگیرید، در محیط <a href="https://devtools.vuejs.org/" target="_blank">افزونه vue</a> می&zwnj;توانید تغییرات لحظه&zwnj;ای داده <span class="engliSpan">formValues</span> را هنگام ورود محتویات <code>&lt;input&gt;</code> توسط کاربر بررسی کنید.
                    </p>

                    <h3 class="subTitle2 mt-5">ارسال اطلاعات فرم&zwnj;ها:</h3>
                    <p class="text-justify textPara">
                        اگرچه به کمک v-model یا استفاده از ویژگی values در کامپوننت <code>&lt;FormulateForm&gt;</code>  می&zwnj;توانید به اطلاعات درون <code>&lt;input&gt;</code> ها دسترسی داشته باشید یا به آنها مقدار دهید، اما روش بهینه&zwnj;ای که ویو – فرمولیت برای ارسال اطلاعات فرم&zwnj;ها پیشنهاد می&zwnj;کند، استفاده از <span class="engliSpan" dir="ltr">@submit</span> در کامپوننت <code>&lt;FormulateForm&gt;</code> است.
                    </p>

                    <p class="text-justify textPara">
                        همان طور که در کد زیر مشاهده می&zwnj;کنید، شما می&zwnj;توانید با فراخوانی یک متود (که در اینجا <span class="engliSpan">submitFunc</span> نام گذاری شده است) به اطلاعات درون <code>&lt;input&gt;</code> ها (با توجه به ویژگی <span class="engliSpan">name</span> که برای هر <code>&lt;input&gt;</code> تعریف شده است) دسترسی داشته باشید. همچنین چنانچه از روش جاوا اسکریپت ناهمگام (<span class="engliSpan">Asynchronous JS</span>) در کد این تابع استفاده شود، ویو – فرمولیت از طریق عبارت <code>&rdquo;{isLoading}&ldquo;=default#</code> که در کامپوننت <code>&lt;FormulateForm&gt;</code> تعریف می&zwnj;شود، می&zwnj;تواند به صورت اتوماتیک زمان اجرای فرآیند را تخمین بزند و متناسب با آن پارامتر <span class="engliSpan">isLoading</span> تعریف شده را به حالت&zwnj;های true یا false تغییر دهد. کاربرد چنین پارامتری در متن مربوط به <code>&lt;FormulateInput&gt;</code> با <code>&rdquo;type=&ldquo;submit</code> در کد زیر مشخص است. در اینجا تا هنگام اتمام عملیات ارسال اطلاعات، پارامتر <span class="engliSpan">isLoading</span> به حالت true بوده و پس از آن به حالت false تغییر می&zwnj;کند. در نتیجه متن دکمه مربوط به ارسال اطلاعات (همان <code>&lt;FormulateInput&gt;</code> از نوع submit) از submit به <span class="engliSpan">loading</span> تغییر می&zwnj;کند.
                    </p>

                    <BaseTab
                        :head-tabs = "tabDatas10"
                        @indexSend = "loadTabContent10($event)"
                    >
                        <template>
                            <div class="col-md-10 mt-5 mx-auto code-img">
                                <img class="img-fluid img-thumbnail" :src="require(`./imgFolder/${tabDatas10[activeTab10].data.imgName}`)" :alt="`${tabDatas10[activeTab10].data.altText}`">
                            </div>
                        </template>

                    </BaseTab>

                </div>
                <!-- end subtitles in indented paragraphs -->

                <!-- conclusion part -->
                <h2 class="subTitle pb-3 mt-5">جمع بندی: </h2>
                <blockquote class="blockquote conclu">

                    <p class="text-justify textPara pt-3">
                        در این مقاله با ویو – فرمولیت آشنا شدیم. این کتابخانه، ابزاری قوی برای کار با <code>&lt;input&gt;</code> ها در یک وب&zwnj;سایت ساخته شده با فریم&zwnj;ورک ویو - جی&zwnj;اس است. گرچه تلاش ما بر آن بود که مسائل عمده و مهم مرتبط با ویو – فرمولیت (مانند کامپوننت&zwnj;های مهم آن و اعتبارسنجی فرم&zwnj;ها) را بیان کنیم، ولی مسلما تمامی مطالب مرتبط با آن در این مقاله پوشش داده <span class="farsiSpan">نشده است</span>. علاقه&zwnj;مندان می&zwnj;توانند با مراجعه به <a href="https://vueformulate.com/guide/" target="_blank">وب&zwnj;سایت رسمی</a> این ابزار، اطلاعات بیشتری در خصوص قابلیت&zwnj;های آن بدست آورند و با یادگیری آنها به مدیریت بهتر <code>&lt;input&gt;</code> ها و داده&zwnj;های ورودی سایت خود بپردازند.
                    </p>

                </blockquote>

            </div>



            <!-- ways number 1 of loading imgs  -->
            <!--
            <img :src='`${this.publicPath}articles/${this.$route.params.id}/first.png`' alt="تصویر نمونه">
-->

                <!-- ways number 2 of loading imgs  -->
                <!--
               <img src='./imgFolder/img1.png' alt="تصویر نمونه">
               -->
        </div>
</template>

<script>
import BaseTab from '../../BaseTab.vue';
import BaseList from '../../BaseList.vue';
import BaseTable from '../../BaseTable.vue';

export default {
                name: "BaseArticle",
                components: {
                    BaseTab,
                    BaseList,
                    BaseTable
                },
                data() {
                    return {
                        activeTab: 0,
                        activeTab2: 0,
                        activeTab3: 0,
                        activeTab4: 0,
                        activeTab5: 0,
                        activeTab6: 0,
                        activeTab7: 0,
                        activeTab8: 0,
                        activeTab9: 0,
                        activeTab10: 0,

                        tabDatas: [
                            {
                                tabHeders: "نصب بوتسترپ",
                                data:{
                                    imgName: "add-bootstrap1.png",
                                    altText: "تصویر مربوط به اجرای دستور نصب بوتسترپ در پروژه"
                                }
                            },

                            {
                                tabHeders: "اضافه کردن بوتسترپ",
                                data:{
                                    imgName: "add-bootstrap2.png",
                                    altText: "تصویر مربوط به اضافه کردن بوتسترپ به پروژه"
                                }
                            }
                        ], // end of tabDatas

                        tabDatas2: [
                            {
                                tabHeders: "Vue component",
                                data:{
                                    imgName: "type-code.png",
                                    altText: "تصویر مربوط به تعیین نوع input در ویو - فرمولیت"
                                }
                            },

                            {
                                tabHeders: "Result",
                                data:{
                                    imgName: "type-result.gif",
                                    altText: "تصویر مربوط به خروجی تعیین نوع input در ویو - فرمولیت"
                                }
                            }

                        ], // end of tabDatas2

                        listText: [
                            /* this is the data used for BaseList.vue. the "strongText" data is optional and may be present in some list and not in other lists. */
                            {
                                icon: "fad fa-angle-left",
                                strongText: "ویژگی help",
                                liText: "باعث نمایش متن کمکی در کنار تگ <code>&lt;input&gt;</code> می&zwnj;شود."
                            },

                            {
                                icon: "fad fa-angle-left",
                                strongText: "ویژگی name",
                                liText: "همان ویژگی <span class = engliSpan>name</span> که در تگ&zwnj;های <code>&lt;input&gt;</code> برای شناسایی تگ و همچنین کمک به ارسال اطلاعات به سمت سرور استفاده می&zwnj;شود. "
                            },

                            {
                                icon: "fad fa-angle-left",
                                strongText: "ویژگی label",
                                liText: "این ویژگی که در قسمت قبلی هم به کار گرفته شد، باعث به وجود آمدن یک تگ <code>&lt;label&gt;</code> شده و در واقع مشابه ساختار HTML به عنوان <span class = engliSpan>label</span> (توضیح کنار تگ <code>&lt;input&gt;</code>) برای تگ <code>&lt;input&gt;</code> مورد نظر لحاظ می&zwnj;شود."
                            },

                            {
                                icon: "fad fa-angle-left",
                                strongText: "ویژگی placeholder",
                                liText: "باعث ایجاد placeholder (متن کمکی درون <code>&lt;input&gt;</code>) برای راهنمایی کاربران در خصوص چگونگی پر نمودن <code>&lt;input&gt;</code> می&zwnj;شود."
                            }

                        ],  // end of liText

                        tabDatas3: [
                            {
                                tabHeders: "Vue component",
                                data:{
                                    imgName: "normal-code.png",
                                    altText: " تصویر مربوط به استفاده از ویژگی های معمول ساختار HTML در ویو - فرمولیت"
                                }
                            },

                            {
                                tabHeders: "Result",
                                data:{
                                    imgName: "normal-props.gif",
                                    altText: " تصویر مربوط به خروجی استفاده از ویژگی های معمول ساختار HTML در ویو - فرمولیت"
                                }
                            }
                        ], // end of tabDatas3

                        tabDatas4: [
                            {
                                tabHeders: "Vue component",
                                data:{
                                    imgName: "v-model-code.png",
                                    altText: "تصویر مربوط به استفاده از v-model در ویو - فرمولیت"
                                }
                            },

                            {
                                tabHeders: "Result",
                                data:{
                                    imgName: "v-model-result.gif",
                                    altText: "تصویر مربوط به خروجی استفاده از v-model در ویو - فرمولیت"
                                }
                            }
                        ], // end of tabDatas4

                        tabDatas5: [
                            {
                                tabHeders: "Vue component",
                                data:{
                                    imgName: "validation.png",
                                    altText: " تصویر مربوط به استفاده از قابلیت اعتبارسنجی در ویو - فرمولیت"
                                }
                            },

                            {
                                tabHeders: "Result",
                                data:{
                                    imgName: "validation-result.gif",
                                    altText: " تصویر مربوط به خروجی استفاده از قابلیت اعتبارسنجی در ویو - فرمولیت"
                                }
                            }
                        ], // end of tabDatas5

                        tabDatas6: [
                            {
                                tabHeders: "Vue component",
                                data:{
                                    imgName: "bail.png",
                                    altText: "تصویر مربوط به استفاده از ویژگی Bail در ویو - فرمولیت"
                                }
                            },

                            {
                                tabHeders: "Result",
                                data:{
                                    imgName: "bail-result.gif",
                                    altText: "تصویر مربوط به خروجی استفاده از ویژگی Bail در ویو - فرمولیت"
                                }
                            }
                        ], // end of tabDatas6

                        tabDatas7: [
                            {
                                tabHeders: "Vue component",
                                data:{
                                    imgName: "val-message.png",
                                    altText: "تصویر مربوط به نمایش پیام ها به صورت دلخواه در ویو - فرمولیت"
                                }
                            },

                            {
                                tabHeders: "Result",
                                data:{
                                    imgName: "val-message-result.gif",
                                    altText: " تصویر مربوط به خروجی نمایش پیام ها به صورت دلخواه در ویو - فرمولیت"
                                }
                            }
                        ], // end of tabDatas7

                        tableData: [
                            /* --------------- */
                            /* data for row 1 that is for "thead" of table */
                            /* --------------- */
                            {
                                column1: "ردیف",
                                column2: "آرگومان ورودی",
                                column3: "مقدار آرگومان"
                            },
                            /* --------------- */
                            /* data for row 2 */
                            /* --------------- */
                            {
                                column1: "1",
                                column2: "args",
                                column3: "آرایه ای از آرگومان&zwnj;های قانون تعریف شده (مثلا در قانون <span class = engliSpan>min: 5</span> مقدار <span class = engliSpan>5</span> به عنوان آرگومان این قانون در args قرار می&zwnj;گیرد)."
                            },
                            /* --------------- */
                            /* data for row 3 */
                            /* --------------- */
                            {
                                column1: "2",
                                column2: "name",
                                column3: "نام فیلد مورد نظر"
                            },
                            /* --------------- */
                            /* data for row 4 */
                            /* --------------- */
                            {
                                column1: "3",
                                column2: "value",
                                column3: "مقدار درون <code>&lt;input&gt;</code> مورد نظر"
                            },
                            /* --------------- */
                            /* data for width of each column */
                            /* --------------- */
                            {
                                column1: "10%",
                                column2: "25%",
                                column3: "65%",
                            }
                        ], // end of tableData

                        tabDatas8: [
                            {
                                tabHeders: "Vue component",
                                data:{
                                    imgName: "formulateForm.png",
                                    altText: "تصویر مربوط به چگونگی استفاده از کامپوننت FormulateForm در ویو - فرمولیت"
                                }
                            },

                            {
                                tabHeders: "Result",
                                data:{
                                    imgName: "formulate-form-values.png",
                                    altText: "تصویر مربوط به خروجی استفاده از کامپوننت FormulateForm در ویو - فرمولیت"
                                }
                            }

                        ], // end of tabDatas8

                        tabDatas9: [
                            {
                                tabHeders: "Vue component",
                                data:{
                                    imgName: "formulateForm-v-model.png",
                                    altText: "تصویر مربوط به استفاده از v-model در کامپوننت FormulateForm در ویو - فرمولیت"
                                }
                            },

                            {
                                tabHeders: "Result",
                                data:{
                                    imgName: "formulateForm-v-model-result.gif",
                                    altText: "تصویر مربوط به خروجی استفاده از v-model در کامپوننت FormulateForm در ویو - فرمولیت"
                                }
                            }
                        ], // end of tabDatas9

                        tabDatas10: [
                            {
                                tabHeders: "Vue component",
                                data:{
                                    imgName: "form-submit.png",
                                    altText: "تصویر مربوط به ارسال اطلاعات فرم در ویو - فرمولیت"
                                }
                            },

                            {
                                tabHeders: "Result",
                                data:{
                                    imgName: "form-submit-result.gif",
                                    altText: "تصویر مربوط به خروجی ارسال اطلاعات فرم در ویو - فرمولیت"
                                }
                            }

                        ] // end of tabDatas10

                    }
                }, // end of data

                methods: {
                    loadTabContent: function(indexNow) {
                        this.activeTab = indexNow;
                    },

                    loadTabContent2: function(indexNow2) {
                        this.activeTab2 = indexNow2;
                    },

                    loadTabContent3: function(indexNow3) {
                        this.activeTab3 = indexNow3;
                    },

                    loadTabContent4: function(indexNow4) {
                        this.activeTab4 = indexNow4;
                    },

                    loadTabContent5: function(indexNow5) {
                        this.activeTab5 = indexNow5;
                    },

                    loadTabContent6: function(indexNow6) {
                        this.activeTab6 = indexNow6;
                    },

                    loadTabContent7: function(indexNow7) {
                        this.activeTab7 = indexNow7;
                    },

                    loadTabContent8: function(indexNow8) {
                        this.activeTab8 = indexNow8;
                    },

                    loadTabContent9: function(indexNow9) {
                        this.activeTab9 = indexNow9;
                    },

                    loadTabContent10: function(indexNow10) {
                        this.activeTab10 = indexNow10;
                    }
                } // end of methods

        }
</script>

<style scoped></style>
