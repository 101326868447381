<template>
<!-- this is the texts and contents of "article number 8" of our database. for more info and list of all options available for each article please see this file:
H:/web-codes/websites/portfolio-folder/design-and-assets/sample article/BaseArticle.vue  -->
        <div class="col-md-10 mx-auto">

            <div class="text-right mt-5">
                <!-- ############ -->
                <!-- intro text -->
                <!-- ############ -->
                <h2 class="subTitle">مقدمه: </h2>
                <blockquote class="blockquote">
                    <p class="text-justify textPara pb-5">
                        احتمالا شما تاکنون با وب&zwnj;سایت&zwnj;های فراوانی روبه&zwnj;رو شده&zwnj;اید. اگر به طراحی آنها بیشتر توجه نموده باشید، متوجه می&zwnj;شوید که در اکثر آنها برخی المان&zwnj;ها در نگاه اول دیده نمی&zwnj;شوند و بعد از بارگذاری صفحه سایت یا با کلیک یا حرکت در صفحه توسط کاربر <span class="farsiSpan">به تدریج</span> ظاهر می&zwnj;شوند. به چنین تکنیکی <span class="engliSpan">fade-in</span> گفته می&zwnj;شود که در واقع گونه&zwnj;ای از انیمیشن&zwnj;هایی است که در طراحی وب&zwnj;سایت&zwnj;ها استفاده می&zwnj;شود. در این مقاله قصد داریم به برخی از روش&zwnj;هایی که می&zwnj;تواند به ایجاد چنین افکتی منجر شود اشاره داشته باشیم.
                    </p>
                </blockquote>

                <!-- ############ -->
                <!-- part one -->
                <!-- ############ -->
                <h2 class="subTitle mt-5">استفاده از کتابخانه animate.css :</h2>
                <p class="text-justify textPara">
                    این کتابخانه (library) یکی از کتابخانه&zwnj;های معروف در تولید انیمیشن&zwnj;های CSS است که به کمک آن افکت&zwnj;های CSS مختلفی را می&zwnj;توان ایجاد نمود. یکی از این افکت&zwnj;ها مربوط به fade-in است. برای مطالعه بیشتر در خصوص نحوه استفاده از این کتابخانه و چگونگی دانلود یا به&zwnj;کارگیری آن می&zwnj;توانید به <a href="https://animate.style" target="_blank">سایت مربوط به آن</a> مراجعه نمایید.
                </p>

                <p class="text-justify textPara">
                    برای استفاده از آن در این مقاله ابتدا فایل&zwnj;های لازم را از سایت <a href="https://github.com/animate-css/animate.css" target="_blank">گیت&zwnj;هاب مرتبط با آن</a> دانلود نموده و سپس فایل <span class="engliSpan">animate.css</span> را توسط تگ <code> &lt;link&gt;</code> به بالای فایل html ملحق کرده&zwnj;ایم. مزیت استفاده از چنین کتابخانه&zwnj;هایی در آن است که دیگر نیازی به نوشتن کدهای <span class="engliSpan">CSS</span> و یا <span class="engliSpan">JavaScript</span> نداریم و تنها کافی است که کلاس&zwnj;های تعریف شده در کتابخانه را به تگ html خودمان (المانی که می&zwnj;خواهیم افکت <span class="engliSpan">fade-in</span> را روی آن اجرا کنیم) اضافه کنیم.
                </p>

                <!-- showing code imgs in tabs -->
                <BaseTab
                    :head-tabs = "tabDatas"
                    @indexSend = "loadTabContent($event)"
                >
                    <template>
                        <div class="col-md-10 mt-5 mx-auto code-img">
                            <img class="img-fluid img-thumbnail" :src="require(`./imgFolder/${tabDatas[activeTab].data.imgName}`)" :alt="`${tabDatas[activeTab].data.altText}`">
                        </div>
                    </template>

                </BaseTab>

                <p class="text-justify textPara mt-5">
                    همان&zwnj;طور که از کد استفاده شده مشخص است، با اضافه کردن کلاس&zwnj;های <span class="engliSpan">animate__animated</span> و <span class="engliSpan">animate__fadeIn</span> فرآیند ظاهر شدن تدریجی تصویر صورت می&zwnj;گیرد. با اضافه نمودن کلاس <span class="engliSpan">animate__delay-2s</span> یک تأخیر دو ثانیه&zwnj;ای قبل از اجرا شدن انیمیشن به وجود می&zwnj;آید.
                </p>

                <!-- ############ -->
                <!-- part two -->
                <!-- ############ -->
                <h2 class="subTitle mt-5">استفاده از جاوا&zwnj;اسکریپت(JS) :</h2>
                <p class="text-justify textPara">
                    در این روش ابتدا یک تگ <code>&lt;button&gt;</code> را در کد html خودمان ایجاد کرده&zwnj;ایم که قرار است با کلیک بر روی آن عملیات <span class="engliSpan">fade-in</span> اتفاق بیفتد. در اصل با کلیک بر روی این <code>&lt;button&gt;</code> تابع با نام <span class="engliSpan">()fadeFunc</span> (نام گذاری دلخواه) در کد JS فراخوانی شده و در طی آن المان نگه دارنده تصویر که در این کد با <code>&rdquo;id = &ldquo;main</code> تعریف شده است، در طی یک فرآیند یک ثانیه&zwnj;ای از <span class="engliSpan">opacity = 0</span> به <span class="engliSpan">opacity = 1</span> می&zwnj;رسد. در واقع این فرآیند در این روش توسط تابع <span class="engliSpan">()setTimeout</span> که از توابع خود ساختار زبان جاوا&zwnj;اسکریپت است، رخ می&zwnj;دهد. این تابع کد درون خود را پس از مدت زمانی که به عنوان پارامتر دوم آن تعریف می&zwnj;شود، اجرا می&zwnj;کند. به دلیل اینکه در استایل&zwnj;های تعریف شده برای المان با <code>&rdquo;id = &ldquo;main</code> از ویژگی transition استفاده شده است، این تغییر opacity <span class="farsiSpan">به نرمی و آهسته</span> اتفاق می&zwnj;افتد. کدهای مربوطه و خروجی آنها را در ادامه مشاهده می&zwnj;کنید:
                </p>

                <!-- showing code imgs in tabs -->
                <BaseTab
                    :head-tabs = "tabDatas2"
                    @indexSend = "loadTabContent2($event)"
                >
                    <template>
                        <div class="col-md-10 mt-5 mx-auto code-img">
                            <img class="img-fluid img-thumbnail" :src="require(`./imgFolder/${tabDatas2[activeTab2].data.imgName}`)" :alt="`${tabDatas2[activeTab2].data.altText}`">
                        </div>
                    </template>

                </BaseTab>

                <!-- ############ -->
                <!-- part three -->
                <!-- ############ -->
                <h2 class="subTitle mt-5">استفاده از jQuery :</h2>
                <p class="text-justify textPara">
                    در این روش از کتابخانه <span>jQuery</span> استفاده می&zwnj;شود. لذا باید ابتدا نسخه این کتابخانه را از <a href="https://jquery.com/download/" target="_blank">سایت jQuery</a> دانلود کرده و سپس در قالب یک تگ <code>&lt;script&gt;</code> به فایل html خودمان الحاق کنیم:
                </p>

                <div class="col-md-10 mt-5 mx-auto code-img">
                    <img class="img-fluid img-thumbnail" src="./imgFolder/jq-method1.png" alt="تصویر مربوط به چگونگی الحاق jQuery به فایل html">
                </div>

                <p class="text-justify textPara mt-5">
                    از آنجا که jQuery  یکی از کتابخانه&zwnj;های نوشته شده به <span class="farsiSpan">زبان جاوا&zwnj;اسکریپت</span> است، در حقیقت کار با آن به نوعی مشابه آن است که فردی از پیش کدهای لازم و متناسب برای رفع برخی از خواسته&zwnj;ها و نیازهای برنامه&zwnj;نویسی ما را نوشته باشد و ما صرفا از این کدهای نوشته شده، با دستور تعریف شده در کتابخانه، استفاده می&zwnj;کنیم. در jQuery متودهای مختلفی تعریف می&zwnj;شوند که هر یک کارایی خاص خود را دارند. یکی از این متودها <a href="https://api.jquery.com/fadein/" target="_blank">متود ()fadeIn</a> است. این متود به عنوان پارامتر اول مدت زمانی را به واحد میلی&zwnj;ثانیه قبول می&zwnj;کند که طی آن المان مورد نظر را به صورت تدریجی ظاهر می&zwnj;کند. برای استفاده از این متود لازم است که در فایل CSS خود برای المان مربوطه ویژگی <code>display:none</code> را تعریف کرده باشیم. در ادامه کدهای مربوطه آورده شده است:
                </p>

                <!-- showing code imgs in tabs -->
                <BaseTab
                    :head-tabs = "tabDatas3"
                    @indexSend = "loadTabContent3($event)"
                >
                    <template>
                        <div class="col-md-10 mt-5 mx-auto code-img">
                            <img class="img-fluid img-thumbnail" :src="require(`./imgFolder/${tabDatas3[activeTab3].data.imgName}`)" :alt="`${tabDatas3[activeTab3].data.altText}`">
                        </div>
                    </template>

                </BaseTab>

                <p class="text-justify textPara mt-5">
                    لازم به توضیح است که تنها استایل&zwnj;های مربوط به المان با <code>&rdquo;id = &ldquo;main</code> نمایش داده شده است و تنها <span class="farsiSpan">نکته مهم</span> آن برای اعمال اثر ظاهر شدن تدریجی (fade-in) این است که <strong>display</strong> مربوط به این المان از ابتدا در فایل ما برابر مقدار none در نظر گرفته شود.
                </p>

                <!-- ############ -->
                <!-- part four -->
                <!-- ############ -->
                <h2 class="subTitle mt-5">استفاده از CSS: </h2>
                <p class="text-justify textPara">
                    به کمک <span class="engliSpan">CSS</span> و استایل&zwnj;هایی که در فایل CSS مرتبط با html می&zwnj;دهیم، می&zwnj;توانیم حالت ظاهر شدن تدریجی یک المان بر روی صفحه مرورگر را به وجود آوریم. برای این منظور از ویژگی <span class="engliSpan">animation</span> استفاده می&zwnj;شود. بدین صورت که برای المانی که می&zwnj;خواهیم در صفحه ظاهر شود، یک animation بر اساس ویژگی <span class="engliSpan">opacity</span> تعریف می&zwnj;کنیم. کدهای مربوط به این روش و خروجی آن را در زیر مشاهده می&zwnj;کنید:
                </p>

                <!-- showing code imgs in tabs -->
                <BaseTab
                    :head-tabs = "tabDatas4"
                    @indexSend = "loadTabContent4($event)"
                >
                    <template>
                        <div class="col-md-10 mt-5 mx-auto code-img">
                            <img class="img-fluid img-thumbnail" :src="require(`./imgFolder/${tabDatas4[activeTab4].data.imgName}`)" :alt="`${tabDatas4[activeTab4].data.altText}`">
                        </div>
                    </template>

                </BaseTab>

                <p class="text-justify textPara mt-5">
                    چنانچه در درون فایل CSS به کلاس <span class="engliSpan">imgAnim</span> دقت کنید، مشاهده می&zwnj;شود که یک انیمیشن دو ثانیه&zwnj;ای برای تصویر مورد نظر تعریف شده است. لازم به ذکر است که در اینجا برای نمایش بهتر از یک دکمه (تگ <code>&lt;button&gt;</code>) و کد جاوا&zwnj;اسکریپت هم استفاده شده است تا زمانی که کاربر بر روی دکمه کلیک می&zwnj;کند، کلاس imgAnim به تگ <code>&lt;img&gt;</code> موجود در فایل html اضافه شود و در نتیجه انیمیشن تعریف شده اجرا شود. این در حالی است که به طور کلی ملزم به استفاده از جاوا&zwnj;اسکریپت <span class="farsiSpan">نخواهیم</span> بود و می&zwnj;توان انیمیشن مورد نظر را در درون استایل&zwnj;های خود تگ <code>&lt;img&gt;</code> اضافه نمود. نکته دیگری که در اینجا باید ذکر شود آن است که ویژگی <span class="engliSpan">animation-fill-mode</span> که در این کد به کار رفته، از آن جهت ضروری است که در صورت حذف آن، انیمیشن مورد نظر به حالت اولیه (یعنی <span class="engliSpan">opacity: 0</span>) بر می&zwnj;گردد و دوباره تصویر از صفحه محو می&zwnj;شود.
                </p>

                <!-- ############ -->
                <!-- conclusion part -->
                <!-- ############ -->
                <h2 class="subTitle pb-3 mt-5">جمع بندی: </h2>
                <blockquote class="blockquote conclu">
                    <p class="text-justify textPara pt-3">
                        در این مقاله به بررسی چهار روش برای ظاهر کردن تدریجی المان&zwnj;های موجود در فایل html پرداخته شد. هر روش مزایا و کاربردهای خاص خود را دارد که طراحان و توسعه&zwnj;دهندگان وب با توجه به پروژه مورد نظر، در خصوص انتخاب روش تصمیم گیری می&zwnj;کنند. به طور کلی ایجاد انیمیشن و ظاهر کردن تدریجی المان&zwnj;ها از رویه&zwnj;های متداول امروزی در طراحی وب&zwnj;سایت است که به غیر از خود جاوا&zwnj;اسکریپت و CSS معمولا از <span class="farsiSpan">کتابخانه&zwnj;های</span> مرتبط با این دو نیز برای پیاده&zwnj;سازی آن کمک گرفته می&zwnj;شود. امیدواریم با خواندن این مقاله تا حدودی با اعمال این روش&zwnj;ها آشنا شده باشید.
                    </p>
                </blockquote>

            </div>



            <!-- ways number 1 of loading imgs  -->
            <!--
            <img :src='`${this.publicPath}articles/${this.$route.params.id}/first.png`' alt="تصویر نمونه">
-->

                <!-- ways number 2 of loading imgs  -->
                <!--
               <img src='./imgFolder/img1.png' alt="تصویر نمونه">
               -->
        </div>
</template>

<script>
import BaseTab from '../../BaseTab.vue';

export default {
                name: "BaseArticle",
                components: {
                    BaseTab,
                },
                data() {
                    return {
                        activeTab: 0,
                        activeTab2: 0,
                        activeTab3: 0,
                        activeTab4: 0,

                        tabDatas: [
                            {
                                tabHeders: "HTML",
                                data:{
                                    imgName: "animate-css-1.png",
                                    altText: "کد HTML مربوط به استفاده از animate.css"
                                }
                            },

                            {
                                tabHeders: "CSS",
                                data:{
                                    imgName: "animate-css-2.png",
                                    altText: "کد CSS مربوط به استفاده از animate.css"
                                }
                            },

                            {
                                tabHeders: "Result",
                                data:{
                                    imgName: "animate-css-out.gif",
                                    altText: "خروجی مربوط به استفاده از animate.css"
                                }
                            }
                        ],   // end of tabDatas

                        tabDatas2: [
                            {
                                tabHeders: "HTML",
                                data:{
                                    imgName: "js-method-1.png",
                                    altText: "تصویر کد HTML مربوط به استفاده از روش جاوا اسکریپت"
                                }
                            },

                            {
                                tabHeders: "CSS",
                                data:{
                                    imgName: "js-method-2.png",
                                    altText: "تصویر کد CSS مربوط به استفاده از روش جاوا اسکریپت"
                                }
                            },

                            {
                                tabHeders: "JS",
                                data:{
                                    imgName: "js-method-3.png",
                                    altText: "تصویر کد JS مربوط به استفاده از روش جاوا اسکریپت"
                                }
                            },

                            {
                                tabHeders: "Result",
                                data:{
                                    imgName: "js-method-out.gif",
                                    altText: "تصویر مربوط به خروجی روش جاوا اسکریپت"
                                }
                            }
                        ], // end of tabDatas2

                        tabDatas3: [
                            {
                                tabHeders: "HTML",
                                data:{
                                    imgName: "jq-method3-html.png",
                                    altText: "تصویر کد HTML مربوط به استفاده از روش jQuery"
                                }
                            },

                            {
                                tabHeders: "CSS",
                                data:{
                                    imgName: "jq-method3-css.png",
                                    altText: "تصویر کد CSS مربوط به استفاده از روش jQuery"
                                }
                            },

                            {
                                tabHeders: "JS",
                                data:{
                                    imgName: "jq-method3-js.png",
                                    altText: "تصویر کد JS مربوط به استفاده از روش jQuery"
                                }
                            },

                            {
                                tabHeders: "Result",
                                data:{
                                    imgName: "jq-method-out.gif",
                                    altText: "تصویر خروجی مربوط به استفاده از روش jQuery"
                                }
                            }
                        ], // end of tabDatas3

                        tabDatas4: [
                            {
                                tabHeders: "HTML",
                                data:{
                                    imgName: "css-method-HTMLpart.png",
                                    altText: "تصویر کد HTML مربوط به استفاده از روش CSS"
                                }
                            },

                            {
                                tabHeders: "CSS",
                                data:{
                                    imgName: "css-method-csspart.png",
                                    altText: "تصویر کد CSS مربوط به استفاده از روش CSS"
                                }
                            },

                            {
                                tabHeders: "JS",
                                data:{
                                    imgName: "css-method-jspart.png",
                                    altText: "تصویر کد JS مربوط به استفاده از روش CSS"
                                }
                            },

                            {
                                tabHeders: "Result",
                                data:{
                                    imgName: "css-method-out.gif",
                                    altText: "تصویر خروجی مربوط به استفاده از روش CSS"
                                }
                            }
                        ],   // end of tabDatas4

                    }
                }, // end of data

                methods: {
                    loadTabContent: function(indexNow) {
                        this.activeTab = indexNow;
                    },

                    loadTabContent2: function(indexNow2) {
                        this.activeTab2 = indexNow2;
                    },

                    loadTabContent3: function(indexNow3) {
                        this.activeTab3 = indexNow3;
                    },

                    loadTabContent4: function(indexNow4) {
                        this.activeTab4 = indexNow4;
                    }
                } // end of methods

        }
</script>

<style scoped></style>
