<template>
<!-- This is the footer part of the site and in that we used some simple links and date for "copyright". The special thing about that is the style effect on "span" tags inside links -->
  <div class="container-fluid footerPar">
    <div class="row">
      <div class="col-md-3">
        <div class="d-flex justify-content-center align-items-center divCopy">
        <small dir="ltr">&copy; {{ dateME.getUTCFullYear() }} hamid-davodi</small>
        </div>
      </div>
      <div class="col-md-9">
        <div class="d-flex justify-content-around align-items-center divCopy text-center">
          <!-- social media icons -->
          <a target="_blank" href="https://www.linkedin.com/in/hamid-davoodi/">
            <img class="img-fluid pb-2" src="../assets/img/socials/linkedin.png" alt="آیکون لینکدین">
            <span><em>l</em><em>i</em><em>n</em><em>k</em><em>e</em><em>d</em><em>i</em><em>n</em></span>
          </a>

          <a href="mailto:hadavudi4@gmail.com">
            <img class="img-fluid" src="../assets/img/socials/gmail.png" alt="آیکون جی میل">
            <span><em>g</em><em>m</em><em>a</em><em>i</em><em>l</em></span>
          </a>

          <a target="_blank" href="https://stackoverflow.com/users/13770936/hamid-davodi">
            <img class="img-fluid" src="../assets/img/socials/stack-overflow.png" alt="آیکون استک اور فلو">
            <span><em>s</em><em>t</em><em>a</em><em>c</em><em>k</em><em>-</em><em>o</em><em>v</em><em>e</em><em>r</em><em>f</em><em>l</em><em>o</em><em>w</em></span>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
    export default {
        name: "TheFooter",
        data() {
            return {
                dateME: new Date()
            }
        },
    }
</script>

<style scoped src="../assets/assets-CSS/footer.css"></style>
