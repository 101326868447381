<template>
<!-- This page(view) is used to show sample-works of mine. Then it also has a section to show the services that I could do in web development.  -->
  <div class="mb-5">
    <!-- Text that is shown at the begin of the page. -->
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <div class="py-5 divPara w-100">
                    <p class="text-center my-5 px-5 px-xl-0 paraFirst animate__animated animate__backInDown animate__delay-1s">
                        <span>ساختن</span>
                         چیزهای به ظاهر ساده چندان هم ساده نیست. باید <span>نشان</span> داد که می توان ساخت.
                    </p>
                </div>
            </div>
        </div>
    </div>

    <!-- For showing each sample work you must use "ShowSample" component. -->
    <!-- https://sample-works1.herokuapp.com/  https://bookyar.herokuapp.com  https://bookyar-system.000webhostapp.com/ -->
    <ShowSample
        :link-btn="`${this.$frontUrl}sample-works/seven-samples/`"
        :img-src="require('../assets/img/sampleImgs/sevenSample.png')"
    >
      <template v-slot:headText>
        7 نمونه کد
      </template>
      <template v-slot:mainText>
        مجموعه 7 نمونه کار از ابزار های کاربردی در طراحی وب سایت ها مانند اسلایدر ، گراف، طراحی انیمیشن لود صفحات و ... . این مجموعه به کمک تکنولوژی های بخش فرانت وب ساخته شده و برای بخش داده های آنها نیز از ابزارهایی مانند JSON و XML استفاده شده است.
      </template>
      <template v-slot:techno>
        HTML, CSS, JS, jQuery, Bootstrap, JSON
      </template>
    </ShowSample>

    <DividerCircle></DividerCircle>

    <ShowSample
        link-btn="https://hamidgit68.github.io/international-hotel.github.io/"
        :img-src="require('../assets/img/sampleImgs/hotelWebsite.png')"
    >
      <template v-slot:headText>
        سایت هتل
      </template>
      <template v-slot:mainText>
          یک وب سایت تک صفحه که با موضوع هتل طراحی شده است. در این وب سایت از تکنولوژی های بخش فرانت مانند JavaScript، CSS، HTML و Bootstrap استفاده شده و سایت به صورت responsive طراحی شده است. برای برخی طراحی های به کار رفته در سایت نیز از نرم افزارهای گرافیکی مانند فتوشاپ و ایلاستریتور استفاده شده.
      </template>
      <template v-slot:techno>
        HTML, CSS, JS, Bootstrap
      </template>
    </ShowSample>

    <DividerCircle></DividerCircle>

    <ShowSample
        link-btn="https://login-reg-sample.hamid-davodi.com"
        :img-src="require('../assets/img/sampleImgs/loginReg.png')"
    >
      <template v-slot:headText>
        سیستم ورود و ثبت نام در سایت
      </template>
      <template v-slot:mainText>
          سیستم ثبت نام و ورود به سایت که کاربر بعد از انجام عملیات مربوطه می تواند به صفحه شخصی خود دسترسی داشته باشد و از امکاناتی مانند آپلود عکس و مشاهده لیست محصولات مناسب با سلیقه خود برخوردار شود.
      </template>
      <template v-slot:techno>
        HTML, CSS, JS, jQuery, PHP, MySQL
      </template>
    </ShowSample>

    <DividerCircle></DividerCircle>

    <ShowSample
        link-btn="https://majestic-biscochitos-e3f918.netlify.app"
        :img-src="require('../assets/img/sampleImgs/bookyar.png')"
    >
      <template v-slot:headText>
        سایت کتاب
      </template>
      <template v-slot:mainText>
          سایت تک صفحه ای که با موضوع کتاب کار شده است. در این سایت از Vue و Vue Router برای گردش در صفحات استفاده شده است. کاربر در صفحه اصلی می تواند با نمونه کتاب ها و امکانات سایت آشنا شود. صفحات بعدی هنوز به انجام نرسیده است.
      </template>
      <template v-slot:techno>
        Vue, Vue Router, HTML, CSS, JS, jQuery, Bootstrap
      </template>
    </ShowSample>

    <DividerIcon :divider-title="serviceDivid"></DividerIcon>

    <!-- My services is showing here -->
    <section id="cardContain" class="container my-5">
        <div class="row">

            <BaseCard>

                <template v-slot:header>
                    ساخت وب سایت
                </template>

                <template v-slot:iconCode>
                    <i class="fal fa-browser"></i>
                </template>

                <template v-slot:tozih>
                    توسعه و ساخت وب سایت با موضوعاتی چون وبلاگ، سایتهای تجاری، سایتهای خدماتی و ...
                </template>

                <template v-slot:span1>
                    ساخت و توسعه المانهای سایت
                </template>

                <template v-slot:span2>
                    انجام خدمات امنیتی مانند اعتبارسنجی فرم ها
                </template>

                <template v-slot:span3>
                    انتقال داده به پایگاه داده و  انجام خدمات backend سایت
                </template>

            </BaseCard>

            <BaseCard>

                <template v-slot:header>
                    وب اپلیکیشن
                </template>

                <template v-slot:iconCode>
                    <i class="fal fa-calculator"></i>
                </template>

                <template v-slot:tozih>
                    توسعه و ایجاد اپلیکیشن (نرم افزارهای تحت وب) در بستر مرورگرهای مدرن امروزی، مناسب برای انواع کاربردها
                </template>

                <template v-slot:span1>
                    امکان تبادل داده و تغییرات توسط مراجعه کنندگان به اپلیکیشن
                </template>

                <template v-slot:span2>
                    ایجاد قابلیت ها و عملکردهای شخصی سازی شده
                </template>

                <template v-slot:span3>
                    ارتباط آسان کاربران سایت و رفع نیازهای آنها
                </template>

            </BaseCard>

            <BaseCard>

                <template v-slot:header>
                    SPA
                </template>

                <template v-slot:iconCode>
                    <i class="fal fa-sync"></i>
                </template>

                <template v-slot:tozih>
                    Single Page Application در حقیقت نوعی از اپلیکیشن های تحت وب است که بدون اتصال مداوم به سرور اطلاعات را نمایش می دهد.
                </template>

                <template v-slot:span1>
                    سرعت بالا به جهت عدم نیاز اتصال به سرور
                </template>

                <template v-slot:span2>
                    تفکیک اجزای سایت به کمک کومپونت های طراحی شده
                </template>

                <template v-slot:span3>
                    استفاده از فریم ورک Vue به جهت تسهیل در ساخت SPA
                </template>

            </BaseCard>


            <BaseCard>

                <template v-slot:header>
                    طراحی واکنش گرا
                </template>

                <template v-slot:iconCode>
                    <i class="fal fa-sort-size-up"></i>
                </template>

                <template v-slot:tozih>
                    اضافه نمودن قابلیت واکنش گرایی (responsive) باعث می شود که کاربران سایت بتوانند هم از طریق کامپیوتر و هم از طریق موبایل از خدمات سایت بهره مند شوند.
                </template>

                <template v-slot:span1>
                    طراحی کاملا واکنش گرا متناسب با تمامی ابعاد صفحات موبایل و کامپیوتر
                </template>

                <template v-slot:span2>
                    امکان حذف یا اضافه کردن محتوا در ابعاد مختلف
                </template>

                <template v-slot:span3>
                    استفاده از فریم ورک ها در طراحی واکنش گرا
                </template>

            </BaseCard>

        </div>

    </section>

  </div>
</template>

<script>
/* importing components needed for this page */
/* ----------------------------- */
import ShowSample from "../components/ShowSample";
import DividerCircle from "../components/DividerCircle";
import DividerIcon from "../components/DividerIcon";
import BaseCard from "../components/BaseCard";
    export default {
        metaInfo: {
            title: "نمونه کارها",
        },
        name: "Samples",
        /* ----------------------------- */
        data: () => ({
            /* Data used for the "DividerIcon" component between samples and services.  */
            serviceDivid: {
                title: "خدمات در زمینه وب",
                icon: "fal fa-cogs"
            }

        }),
        /* ----------------------------- */
        components: {
            ShowSample,
            DividerCircle,
            DividerIcon,
            BaseCard
        }

    }
</script>

<style scoped src="../assets/assets-CSS/samples.css"></style>
