<template>
<!-- this is the texts and contents of "article number 12" of our database. for more info and list of all options available for each article please see this file:
H:/web-codes/websites/portfolio-folder/design-and-assets/sample article/BaseArticle.vue  -->
        <div class="col-md-10 mx-auto">

            <div class="text-right mt-5">

                <!-- intro text -->
                <h2 class="subTitle">مقدمه: </h2>
                <blockquote class="blockquote">
                    <p class="text-justify textPara pb-5">
                        معمولا دنیای وب به طور عمده به دو بخش <span class="engliSpan">front</span> و <span class="engliSpan">back</span> تقسیم&zwnj;بندی می&zwnj;شود. در بخش front بیشتر به جنبه&zwnj;های ظاهری سایت و آنچه که کاربران سایت مشاهده می&zwnj;کنند، پرداخته می&zwnj;شود و توسعه&zwnj;دهندگان این بخش معمولا تمایلی به درگیر شدن با کدهای قسمت back که عمدتا در ارتباط با ثبت داده&zwnj;ها و مسائل دیگر از این دست می&zwnj;باشد، <strong>ندارند</strong>. در همین راستا <span class="engliSpan">formspark</span> را می&zwnj;توان ایده&zwnj;آل چنین توسعه&zwnj;دهندگان بخش front دانست. این ابزار در واقع بخش back سایت شما را در قالب اطلاعاتی که از طریق فرم&zwnj;ها ارسال می&zwnj;شوند، مدیریت می&zwnj;کند. به کمک آن می&zwnj;توانید داده&zwnj;های فرم خود را به صورت <span class="farsiSpan">آنلاین</span> ذخیره نمایید. در این مقاله قصد داریم که به برخی از ویژگی&zwnj;های formspark و نحوه کار با آن بپردازیم.
                    </p>
                </blockquote>

                <!-- **************** -->
                <!-- part one -->
                <!-- **************** -->
                <h2 class="subTitle mt-5">ایجاد حساب کاربری formspark :</h2>
                <p class="text-justify textPara">
                    برای بهره&zwnj;مندی از قابلیت&zwnj;های این ابزار، ابتدا باید یک حساب کاربری در آن ایجاد نمایید. با مراجعه به <a href="https://formspark.io/" target="_blank">سایت اصلی</a> آن و استفاده از گزینه <span class="engliSpan">Sign in</span> در قسمت بالای سمت راست صفحه، می&zwnj;توانید با وارد نمودن یک آدرس ایمیل به راحتی یک حساب کاربری برای خود ایجاد نمایید. سپس می&zwnj;توانید به صفحه کاربری خود دسترسی پیدا کنید که شمای کلی آن مشابه تصویر زیر خواهد بود:
                </p>

                <!-- imgs (png,jpg or gif) that are not related to codes -->
                <div class="col-md-10 mt-5 mx-auto">
                    <img class="img-fluid mx-auto d-block img-thumbnail shadow-lg" src="./imgFolder/dashboard.png" alt="تصویر مربوط به صفحه حساب کاربری در formspark">
                </div>

                <!-- **************** -->
                <!-- part two -->
                <!-- **************** -->
                <h2 class="subTitle mt-5">ایجاد فرم جدید و آغاز به کار با formspark :</h2>
                <p class="text-justify textPara">
                    پس از ایجاد حساب کاربری در گام بعدی باید یک فرم را در صفحه کاربری خود ایجاد کنید. این کار باعث می&zwnj;شود که formspark یک <span class="engliSpan">id</span> در اختیار شما بگذارد تا به کمک آن بتوانید به ارسال اطلاعات به پایگاه داده این ابزار بپردازید. برای ایجاد فرم جدید بر روی گزینه <span class="engliSpan">create new form</span> در صفحه کاربری خود کلیک نمایید تا به صفحه ایجاد فرم جدید منتقل شوید:
                </p>

                <div class="col-md-10 mt-5 mx-auto code-img">
                    <img class="img-fluid img-thumbnail" src="./imgFolder/create-form-data.png" alt="تصویر مربوط به ایجاد فرم جدید در formspark">
                </div>

                <!-- paragraphs -->
                <p class="text-justify textPara mt-5">
                    واضح است که با دادن یک نام دلخواه و انتخاب تکنولوژی مورد نظر خود و سپس کلیک بر روی دکمه <span class="engliSpan">create</span> به راحتی می&zwnj;توانید نخستین فرم خود را ایجاد کنید. پس از آن چنانچه به صفحه فرم خود بروید، در قسمت بالای آن <span class="engliSpan">form id</span> مربوط به فرم ایجاد شده را خواهید دید:
                </p>

                <!-- imgs (png,jpg or gif) that are not related to codes -->
                <div class="col-md-10 mt-5 mx-auto">
                    <img class="img-fluid mx-auto d-block img-thumbnail shadow-lg" src="./imgFolder/form-id.png" alt="تصویر مربوط به id اختصاص داده شده به فرم جدید در formspark">
                </div>


                <p class="text-justify textPara mt-5">
                    این id همان چیزی است که برای برقراری ارتباط میان فرم ساخته شده شما و formspark نیاز دارید. در قسمت&zwnj;های بعدی مقاله به نحوه استفاده از آن خواهیم پرداخت.
                </p>

                <!-- **************** -->
                <!-- part three -->
                <!-- **************** -->
                <h2 class="subTitle mt-5">ارسال اطلاعات به وسیله فرم ساخته شده:</h2>
                <p class="text-justify textPara">
                    برای ارسال اطلاعات به formspark کافی است که یک <code>&lt;form&gt;</code> در فایل HTML خود ایجاد کرده و برای ویژگی (<span class="engliSpan">attribute</span>) اکشن (<span class="engliSpan">action</span>) آن از عبارت <code>&rdquo;https://submit-form.com/form-id&ldquo;</code> استفاده کنیم. در عبارت فوق به جای <span class="engliSpan">form-id</span> همان id تولید شده در بخش قبل را قرار دهید. همچنین باید تمامی <code>&lt;input&gt;</code> ها (یا تگ&zwnj;های مشابه دیگر چون<code>&lt;select&gt;</code>) تعریف شده درون فرم دارای ویژگی <span class="engliSpan">name</span> باشند تا تبادل اطلاعات به شکل صحیح صورت گیرد. نکته دیگر در این راستا آن است که حتما باید در داخل <code>&lt;form&gt;</code> از یک تگ <code>&lt;button&gt;</code> با <code>&rdquo;type=&ldquo;submit</code> استفاده نمایید:
                </p>

                <BaseTab
                    :head-tabs = "tabDatas"
                    @indexSend = "loadTabContent($event)"
                >
                    <template>
                        <div class="col-md-10 mt-5 mx-auto code-img">
                            <img class="img-fluid img-thumbnail" :src="require(`./imgFolder/${tabDatas[activeTab].data.imgName}`)" :alt="`${tabDatas[activeTab].data.altText}`">
                        </div>
                    </template>

                </BaseTab>


                <p class="text-justify textPara mt-5">
                    پس از ارسال موفق اطلاعات و نمایش پیغام <span class="engliSpan">Your form has been submitted</span> توسط formspark، شما می&zwnj;توانید اطلاعات ارسال شده را در قسمت حساب کاربری خود در این ابزار، پس از انتخاب فرم مربوطه، در بخش <span class="engliSpan">Submissions</span> مشاهده نمایید.
                </p>

                <div class="col-md-10 mt-5 mx-auto">
                    <img class="img-fluid mx-auto d-block img-thumbnail shadow-lg" src="./imgFolder/result-submit-simple.png" alt="تصویر مربوط به ثبت داده های فرم در formspark">
                </div>

                <!-- **************** -->
                <!-- part four -->
                <!-- **************** -->
                <h2 class="subTitle mt-5">تغییر صفحه نمایش پیام ارسال:</h2>
                <p class = "text-justify textPara">
                    در بخش قبل مشاهده شد که پس از ارسال فرم، مرورگر به صفحه جدیدی منتقل می&zwnj;شود. در واقع این صفحه به صورت <span class="farsiSpan">پیش&zwnj;فرض</span> توسط formspark برای نمایش پیام موفقیت&zwnj;آمیز بودن ثبت داده&zwnj;ها طراحی شده است. ممکن است که شما در پروژه خود <strong>صفحه خاصی</strong> را برای این کار طراحی کرده باشید یا از اساس بخواهید پس از ارسال اطلاعات، کاربر به صفحه دیگری در سایت شما منتقل شود. برای رسیدن به چنین هدفی کافی است که در کد بخش قبل در داخل <code>&lt;form&gt;</code> یک <code>&lt;input&gt;</code> جدید اضافه کنیم و ویژگی <code>&rdquo;type=&ldquo;hidden</code> را به آن بدهیم. همچنین ویژگی&zwnj;های <span class="engliSpan">value</span> و <span class="engliSpan">name</span> نیز باید مشابه کد زیر تعریف شده باشد:
                </p>

                <BaseTab
                    :head-tabs = "tabDatas2"
                    @indexSend = "loadTabContent2($event)"
                >
                    <template>
                        <div class="col-md-10 mt-5 mx-auto code-img">
                            <img class="img-fluid img-thumbnail" :src="require(`./imgFolder/${tabDatas2[activeTab2].data.imgName}`)" :alt="`${tabDatas2[activeTab2].data.altText}`">
                        </div>
                    </template>

                </BaseTab>

                <p class="text-justify textPara mt-5">
                    همان&zwnj;طور که در کد فوق می&zwnj;بینید برای مقدار name باید از عبارت <code>&rdquo;redirect_&ldquo;</code> استفاده شود. برای قسمت value نیز آدرس <span class="engliSpan">url</span> صفحه&zwnj;ای را که می&zwnj;خواهید بعد از ارسال اطلاعات به آن منتقل شوید، قرار دهید. ما در مثال فعلی از نرم افزار <span class="engliSpan">webstorm</span> و قابلیت <a href="https://www.jetbrains.com/help/webstorm/debugging-javascript-in-chrome.html#debugging_js_on_built_in_server" target="_blank">built-in server</a> آن کمک گرفته&zwnj;ایم. ولی شما متناسب با پروژه خود، می&zwnj;توانید آدرس صفحه مورد نظر خود را در مقابل ویژگی value این <code>&lt;input&gt;</code> قرار دهید.
                </p>


                <!-- **************** -->
                <!-- part five -->
                <!-- **************** -->
                <h2 class="subTitle mt-5">چگونگی کار با انواع دیگر input ها:</h2>
                <p class="text-justify textPara">
                    در مثال&zwnj;های بخش&zwnj;های قبل از تگ&zwnj;های <code>&lt;input&gt;</code> از نوع <span class="engliSpan">text</span> و <span class="engliSpan">email</span> و ... استفاده شد. اما اگر شما بخواهید از <code>&lt;input&gt;</code> های از نوع radio در سایت خود استفاده نمایید، باید دقت بیشتری در پر کردن ویژگی&zwnj;های <code>&lt;input&gt;</code> بنمایید. در چنین حالتی تمامی <code>&lt;input&gt;</code> های از نوع radio باید دارای <span class="engliSpan">name</span> یکسانی باشند. در صورت انتخاب هر یک توسط کاربر، مقدار <span class="engliSpan">value</span> مربوط به همان <code>&lt;input&gt;</code> به formspark منتقل می&zwnj;شود.
                </p>

                <BaseTab
                    :head-tabs = "tabDatas3"
                    @indexSend = "loadTabContent3($event)"
                >
                    <template>
                        <div class="col-md-10 mt-5 mx-auto code-img">
                            <img class="img-fluid img-thumbnail" :src="require(`./imgFolder/${tabDatas3[activeTab3].data.imgName}`)" :alt="`${tabDatas3[activeTab3].data.altText}`">
                        </div>
                    </template>

                </BaseTab>


                <!-- paragraphs -->
                <p class="text-justify textPara mt-5">
                    در کنار <code>&lt;input&gt;</code> های از نوع radio، معمولا <code>&lt;input&gt;</code> های از نوع <span class="engliSpan">checkbox</span> نیز مطرح می&zwnj;شوند. checkbox ها معمولا زمانی به کار می&zwnj;روند که کاربر می&zwnj;تواند <span class="farsiSpan">بیشتر</span> از یک گزینه (از میان گزینه&zwnj;های موجود) را نیز انتخاب کند. در چنین حالتی تمامی این اطلاعات باید به سرور سایت منتقل شوند. در اینجا (در حالت استفاده از formspark) برای هر <code>&lt;input&gt;</code> از نوع checkbox باید یک نام (ویژگی <span class="engliSpan">name</span>) یکتا انتخاب شود تا اطلاعات آن به درستی به formspark منتقل شود. اما این ابزار قابلیت دیگری هم دارد و آن این است که می&zwnj;توانید در جلوی ویژگی <code>  &rdquo; &ldquo;=name</code> در تگ&zwnj;های <code>&lt;input&gt;</code> از حالت <span class="farsiSpan">آرایه&zwnj;ای</span> نیز کمک بگیرید. نحوه به&zwnj;کارگیری این روش در کد زیر آورده شده است:
                </p>

                <BaseTab
                    :head-tabs = "tabDatas4"
                    @indexSend = "loadTabContent4($event)"
                >
                    <template>
                        <div class="col-md-10 mt-5 mx-auto code-img">
                            <img class="img-fluid img-thumbnail" :src="require(`./imgFolder/${tabDatas4[activeTab4].data.imgName}`)" :alt="`${tabDatas4[activeTab4].data.altText}`">
                        </div>
                    </template>

                </BaseTab>


                <!-- paragraphs -->
                <p class="text-justify textPara mt-5">
                    در واقع کدهای بالا تنها بخش مربوط به checkbox ها را نشان می&zwnj;دهند که می&zwnj;توانید آنها را به فایل&zwnj;های <span class="engliSpan">HTML</span> و <span class="engliSpan">CSS</span> که در قسمت radio ذکر شد، <span class="farsiSpan">اضافه</span> کنید (یا جایگزین آنها کنید). استفاده از حالت آرایه&zwnj;ای باعث می&zwnj;شود که تمامی گزینه&zwnj;های انتخاب شده در قالب <span class="farsiSpan">یک</span> آرایه به formspark منتقل شوند.
                </p>

                <div class="col-md-10 mt-5 mx-auto">
                    <img class="img-fluid mx-auto d-block img-thumbnail shadow-lg" src="./imgFolder/checkbox-inputs-data.png" alt="تصویر مربوط به ثبت داده در formspark برای تگ های input از نوع checkbox">
                </div>

                <p class="text-justify textPara mt-5">
                    البته شما می&zwnj;توانید از نام&zwnj;های <span class="farsiSpan">کاملا مجزا</span> نیز برای هر گزینه استفاده کنید، ولی معمولا از آنجا که تمامی <code>&lt;input&gt;</code> ها درباره یک موضوع <span class="farsiSpan">مشترک</span> هستند، شاید استفاده از حالت آرایه&zwnj;ای معنا و مفهوم بهتری داشته باشد.
                </p>

                <!-- **************** -->
                <!-- part six -->
                <!-- **************** -->
                <h2 class="subTitle mt-5">ارسال اطلاعات به کمک AJAX :</h2>
                <p class="text-justify textPara">
                    قابلیت دیگری که در formspark قرار داده شده است، ارسال اطلاعات به کمک <span class="engliSpan">AJAX</span> است. همان&zwnj;طور که می&zwnj;دانید AJAX مخفف عبارات <strong>Asynchronous JavaScript And XML</strong> است و کاربرد آن در تبادل اطلاعات با سرور سایت به صورت <span class="farsiSpan">غیر همزمان</span> است که می&zwnj;تواند اطلاعات مربوط به فرم&zwnj;ها را نیز ارسال کند. در هنگام استفاده از این روش در formspark، دیگر نیازی به تعریف ویژگی action برای <code>&lt;form&gt;</code> نخواهیم داشت و تمامی فرآیند ارسال اطلاعات در کد جاوا اسکریپت مدیریت می&zwnj;شود:
                </p>

                <BaseTab
                    :head-tabs = "tabDatas5"
                    @indexSend = "loadTabContent5($event)"
                >
                    <template>
                        <div class="col-md-10 mt-5 mx-auto code-img">
                            <img class="img-fluid img-thumbnail" :src="require(`./imgFolder/${tabDatas5[activeTab5].data.imgName}`)" :alt="`${tabDatas5[activeTab5].data.altText}`">
                        </div>
                    </template>

                </BaseTab>

                <p class="text-justify textPara mt-5">
                    در قسمت خروجی کد بالا مشاهده می&zwnj;کنید که دیگر ما به صفحه جدیدی منتقل <span class="farsiSpan">نمی&zwnj;شویم</span> و این همان معنای استفاده از AJAX است. اگر به صفحه کاربری خود در formspark رجوع کنید، مشاهده خواهید کرد که داده&zwnj;های ارسالی در قسمت <span class="engliSpan">Submissions</span> فرم مورد نظر ثبت شده&zwnj;اند:
                </p>

                <div class="col-md-10 mt-5 mx-auto">
                    <img class="img-fluid mx-auto d-block img-thumbnail shadow-lg" src="./imgFolder/ajax-result-data.png" alt="تصویر مربوط به ثبت داده در formspark بعد از درخواست AJAX">
                </div>

                <!-- **************** -->
                <!-- part seven -->
                <!-- **************** -->
                <h2 class="subTitle mt-5">استفاده ازformspark در فریم ورک ویو - جی&zwnj;اس:</h2>

                <p class="text-justify textPara">
                    تاکنون از فایل HTML برای ارسال اطلاعات به formspark کمک گرفتیم. ملاحظه کردید که علاوه بر استفاده از تگ <code>&lt;form&gt;</code> و قابلیت&zwnj;های آن، می&zwnj;توانید از طریق درخواست AJAX نیز به ارسال داده&zwnj;ها بپردازید. اما در این بخش می&zwnj;خواهیم از formspark درون یک وب&zwnj;سایت ساخته شده با <span class="farsiSpan">فریم&zwnj;ورک ویو - جی&zwnj;اس</span> (<a href="https://vuejs.org" target="_blank">Vue-js</a>) استفاده کنیم. برای این کار در گام نخست باید به نصب <a href="https://github.com/formspark/vue-use-formspark" target="_blank">vue-use-formspark</a> در وب&zwnj;سایت مورد نظر بپردازیم. لذا در محیط ترمینال به آدرس پوشه&zwnj;ای که وب&zwnj;سایت  ویو-جی&zwnj;اس را ایجاد کرده&zwnj;اید، رفته و دستور زیر را وارد نمایید:
                </p>

                <code class="text-center d-block p-2">npm install @formspark/vue-use-formspark</code>

                <p class="text-justify textPara mt-3">
                    لازم به ذکر است که vue-use-formspark با حالت <span class="engliSpan">Composition-API</span> نسخه شماره 3 فریم&zwnj;ورک ویو-جی&zwnj;اس سازگاری دارد. پس از نصب این ابزار با <span class="engliSpan">import</span> کردن آن درون کامپوننتی که می&zwnj;خواهیم فرم خود را در آن ایجاد کنیم، می&zwnj;توان از قابلیت&zwnj;های آن استفاده نمود. در کد زیر چگونگی استفاده از آن را در فرم ساخته شده با <a href="https://getbootstrap.com/docs/5.1/forms/overview/" target="_blank">فریم&zwnj;ورک بوتسترپ</a>، مشاهده می&zwnj;کنید. به طور کلی دو متغیر (تابع) <span class="engliSpan">submit</span> و <span class="engliSpan">submitting</span> که در این کد دیده می&zwnj;شوند، فرآیند اصلی ارسال اطلاعات را بر عهده می&zwnj;گیرند:
                </p>

                <BaseTab
                    :head-tabs = "tabDatas6"
                    @indexSend = "loadTabContent6($event)"
                >
                    <template>
                        <div class="col-md-10 mt-5 mx-auto code-img">
                            <img class="img-fluid img-thumbnail" :src="require(`./imgFolder/${tabDatas6[activeTab6].data.imgName}`)" :alt="`${tabDatas6[activeTab6].data.altText}`">
                        </div>
                    </template>

                </BaseTab>

                <p class="text-justify textPara mt-5">
                    همان&zwnj;طور که در خروجی کد فوق ملاحظه می&zwnj;کنید، در هنگام ارسال اطلاعات دکمه submit به حالت غیرفعال (<span class="engliSpan">disabled</span>) در می&zwnj;آید که این کار به کمک متغیر <span class="engliSpan">submitting</span> قابل انجام است. بعد از ارسال اطلاعات می&zwnj;توانید با مراجعه به صفحه کاربری خود در formspark نسبت به ثبت شدن داده&zwnj;ها اطمینان حاصل نمایید:
                </p>

                <div class="col-md-10 mt-5 mx-auto">
                    <img class="img-fluid mx-auto d-block img-thumbnail shadow-lg" src="./imgFolder/vue-formspark-data.png" alt="تصویر مربوط به ثبت داده در formspark بعد از ارسال از طریق فریم ورک ویو – جی اس">
                </div>

                <p class="text-justify textPara mt-5">
                    شاید برای شما این سؤال مطرح شده باشد که اگر بخواهید از نسخه <span class="farsiSpan">شماره 2</span> فریم&zwnj;ورک ویو-جی&zwnj;اس استفاده کنید، چگونه می&zwnj;توانید به formspark داده ارسال کنید؟ پاسخ این سؤال در همان قابلیت <span class="engliSpan">AJAX</span> این ابزار است. شما به کمک تابع <span class="engliSpan">()fetch</span> در جاوا اسکریپت به این هدف خواهید رسید. کد زیر که در یک کامپوننت مربوط به ویو - جی&zwnj;اس نسخه دو نوشته شده است، چگونگی انجام این امر را نشان می&zwnj;دهد:
                </p>

                <BaseTab
                    :head-tabs = "tabDatas7"
                    @indexSend = "loadTabContent7($event)"
                >
                    <template>
                        <div class="col-md-10 mt-5 mx-auto code-img">
                            <img class="img-fluid img-thumbnail" :src="require(`./imgFolder/${tabDatas7[activeTab7].data.imgName}`)" :alt="`${tabDatas7[activeTab7].data.altText}`">
                        </div>
                    </template>

                </BaseTab>

                <p class="text-justify textPara mt-5">
                    مشخص است که تنها با قرار دادن آدرس <span class="engliSpan">url</span> مربوطه به همراه <span class="engliSpan">id</span> خاص فرم خود در متود ()fetch می&zwnj;توان به ارسال داده ها به formspark پرداخت. پس از ارسال داده&zwnj;ها می&zwnj;توانید نسبت به ثبت داده&zwnj;ها در پایگاه داده خود (با مراجعه به حساب کاربری) اطمینان حاصل کنید:
                </p>

                <div class="col-md-10 mt-5 mx-auto">
                    <img class="img-fluid mx-auto d-block img-thumbnail shadow-lg" src="./imgFolder/vue-version2-data.png" alt="تصویر مربوط به ثبت داده در formspark بعد از ارسال از طریق فریم ورک ویو – جی اس">
                </div>

                <!-- conclusion part -->
                <h2 class="subTitle pb-3 mt-5">جمع بندی: </h2>
                <blockquote class="blockquote conclu">
                    <p class="text-justify textPara pt-3">
                        در این مقاله به بررسی formsaprk، به عنوان یک پایگاه داده برای اطلاعات ارسالی توسط فرم&zwnj;ها، پرداختیم. چنانچه شما نیز نیاز به یک پایگاه داده آنلاین دارید، ولی نمی&zwnj;خواهید یک <span class="farsiSpan">سرور</span> برای آن ایجاد کنید، می&zwnj;توانید از قابلیت&zwnj;ها و ویژگی&zwnj;های این ابزار استفاده کنید. البته در formspark شما می&zwnj;توانید حساب کاربری <strong>رایگان</strong> خود را با پرداخت هزینه گسترش دهید که این کار باعث می&zwnj;شود حق ارسال داده&zwnj;های شما افزایش یابد. چرا که به صورت رایگان شما تنها حق ارسال <span class="farsiSpan">250</span> داده را دارید. همچنین می&zwnj;توانید با مراجعه به <a href="https://formspark.io/" target="_blank">وب&zwnj;سایت رسمی این ابزار</a> به مطالعه جزئیات بیشتری در خصوص قابلیت&zwnj;های آن بپردازید.
                    </p>

                </blockquote>

            </div>



            <!-- ways number 1 of loading imgs  -->
            <!--
            <img :src='`${this.publicPath}articles/${this.$route.params.id}/first.png`' alt="تصویر نمونه">
-->

                <!-- ways number 2 of loading imgs  -->
                <!--
               <img src='./imgFolder/img1.png' alt="تصویر نمونه">
               -->
        </div>
</template>

<script>
import BaseTab from '../../BaseTab.vue';

export default {
                name: "BaseArticle",
                components: {
                    BaseTab,
                },
                data() {
                    return {
                        activeTab: 0,
                        activeTab2: 0,
                        activeTab3: 0,
                        activeTab4: 0,
                        activeTab5: 0,
                        activeTab6: 0,
                        activeTab7: 0,

                        tabDatas: [
                            {
                                tabHeders: "HTML",
                                data:{
                                    imgName: "submit-simple-code.png",
                                    altText: "تصویر مربوط به کد html ارسال داده های فرم به formspark"
                                }
                            },

                            {
                                tabHeders: "CSS",
                                data:{
                                    imgName: "submit-simple-style.png",
                                    altText: "تصویر مربوط به کد css ارسال داده های فرم به formspark"
                                }
                            },

                            {
                                tabHeders: "Result",
                                data:{
                                    imgName: "submit-simple.gif",
                                    altText: "تصویر مربوط به خروجی ارسال داده های فرم به formsparkی"
                                }
                            }

                        ],

                        tabDatas2: [
                            {
                                tabHeders: "HTML",
                                data:{
                                    imgName: "input-hidden.png",
                                    altText: "تصویر مربوط کد تغییر دادن صفحه نمایش پیام"
                                }
                            },

                            {
                                tabHeders: "Result",
                                data:{
                                    imgName: "custom-redirect.gif",
                                    altText: "تصویر مربوط خروجی تغییر دادن صفحه نمایش پیام"
                                }
                            }

                        ],

                        tabDatas3: [
                            {
                                tabHeders: "HTML",
                                data:{
                                    imgName: "radio-html.png",
                                    altText: "تصویر مربوط به کد html تگ های input از نوع radio"
                                }
                            },

                            {
                                tabHeders: "CSS",
                                data:{
                                    imgName: "radio-CSS.png",
                                    altText: "تصویر مربوط به کد css تگ های input از نوع radio"
                                }
                            },

                            {
                                tabHeders: "Result",
                                data:{
                                    imgName: "radio-inputs.gif",
                                    altText: "تصویر مربوط به خروجی تگ های input از نوع radio"
                                }
                            },

                            {
                                tabHeders: "Data in formspark",
                                data:{
                                    imgName: "radio-inputs-data.png",
                                    altText: "تصویر مربوط به ثبت داده در formspark برای تگ های input از نوع radio"
                                }
                            }

                        ],

                        tabDatas4: [
                            {
                                tabHeders: "HTML",
                                data:{
                                    imgName: "checkbox-html.png",
                                    altText: "تصویر مربوط به کد html تگ های input از نوع checkbox"
                                }
                            },

                            {
                                tabHeders: "CSS",
                                data:{
                                    imgName: "checkbox-css.png",
                                    altText: "تصویر مربوط به کد css تگ های input از نوع checkbox"
                                }
                            },

                            {
                                tabHeders: "Result",
                                data:{
                                    imgName: "checkbox-inputs.gif",
                                    altText: "تصویر مربوط به خروجی تگ های input از نوع checkbox"
                                }
                            }

                        ],

                        tabDatas5: [
                            {
                                tabHeders: "HTML",
                                data:{
                                    imgName: "ajax-html.png",
                                    altText: "تصویر مربوط به کد html درخواست AJAX به formspark"
                                }
                            },

                            {
                                tabHeders: "CSS",
                                data:{
                                    imgName: "submit-simple-style.png",
                                    altText: "تصویر مربوط به کد css درخواست AJAX به formspark"
                                }
                            },

                            {
                                tabHeders: "JS",
                                data:{
                                    imgName: "ajax-js.png",
                                    altText: "تصویر مربوط به کد JS درخواست AJAX به formspark"
                                }
                            },

                            {
                                tabHeders: "Result",
                                data:{
                                    imgName: "ajax-result.gif",
                                    altText: "تصویر مربوط به خروجی درخواست AJAX به formspark"
                                }
                            }

                        ],

                        tabDatas6: [
                            {
                                tabHeders: "Vue Component",
                                data:{
                                    imgName: "vue-formspark-code.png",
                                    altText: "تصویر کد مربوط به کامپوننت ویو – جی اس برای ارسال داده به formspark"
                                }
                            },

                            {
                                tabHeders: "Result",
                                data:{
                                    imgName: "vue-formspark.gif",
                                    altText: "تصویر مربوط به خروجی ارسال داده به formspark به وسیله vue-js  "
                                }
                            }

                        ],

                        tabDatas7: [
                            {
                                tabHeders: "Vue Component",
                                data:{
                                    imgName: "vue-version2-code.png",
                                    altText: "تصویر کد مربوط به کامپوننت ویو – جی اس برای ارسال داده به formspark"
                                }
                            },

                            {
                                tabHeders: "Result",
                                data:{
                                    imgName: "vue-version2.gif",
                                    altText: "تصویر مربوط به خروجی ارسال داده به formspark به وسیله vue-js"
                                }
                            }

                        ]

                    }
                }, // end of data

                methods: {
                    loadTabContent: function(indexNow) {
                        this.activeTab = indexNow;
                    },

                    loadTabContent2: function(indexNow2) {
                        this.activeTab2 = indexNow2;
                    },

                    loadTabContent3: function(indexNow3) {
                        this.activeTab3 = indexNow3;
                    },

                    loadTabContent4: function(indexNow4) {
                        this.activeTab4 = indexNow4;
                    },

                    loadTabContent5: function(indexNow5) {
                        this.activeTab5 = indexNow5;
                    },

                    loadTabContent6: function(indexNow6) {
                        this.activeTab6 = indexNow6;
                    },

                    loadTabContent7: function(indexNow7) {
                        this.activeTab7 = indexNow7;
                    }
                } // end of methods

        }
</script>

<style scoped></style>
