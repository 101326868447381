<template>
<!-- this is the texts and contents of "article number 10" of our database. for more info and list of all options available for each article please see this file:
H:/web-codes/websites/portfolio-folder/design-and-assets/sample article/BaseArticle.vue  -->
        <div class="col-md-10 mx-auto">
            <div class="text-right mt-5">
                <!-- **************** -->
                <!-- intro text -->
                <!-- **************** -->
                <h2 class="subTitle">مقدمه: </h2>
                <blockquote class="blockquote">
                    <p class="text-justify textPara pb-5">
                        امروزه اهمیت نوار جستجو (<span class="engliSpan">search bar</span>) در وب سایت&zwnj;ها بر کسی پوشیده نیست. شاید برای سایت&zwnj;هایی که محصولات اندکی ارائه می&zwnj;کنند، وجود نوار جستجو چندان ضروری به نظر نیاید، اما برای سایت&zwnj;هایی که تنوع محصولات و خدمات بالایی دارند، اهمیت جستجو در محصولات بسیار بالا می&zwnj;رود. برای مثال در سایت&zwnj;های تجاری مانند سایت&zwnj;های خرید اینترنتی، اغلب کاربران نیاز خود را به کمک قابلیت جستجویی که در سایت قرار داده شده است، مرتفع می&zwnj;سازند. در این مقاله قرار است به طراحی و شخصی&zwnj;سازی (customize) کردن <span class="farsiSpan">استایل</span> یک نوار جستجو به کمک <a href="https://getbootstrap.com/docs/5.1/getting-started/introduction/" target="_blank">بوتسترپ</a> و <a href="https://nodejs.org/en/" target="_blank">نود&zwnj;جی&zwnj;اس</a> بپردازیم. در اینجا فرض ما بر آن است که خوانندگان محترم آشنایی اولیه و مقدماتی با این دو ابزار و تکنولوژی مورد استفاده در وب را داشته باشند، هر چند که سعی شده است توضیحات نسبتاً کاملی در خصوص هر بخش ارائه شود.
                    </p>
                </blockquote>

                <!-- **************** -->
                <!-- part one -->
                <!-- **************** -->
                <h2 class="subTitle mt-5">اقدامات اولیه و آغاز پروژه به کمک نود:</h2>
                <p class="text-justify textPara">
                    همان&zwnj;گونه که در <a href="https://getbootstrap.com/docs/5.1/getting-started/download/#package-managers" target="_blank">سایت رسمی بوتسترپ</a> مشاهده می&zwnj;شود، برای نصب و اضافه کردن این فریم ورک محبوب به پروژه وب&zwnj;سایت خود می&zwnj;توانید از روش&zwnj;های متعددی اقدام نمایید. یکی از این روش&zwnj;ها استفاده از نود&zwnj;جی&zwnj;اس و سیستم مدیریت پکیج&zwnj;ها در نود (<span class="engliSpan">npm</span>) است. برای اعمال این روش لازم است که نود&zwnj;جی&zwnj;اس بر روی سیستم شما <span class="farsiSpan">نصب</span> شده باشد. برای دانلود و نصب آن می&zwnj;توانید به <a href="https://nodejs.org/en/" target="_blank">وب&zwnj;سایت نود</a> مراجعه کنید. برای چک کردن اینکه آیا قبلا نود&zwnj;جی&zwnj;اس بر روی سیستم شما نصب شده است یا خیر، می&zwnj;توانید دستور <code>node –v</code> را در محیطی مشابه <span class="engliSpan">Command Prompt</span> (که به اختصار در این مقاله محیط ترمینال نام&zwnj;گذاری می&zwnj;شود) در سیستم ویندوز اجرا کنید که در صورت نصب بودن، ورژن مربوطه را به شما نشان خواهد داد:
                </p>

                <!-- imgs (png,jpg or gif) that are not related to codes -->
                <div class="col-md-10 mt-5 mx-auto">
                    <img class="img-fluid mx-auto d-block img-thumbnail shadow-lg" src="./imgFolder/node-v-min.png" alt="تصویر مربوط به اجرای دستور node -v در ترمینال">
                </div>

                <p class="text-justify textPara mt-5">
                    با نصب نود، به صورت <strong>اتوماتیک</strong> سیستم مدیریت پکیج&zwnj;ها (<span class="engliSpan">npm</span>) نیز بر روی کامپیوتر شما نصب خواهد شد. این سیستم به شما کمک می&zwnj;کند تا بتوانید یک پروژه نود&zwnj;جی&zwnj;اس را آغاز کرده و فریم&zwnj;ورک&zwnj;ها و پکیج&zwnj;های ضروری آن را به پروژه اضافه کنید.
                </p>

                <p class="text-justify textPara">
                    برای آغاز یک پروژه نود&zwnj;جی&zwnj;اس کافی است که دستور <code>npm init –y</code> را در محیط ترمینال، در فولدری (مسیری) که می&zwnj;خواهید پروژه را در آن قرار بدهید، وارد نمایید. با این کار فایل <span class="engliSpan">package.json</span> که به نوعی دربرگیرنده مشخصات و تنظیمات پروژه است، در فولدر مربوطه ایجاد خواهد شد. به عنوان مثال در این مقاله پروژه را در فولدری به نام <span class="engliSpan">npm-bootstrap</span> موجود در درایو <span class="engliSpan">H</span> ایجاد نموده&zwnj;ایم. خروجی دستور <code>npm init –y</code> را می&zwnj;توانید در تصویر زیر مشاهده کنید:
                </p>

                <!-- imgs (png,jpg or gif) that are not related to codes -->
                <div class="col-md-10 mt-5 mx-auto">
                    <img class="img-fluid mx-auto d-block img-thumbnail shadow-lg" src="./imgFolder/npm-init-min.png" alt="تصویر مربوط به اجرای دستور npm init -y در ترمینال">
                </div>


                <!-- **************** -->
                <!-- part two -->
                <!-- **************** -->
                <h2 class="subTitle mt-5">نصب ابزارها و پکیج&zwnj;های ضروری پروژه:</h2>
                <p class="text-justify textPara">
                    بعد از ایجاد فایل <span class="engliSpan">package.json</span> لازم است که ابزارها و فریم&zwnj;ورک&zwnj;هایی که قرار است با آن&zwnj;ها در این پروژه کار کنیم، نصب شوند. لازم به توضیح است که در فایل ذکر شده دو قسمت به نام&zwnj;های <span class="engliSpan">dependencies</span> و <span class="engliSpan">devDependencies</span> بعد از نصب اولین پکیج ایجاد خواهند شد که بسته به نوع ابزار (پکیج) مورد نظر، نام پکیج نصب شده در یکی از این دو قسمت قرار می&zwnj;گیرد. برای نصب هر پکیج معمولا از دستور <span class="engliSpan">npm install</span> کمک گرفته می&zwnj;شود که نام پکیج بعد از آن ذکر می&zwnj;شود. برای مثال جهت نصب بوتسترپ و افزودن آن به پروژه می&zwnj;توانید دستور <code>npm install bootstrap</code> را در محیط ترمینال وارد نمایید که احتمالاً خروجی مشابه شکل زیر را دریافت خواهید کرد:
                </p>

                <!-- imgs (png,jpg or gif) that are not related to codes -->
                <div class="col-md-10 mt-5 mx-auto">
                    <img class="img-fluid mx-auto d-block img-thumbnail shadow-lg" src="./imgFolder/npm-install-bootstrap-min.png" alt="تصویر مربوط به اجرای دستور npm install bootstrap در ترمینال">
                </div>

                <p class="text-justify textPara mt-5">
                    با اجرای دستور فوق چنانچه به فولدر پروژه مراجعه کنید، مشاهده خواهید کرد که یک پوشه جدید به نام <span class="engliSpan">node_modules</span> نیز اضافه شده است که حاوی فایل&zwnj;های ضروری هر پکیج است. به طریق مشابه به کمک دستورهای <a dir="ltr" href="https://expressjs.com/en/starter/installing.html" target="_blank">npm install express --save</a>،  <a dir="ltr" href="https://nodemon.io" target="_blank">npm install --save-dev nodemon</a> و <a href="https://icons.getbootstrap.com" target="_blank">npm i bootstrap-icons</a> می&zwnj;توانید سایر پکیج&zwnj;های مورد نیاز را نصب نمایید. بعد از نصب و افزودن پکیج&zwnj;های مورد نیاز، احتمالاً فایل <span class="engliSpan">package.json</span> موجود در پروژه محتویاتی مشابه شکل زیر خواهد داشت:
                </p>

                <!-- images related to codes -->
                <div class="col-md-10 mt-5 mx-auto code-img">
                    <img class="img-fluid img-thumbnail" src="./imgFolder/package-json-min.png" alt="تصویر مربوط به فایل package.json بعد از نصب ابزارهای ذکر شده">
                </div>

                <!-- **************** -->
                <!-- part three -->
                <!-- **************** -->
                <h2 class="subTitle mt-5">شروع کدنویسی و آماده سازی صفحه مرورگر:</h2>
                <p class="text-justify textPara">
                    در این مرحله قرار است که به کمک فایل&zwnj;های جاوا&zwnj;اسکریپت ساختار پروژه را کامل&zwnj;تر نموده و بتوانیم محتویات مورد نظر خود را بر روی صفحه مرورگر به نمایش درآوریم. نکته&zwnj;ای که در ابتدا باید به آن توجه کنیم آن است که برای اجرای یک سایت به کمک <span class="engliSpan">node-js</span> معمولاً از دستورات خود نود در محیط ترمینال استفاده می&zwnj;شود. به عنوان مثال چنانچه شما بخواهید یک فایل جاوا&zwnj;اسکریپت به نام <span class="engliSpan">app.js</span> را اجرا کنید، کافی است که در محیط <span class="engliSpan">Command Prompt</span> دستور <code>node app.js</code> را تایپ نمایید. اما در این مقاله به جهت سهولت بیشتر از پکیج <a href="https://nodemon.io" target="_blank">nodemon</a> استفاده می&zwnj;شود که به صورت <span class="farsiSpan">اتوماتیک</span> با هر تغییر در فایل&zwnj;های پروژه، محیط سرور را دوباره برای ما اجرا می&zwnj;کند. برای آنکه این پکیج با ساختار پروژه ما تطبیق پیدا کند، لازم است که دستور اجرایی آن را در فایل <span class="engliSpan">package.json</span> مشخص کنیم. چنانچه به تصویر مرحله قبلی دقت نمایید، مشاهده خواهید کرد که در قسمت <span class="engliSpan">scripts</span> دستور زیر وارد شده است:
                </p>

                <!-- images related to codes -->
                <div class="col-md-10 mt-5 mx-auto code-img">
                    <img class="img-fluid img-thumbnail" src="./imgFolder/script-watch-min.png" alt="تصویر مربوط به قسمت scripts در فایل package.json ">
                </div>


                <p class="text-justify textPara mt-5">
                    این دستور بدان معنا است که برای شروع پروژه با اجرای دستور <code>npm run watch</code> پکیج <span class="engliSpan">nodemon</span> به سراغ فایل <span class="engliSpan">app.js</span> می&zwnj;رود و آن را اجرا می&zwnj;کند و سپس با هر تغییر در فایل&zwnj;های پروژه، تغییرات مورد نظر به صورت اتوماتیک اعمال می&zwnj;شوند. اما برای شروع باید فایل app.js را در ساختار پروژه ایجاد نماییم. لذا با ایجاد یک فایل جدید و نام&zwnj;گذاری آن به app.js در <span class="farsiSpan">فولدر اصلی پروژه</span>، می&zwnj;توانید محتویات زیر را به آن فایل اضافه نمایید:
                </p>

                <!-- images related to codes -->
                <div class="col-md-10 mt-5 mx-auto code-img">
                    <img class="img-fluid img-thumbnail" src="./imgFolder/app-js-min.png" alt="تصویر مربوط به فایل app.js برای شروع پروژه">
                </div>

                <p class="text-justify textPara mt-5">
                    در این فایل بعد از اضافه نمودن فریم&zwnj;ورک <a href="https://expressjs.com" target="_blank">express</a> که برای مدیریت صفحات سایت استفاده می&zwnj;شود، آدرس فایلی که قرار است کدهای مربوط به صفحات پروژه در داخل آن نوشته شود (فایل <span class="engliSpan">index.js</span> که درون فولدر <span class="engliSpan">routes</span> ایجاد می&zwnj;کنیم) به پروژه شناسانده شده است. در پایان شماره پورتی که قرار است سایت بر روی آن تست شود، معرفی شده است که در اینجا از پورت <span class="engliSpan">3100</span> استفاده می&zwnj;شود. محتویات فایل <span class="engliSpan">index.js</span> که ذکر شد، به قرار زیر است:
                </p>

                <!-- images related to codes -->
                <div class="col-md-10 mt-5 mx-auto code-img">
                    <img class="img-fluid img-thumbnail" src="./imgFolder/index-js-min.png" alt="تصویر مربوط به فایل index.js برای شروع پروژه">
                </div>

                <p class="text-justify textPara mt-5">
                    پس از ایجاد دو فایل ذکر شده چنانچه دستور <code>npm run watch</code> را در محیط ترمینال اجرا کنیم و سپس آدرس <code dir="ltr">http://localhost:3100/</code> را در مرورگر وارد نماییم، مشاهده خواهیم کرد که صفحه مرورگر متن موجود در فایل <span class="engliSpan">index.js</span> را به نمایش می&zwnj;گذارد:
                </p>

                <!-- for showing multiple content in tabs -->
                <BaseTab
                    :head-tabs = "tabDatas"
                    @indexSend = "loadTabContent($event)"
                >
                    <template>
                        <div class="col-md-10 mt-5 mx-auto code-img">
                            <img class="img-fluid img-thumbnail" :src="require(`./imgFolder/${tabDatas[activeTab].data.imgName}`)" :alt="`${tabDatas[activeTab].data.altText}`">
                        </div>
                    </template>
                </BaseTab>



                <!-- **************** -->
                <!-- part four -->
                <!-- **************** -->
                <h2 class="subTitle mt-5">معرفی بوتسترپ به پروژه و ایجاد نوار جستجو: </h2>
                <p class="text-justify textPara">
                    تاکنون به کمک جاوا&zwnj;اسکریپت و نود&zwnj;جی&zwnj;اس توانستیم محتویات ساده&zwnj;ای را بر روی صفحه مرورگر به نمایش در بیاوریم. از آنجایی که هدف ما ایجاد یک نوار جستجو در صفحه به کمک فریم&zwnj;ورک بوتسترپ است، ابتدا باید یک فایل <span class="engliSpan">HTML</span> داشته باشیم و سپس بتوانیم از المان&zwnj;ها و کلاس&zwnj;های بوتسترپ در آن استفاده نماییم. برای این منظور ابتدا در فولدر اصلی پروژه، پوشه&zwnj;ای به نام <span class="engliSpan">views</span> ایجاد می&zwnj;کنیم و در داخل آن فایل <span class="engliSpan">index.html</span> را به وجود می&zwnj;آوریم. سپس به فایل <span class="engliSpan">index.js</span> رفته و تغییرات زیر را در آن اعمال می&zwnj;کنیم:
                </p>

                <!-- images related to codes -->
                <div class="col-md-10 mt-5 mx-auto code-img">
                    <img class="img-fluid img-thumbnail" src="./imgFolder/index2-min.png" alt="تصویر مربوط به تغییرات اعمال شده در فایل index.js ">
                </div>

                <p class="text-justify textPara mt-5">
                    با اعمال این تغییرات می&zwnj;توانیم فایل HTML مورد نظر را در صفحه مرورگر مشاهده نماییم. برای امتحان کردن خروجی خود ابتدا یک فایل HTML ساده به شکل زیر تولید می&zwnj;کنیم:
                </p>

                <!-- for showing multiple content in tabs -->
                <BaseTab
                    :head-tabs = "tabDatas2"
                    @indexSend = "loadTabContent2($event)"
                >
                    <template>
                        <div class="col-md-10 mt-5 mx-auto code-img">
                            <img class="img-fluid img-thumbnail" :src="require(`./imgFolder/${tabDatas2[activeTab2].data.imgName}`)" :alt="`${tabDatas2[activeTab2].data.altText}`">
                        </div>
                    </template>
                </BaseTab>

                <p class="text-justify textPara mt-5">
                    در گام بعد باید بتوانیم بوتسترپ را به این فایل اضافه کنیم. لذا به سراغ فایل <span class="engliSpan">app.js</span> که قبلا ایجاد کرده بودیم رفته و تغییرات زیر را بر روی آن اعمال می&zwnj;کنیم:
                </p>

                <!-- images related to codes -->
                <div class="col-md-10 mt-5 mx-auto code-img">
                    <img class="img-fluid img-thumbnail" src="./imgFolder/app2-min.png" alt="تصویر مربوط به تغییرات اعمال شده در فایل app.js ">
                </div>

                <p class="text-justify textPara mt-5">
                    همان&zwnj;طور که در تصویر دیده می&zwnj;شود، تغییرات مورد نظر از خطوط 8 الی 15 رخ داده است. در ابتدا یکی از ماژول&zwnj;های مربوط به نود (<span class="engliSpan">node modules</span>) به نام <span class="engliSpan">path</span> را به فایل اضافه کرده&zwnj;ایم که در شناساندن آدرس فایل&zwnj;ها به نود&zwnj;جی&zwnj;اس کمک می&zwnj;کند. سپس به کمک امکانات این ماژول و استفاده از <a href="https://expressjs.com/en/starter/static-files.html" target="_blank">متود static</a> در فریم&zwnj;ورک <span class="engliSpan">express</span> آدرس محل فایل&zwnj;های جاوا&zwnj;اسکریپت، CSS و آیکون&zwnj;های مربوط به بوتسترپ را به نود&zwnj;جی&zwnj;اس داده&zwnj;ایم. در پایان نیز آدرس مربوط به فایل CSS که قرار است استایل&zwnj;های مد نظر <span class="farsiSpan">خودمان</span> را در آن اعمال کنیم، تعریف کرده&zwnj;ایم. با در نظر گرفتن این تغییرات، چنانچه به فایل <span class="engliSpan">index.html</span> رفته و موارد زیر را اضافه کنیم، قادر خواهیم بود تا از بوتسترپ در پروژه خود استفاده کنیم:
                </p>

                <!-- for showing multiple content in tabs -->
                <BaseTab
                    :head-tabs = "tabDatas3"
                    @indexSend = "loadTabContent3($event)"
                >
                    <template>
                        <div class="col-md-10 mt-5 mx-auto code-img">
                            <img class="img-fluid img-thumbnail" :src="require(`./imgFolder/${tabDatas3[activeTab3].data.imgName}`)" :alt="`${tabDatas3[activeTab3].data.altText}`">
                        </div>
                    </template>
                </BaseTab>

                <p class="text-justify textPara mt-5">
                    در این حالت مشاهده می&zwnj;شود که کلاس&zwnj;های بوتسترپ مانند <span class="engliSpan">text-center</span> در فایل ما قابل شناسایی هستند و خروجی آنها در مرورگر قابل رؤیت است.
                </p>

                <p class="text-justify textPara">
                    اکنون که می&zwnj;توانیم از قابلیت&zwnj;های بوتسترپ در سایت و پروژه خود استفاده کنیم، به سراغ ساخت نوار جستجو می&zwnj;رویم. طبق سایت رسمی بوتسترپ برای ایجاد <code>&lt;input&gt;</code> های مناسب و سازگار با این فریم&zwnj;ورک معمولاً از کلاس&zwnj;های <a href="https://getbootstrap.com/docs/5.1/forms/input-group/" target="_blank">input-group</a> و <a href="https://getbootstrap.com/docs/5.1/forms/form-control/" target="_blank">form-control</a> استفاده می&zwnj;شود. لذا با اضافه نمودن کد زیر به فایل <span class="engliSpan">index.html</span> می&zwnj;توانیم خروجی نوار جستجو را در صفحه مرورگر ببینیم. لازم به توضیح است که درون دکمه&zwnj;ای (<code>&lt;button&gt;</code>) که در این <span class="engliSpan">input-group</span> استفاده شده، از آیکون&zwnj;های بوتسترپ کمک گرفته شده است تا شکل آیکون ذره بین در کنار نوار جستجو به وجود آید.
                </p>

                <!-- for showing multiple content in tabs -->
                <BaseTab
                    :head-tabs = "tabDatas4"
                    @indexSend = "loadTabContent4($event)"
                >
                    <template>
                        <div class="col-md-10 mt-5 mx-auto code-img">
                            <img class="img-fluid img-thumbnail" :src="require(`./imgFolder/${tabDatas4[activeTab4].data.imgName}`)" :alt="`${tabDatas4[activeTab4].data.altText}`">
                        </div>
                    </template>
                </BaseTab>

                <!-- **************** -->
                <!-- part five -->
                <!-- **************** -->
                <h2 class="subTitle mt-5">شخصی&zwnj;سازی کردن طراحی نوار جستجو به کمک CSS :</h2>
                <p class="text-justify textPara">
                    معمولاً برای اینکه المان&zwnj;های بوتسترپ با طراحی و رنگ&zwnj;بندی سایت ما سازگار شوند، نیاز است که تغییراتی در رنگ&zwnj;ها و استایل&zwnj;های آنها اعمال شود. برای این منظور از CSS کمک می&zwnj;گیریم. ابتدا در فولدر اصلی پروژه، پوشه&zwnj;ای به نام <span class="engliSpan">assets</span> ایجاد کرده و در داخل آن پوشه دیگری به نام CSS ایجاد می&zwnj;نماییم و در نهایت در داخل آن فایل <span class="engliSpan">style.css</span> را خلق می&zwnj;کنیم. همان&zwnj;طور که در بخش قبلی ذکر شد، ما پیش از این به محیط سرور (<span class="engliSpan">node-js</span>) این فایل را شناسانده&zwnj;ایم و لینک مربوط به آنرا نیز قبلاً در فایل <span class="engliSpan">HTML</span> خود وارد کرده&zwnj;ایم. لذا تنها کافی است که استایل&zwnj;های مد نظر خود را در این فایل ایجاد شده وارد نماییم.
                </p>

                <p class="text-justify textPara">
                    معمولا رنگ&zwnj;بندی هر سایت با سایت&zwnj;های دیگر متفاوت خواهد بود. در این پروژه برای تغییر طراحی&zwnj;های پیش&zwnj;فرض بوتسترپ، استایل&zwnj;های <span class="farsiSpan">دلخواه</span> زیر را در فایل CSS خود اعمال می&zwnj;کنیم. همچنین نیاز داریم که تغییراتی در فایل index.html ایجاد کنیم. به شکل واضح&zwnj;تر باید بتوانیم <span class="engliSpan">id</span> هایی را به المان&zwnj;های مد نظر اضافه کنیم تا فایل CSS از طریق آنها اثرگذار شود. لذا فایل&zwnj;های CSS ، HTML و خروجی آنها را به شکل زیر خواهیم داشت:
                </p>

                <!-- for showing multiple content in tabs -->
                <BaseTab
                    :head-tabs = "tabDatas5"
                    @indexSend = "loadTabContent5($event)"
                >
                    <template>
                        <div class="col-md-10 mt-5 mx-auto code-img">
                            <img class="img-fluid img-thumbnail" :src="require(`./imgFolder/${tabDatas5[activeTab5].data.imgName}`)" :alt="`${tabDatas5[activeTab5].data.altText}`">
                        </div>
                    </template>
                </BaseTab>

                <!-- **************** -->
                <!-- part six -->
                <!-- **************** -->
                <h2 class="subTitle mt-5">اضافه نمودن جاوا&zwnj;اسکریپت به پروژه:</h2>
                <p class="text-justify textPara">
                    در انتها برای زیباتر شدن خروجی کار قصد داریم در صورتی که کاربر <strong>بدون وارد کردن</strong> متنی دکمه جستجو را کلیک نماید، پیغامی ظاهر شود که به کاربر اخطار دهد که باید متنی را در نوار جستجو تایپ نماید. برای این منظور از <a href="https://getbootstrap.com/docs/5.1/components/popovers/" target="_blank">bootstrap popovers</a> استفاده خواهیم کرد. برای عملیاتی شدن این ایده کافی است در انتهای فایل <span class="engliSpan">index.html</span> بعد از افزودن فایل جاوا&zwnj;اسکریپت بوتسترپ یک تگ <code>&lt;script&gt;</code> اضافه کرده و کد زیر را در آن بنویسیم:
                </p>

                <!-- images related to codes -->
                <div class="col-md-10 mt-5 mx-auto code-img">
                    <img class="img-fluid img-thumbnail" src="./imgFolder/popover-js-min.png" alt="تصویر مربوط به کد جاوا اسکریپت برای ایجاد bootstrap popovers">
                </div>

                <p class="text-justify textPara mt-5">
                    لازم به یادآوری است برای آنکه <span class="engliSpan">bootstrap popover</span> در صفحه نمایش داده شود، نیاز است نسخه <span class="engliSpan">bootstrap.bundle.js</span> به پروژه اضافه شود. در غیر این صورت باید خود کتابخانه <a href="https://popper.js.org" target="_blank">Popper</a> که این کامپوننت بوتسترپ بر مبنای آن کدنویسی شده است، به پروژه اضافه شود. چنانچه به کد نوشته شده دقت شود، مشاهده خواهیم کرد که ابتدا دکمه موجود در فایل که با <span class="engliSpan">id=btnSearch</span> قبلا مشخص شده است، در یک متغیر ذخیره شده و سپس به کمک دستور <code dir="ltr">new bootstrap.Popover()</code> تنظیمات مربوط به <span class="engliSpan">popover</span> بیان شده است. در نهایت با به&zwnj;کارگیری متودهای <span class="engliSpan" dir="ltr">show()</span> و <span class="engliSpan" dir="ltr">hide()</span> مربوط به بوتسترپ توانسته&zwnj;ایم در حالتی که نوار جستجو خالی بوده و دکمه کلیک شود، popover را به نمایش درآوریم. همچنین در حالت کلیک در خارج دکمه (اصطلاحا در حالت <span class="engliSpan">blur</span>) popover را پنهان کرده&zwnj;ایم. برای آنکه طراحی و رنگ&zwnj;بندی popover با سایت ما همخوانی داشته باشد، نیاز است که کدهای زیر را به فایل CSS خود اضافه کنیم:
                </p>

                <!-- images related to codes -->
                <div class="col-md-10 mt-5 mx-auto code-img">
                    <img class="img-fluid img-thumbnail" src="./imgFolder/customize-popover-min.png" alt="تصویر مربوط به استایل های داده شده به popover ">
                </div>

                <p class="text-justify textPara mt-5">
                    در پایان پس از اعمال تغییرات ذکر شده خروجی کار به شکل زیر خواهد بود:
                </p>

                <!-- images related to codes -->
                <div class="col-md-10 mt-5 mx-auto code-img">
                    <img class="img-fluid img-thumbnail" src="./imgFolder/final-min.gif" alt="تصویر مربوط به خروجی نهایی نوار جستجو ساخته شده ">
                </div>

                <!-- **************** -->
                <!-- conclusion part -->
                <!-- **************** -->
                <h2 class="subTitle pb-3 mt-5">جمع بندی: </h2>
                <blockquote class="blockquote conclu">

                    <p class="text-justify textPara pt-3">
                        در این مقاله سعی ما بر آن بود تا به کمک محیط نود&zwnj;جی&zwnj;اس و فریم&zwnj;ورک&zwnj;های اکسپرس و بوتسترپ یک نوار جستجو ساخته و سپس به وسیله فایل CSS خودمان، طراحی مورد نظر را به آن اعمال نماییم. البته آنچه در این مقاله انجام شد به کمک یک فایل HTML و فایل&zwnj;های CSS و JS مرتبط با آن نیز <span class="farsiSpan">قابل انجام</span> است، ولی هدف ما به&zwnj;کارگیری محیط نود&zwnj;جی&zwnj;اس بود. چرا که این ابزار برنامه&zwnj;نویسی به نوعی به عنوان یک زبان <span class="engliSpan">back-end</span> در طراحی وب&zwnj;سایت در نظر گرفته می&zwnj;شود و یادگیری و استفاده از آن برای قسمت&zwnj;هایی از سایت که ممکن است با سرور در ارتباط باشند (مانند همین نوارهای جستجو در سایت&zwnj;ها که داده&zwnj;ها را به سرور ارسال می&zwnj;کنند)، ضروری به نظر می&zwnj;آید.
                    </p>

                </blockquote>

            </div>



            <!-- ways number 1 of loading imgs  -->
            <!--
            <img :src='`${this.publicPath}articles/${this.$route.params.id}/first.png`' alt="تصویر نمونه">
-->

                <!-- ways number 2 of loading imgs  -->
                <!--
               <img src='./imgFolder/img1.png' alt="تصویر نمونه">
               -->
        </div>
</template>

<script>
import BaseTab from '../../BaseTab.vue';

export default {
                name: "BaseArticle",
                components: {
                    BaseTab
                },
                data() {
                    return {
                        activeTab: 0,
                        activeTab2: 0,
                        activeTab3: 0,
                        activeTab4: 0,
                        activeTab5: 0,

                        tabDatas: [
                            {
                                tabHeders: "Command Prompt",
                                data:{
                                    imgName: "npm-run-watch-before-bootstrap-min.png",
                                    altText: "تصویر مربوط به اجرای دستور npm run watch  در ترمینال"
                                }
                            },

                            {
                                tabHeders: "Result",
                                data:{
                                    imgName: "it-works-min.png",
                                    altText: "تصویر مربوط به خروجی اجرای دستور npm run watch "
                                }
                            }
                        ], // end of tabDatas

                        tabDatas2: [
                            {
                                tabHeders: "HTML",
                                data:{
                                    imgName: "simple-html-min.png",
                                    altText: "تصویر مربوط به کد HTML استفاده شده در پوشه views"
                                }
                            },

                            {
                                tabHeders: "Result",
                                data:{
                                    imgName: "simple-browser-min.png",
                                    altText: "تصویر مربوط به خروجی کد HTML استفاده شده در پوشه views"
                                }
                            }
                        ], // end of tabDatas2

                        tabDatas3: [
                            {
                                tabHeders: "HTML",
                                data:{
                                    imgName: "index-added-bootstrap-min.png",
                                    altText: "تصویر مربوط به تغییرات اعمال شده در فایل index.html برای اضافه کردن بوتسترپ"
                                }
                            },

                            {
                                tabHeders: "Result",
                                data:{
                                    imgName: "bootstrap-add-browser-min.png",
                                    altText: "تصویر مربوط به خروجی تغییرات اعمال شده در فایل index.html برای اضافه کردن بوتسترپ"
                                }
                            }
                        ], // end of tabDatas3

                        tabDatas4: [
                            {
                                tabHeders: "HTML",
                                data:{
                                    imgName: "search-bootstrap-min.png",
                                    altText: "تصویر مربوط به تغییرات اعمال شده در فایل index.html برای ایجاد نوار جستجو"
                                }
                            },

                            {
                                tabHeders: "Result",
                                data:{
                                    imgName: "search-bootstrap-min.gif",
                                    altText: "تصویر مربوط به خروجی تغییرات اعمال شده در فایل index.html برای ایجاد نوار جستجو"
                                }
                            }
                        ], // end of tabDatas4

                        tabDatas5: [
                            {
                                tabHeders: "HTML",
                                data:{
                                    imgName: "index-customize-min.png",
                                    altText: "تصویر مربوط به تغییرات اعمال شده در فایل index.html برای پیاده سازی استایل های دلخواه"
                                }
                            },

                            {
                                tabHeders: "CSS",
                                data:{
                                    imgName: "css-customize-min.png",
                                    altText: "تصویر مربوط به فایل CSS برای پیاده سازی استایل های دلخواه"
                                }
                            },

                            {
                                tabHeders: "Result",
                                data:{
                                    imgName: "index-css-min.gif",
                                    altText: "تصویر مربوط به خروجی نوار جستجو بعد از اعمال استایل های دلخواه"
                                }
                            }
                        ] // end of tabDatas5

                    }
                }, // end of data

                methods: {
                    loadTabContent: function(indexNow) {
                        this.activeTab = indexNow;
                    },

                    loadTabContent2: function(indexNow2) {
                        this.activeTab2 = indexNow2;
                    },

                    loadTabContent3: function(indexNow3) {
                        this.activeTab3 = indexNow3;
                    },

                    loadTabContent4: function(indexNow4) {
                        this.activeTab4 = indexNow4;
                    },

                    loadTabContent5: function(indexNow5) {
                        this.activeTab5 = indexNow5;
                    }
                }

        }
</script>

<style scoped></style>
