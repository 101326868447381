<template>
        <div class="col-md-10 mx-auto">

            <div class="text-right mt-5">

                <!-- intro text -->
                <h2 class="subTitle">مقدمه: </h2>
                <blockquote class="blockquote">
                    <p class="text-justify textPara pb-5">
                        احتمالا تمام افرادی که در حیطه برنامه&zwnj;نویسی فعالیت می&zwnj;کنند، نام این دو متود برنامه&zwnj;نویسی را شنیده&zwnj;اند: برنامه&zwnj;نویسی <span class="farsiSpan">شئ&zwnj;محور</span> (OOP) و برنامه&zwnj;نویسی <span class="farsiSpan">رویه&zwnj;ای</span> (FP). در این مقاله قصد داریم به توضیح مختصری در خصوص هر یک بپردازیم. هدف ما در اینجا این <span class="farsiSpan">نیست</span> که بگوییم کدام روش برنامه&zwnj;نویسی از دیگری بهتر است، چرا که در اصل نیز بهتر بودن معنای خاصی <strong>نخواهد</strong> داشت. بلکه هر یک از این دو روش کارایی&zwnj;ها و توانمندی&zwnj;های خاص خود را دارند. معمولا برنامه&zwnj;نویسان با  توجه به نیاز و سلیقه خود به سراغ یکی از آنها می&zwnj;روند. ضمن آنکه گاهی دیده می&zwnj;شود که در برخی از پروژه&zwnj;ها از ترکیبی از این دو نیز استفاده می&zwnj;شود.
                    </p>
                </blockquote>

                <!-- paragraphs -->
                <h2 class="subTitle mt-5">شناخت FP و OOP :</h2>
                <p class="text-justify textPara">
                    وقتی صحبت از مقایسه دو روش برنامه&zwnj;نویسی می&zwnj;شود، ابتدا باید بتوانیم آنها را به درستی تعریف نموده و بشناسیم. در برنامه&zwnj;نویسی OOP همه چیز حول محوری به نام <span class="farsiSpan">شئ</span> (Object) می&zwnj;گردد. در واقع شئ به عنوان هسته اصلی برنامه&zwnj;نویسی است که ویژگی&zwnj;ها و کارهای مرتبط با آن توسط متغیرها و توابع (متودها) تعریف شده درون آن مدیریت می&zwnj;شود و یک واحد یکپارچه را به وجود می آورد.
                </p>

                <p class="text-justify textPara">
                    در مقابل در برنامه&zwnj;نویسی رویه&zwnj;ای یا به عبارتی تابع&zwnj;محور (FP)، سعی بر تفکیک نمودن داده&zwnj;ها از عملیات (توابع) است و در واقع چیزی به عنوان یک واحد یکپارچه که ویژگی&zwnj;ها و متودهایی برای آن تعریف شود، وجود <span class="farsiSpan">ندارد</span>. بلکه روند برنامه نویسی به کمک تعریف کردن متغیرها به صورت مستقل از یک سو و توابع نیز به صورت مجزا از سوی دیگر صورت می&zwnj;گیرد که با فراخوانی توابع و وارد کردن داده به آنها مسئله مورد نظر حل می&zwnj;شود.
                </p>

                <h2 class="subTitle mt-5">شباهت&zwnj;های OOP و FP :</h2>
                <p class="text-justify textPara">
                    هر دو روش برنامه&zwnj;نویسی اهداف مشترکی را دنبال می&zwnj;کنند که می توان برخی از آنها را به این نحو بیان نمود:
                </p>
                <p class="text-justify textPara">
                    <BaseList :list-data = "listText"></BaseList>
                </p>

                <h2 class="subTitle mt-5">تفاوت&zwnj;های OOP و FP :</h2>
                <p class="text-justify textPara">
                    از جمله مواردی که می&zwnj;توان به عنوان وجه تمایز میان این دو روش ذکر کرد عبارتند از:
                </p>
                <p class="text-justify textPara">
                    <BaseList :list-data = "listText2"></BaseList>
                </p>

                <h2 class="subTitle mt-5">مفاهیم مورد استفاده:</h2>
                <!-- paragraphs -->
                <p class="text-justify textPara">
                    تا اینجا در خصوص کلیت برنامه&zwnj;نویسی به دو روش FP و OOP صحبت کردیم و به برخی تفاوت&zwnj;ها و شباهت&zwnj;های میان این دو روش نیز اشاره شد. در ادامه به برخی مفاهیم کاربردی که در هر یک از این روش&zwnj;ها به خصوص در زبان برنامه نویسی <span class="farsiSpan">جاوا اسکریپت</span>، استفاده می&zwnj;شود، به شکل مختصری اشاره خواهد شد. لازم به توضیح است که برای درک هر یک از این مفاهیم، مطالعه و تمرین بیشتری نیاز است و هدف این مقاله آموزش این مفاهیم <strong>نیست</strong>.
                </p>
                <!-- when you want to have "indented" texts -->
                <div class="indentPara">
                    <!-- Base concepts in FP method -->
                    <h3 class="subTitle2 mt-5">مفاهیم پایه در روش FP :</h3>
                    <p class="text-justify textPara">
                        <strong class="strongFirst"><u>ترکیب توابع (function composition)</u>: </strong>این مفهوم به ترکیب کردن توابع اشاره می&zwnj;کند. به عبارت دیگر در روش FP و طراحی و ساخت توابع گوناگون، حالت بهینه آن است که هر تابع تنها مخصوص به انجام یک کار <span class="farsiSpan">منحصر به فرد</span> باشد، اما گاهی نیاز است که چندین عملیات که هر یک توسط تابع خاص خود انجام می&zwnj;گیرد، <strong>پشت سر هم</strong> به نتیجه برسند. در نتیجه لازم است که خروجی یک تابع به عنوان ورودی تابع دیگر استفاده شود و این همان مفهوم ترکیب کردن توابع است. شاید بتوان با نماد ریاضی آن را به شکل زیر نمایش داد:
                    </p>
                    <!-- when you want to have center texts -->
                    <strong class="strongFirst d-block text-center">f(g(x))</strong>
                    <p class="text-justify textPara mt-1">
                        منظور از این نماد آن است که تابع g ورودی x را می&zwnj;پذیرد و خروجی آن که g(x) باشد، خود به عنوان ورودی به تابع f داده می&zwnj;شود.
                    </p>

                    <p class="text-justify textPara">
                        <strong class="strongFirst"><u>تابع خالص (pure function)</u>: </strong>شاید بتوان به بیان ساده یک تابع خالص را در <strong>جاوا اسکریپت</strong>، تابعی دانست که دو ویژگی زیر را داشته باشد:
                        <BaseList :list-data = "listText3"></BaseList>
                    </p>

                    <p class="text-justify textPara">
                        به عنوان یک مثال ساده از تابع خالص می توان تابعی را در نظر گرفت که تنها دو ورودی می&zwnj;گیرد و مثلا آنها را در یکدیگر ضرب می&zwnj;کند و خروجی را بر می&zwnj;گرداند. از جمله دلایلی که در روش FP از اینگونه توابع استفاده می&zwnj;شود آن است که این توابع اثرات جانبی از خود به جای <strong>نمی&zwnj;گذارند</strong> و این امر باعث واضح&zwnj;تر شدن و خواناتر شدن و کاربری آسان&zwnj;تر کد ما می&zwnj;شود.
                    </p>

                    <p class="text-justify textPara">
                        <strong class="strongFirst"><u>تکرار شوندگی (recursion)</u>: </strong>این مفهوم در برنامه&zwnj;نویسی به معنای آن است که توابع ما به شکل متناوب و تکراری فراخوانی شوند. مثلا فرض کنید تابعی وظیفه انجام محاسبه خاصی را بر عهده دارد و سپس در جایی درون خود این تابع، <span class="farsiSpan">خودش</span> را فراخوانی می&zwnj;کند. به این ترتیب تمامی عملیات محاسبات دوباره تکرار می&zwnj;شود (مانند حالتی که درون حلقه&zwnj;های برنامه&zwnj;نویسی رخ می&zwnj;دهد). این مفهوم نیز از جمله مفاهیمی است که به خصوص در روش FP برای جلوگیری از مقداردهی مکرر به متغیرها استفاده می&zwnj;شود.
                    </p>
                    <!-- Base concepts in OOP method -->
                    <h3 class="subTitle2 mt-5">مفاهیم پایه در روش OOP :</h3>
                    <p class="text-justify textPara">
                        <strong class="strongFirst"><u>خلاصه سازی (encapsulation)</u>: </strong>این مفهوم از جمله مفاهیم پرکاربرد در روش OOP است و به خاصیتی در برنامه&zwnj;نویسی شئ&zwnj;محور اشاره می&zwnj;کند که در آن متغیرهایی که تنها برای داخل ساختار یک شئ (object) مفید هستند و ارتباطی با خارج از ساختار آن (سایر قسمت&zwnj;های کد یا اشیاء دیگر) <span class="farsiSpan">ندارند</span>، از دسترس خارج می&zwnj;شوند. یعنی به کمک قابلیت&zwnj;های این مفهوم اشیاء دیگر <strong>نمی&zwnj;توانند</strong> به چنین متغیرهایی دسترسی داشته باشند (مثلا مقدار آنرا تغییر دهند و ...). این خاصیت علاوه بر اینکه به خوانایی و روان&zwnj;تر&zwnj;شدن کد کمک می&zwnj;کند، از بروز اشتباهات ناخواسته در امر برنامه&zwnj;نویسی نیز جلوگیری می&zwnj;کند.
                    </p>

                    <p class="text-justify textPara">
                        <strong class="strongFirst"><u>پنهان&zwnj;سازی (abstraction)</u>: </strong>این مفهوم نیز مشابه مفهوم خلاصه سازی عمل می&zwnj;کند. با این تفاوت که در واقع <strong>نحوه اجرای کد</strong> را پنهان می&zwnj;کند. یعنی برای جلوگیری از تداخل با سایر قسمت&zwnj;های کد، توابع و عملیاتی که درون یک شئ تعریف می&zwnj;شود و به سایر قسمت&zwnj;ها ارتباط ندارد، از دسترس خارج می&zwnj;شود. با این کار توسعه نرم افزارها و رفع ایرادهای آتی آنها نیز تا حدودی راحت&zwnj;تر خواهد بود.</p>

                    <p class="text-justify textPara">
                        <strong class="strongFirst"><u>کلاس (class)</u>: </strong>کلاس&zwnj;ها نیز از جمله مفاهیم مهم و پایه در روش OOP هستند. در واقع کلاس به عنوان یک طرح کلی (یا به اصطلاح <strong>blueprint</strong>) برای شئ (object) ایفای نقش می&zwnj;کند. با تعریف کلاس و ویژگی&zwnj;ها و متودهای مربوط به آن می توانیم اشیاء مورد نیاز خود را از آن اقتباس کنیم و تنها <span class="farsiSpan">مقادیر</span> ویژگی&zwnj;ها و یا پارامترهای کلاس&zwnj;ها را برای هر شئ تغییر دهیم. در اصل این مفهوم در ساختارهای قبلی جاوا اسکریپت به کمک ویژگی <span class="engliSpan">prototype</span> پیاده سازی می&zwnj;شد و حدودا از سال 2015 به بعد (<span class="englObl">JS ES6</span>) واژه کلاس به زبان جاوا اسکریپت اضافه شده است.</p>
                </div> <!-- end of ".indentPara" block -->


                <!-- conclusion part -->
                <h2 class="subTitle pb-3 mt-5">جمع بندی: </h2>
                <blockquote class="blockquote conclu">
                    <p class="text-justify textPara pt-3">
                        در این مقاله به صورت مختصر به معرفی روش های OOP و FP در ساختار برنامه&zwnj;نویسی پرداخته شد. در ابتدا تعریف کلی این دو روش بیان شد و سپس به تفاوت&zwnj;ها و شباهت&zwnj;های میان آن دو اشاره شد. در پایان نیز برخی مفاهیم اصلی در این روش&zwnj;ها (صرفا به جهت آشنایی مخاطبان) مطرح شد. مشخص است که هر یک از این روش&zwnj;ها دارای پیچیدگی&zwnj;ها و مباحث خاص خود هستند که پرداختن به آنها خارج از حدود این مقاله است. یادگیری جزئیات چنین روش&zwnj;هایی و حرفه ای شدن در آنها می&zwnj;تواند برای برنامه&zwnj;نویسان در انجام پروژه&zwnj;ها مزیت مهمی محسوب شود.
                    </p>
                </blockquote>

            </div>

        </div>
</template>

<script>
import BaseList from '../../BaseList.vue';

export default {
                name: "BaseArticle",
                components: {
                    BaseList
                },
                /* ----------------------------- */
                data() {
                    return {
                        listText: [
                            /* this is the data used for BaseList.vue. the "strongText" data is optional and may be present in some list and not in other lists. */
                            {
                                icon: "fad fa-angle-left",
                                strongText: "کدنویسی تمیز و واضح",
                                liText: "هر دو روش در حقیقت ابزارهایی هستند که به برنامه&zwnj;نویسان کمک می&zwnj;کنند تا بتوانند کدنویسی مشخص و تبیین شده&zwnj;ای داشته باشند. در روش OOP این کار به کمک <strong>کلاس&zwnj;های</strong> مجزا که هر یک ویژگی&zwnj;های خاص خود را دارند صورت می&zwnj;گیرد. در روش FP این مسئله به کمک <strong>توابعی</strong> که هر یک عملیات و نقش مخصوص خود را در حل مسئله اجرا می&zwnj;کنند، صورت می&zwnj;پذیرد."
                            },

                            {
                                icon: "fad fa-angle-left",
                                strongText: "ذخیره حافظه",
                                liText: "در هر دو روش ابزارهایی برای پیشگیری از استفاده بیش از حد از حافظه تبیین شده است. در روش OOP مفهومی به نام <span class=engliSpan >inheritance</span> وجود دارد که از زیاد شدن حجم کد جلوگیری می&zwnj;کند و در روش FP نیز مفهومی به نام <span class=engliSpan >closures</span> وجود دارد که به این مسئله کمک می&zwnj;کند."
                            },
                            {
                                icon: "fad fa-angle-left",
                                strongText: "جلوگیری از تکرار",
                                liText: 'در کدنویسی قاعده ای به نام <span class=engliSpan>DRY</span> (Do not repeat yourself) وجود دارد که مفهوم آن، این است که کدهای ما نباید تکراری باشند و به شکل غیر&zwnj;بهینه&zwnj;ای نوشته شوند. در برنامه&zwnj;نویسی FP این مفهوم به کمک توابع اجرا می&zwnj;شود. چرا که توابع در اصل مجموعه&zwnj;ای از کدها هستند که عملیاتی خاص را در هنگام فراخوانی انجام می&zwnj;دهند. در نتیجه دیگر نیازی نیست که هر بار کد لازم برای اجرای آن عمل را بنویسیم. در روش OOP نیز با تعریف کلاس&zwnj;ها و سپس استخراج اشیاء از آن کلاس&zwnj;ها در حقیقت چنین هدفی را دنبال می&zwnj;کنیم.'
                            }

                        ],  // end of liText
                        listText2: [
                            {
                                icon: "fad fa-chevron-square-left",
                                strongText: "قابلیت برنامه&zwnj;نویسی موازی",
                                liText: "قابلیت برنامه&zwnj;نویسی موازی (parallel programming) توسط FP پشتیبانی می&zwnj;شود ولی به وسیله روش OOP <span class = farsiSpan>نمی&zwnj;توان</span> چنین حالتی از برنامه نویسی را پشتیبانی نمود."
                            },
                            {
                                icon: "fad fa-chevron-square-left",
                                strongText: "مدل برنامه&zwnj;نویسی",
                                liText: "اصطلاحا گفته می&zwnj;شود که مدل برنامه&zwnj;نویسی در OOP به شکل <span class = engliSpan >imperative</span> است ولی در روش FP مدل برنامه&zwnj;نویسی به شکل <span class = engliSpan >declarative</span> است. به عبارت ساده تر در روش FP آنچه که یک برنامه باید به انجام برساند، تبیین می&zwnj;شود ولی در روش OOP نحوه و چگونگی به انجام رساندن آن (روند انجام کار) معرفی می شود."
                            },
                            {
                                icon: "fad fa-chevron-square-left",
                                strongText: "ترتیب اجرای کد",
                                liText: "در برنامه&zwnj;نویسی OOP ترتیب اجرای کد اهمیت دارد. به عبارتی مراحل کار دارای ترتیب و اولویت&zwnj;بندی است. در حالیکه در روش FP اینگونه <span class = farsiSpan>نیست</span> و چون قسمتهای مختلف کد به یکدیگر وابستگی ندارند، می&zwnj;توان آنها را با هر ترتیبی اجرا نمود."
                            },
                            {
                                icon: "fad fa-chevron-square-left",
                                strongText: "نحوه استفاده",
                                liText: "شاید کاربردی ترین تفاوت میان این دو روش در همین نحوه استفاده آنها باشد. معمولا از آنجایی که در روش OOP کلاس&zwnj;هایی تعریف می&zwnj;شوند و بعد می توان از آنها به هر مقدار که نیاز است، تولید کرده و قابلیت آن کلاس را در اشیاء مختلف به کار گرفت، این روش برای مسائلی که در آنها نیاز به تعداد زیادی از اشیاء با ویژگی&zwnj;های متفاوت ولی  <span class = farsiSpan>ساختار مشابه</span> احساس می&zwnj;شود، مناسب است و در مسائلی که نیاز به عملیات تابعی مختلف و فراوان دارند، استفاده از این روش چندان مناسب <span class = farsiSpan>نیست</span>. به عنوان مثال چنانچه هدف ما از نوشتن یک برنامه این باشد که تعداد زیادی دایره در صفحه نمایش تولید کنیم که هر یک ابعاد و رنگ خاص خود را دارند، شاید روش OOP گزینه مناسبی باشد. اما چنانچه قرار باشد یک دایره تولید کنیم که کارها و قابلیت&zwnj;های متفاوتی را بروز دهد، احتمالا استفاده از روش FP مناسب&zwnj;تر خواهد بود. چرا که معمولا در شرایطی که داده اندکی داشته باشیم (یک دایره)، ولی تعداد عملیات (توابع) ما زیاد باشد، روش FP حالت بهینه&zwnj;تری خواهد بود."
                            }
                        ], // end of listText2
                        listText3: [
                            {
                                icon: "fas fa-hand-point-left",
                                strongText: "ویژگی اول",
                                liText: "تابع خالص به مقادیر متغیرهایی که خارج از تابع تعریف می&zwnj;شوند (به اصطلاح متغیرهای non-local) بستگی <span class = farsiSpan>ندارد</span> و این متغیرها در عملیات تابع دخالت داده نمی&zwnj;شوند."
                            },
                            {
                                icon: "fas fa-hand-point-left",
                                strongText: "ویژگی دوم",
                                liText: "تابع خالص اثرات جانبی (<span class = engliSpan >side effect</span>) از خود به جای نمی&zwnj;گذارد. یعنی این تابع مقادیر متغیرهای خارج از محدوده خود را تغییر نمی&zwnj;دهد و یا توابع غیر خالص دیگر را در خود فراخوانی نمی&zwnj;کند و به عبارتی <span class = farsiSpan>تنها و فقط</span> عملیاتی را انجام می&zwnj;دهد که در خود تابع جریان دارد و روی سایر قسمت&zwnj;های کد تأثیر نمی&zwnj;گذارد."
                            }
                        ],
                        // publicPath: process.env.BASE_URL,
                    }
                }
        }
</script>

<style scoped></style>

