<template>
<!-- This page (view) is for showing articles's previews and also some functionality related to articles like "search bar" or "pagination" or "sorting articles" ...  -->
  <div class="container-fluid parentAll">
    <div class="row">
        <!-- ################## -->
        <!-- article previews part -->
        <!-- ################## -->
        <div class="col-xl-9 col-lg-8 col-12 order-lg-1 order-2">
            <section>
                <div>

                <!-- search bar of the page. -->
                <BaseSearch
                    :key = "this.filterData"
                    :search-item = "this.searchText"
                    :srt-prop = "this.columnData"
                    @blurFunc = "checkEmpt($event)"
                    @searchDone="newArti($event)"
                >
                </BaseSearch>
                </div>

                <!-- message of not finding search result -->
                <div id="errMess" class="d-none col-md-10 mx-auto text-center p-3">متأسفانه در خصوص کلید واژه جستجو شده موردی یافت نشد. لطفا کلید&zwnj;واژه&zwnj;های مشابه و حتی&zwnj;الامکان کوتاه دیگر را امتحان نمایید.</div>

                <DividerCircle></DividerCircle>

                <!-- showing messages according to filter or sort -->
                <div v-if="filterData.length" class="jumbotron mx-auto col-md-10 d-flex justify-content-center align-items-center" id="messageFilter">
                <span class="badge p-2">مقالات حاوی برچسب {{ this.filterData }}</span>
                </div>

                <!-- showing articles previews -->
                <div v-if = "showFlag">
                <ArticlePrev
                    v-for="(artiItem, indexItem) in articlesInfo"
                    :key="artiItem.id"
                    :info-art="artiItem"
                    :tags="tgData[indexItem] ? tgData[indexItem][1] : []"
                >
                </ArticlePrev>
                </div>

                <!-- pagination part of the site. -->
                <div v-if = "showPagin">
                    <BasePagination
                        :key = "this.pageNum"
                        @changePage="definePage($event)"
                        :page-shows = "4"
                        :count-page = "this.kolPage"
                    >
                    </BasePagination>
                </div>
            </section>
        </div>
        <!-- ################## -->
        <!-- side bar part -->
        <!-- ################## -->
        <div class="col-xl-3 col-lg-4 col-12 order-lg-2 order-1">
            <aside>
                <div class="row justify-content-around align-items-center">
                    <div class="col-md-5 col-sm-6 col-9 mx-auto col-lg-12">
                    <!-- first radio inputs for sorting articles. -->
                    <BaseRadio
                        :radio-name="radio1Name"
                        :item-options="dataRadio1"
                        @changeRadio="sortFunc($event)"
                    >
                        مرتب سازی مقالات بر اساس:
                    </BaseRadio>
                    </div>

                    <div class="d-none d-lg-block w-100">
                    <DividerLine
                        reso="md"
                        :size="11"
                    >
                    </DividerLine>
                    </div>

                    <div class="col-md-5 col-sm-6 col-9 mx-auto col-lg-12">
                    <!-- second radio inputs for filtering articles with their tags -->
                    <BaseRadio
                        :radio-name="radio2Name"
                        :item-options="dataRadio2"
                        check-val = "nparam"
                        @changeRadio="filterFunc($event)"
                        :key = "this.offRadio"
                    >
                        انتخاب مقالات بر اساس موضوع:
                    </BaseRadio>

                    <!-- buttons for destroying filters and showing all articles again. -->
                    <BaseButton
                        id="myFilter"
                        class="text-center"
                        @routing="filterNone"
                        color2-prop
                        type-prop="button"
                        linked="false"
                        text="حذف فیلتر انتخابی"
                    >
                    </BaseButton>
                    </div>
                </div>

            </aside>
        </div>
    </div>
  </div>
</template>

<script>
/* importing components needed for this page */
/* ----------------------------- */
import ArticlePrev from '../components/ArticlePrev.vue';
import BasePagination from '../components/BasePagination.vue';
import BaseSearch from '../components/BaseSearch.vue';
import DividerCircle from '../components/DividerCircle.vue';
import DividerLine from '../components/DividerLine.vue';
import BaseRadio from '../components/BaseRadio.vue';
import BaseButton from '../components/BaseButton.vue';

export default {
  name: 'Articles',
  /* ----------------------------- */
  components: {
      ArticlePrev,
      BasePagination,
      BaseSearch,
      DividerCircle,
      DividerLine,
      BaseRadio,
      BaseButton
  },

/* ---------------------- */
/* end of components */
/* ---------------------- */

  data: () => ({
      showFlag: false,
      showPagin: false,
      articlesInfo: [], /* This is the array that contains the information about articles. */
      tgData: [],
      addNumArti: 8,
      pageNum: 1, /* This is the data that uses to define which page must be rendered. */
      allRows: null,
      idSearch: [], /* This data is used when the user searches on search bar (BaseSearch component) */
      searchText: "",
      columnData: "update_date",
      filterData: "",
      offRadio: 0,
      radio1Name: "radioOpt1",
      dataRadio1: [
          {
              label: "زمان به روز رسانی",
              value: "update_date"
          },
          {
              label: "میزان محبوبیت",
              value: "likes"
          },
          {
              label: "سطح مقاله",
              value: "level"
          }
      ],
      radio2Name: "radioOpt2",
      dataRadio2: [
          {
              label: "سی اس اس",
              value: "CSS"
          },
          {
              label: "جاوا اسکریپت",
              value: "JavaScript"
          },
          {
              label: "بوتسترپ",
              value: "Bootstrap"
          },
          {
              label: "ویو جی اس",
              value: "Vue"
          }
        ]
  }),

/* ---------------------- */
/* end of data */
/* ---------------------- */

  computed: {

    numberArti() {
        /* the initial number of articles that are showed in each page is 8 */
        if ( this.idSearch.length < 1 || this.idSearch.length > 8 ) {
            return 8;
        } else {
            return this.idSearch.length;
        }
    },

    kolPage() {
        /* calculate the numbers of all pages according to the data from database. */
        let kolPageha = Math.ceil(this.allRows / this.addNumArti);
        return kolPageha;
    }

  },

/* ---------------------- */
/* end of computed data */
/* ---------------------- */

  methods: {
            //  for fetching data and linking to backend
            /* ---------------------- */
            dataFetch: function (pagePara) {
                 let artiId =  this.numberArti + ( this.addNumArti * (pagePara - 1) ); /* this variable is used as "url params" in the backend of site. the role of that is to clear how many articles must be extracted from database. */
                 if ( this.idSearch.length < 1 ) {
                     /* this section is running when the search result is empty or the user does not search anything (initial load) or when the user filters data.   */
                     const myInit = {
                         method: 'GET',
                         mode: 'cors',
                         cache: 'default'
                     };
                     // sending request to back-end
                     fetch(this.$backUrl + "sql/articles/" + artiId + "?sortId=" + this.columnData + "&filterId=" + this.filterData, myInit)
                         .then((response) => {
                             if (!response.ok) {
                                 throw new Error(`HTTP error! status: ${response.status}`);
                             }
                             return response.json();
                         })
                         .then(result => {
                             this.articlesInfo = result;
                             let tempArr2 = [];
                             result.forEach(function (item7, index7) {
                                tempArr2.push(item7.id);
                             })
                             this.tagFetch(tempArr2);
                             this.showFlag = true;
                         });
                     } // end of if statement
                     else {
                         let sortColumn1 = this.columnData;
                         let idSend = this.idSearch;

                         const myInit = {
                             method: 'POST',
                             mode: 'cors',
                             cache: 'default',
                             headers: {
                                'Content-Type': 'application/json;charset=utf-8'
                             },
                             body: JSON.stringify({
                                 dataInfo: [idSend, sortColumn1]
                             })
                         };
                         // sending request to back-end
                         fetch(this.$backUrl + "search/article/" + artiId, myInit)
                             .then((response) => {
                                 /* response here is the response of express.js backend server and we must "return" it.  */
                                 if (!response.ok) {
                                     throw new Error(`HTTP error! status: ${response.status}`);
                                 }
                                 return response.json();
                             })
                             .then(result => {

                                 this.articlesInfo = result;
                                 let tempArr = [];
                                 result.forEach(function (item7, index7) {
                                    tempArr.push(item7.id);
                                 })

                                 this.tagFetch(tempArr);
                                 this.showFlag = true;

                             });
                        } // end of else statement
            },   // end of "dataFetch" method

            //  for fetching tags of articles
            /* ---------------------- */
            tagFetch: function (artiId) {
                     const myInit = {
                         method: 'POST',
                         mode: 'cors',
                         cache: 'default',
                         headers: {
                            'Content-Type': 'application/json;charset=utf-8'
                         },
                         body: JSON.stringify({
                             artiIds: artiId
                         })
                     };
                     // sending request to back-end
                     fetch(this.$backUrl + "sql/tags", myInit)
                         .then((response) => {
                             /* response here is the response of express.js backend server and we must "return" it.  */
                             if (!response.ok) {
                                 throw new Error(`HTTP error! status: ${response.status}`);
                             }
                             return response.json();
                         })
                         .then(result => {
                             this.tgData = result;
                         });

            },   // end of "tagFetch" method

            //  for fetching the number of all articles to set pagination correctly
            /* ---------------------- */
            numberLoad: function() {
                 if (this.idSearch.length < 1) {
                    /* this section is running when the search result is empty or the user does not search anything (initial load) or when the user filters data.  */
                     const myInit = {
                         method: 'GET',
                         mode: 'cors',
                         cache: 'default'
                     };
                     // sending request to back-end
                     fetch(this.$backUrl + "sql/articles" + "?filterId=" + this.filterData, myInit)
                         .then((response) => {
                             if (!response.ok) {
                                 throw new Error(`HTTP error! status: ${response.status}`);
                             }
                             return response.json();
                         })
                         .then(result => {
                             this.allRows = parseInt(result[0].allArtic);
                             this.showPagin = true;
                         });

                 } else {
                     this.allRows = this.idSearch.length;
                     this.showPagin = true;
                 }

            }, // end of "numberLoad" method

            //  for activating the page and data related to that in pagination calls
            /* ---------------------- */
            definePage: function(pageThis) {
                 this.pageNum = pageThis;
                 this.dataAllFetch(this.pageNum);
            }, // end of "definePage" method

            // for calling the dataFetch function
            /* ---------------------- */
            dataAllFetch: function(pageParam) {
                     this.dataFetch(pageParam);
            }, // end of "dataAllFetch" method

            //  for calling data after user searchs for a term
            /* ---------------------- */
            newArti: function(idNums) {
                 this.idSearch = idNums[0];
                 this.searchText = idNums[1];
                 if (this.idSearch.length < 1) {
                     document.getElementById("errMess").classList.remove("d-none");
                 } else {
                     document.getElementById("errMess").classList.add("d-none");
                 }
                 this.filterData = "";
                 this.offRadio++;
                 $("#myFilter").fadeOut(300);
                 this.searchReset();

            },  // end of "newArti" method

            //  reseting some data and recalling some method
            /* ---------------------- */
            searchReset: function() {
                 this.showPagin= false;
                 this.pageNum = 1;
                 sessionStorage.removeItem("activePage");
                 sessionStorage.removeItem("minPagInd");
                 this.showFlag = false;
                 this.articlesInfo = [];
                 this.tgData = [];
                 this.dataAllFetch(this.pageNum);
                 this.numberLoad();
            },  // end of "searchReset" method

            //  for showing not found message after search
            /* ---------------------- */
            checkEmpt: function(inpLength) {
                 if (inpLength < 1) {
                     document.getElementById("errMess").classList.add("d-none");
                     this.searchText = "";
                     this.idSearch = [];
                     this.filterData = "";
                     this.offRadio++;
                     $("#myFilter").fadeOut(300);
                     this.searchReset();
                 }
            },  // end of "checkEmpt" method

            //  for sorting data according to radio buttons
            /* ---------------------- */
            sortFunc: function(sortColumn) {
                 /* this function sends radio option to the backend to return the sorted articles according to that */
                     this.columnData = sortColumn;
                     this.searchReset();
            },  // end of "sortFunc" method

            //  for filtering data according to radio buttons
            /* ---------------------- */
            filterFunc: function(filterVar) {
                 /* this function sends radio option to the backend to return the filtered articles according to that */

                this.searchText = "";
                this.idSearch = [];
                document.getElementById("errMess").classList.add("d-none");
                this.filterData= filterVar;
                $("#myFilter").fadeIn(500);
                this.searchReset();
            }, // end of "filterFunc" method

            //  for clearing filters
            /* ---------------------- */
            filterNone: function() {
                 /* this function is used to destroy filtered tags and shows articles in a normal way */
                 this.filterData = "";
                 this.offRadio++;
                 $("#myFilter").fadeOut(500);
                 this.searchReset();
            } // end of "filterNone" method
  },

/* ---------------------- */
/* end of methods */
/* ---------------------- */

  mounted() {
      this.numberLoad(); /* this function loads the number of all articles on database that is usefull for defining the number of pages in pagination */
      $("#myFilter").fadeOut(1);
  }

/* ---------------------- */
/* end of mounted */
/* ---------------------- */

};
</script>

<style scoped src="../assets/assets-CSS/article.css"></style>
