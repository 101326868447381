<template>
<!-- This is the main file of our site that renders pages according to "vue-router" system. It simply renders "navigation" and "footer" on each page. The main content of each page comes from "router-view". -->
  <div id="app">

    <!-- showing message if the window width is lower than 1248px. -->
    <div id="error-message" class="alert alert-warning text-center p-5">
        در حالت حاضر سایت ما برای نمایش در موبایل و تبلت (دستگاه های با عرض کمتر از 1248 پیکسل) بهینه نشده است. لطفا برای استفاده مناسب از سایت، از لب تاپ یا کامپیوتر استفاده نمایید.
    </div>
    <!-- ////////////////////////// -->

    <div class="container-fluid">
        <div class="bg-head">
            <!-- navigation -->
            <TheNavigation></TheNavigation>
        </div>
    </div>

    <!-- main content of the pages -->
    <router-view/>
    <!-- footer of pages -->
    <TheFooter></TheFooter>
  </div>
</template>

<script>
  import TheFooter from "./components/TheFooter";
  import TheNavigation from "./components/TheNavigation";
  export default {
      metaInfo: {
        title: "مقالات",
        titleTemplate: '%s - سایت حمید داودی'
      },
      
      components: {
          TheNavigation,
          TheFooter
      },
  };
</script>

<style src="../public/css/style.css"></style>

