<template>
    <!-- This is the component that is used for allowing users to up-vote or down-vote an article -->
    <section id="likePar">
    <div class="col-md-10 col-11 mx-auto likePart mt-4">
        <div class="row">
            <div class="col-md-8">

                    <p class="paraEnd text-center mt-4 mt-md-5">آیا مطالب موجود در این مقاله برای شما مفید بود؟</p>

            </div>

            <div class="col-md-4 mb-3 mb-md-auto">
                 <div class="d-flex justify-content-around align-items-center mt-md-5 mt-2">
                     <i @click = "updateLike" class="fad fa-arrow-alt-square-up sucColor mt-md-4"></i>
                     <i @click = "updateLike" class="fad fa-arrow-alt-square-down errColor mt-md-4"></i>
                 </div>
            </div>
        </div>
    </div>

    <!-- this is the message that after clicking on icons of like or dislike is shown. -->
    <div class="alert w-75 mx-auto mt-5" role="alert">
      <p class="paraEnd text-center">
          از اینکه با ارسال نظر خود ما را در بهبود مطالب سایت یاری می&zwnj;دهید، بسیار سپاسگزاریم.
      </p>
      <p class="paraEnd text-center">
          چنانچه مطالب موجود در این مقاله برای شما مفید بود، می&zwnj;توانید از <router-link class="link" :to="{name: 'DonateMe'}">این لینک</router-link> به سایت کمک کنید.
      </p>
    </div>

    </section>
</template>

<script>
        export default {
                name: "TheLike", 

                methods: {
                    updateLike: function(eve) {
                        /* This function is triggered after the user clicked on up or down icons. Then it sets "minus" or "plus" according to which icon is clicked. After that it sets "localStorage" for that special article to prevent a user for duplicate votes, then shows "thanks" message by calling "showMessage" method, after that by calling "sendLike" method it sends data to back-end.  */
                        let allClass = eve.target.classList;
                        let arrClass = [... allClass];
                        let artiId = this.$route.params.id;
                        let updateAction;

                        let findClass = arrClass.find(function (element) {
                            return element == "errColor";
                        });

                        if ( findClass == "errColor") {
                            updateAction = "minus";
                        } else {
                            updateAction = "plus";
                        }
                        /* setting localStorage */
                        localStorage.setItem("likes" + artiId, true);
                        /* display message of reciving like */
                        this.showMessage(findClass);
                        /* sending data to database */
                        this.sendLike(updateAction);

                    }, // end of updateLike() method

                    showMessage: function(findClassVar) {
                        /* showing message after clicking icons. */
                        $(".likePart").css("min-height", 0);
//                        let vuInc = this
                        $(".likePart").slideUp( 1100, function() {
                            if(findClassVar == "errColor") {
                                $(this).parent().find(".alert p:first-child").css("display", "block");
                                $(this).parent().find(".alert p:last-child").css("display", "none");
                            } else {
                                $(this).parent().find(".alert p:first-child").css("display", "none");
                                $(this).parent().find(".alert p:last-child").css("display", "block");
                            }
                            $("#likePar .alert").fadeIn(500);
                        });

                    }, // end of showMessage() method

                    sendLike: function(updatePara) {
                        /* sending user like or dislike to the database. */
                        let artiId = this.$route.params.id;

                        const myInit = {
                             method: 'POST',
                             mode: 'cors',
                             cache: 'default',
                             headers: {
                                'Content-Type': 'application/json;charset=utf-8'
                             },
                             body: JSON.stringify({
                                 dataInfo: updatePara
                             })
                         };
                         fetch(this.$backUrl + "sql/like/" + artiId, myInit)
                             .then((response) => {

                                 if (!response.ok) {
                                     throw new Error(`HTTP error! status: ${response.status}`);
                                 }
                                 return response.json();
                             })
                             .then(result => {

                                console.log(result);

                             });
                    }, // end of sendLike() method

                } // end of methods
        }
</script>

<style scoped src="../assets/assets-CSS/theLike.css"></style>