<template>
<!-- This component is used to show "input HTML tags" in the site. It supports both valid and invalid inputs, but the validation process must be done in the parent or back-end of the site. -->
  <div
      v-if="dataValid === 'valid'"
      :class="classSize"
      class="d-flex justify-content-md-center align-items-end validInp mb-5"
  >
    <label :for="nameInp"> {{ labelText }} </label>
    <input
        class="p-sm-1"
        :id="nameInp"
        :type="inpType"
        :name="nameInp"
        :value="valueInp2"
    >
  </div>

  <!-- This is the option for invalid form. Validation rules must be applied in the parent component. -->
  <div
      v-else
      :class="classSize"
      class="d-flex justify-content-md-center align-items-end  invalidInp mb-5"
  >
    <label :for="nameInp"> {{ labelText }}  </label>
    <input
        class="p-sm-1"
        :id="nameInp"
        :type="inpType"
        :name="nameInp"
        :value="valueInp2"
    >
  </div>
</template>

<script>
    export default {
        name: "BaseInput",
        /* ------------------------ */
        props: {
            labelText: String,
            nameInp: String,
            classSize: String,
            inpType: String,
            dataValid: String,
            propVal: String
        },
        /* ------------------------ */
        computed: {
            valueInp2() {
                return this.propVal;
            }
        }
    }
</script>

<style scoped src="../assets/assets-CSS/input.css"></style>
