<template>
<!-- this is the texts and contents of "article number 14" of our database. for more info and list of all options available for each article please see this file:
H:/web-codes/websites/portfolio-folder/design-and-assets/sample article/BaseArticle.vue  -->
        <div class="col-md-10 mx-auto">

            <div class="text-right mt-5">
                <!-- **************** -->
                <!-- intro text -->
                <!-- **************** -->
                <h2 class="subTitle">مقدمه: </h2>
                <blockquote class="blockquote">
                    <p class="text-justify textPara pb-5">
                        اگر شما هم با پروژه های وب که نسبتا حجیم بوده و دارای کدنویسی&zwnj;های سنگین و پیچیده هستند، روبه&zwnj;رو شده باشید احتمالا نام <span class="farsiSpan">مدیریت پکیج&zwnj;ها</span> به گوشتان خورده است. اگر جزئیات زیادی در خصوص این مفهوم و چگونگی به کارگیری آن نمی&zwnj;دانید، شاید خواندن این مقاله بتواند به شما در پیشبرد اهداف یادگیری&zwnj;تان کمک کند. در این مقاله ضمن تعریف و تبیین مفهوم پکیج در برنامه&zwnj;نویسی وب و <span class="farsiSpan">علت</span> استفاده از آن، سعی داریم تا با یکی از معروف&zwnj;ترین ابزارهای مدیریت پکیج در زبان جاوا&zwnj;اسکریپت (یا به عبارت بهتر <span class="engliSpan">node-js</span>)، یعنی npm، آشنایی بیشتر و کامل&zwnj;تری پیدا کنیم.
                    </p>
                </blockquote>

                <!-- **************** -->
                <!-- part one -->
                <!-- **************** -->
                <h2 class="subTitle mt-5">مفهوم پکیج در کدنویسی چیست؟</h2>
                <p class="text-justify textPara">
                    احتمالا تمامی توسعه&zwnj;دهندگان وب&zwnj;سایت در پروژه&zwnj;های خود از کدهای دیگران استفاده کرده&zwnj;اند و می&zwnj;کنند. به عنوان مثال فرض کنید که شما برای طراحی بهتر و ایجاد حالت واکنش&zwnj;گرا (<span class="engliSpan">Responsive</span>) در وب&zwnj;سایتی که می&zwnj;سازید، از <a href="https://getbootstrap.com" target="_blank">فریم ورک بوتسترپ</a> استفاده کنید. در این حالت در اصل فریم ورک بوتسترپ یک پکیج محسوب می&zwnj;شود. به عبارت ساده&zwnj;تر پکیج&zwnj;ها همان <strong>کدهای آماده و نوشته شده توسط دیگر توسعه&zwnj;دهندگان وب</strong> هستند که به علت کارایی و عملکرد مناسبی که دارند، هر توسعه&zwnj;دهنده&zwnj;ای تصمیم به استفاده از آنها در پروژه خود می&zwnj;گیرد. در غیر این صورت شما باید تمامی کدهایی را که برای هرگونه عملکردی در سایت خود نیاز دارید، <strong>خودتان</strong> از پایه بنویسید که نه تنها معقول و منطقی نیست، بلکه امکان دارد دچار خطاها و اشتباهات فراوانی هم بشوید و زمان و انرژی زیادی را نیز صرف مسائلی نمایید که به عنوان هدف اصلی پروژه شما تعریف <span class="farsiSpan">نشده&zwnj;اند</span>. بنابراین هیچ بعید نیست که شما تاکنون از پکیج&zwnj;های زیادی در پروژه&zwnj;های خود استفاده کرده باشید، بدون آن که با مفهوم پکیج به این شکل آشنایی داشته باشید.
                </p>


                <!-- **************** -->
                <!-- part two -->
                <!-- **************** -->
                <h2 class="subTitle mt-5">ضرورت استفاده از ابزارهای مدیریت پکیج:</h2>
                <p class="text-justify textPara">
                    شاید این سؤال برای شما پیش آید که اگر پکیج&zwnj;ها کدهای نوشته شده&zwnj;ای هستند که ما به پروژه خود اضافه می&zwnj;کنیم، مفهوم <span class="farsiSpan">مدیریت پکیج</span> چیست؟ ما می&zwnj;توانیم به کمک تگ&zwnj;هایی چون <code>&lt;link&gt;</code> و <code>&lt;script&gt;</code> و ... کدهای دیگران را به پروژه خود اضافه کنیم و از آنها استفاده کنیم. مثلا برای اضافه کردن بوتسترپ به یک فایل HTML می&zwnj;توان به این شکل عمل نمود:
                </p>

                <!-- imgs (png,jpg or gif) that are not related to codes -->
                <div class="col-md-10 mt-5 mx-auto">
                    <img class="img-fluid mx-auto d-block img-thumbnail shadow-lg" src="./imgFolder/add-bootstrap.png" alt="تصویر مربوط به اضافه کردن فریم ورک بوتسترپ به یک فایل HTML">
                </div>

                <p class="text-justify textPara mt-5">
                    مسئله آن است که گرچه در این حالت پکیج مورد نظر به خوبی عمل می&zwnj;کند و در پروژه ما قابل شناسایی است، اما اگر شما نیاز به ده&zwnj;ها پکیج در یک پروژه داشته باشید چه؟ آیا در آن حالت نیز اضافه کردن تمامی آنها به کمک روش قبل کاری منطقی به نظر می&zwnj;آید؟ علاوه بر آن هر کدام از این پکیج&zwnj;ها ممکن است خود به پکیج&zwnj;های دیگری نیاز داشته باشند که تعداد آنها را افزون می&zwnj;کند. همچنین ممکن است که برخی از آنها به مرور زمان <strong>به&zwnj;روز&zwnj;رسانی</strong> شوند و شما بخواهید نسخه جدیدتر را در پروژه استفاده نمایید. حتی ممکن است برخی از آنها با یکدیگر سازگار <span class="farsiSpan">نباشند</span> و نتوانند به خوبی در کنار یکدیگر در یک پروژه استفاده شوند.
                </p>

                <p class="text-justify textPara">
                    چنین مسائلی که شاید بتوان باز هم به لیست آنها افزود، همگی از جمله دلایلی است که باعث می&zwnj;شوند نیاز به یک ابزار قوی جهت مدیریت پکیج&zwnj;ها احساس شود، چرا که معمولا توسعه&zwnj;دهندگان وب&zwnj;سایت <span class="farsiSpan">نمی&zwnj;توانند</span> خود به انجام تمامی این امور بپردازند. اگر هم چنین قصدی داشته باشند، احتمالا خطای انسانی بسیار بالا خواهد بود. لذا ابزارهایی تحت عنوان <strong>ابزارها یا سیستم&zwnj;های مدیریت پکیج</strong> توسعه یافته&zwnj;اند که هدف آنها در حقیقت اتوماتیک کردن فرآیندهای نصب، به&zwnj;روز&zwnj;رسانی، تنظیمات، بررسی خطاها یا حذف پکیج&zwnj;ها در پروژه های مرتبط با طراحی وب&zwnj;سایت است. چنین ابزارهایی اطلاعات هر پکیج را در نظر گرفته و ساختار هماهنگی را جهت ایجاد یک محصول کارآمد در اختیار توسعه&zwnj;دهنده وب&zwnj;سایت قرار می&zwnj;دهند تا از این بابت بتواند با آسودگی خاطر به پیشبرد اهداف پروژه خود بپردازد.
                </p>

                <!-- **************** -->
                <!-- part three -->
                <!-- **************** -->
                <h2 class="subTitle mt-5">سیستم&zwnj;های مدیریت پکیج در جاوا&zwnj;اسکریپت:</h2>
                <p class="text-justify textPara">
                    تاکنون با تعریف پکیج و ضرورت استفاده از سیستم مدیریت پکیج آشنا شدید. در این بخش به معرفی مختصری از برخی سیستم&zwnj;های مدیریت پکیج رایج در زبان <span class="farsiSpan">جاوا&zwnj;اسکریپت</span> (<span class="engliSpan">JavaScript</span>) می&zwnj;پردازیم. لازم به توضیح است که در واقع این سیستم&zwnj;های مدیریت پکیج برای محیط نود-جی&zwnj;اس (<span class="engliSpan">node-js</span>) طراحی و ساخته شده&zwnj;اند، ولی از آنجایی که خود نود-جی&zwnj;اس در حقیقت به زبان جاوا&zwnj;اسکریپت ساخته شده است، به نوعی می&zwnj;توان آنها را سیستم&zwnj;های مدیریت پکیج در جاوا&zwnj;اسکریپت لقب داد.
                </p>

                <BaseList :list-data = "listText"></BaseList>

                <!-- **************** -->
                <!-- part four -->
                <!-- **************** -->
                <h2 class="subTitle mt-5">npm چگونه به مدیریت پکیج&zwnj;ها می&zwnj;پردازد؟</h2>

                <!-- begin subtitles in indented paragraphs -->
                <div class="indentPara">
                    <!-- **************** -->
                    <!-- part four section 1 -->
                    <!-- **************** -->
                    <h3 class="subTitle2 mt-4">آغاز به کار با npm : </h3>
                    <p class="text-justify textPara">
                        در این قسمت قرار است که به شکل جزئی&zwnj;تر و کامل&zwnj;تری به نحوه عملکرد npm در زمینه مدیریت پکیج&zwnj;ها در یک پروژه نود جی&zwnj;اس بپردازیم. به طور کلی وظیفه اصلی سیستم&zwnj;های مدیریت پکیج <strong>نصب</strong>، <strong>به&zwnj;روز&zwnj;رسانی</strong>، <strong>حذف</strong> و یا سایر اقدامات ضروری در خصوص پکیج&zwnj;ها است. لذا برای فهم کامل&zwnj;تر این فرآیند بهتر است که با ذکر مثالی به نصب یک پکیج در یک پروژه فرضی بپردازیم. برای این کار در یک محیط خط فرمان (command line) مانند محیط <span class="engliSpan">command prompt</span> یا ابزارهای مشابه آن در ویندوز، به کمک دستور <span class="engliSpan">cd</span> به آدرس پوشه&zwnj;ای که می&zwnj;خواهیم به نصب پکیج&zwnj;ها بپردازیم، رفته و دستور زیر را اجرا می&zwnj;کنیم:
                    </p>

                    <!-- imgs (png,jpg or gif) that are not related to codes -->
                    <div class="col-md-10 mt-5 mx-auto">
                        <img class="img-fluid mx-auto d-block img-thumbnail shadow-lg" src="./imgFolder/npm-init.png" alt="تصویر مربوط به آغاز کردن یک پروژه به کمک npm">
                    </div>


                    <p class="text-justify textPara mt-5">
                        با اجرای این دستور یک سری سؤالات از شما پرسیده می&zwnj;شود. سؤالاتی مانند انتخاب نام پروژه یا کلمات کلیدی مربوط به پروژه از جمله این سؤالات هستند که در تصویر زیر به شکل واضح&zwnj;تری مشاهده می&zwnj;کنید:
                    </p>

                    <div class="col-md-10 mt-5 mx-auto">
                        <img class="img-fluid mx-auto d-block img-thumbnail shadow-lg" src="./imgFolder/after-npm-init.png" alt="تصویر مربوط به سوالات آغازین یک پروژه npm">
                    </div>

                    <p class="text-justify textPara mt-5">
                        بعد از تأیید مراحل فوق، npm در پوشه مورد نظر یک فایل به نام <span class="engliSpan">package.json</span> ایجاد می&zwnj;کند که در واقع به نوعی هسته اصلی فعالیت&zwnj;های پروژه شما محسوب می&zwnj;شود و npm به کمک آن می&zwnj;تواند قابلیت&zwnj;های خود را بروز دهد.
                    </p>
                    <!-- **************** -->
                    <!-- part four section 2 -->
                    <!-- **************** -->
                    <h3 class="subTitle2 mt-4">نصب اولین پکیج در پروژه:</h3>
                    <p class="text-justify textPara">
                        تاکنون تنها یک فایل <span class="engliSpan">package.json</span> در پوشه پروژه خود ایجاد کرده&zwnj;ایم که تنها شامل یک سری اطلاعات کلی در خصوص پروژه ما است. اما همان&zwnj;طور که از نام این فایل بر می&zwnj;آید، قرار است به کمک چنین فایلی به مدیریت پکیج&zwnj;ها در پروژه خود بپردازیم. لذا برای شروع ابتدا به کمک دستور <code>npm install</code> به نصب یک پکیج در پروژه به شکل زیر می&zwnj;پردازیم:
                    </p>

                    <div class="col-md-10 mt-5 mx-auto">
                        <img class="img-fluid mx-auto d-block img-thumbnail shadow-lg" src="./imgFolder/loadash-install.png" alt="تصویر مربوط به نصب یک پکیج به وسیله npm">
                    </div>

                    <p class="text-justify textPara mt-5">
                        در این دستور پکیجی به نام <span class="engliSpan">lodash</span> را به پروژه خود اضافه کرده&zwnj;ایم. در واقع صورت کلی دستور به شکل زیر است:
                    </p>

                    <div class="text-center">
                        <code>&lt;npm install &lt;package-name</code>
                    </div>

                    <p class="text-justify textPara mt-4">
                        که در قسمت <code>&lt;package-name&gt;</code> نام پکیج مورد نظر ذکر می&zwnj;شود. البته در تصویر فوق از حالت مخفف <span class="engliSpan">install</span> ،یعنی <span class="engliSpan">i</span>، استفاده شده است. همچنین عبارت <span class="engliSpan" dir="ltr">--save</span> باعث قرار دادن پکیج در لیست <span class="engliSpan">dependencies</span> در فایل package.json می&zwnj;شود. با اجرای این دستور سه تغییر در پروژه ما ایجاد می&zwnj;شود:
                    </p>

                    <BaseList :list-data = "listText2"></BaseList>

                    <p class="text-justify textPara mt-3">
                        این سه تغییر به نوبه خود نشان&zwnj;دهنده نحوه مدیریت پکیج&zwnj;ها توسط npm هستند. در تغییر اول فایل <span class="engliSpan">package-lock.json</span> در واقع فایلی است که <span class="farsiSpan">جزئیات بیشتری</span> در خصوص پکیج&zwnj;های نصب شده در اختیار ما می&zwnj;گذارد. به عنوان مثال در خصوص این پکیج نصب شده فایل  package-lock.json به شکل زیر خواهد بود:
                    </p>

                    <div class="col-md-10 mt-5 mx-auto code-img">
                        <img class="img-fluid img-thumbnail" src="./imgFolder/package-lock.png" alt="تصویر مربوط به فایل package-lock.json بعد از نصب اولین پکیج">
                    </div>

                    <p class="text-justify textPara mt-5">
                        تغییر دوم <span class="farsiSpan">اصل فایل&zwnj;های</span> مربوط به پکیج مورد نظر را در اختیار ما می&zwnj;گذارد. چنانچه به داخل آن پوشه مراجعه نمایید، مشاهده خواهید کرد که در حال حاضر یک پوشه به نام <span class="engliSpan">lodash</span> در آن وجود دارد.
                    </p>

                    <p class="text-justify textPara">
                        نهایتاً تغییر سوم باعث می&zwnj;شود که در صورت به اشتراک&zwnj;گذاری پروژه با دیگران (مثلا در هنگامی که پروژه&zwnj;های وب به صورت <span class="farsiSpan">تیمی</span> انجام می&zwnj;شوند)، اطلاعات نسبتا کاملی از لیست پکیج&zwnj;های مورد استفاده در اختیار باشد که مزیت آن را در ادامه مشاهده خواهید کرد.
                    </p>

                    <div class="col-md-10 mt-5 mx-auto code-img">
                        <img class="img-fluid img-thumbnail" src="./imgFolder/package-json.png" alt="تصویر مربوط به فایل package.json بعد از نصب اولین پکیج">
                    </div>

                    <!-- **************** -->
                    <!-- part four section 3 -->
                    <!-- **************** -->
                    <h3 class="subTitle2 mt-5">راه&zwnj;اندازی یک پروژه به کمک فایل package.json :</h3>
                    <p class="text-justify textPara">
                        برای درک بهتر چگونگی مدیریت کردن پکیج&zwnj;ها توسط <span class="engliSpan">npm</span> و فایل <span class="engliSpan">package.json</span>، در این بخش به ارائه مثالی می&zwnj;پردازیم. فرض کنید که در یک پروژه تیمی، همکار شما روی کدهای مربوط به یک قسمت پروژه کار کرده است و آنها را در سایت <a href="https://github.com" target="_blank">GitHub</a> بارگذاری نموده است. برای این که شما بتوانید ادامه پروژه را انجام دهید، باید فایل&zwnj;های مربوطه را دانلود کرده و بر روی سیستم خود به ادامه پروژه بپردازید. در اینجا فرض می&zwnj;کنیم که فایل&zwnj;های مذکور در <a href="https://github.com/hamidGit68/node-sql-api" target="_blank">این آدرس</a> بارگذاری شده&zwnj;اند. می&zwnj;توانید به آدرس فوق رفته و اکنون فایل&zwnj;ها را دانلود نمایید:
                    </p>

                    <div class="col-md-10 mt-5 mx-auto">
                        <img class="img-fluid mx-auto d-block img-thumbnail shadow-lg" src="./imgFolder/github-download.png" alt="تصویر مربوط به چگونگی دانلود فایل ها از سایت GitHub">
                    </div>

                    <p class="text-justify textPara mt-5">
                        بعد از آن که آنها را از حالت zip خارج کردید، در محیط خط فرمان به آدرس پوشه مربوطه منتقل شوید. در حالت <span class="farsiSpan">طبیعی</span> می&zwnj;توانید با اجرای دستور node index.js به اجرای پروژه بپردازید (اگر با دستورهای مربوط به Node-js آشنایی <span class="farsiSpan">ندارید</span>، خیلی برای فهم موضوع این بخش اهمیت نخواهد داشت. فقط همین قدر را بدانید که با دستور <span class="engliSpan">node</span> و سپس ذکر نام فایل جاوا&zwnj;اسکریپت مورد نظر، می&zwnj;توانید آن فایل را در محیط خط فرمان اجرا کنید). اما در این حالت با پیغام خطا مواجه خواهید شد:
                    </p>

                    <div class="col-md-10 mt-5 mx-auto">
                        <img class="img-fluid mx-auto d-block img-thumbnail shadow-lg" src="./imgFolder/package-error.png" alt="تصویر مربوط به وجود خطا هنگام اجرای پروژه دانلود شده">
                    </div>

                    <p class="text-justify textPara mt-5">
                        دلیل این پیغام خطا کاملا واضح است. اگر به محتویات پوشه دانلود شده دقت نمایید، مشاهده خواهید کرد که پوشه <span class="engliSpan">node_modules</span> در آن دیده <span class="farsiSpan">نمی&zwnj;شود</span>. یعنی به عبارت ساده&zwnj;تر پکیج&zwnj;هایی که همکار شما در پروژه استفاده کرده است، در سایت GitHub بارگذاری نشده است. مسلما همکار شما فراموش نکرده که آنها را بارگذاری کند! بلکه قضیه از این قرار است که این پکیج&zwnj;ها در فایل <span class="engliSpan">package.json</span> که از آن صحبت کرده&zwnj;ایم، لیست شده&zwnj;اند:
                    </p>

                    <div class="col-md-10 mt-5 mx-auto code-img">
                        <img class="img-fluid img-thumbnail" src="./imgFolder/list-of-dependencies.png" alt="تصویر مربوط به فایل package.json مجموعه فایل های دانلود شده">
                    </div>

                    <p class="text-justify textPara mt-5">
                        نقش یک سیستم مدیریت پکیج مانند <span class="engliSpan">npm</span> در اینجا آشکار می&zwnj;شود. برای اینکه بتوانید پکیج&zwnj;ها را بر روی سیستم خود داشته باشید، تنها کافی است که در محیط خط فرمان در آدرس پوشه مربوطه، دستور <code>npm i</code> را اجرا کنید:
                    </p>

                    <div class="col-md-10 mt-5 mx-auto">
                        <img class="img-fluid mx-auto d-block img-thumbnail shadow-lg" src="./imgFolder/npm-i.png" alt="تصویر مربوط به اجرای دستور npm i">
                    </div>

                    <p class="text-justify textPara mt-5">
                        پس از این کار و دانلود پکیج&zwnj;ها و سپس نصب هر یک توسط npm، اکنون اگر بار دیگر دستور <span class="engliSpan">node index.js</span> را در خط فرمان اجرا کنید، دیگر خبری از پیغام خطا نخواهد بود. اگر به پوشه مربوطه نیز توجه کنید، مشاهده خواهید کرد که پوشه <span class="engliSpan">node_modules</span> به مجموعه پوشه&zwnj;ها و فایل&zwnj;ها اضافه شده است.
                    </p>
                    <!-- **************** -->
                    <!-- part four section 4 -->
                    <!-- **************** -->
                    <h3 class="subTitle2 mt-5">بررسی نسخه&zwnj;های مختلف پکیج&zwnj;ها در npm :</h3>
                    <p class="text-justify textPara">
                        همان&zwnj;طور که در بخش قبل مشاهده کردید، با اجرای دستور npm i در پوشه مورد نظر، npm با اتصال به اینترنت و <a href="https://www.npmjs.com" target="_blank">مرکز داده&zwnj;های</a> خود، تمامی پکیج&zwnj;های موجود در فایل package.json را دانلود کرده و در پوشه <span class="engliSpan">node_modules</span> قرار می&zwnj;دهد. به این ترتیب شما می&zwnj;توانید بر روی پروژه&zwnj;های مختلفی که دانلود می&zwnj;کنید، تنها با داشتن فایل package.json آن پروژه، کار کنید. اما اگر به ساختار این فایل در قسمت <span class="engliSpan">dependencies</span> توجه نموده باشید، متوجه شده&zwnj;اید که در جلوی نام هر پکیج علامت و عددی ذکر شده است. برای مثال در پوشه دانلود شده در بخش قبل در جلوی پکیج با نام <span class="engliSpan">helmet</span> عبارت <span class="engliSpan" dir="ltr">^4.2.0</span> ذکر شده است.
                    </p>

                    <p class="text-justify textPara">
                        عدد موجود در این قسمت (<span class="engliSpan">4.2.0</span>)، نشان&zwnj;دهنده ورژن یا همان نسخه پکیج است که به ترتیب از چپ به راست از اهمیت اعداد آن کاسته می&zwnj;شود. به بیان دیگر نسخه اصلی (اصطلاحا <span class="engliSpan">major release</span>) این پکیج شماره <span class="engliSpan">4</span> است و نسخه فرعی آن (اصطلاحا <span class="engliSpan">minor release</span>) برابر <span class="engliSpan">2</span> بوده و نسخه فرعی&zwnj;تر (اصطلاحا <span class="engliSpan">patch release</span>) در اینجا برابر <span class="engliSpan">0</span> است. این اعداد از آن جهت دارای اهمیت هستند که مثلا تغییرات مهم در پکیج&zwnj;ها (مانند تغییراتی که ممکن است عملکرد پکیج را <span class="farsiSpan">دگرگون</span> کند و با نسخه&zwnj;های قبلی سازگار <span class="farsiSpan">نباشد</span>) با عدد <span class="engliSpan">major</span> شماره گذاری می&zwnj;شوند. تغییرات جزئی&zwnj;تر (که با نسخه&zwnj;های قبلی سازگاری دارند) با عدد <span class="engliSpan">minor</span> شماره&zwnj;گذاری می&zwnj;شوند و تغییرات اندک (در حد برطرف نمودن ایرادهای جزئی) با عدد <span class="engliSpan">patch</span> شماره&zwnj;گذاری می&zwnj;شوند.
                    </p>

                    <p class="text-justify textPara">
                        با توجه به توضیحات فوق، قرار است در این بخش به کمک علامت&zwnj;هایی که در کنار این اعداد قرار داده می&zwnj;شوند، با مفهوم نصب پکیج&zwnj;ها با <span class="farsiSpan">ذکر نسخه مورد نظر</span> بیشتر آشنا شویم. به غیر از علامت <span class="engliSpan">^</span>، علامت&zwnj;های دیگری را نیز می&zwnj;توان در کنار شماره نسخه قرار داد که در ادامه به برخی از آنها می&zwnj;پردازیم. برای آنکه با مفاهیم این علامت&zwnj;ها به صورت عملی آشنا شوید، می&zwnj;توانید یک کپی از فایل&zwnj;های دانلود شده در بخش قبل (سایت GitHub) برداشته و در یک پوشه جدید جایگذاری کنید. سپس پوشه <span class="engliSpan">node_modules</span> و فایل <span class="engliSpan">package-lock.json</span> را از میان فایل&zwnj;ها پاک (<span class="engliSpan">delete</span>) نمایید. همان&zwnj;طور که قبلا ذکر شد، فایل package-lock.json اطلاعات دقیق&zwnj;تر هر پکیج را در خود نگه می&zwnj;دارد. لذا اکنون که این فایل را حذف کرده&zwnj;اید، اگر به آدرس پوشه جدید در خط فرمان منتقل شوید و دوباره دستور <code>npm i</code> را اجرا نمایید، دیگر npm به این اطلاعات دقیق دسترسی <span class="farsiSpan">ندارد</span>. لذا برای دانلود نمودن پکیج&zwnj;های لیست شده در فایل package.json به علامت&zwnj;هایی که در جلوی نسخه هر پکیج وجود دارد، اتکا می&zwnj;کند.
                    </p>

                    <p class="text-justify textPara">
                        مفهوم علامت <span class="engliSpan">^</span> که در جلوی عدد <span class="engliSpan">4.2.0</span> در پکیج <span class="engliSpan">helmet</span> قرار گرفته است، آن است که برای نصب و یا به&zwnj;روزرسانی این پکیج، به npm تنها اجازه تغییر اعداد minor و یا patch داده شده است و چنانچه نسخه&zwnj;ای از این پکیج با تغییرات مهم (<span class="engliSpan">major</span>) منتشر شده باشد، npm حق استفاده از آن را <strong>ندارد</strong>. در مثال کنونی در زمان نوشتن این مقاله آخرین نسخه منتشر شده برای پکیج helmet برابر <span class="engliSpan">5.0.2</span> است. یعنی نسخه major بعدی منتشر شده است. اما چون علامت <span class="engliSpan">^</span> در فایل <span class="engliSpan">package.json</span> وجود دارد، npm به سراغ آن نسخه نمی&zwnj;رود و در میان نسخه&zwnj;های شماره <span class="engliSpan">4</span> بالاترین آنها را به پروژه اضافه می&zwnj;کند.
                    </p>

                    <p class="text-justify textPara">
                        برای اطلاع از تمامی نسخه&zwnj;های منتشر شده این پکیج می&zwnj;توانید به <a href="https://www.npmjs.com/package/helmet" target="_blank">آدرس اینترنتی آن</a> مراجعه نمایید. npm دارای دستورهای مختلفی است. یکی از این دستورات، دستور <code>&lt;npm list &lt;package-name</code> است که اگر در آدرس پوشه پروژه اجرا شود، به شما نسخه <span class="farsiSpan">نصب شده</span> پکیج تعیین شده در دستور را در پروژه مورد نظر ارائه می&zwnj;دهد. لذا پس از اجرای دستور <code>npm i</code> اگر دستور <code>npm list helmet</code> را در پوشه مورد نظر اجرا نمایید، مشاهده خواهید کرد که دیگر نسخه پکیج مربوطه <span class="engliSpan">4.2.0</span> نخواهد بود، بلکه نسخه <span class="engliSpan">4.6.0</span> نصب شده است.
                    </p>

                    <div class="col-md-10 mt-5 mx-auto">
                        <img class="img-fluid mx-auto d-block img-thumbnail shadow-lg" src="./imgFolder/helmet4.6.0.png" alt="تصویر مربوط به اجرای دستور npm list در حالت علامت ^">
                    </div>

                    <p class="text-justify textPara mt-5">
                        در حالی که اگر همین دستور را در پوشه ابتدایی (مربوط به بخش قبل) اجرا کنید، خروجی دیگری خواهید گرفت:
                    </p>

                    <div class="col-md-10 mt-5 mx-auto">
                        <img class="img-fluid mx-auto d-block img-thumbnail shadow-lg" src="./imgFolder/helmet4.2.0.png" alt="تصویر مربوط به اجرای دستور npm list در حالت علامت ^">
                    </div>

                    <p class="text-justify textPara mt-5">
                        اما بحث اصلی ما بر سر علامت&zwnj;های دیگری است که می&zwnj;توان به جای <span class="engliSpan">^</span> به کار گرفت. در اینجا برای نمونه به برخی از آنها اشاره می&zwnj;کنیم. برای مثال اگر از علامت <span class="engliSpan">~</span> به جای <span class="engliSpan">^</span> در فایل <span class="engliSpan">package.json</span> استفاده شود، به معنای آن است که npm <span class="farsiSpan">تنها</span> مجاز است که عدد patch را به&zwnj;روزرسانی نماید و حق تغییر دادن اعداد major و یا minor را <strong>ندارد</strong>. لذا اگر بار دیگر از فایل&zwnj;های اصلی کپی گرفته و دوباره پوشه <span class="engliSpan">node_modules</span> و فایل <span class="engliSpan">package-lock.json</span> را حذف کنید و این بار به صورت دستی در فایل <span class="engliSpan">package.json</span> تغییر علامت مورد نظر را اعمال کنید:
                    </p>

                    <div class="col-md-10 mt-5 mx-auto code-img">
                        <img class="img-fluid img-thumbnail" src="./imgFolder/patch-package.png" alt="تصویر مربوط به اعمال تغییر علامت در فایل package.json">
                    </div>

                    <p class="text-justify textPara mt-5">
                        این بار با اجرای دستورات <code>npm i</code> و سپس <code>npm list helmet</code>، نسخه&zwnj;ای که از این پکیج دریافت خواهید کرد همان نسخه <span class="engliSpan">4.2.0</span> خواهد بود. چرا که اگر به <a href="https://www.npmjs.com/package/helmet" target="_blank">لیست نسخه&zwnj;های منتشر شده</a> در سایت مربوط به پکیج دقت کنید، متوجه خواهید شد که نسخه&zwnj;ای به شماره مشابه <span class="engliSpan">4.2.4</span> یا <span class="engliSpan">4.2.5</span> یا امثال آن منتشر نشده است. لذا <span class="farsiSpan">استثنائاً</span> در این مثال همان نسخه <span class="engliSpan">4.2.0</span> بر روی سیستم شما نصب می&zwnj;شود.
                    </p>

                    <div class="col-md-10 mt-5 mx-auto">
                        <img class="img-fluid mx-auto d-block img-thumbnail shadow-lg" src="./imgFolder/third-npm.png" alt="تصویر مربوط به اجرای دستور npm list در حالت علامت ~">
                    </div>

                    <p class="text-justify textPara mt-5">
                        به طریق مشابه اگر از علامت <span class="engliSpan" dir="ltr">></span> به جای <span class="engliSpan">^</span> در فایل <span class="engliSpan">package.json</span> استفاده شود، به معنای آن است که <span class="engliSpan">npm</span> مجاز خواهد بود که هر نسخه بالاتر از <span class="engliSpan">4.2.0</span> را بر روی پروژه نصب نماید که در زمان نوشتن این مقاله مسلماً نسخه&zwnj;ای که npm انتخاب می&zwnj;کند برابر <span class="engliSpan">5.0.2</span> خواهد بود.
                    </p>

                    <div class="col-md-10 mt-5 mx-auto">
                        <img class="img-fluid mx-auto d-block img-thumbnail shadow-lg" src="./imgFolder/helmet5.0.2.png" alt="تصویر مربوط به اجرای دستور npm list در حالت علامت >">
                    </div>

                    <p class="text-justify textPara mt-5">
                        همچنین می&zwnj;توان از <span class="farsiSpan">هیچ علامتی</span> هم در کنار عدد مربوط به نسخه ذکر شده در فایل package.json استفاده نکرد. در این صورت <span class="farsiSpan">دقیقاً</span> همان نسخه ذکر شده نصب خواهد شد و هیچ گونه به&zwnj;روزرسانی صورت نخواهد گرفت.
                    </p>

                </div>
                <!-- end subtitles in indented paragraphs -->

                <!-- conclusion part -->
                <h2 class="subTitle pb-3 mt-5">جمع بندی: </h2>
                <blockquote class="blockquote conclu">

                    <p class="text-justify textPara pt-3">
                        در این مقاله سعی ما بر آن بود که شما خوانندگان محترم با مفهوم پکیج (package) در توسعه وب&zwnj;سایت&zwnj;ها و چگونگی مدیریت آن بیشتر آشنا شوید. در ابتدا ضمن توضیح و تعریف مختصری از پکیج و ضرورت استفاده از ابزار مدیریت پکیج، به بررسی برخی از انواع ابزارهای رایج در این راستا در زبان جاوااسکریپت پرداخته شد. در ادامه نیز سیستم <span class="farsiSpan">مدیریت پکیج نود</span> (npm) با ذکر مثال و جزئیات بیشتری مورد بررسی قرار گرفت. مشخص است که چنین ابزارهایی مسلماً پیچیده&zwnj;تر از آن هستند که بتوان تمامی جزئیات و قابلیت&zwnj;های آنها را در یک مقاله خلاصه نمود و هدف ما نیز این نبوده است. بلکه تنها سعی نمودیم که ذهن شما خوانندگان سایت را بیشتر با این مفاهیم و مثال&zwnj;هایی کاربردی از استفاده از آنها آشنا کنیم. علاقه&zwnj;مندان به یادگیری چنین ابزارهایی می&zwnj;توانند به مراجع معتبر در این زمینه&zwnj;ها مانند <a href="https://www.npmjs.com" target="_blank">سایت اصلی npm</a> مراجعه کنند.
                    </p>

                </blockquote>

            </div>



            <!-- ways number 1 of loading imgs  -->
            <!--
            <img :src='`${this.publicPath}articles/${this.$route.params.id}/first.png`' alt="تصویر نمونه">
-->

                <!-- ways number 2 of loading imgs  -->
                <!--
               <img src='./imgFolder/img1.png' alt="تصویر نمونه">
               -->
        </div>
</template>

<script>
import BaseList from '../../BaseList.vue';

export default {
                name: "BaseArticle",
                components: {
                    BaseList
                },
                data() {
                    return {

                        listText: [
                            /* this is the data used for BaseList.vue. the "strongText" data is optional and may be present in some list and not in other lists. */
                            {
                                icon: "fad fa-caret-circle-left",
                                strongText: "<a href= https://www.npmjs.com target = _blank>npm </a>",
                                liText: "این ابزار که در حقیقت مخفف عبارت <span class = engliSpan>Node Package Manager</span> است، شاید سرشناس&zwnj;ترین سیستم مدیریت پکیج در نود-جی&zwnj;اس باشد که با نصب نود به صورت <span class = farsiSpan>پیش&zwnj;فرض</span> نصب می&zwnj;شود و می&zwnj;توانید از قابلیت&zwnj;های آن در پروژه خود استفاده کنید. در قسمت&zwnj;های بعدی مقاله توضیحات بیشتری در خصوص آن ارائه خواهد شد."
                            },

                            {
                                icon: "fad fa-caret-circle-left",
                                strongText: "<a href = https://yarnpkg.com target = _blank>Yarn</a> ",
                                liText: "این ابزار نیز مشابه npm برای مدیریت پکیج&zwnj;ها به شکل <strong>سریع</strong>، <strong>امن</strong> و <strong>قابل اطمینان</strong> طراحی شده است که علاوه بر آن که به عنوان یک ابزار مدیریت پکیج شناخته می&zwnj;شود، به عنوان ابزاری برای مدیریت پروژه&zwnj;ها نیز در نظر گرفته می&zwnj;شود. همچنین این ابزار، مشابه npm قابلیت استفاده در محیط&zwnj;های لینوکس، ویندوز و مک را دارد."
                            },

                            {
                                icon: "fad fa-caret-circle-left",
                                strongText: "<a href = https://pnpm.js.org target = _blank>pnpm</a> ",
                                liText: "ابزارهای npm و Yarn از پوشه&zwnj;ای به نام <span class = engliSpan>node_modules</span> برای ذخیره کردن پکیج&zwnj;ها و کدهای مرتبط با آنها استفاده می&zwnj;کنند، اما شاید تفاوت عمده pnpm با آنها در <span class = farsiSpan>مدیریت کردن فضای هارد</span> باشد. این ابزار به نوعی به کمک لینک&zwnj;ها، فایل&zwnj;های درون پوشه <span class = engliSpan>node_modules</span> را به پکیج&zwnj;ها ارتباط می&zwnj;دهد و از این لحاظ ساختار متفاوتی نسبت به دو ابزار قبلی دارد."
                            }

                        ],  // end of liText

                        listText2: [
                            /* this is the data used for BaseList.vue. the "strongText" data is optional and may be present in some list and not in other lists. */
                            {
                                icon: "fad fa-hand-point-left",
                                strongText: "تغییر اول ",
                                liText: "اضافه شدن یک فایل جدید به نام <span class = engliSpan>package-lock.json</span> به پوشه پروژه"
                            },

                            {
                                icon: "fad fa-hand-point-left",
                                strongText: "تغییر دوم ",
                                liText: "اضافه شدن یک پوشه جدید به نام <span class = engliSpan>node_modules</span> به پوشه پروژه"
                            },

                            {
                                icon: "fad fa-hand-point-left",
                                strongText: "تغییر سوم ",
                                liText: "اضافه شدن قسمت dependencies به فایل <span class = engliSpan>package.json</span>"
                            }

                        ],  // end of liText2

                    }
                }, // end of data

        }
</script>

<style scoped></style>
