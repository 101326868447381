<template>
<!-- this is the texts and contents of "article number 16" of our database. for more info and list of all options available for each article please see this file:
H:/web-codes/websites/portfolio-folder/design-and-assets/sample article/BaseArticle.vue  -->
        <div class="col-md-10 mx-auto">

            <div class="text-right mt-5">
                <!-- ############ -->
                <!-- intro text -->
                <!-- ############ -->
                <h2 class="subTitle">مقدمه: </h2>
                <blockquote class="blockquote">
                    <p class="text-justify textPara pb-5">
                        حلقه&zwnj;ها و ساختارهای تکرار در برنامه&zwnj;نویسی معمولا از قسمت&zwnj;های مهمی محسوب می&zwnj;شوند که هر توسعه&zwnj;دهنده&zwnj;ای باید نسبت به آنها اطلاعات کافی داشته باشد. شاید به ندرت به کد یا پروژه&zwnj;ای برخورد کرده باشیم که از حلقه&zwnj;ها در آن استفاده نشده باشد. به طور کلی کاربرد حلقه&zwnj;ها در برنامه&zwnj;نویسی در زمینه اجرای یک سری عملیات به تعداد دفعات معینی است که معمولا این تعداد تکرار با توجه به <strong>شرطی</strong> که در حلقه ذکر می&zwnj;شود، تعیین می&zwnj;شود. در این مقاله قصد داریم با برخی از انواع مختلف حلقه&zwnj;ها و مفاهیمی که در زبان جاوااسکریپت برای ایجاد ساختارهای تکرارشونده به کار می&zwnj;روند، آشنایی بیشتر و کامل&zwnj;تری پیدا کنیم.
                    </p>
                </blockquote>

                <!-- ############ -->
                <!-- part one -->
                <!-- ############ -->
                <h2 class="subTitle mt-5">آماده&zwnj;سازی اولیه:</h2>
                <p class="text-justify textPara">
                    برای نمایش خروجی حلقه&zwnj;ها و مثال&zwnj;هایی که در این مقاله مورد بررسی قرار می&zwnj;گیرند، سه فایل <span dir="ltr">HTML</span>، <span dir="ltr">CSS</span> و <span dir="ltr">JS</span> را ایجاد می&zwnj;کنیم. در فایل HTML یک ساختار ساده ایجاد کرده&zwnj;ایم که خروجی&zwnj;های کدهای جاوااسکریپت و حلقه&zwnj;ها را در آن نمایش دهیم. در این فایل از یک دکمه (تگ <code>&lt;button&gt;</code>) استفاده شده که با کلیک بر روی آن، هر بار تابع <span class="engliSpan">loopFunc</span> فراخوانی می&zwnj;شود و عملیات حلقه مربوطه در آن اجرا می&zwnj;شود. فایل CSS نیز برای اعمال یک سری استایل&zwnj;های اولیه (صرفا به جهت زیبایی خروجی) در اینجا به کار گرفته شده است. اما آنچه که <strong>غالباً</strong> در خصوص هر یک از حلقه&zwnj;ها متغیر خواهد بود، فایل JS است که در خصوص هر حلقه عملیات مورد نظر را اجرا می&zwnj;کند. در ادامه می&zwnj;توانید ساختار کلی استفاده شده برای هر یک از این سه فایل را مشاهده کنید:
                </p>

                <!-- showing code imgs in tabs -->
                <BaseTab
                    :head-tabs = "tabDatas"
                    @indexSend = "loadTabContent($event)"
                >
                    <template>
                        <div class="col-md-10 mt-5 mx-auto code-img">
                            <img class="img-fluid img-thumbnail" :src="require(`./imgFolder/${tabDatas[activeTab].data.imgName}`)" :alt="`${tabDatas[activeTab].data.altText}`">
                        </div>
                    </template>
                </BaseTab>

                <!-- ############ -->
                <!-- part two -->
                <!-- ############ -->
                <h2 class="subTitle mt-5">حلقه for :</h2>
                <p class="text-justify textPara">
                    این حلقه یکی از ساده&zwnj;ترین و در عین حال پرکاربردترین حلقه&zwnj;ها در زبان جاوااسکریپت است. ساختار (<span class="engliSpan">syntax</span>) کلی این حلقه را به صورت شماتیک در زیر مشاهده می&zwnj;کنید:
                </p>

                <!-- images related to codes -->
                <div class="col-md-10 mt-5 mx-auto code-img">
                    <img class="img-fluid img-thumbnail" src="./imgFolder/for-loop-syntax.png" alt="تصویر مربوط به ساختار کلی حلقه for">
                </div>

                <p class="text-justify textPara mt-5">
                    همان&zwnj;گونه که مشخص است، برای تعریف اجزای این حلقه کافی است که سه بخش <span dir="ltr" class="engliSpan">initialization</span>، <span dir="ltr" class="engliSpan">condition</span> و <span dir="ltr" class="engliSpan">finalExpression</span> را معین کنیم. در بخش <span class="engliSpan">initialization</span> متغیری که در واقع شمارنده حلقه است، تعریف شده و یک مقدار اولیه به آن اختصاص داده می&zwnj;شود. در قسمت <span class="engliSpan">condition</span> شرطی که برای پایان حلقه باید در نظر گرفته شود، قید می&zwnj;شود. چرا که همان&zwnj;طور که گفته شد، حلقه&zwnj;ها عملیات درون خود را به <strong>تعداد دفعات مشخصی</strong> تکرار می&zwnj;کنند و اگر شرطی برای پایان آنها ذکر <span class="farsiSpan">نشود</span>، تعداد تکرار آنها تا بی&zwnj;نهایت ادامه خواهد داشت. در پایان در قسمت <span class="engliSpan">finalExpression</span> معمولا نرخ افزایش یا کاهش شمارنده حلقه ذکر می&zwnj;گردد. برای درک بهتر این حلقه به مثال زیر توجه کنید:
                </p>

                <!-- showing code imgs in tabs -->
                <BaseTab
                    :head-tabs = "tabDatas2"
                    @indexSend = "loadTabContent2($event)"
                >
                    <template>
                        <div class="col-md-10 mt-5 mx-auto code-img">
                            <img class="img-fluid img-thumbnail" :src="require(`./imgFolder/${tabDatas2[activeTab2].data.imgName}`)" :alt="`${tabDatas2[activeTab2].data.altText}`">
                        </div>
                    </template>
                </BaseTab>

                <p class="text-justify textPara mt-5">
                    در اصل خروجی حاصل که در بالا مشاهده می&zwnj;کنید از ترکیب دو فایل <span class="engliSpan">HTML</span> و <span class="engliSpan">CSS</span> ذکر شده در قسمت <span class="farsiSpan">آغازین</span> مقاله و فایل <span class="engliSpan">JS</span> حاضر در اینجا بدست آمده است. در این فایل جاوااسکریپت مشخص است که شمارنده مورد نظر متغیر <code>i</code> تعریف شده و شرط پایان تکرار حلقه کوچکتر بودن از عدد <span class="farsiSpan">15</span> لحاظ شده است. همچنین در هر بار تکرار شمارنده <code>i</code> به میزان یک واحد، افزایش می&zwnj;یابد. خروجی تکرار هر بار حلقه درون یک تگ <code>&lt;span&gt;</code> قرار داده شده و درون المان با کلاس <span class="engliSpan">parentAll</span>، که قبلا در فایل HTML تعریف کرده&zwnj;ایم، جایگذاری می&zwnj;شود.
                </p>

                <!-- ############ -->
                <!-- part three -->
                <!-- ############ -->
                <h2 class="subTitle mt-5">استفاده از break :</h2>
                <p class="text-justify textPara">
                    عبارت <code dir="ltr">break;</code> در ساختار حلقه&zwnj;ها باعث می&zwnj;شود که تکرار حلقه <strong>قبل از</strong> رسیدن به شرط کلی تعیین شده برای آن، پایان یابد. برای مثال فرض کنید که در حلقه for ذکر شده در بخش قبل بخواهیم هنگامی که متغیر شمارنده به عدد <span class="farsiSpan">6</span> می&zwnj;رسد، تکرار حلقه پایان یابد. در این حالت می&zwnj;توان از عبارت <code dir="ltr">break;</code> مشابه کد زیر سود برد:
                </p>

                <!-- showing code imgs in tabs -->
                <BaseTab
                    :head-tabs = "tabDatas3"
                    @indexSend = "loadTabContent3($event)"
                >
                    <template>
                        <div class="col-md-10 mt-5 mx-auto code-img">
                            <img class="img-fluid img-thumbnail" :src="require(`./imgFolder/${tabDatas3[activeTab3].data.imgName}`)" :alt="`${tabDatas3[activeTab3].data.altText}`">
                        </div>
                    </template>
                </BaseTab>

                <p class="text-justify textPara mt-5">
                    نکته&zwnj;ای که باید در اینجا به آن توجه شود، این است که دستور <code dir="ltr">break;</code> تنها کد را از درونی&zwnj;ترین حلقه خارج می&zwnj;سازد و اگر ساختار کد ما دارای حلقه&zwnj;های <span class="farsiSpan">تو در تو</span> باشد، حلقه&zwnj;های بیرونی به فرآیند خود <span class="farsiSpan">ادامه</span> خواهند داد:
                </p>

                <!-- showing code imgs in tabs -->
                <BaseTab
                    :head-tabs = "tabDatas4"
                    @indexSend = "loadTabContent4($event)"
                >
                    <template>
                        <div class="col-md-10 mt-5 mx-auto code-img">
                            <img class="img-fluid img-thumbnail" :src="require(`./imgFolder/${tabDatas4[activeTab4].data.imgName}`)" :alt="`${tabDatas4[activeTab4].data.altText}`">
                        </div>
                    </template>
                </BaseTab>

                <p class="text-justify textPara mt-5">
                    در مثال بالا مشخص است که حلقه درونی (که دارای دستور <code dir="ltr">break;</code> است) تنها تا <code dir="ltr">i=4</code> به تکرار خود ادامه می&zwnj;دهد. ولی حلقه بیرونی تا انتهای شرط حلقه (<code dir="ltr">j=7</code>) به تکرار می&zwnj;پردازد. در این مثال در 8 بار تکرار حلقه بیرونی، <span class="farsiSpan">هر بار</span> حلقه درونی تنها 5 تکرار از 15 تکرار خود را به انجام می&zwnj;رساند و سپس کد ما از حلقه درونی خارج می&zwnj;شود.
                </p>

                <!-- ############ -->
                <!-- part four -->
                <!-- ############ -->
                <h2 class="subTitle mt-5">حلقه for…in :</h2>
                <p class="text-justify textPara">
                    حلقه <code dir="ltr">for…in</code> نیز یکی دیگر از ساختارهایی است که می&zwnj;توانید در زبان جاوااسکریپت برای ایجاد تکرار از آن بهره بگیرید. نکته مهم در مورد این حلقه آن است که باید در خصوص <span class="engliSpan">object</span> ها استفاده شود و استفاده از آن در سایر موارد (مانند استفاده در خصوص آرایه&zwnj;ها و ...) توصیه <span class="farsiSpan">نمی&zwnj;شود</span>. این حلقه به صورت خودکار تعداد اعضای <span class="engliSpan">object</span> مورد نظر را شناسایی کرده و پس از اتمام اعضای <span class="engliSpan">object</span>، حلقه نیز پایان می&zwnj;یابد. ساختار کلی این حلقه به شکل زیر است:
                </p>

                <!-- images related to codes -->
                <div class="col-md-10 mt-5 mx-auto code-img">
                    <img class="img-fluid img-thumbnail" src="./imgFolder/for-in-syntax.png" alt="تصویر مربوط به ساختار کلی حلقه for…in">
                </div>

                <p class="text-justify textPara mt-5">
                    برای درک بهتر کارایی این حلقه، کافی است که یک object را درکد جاوااسکریپت خود تعریف نموده و سپس این حلقه را در مورد آن به کار گیرید. مثال زیر در حقیقت همین کار را انجام داده است:
                </p>

                <!-- showing code imgs in tabs -->
                <BaseTab
                    :head-tabs = "tabDatas5"
                    @indexSend = "loadTabContent5($event)"
                >
                    <template>
                        <div class="col-md-10 mt-5 mx-auto code-img">
                            <img class="img-fluid img-thumbnail" :src="require(`./imgFolder/${tabDatas5[activeTab5].data.imgName}`)" :alt="`${tabDatas5[activeTab5].data.altText}`">
                        </div>
                    </template>
                </BaseTab>

                <!-- ############ -->
                <!-- part five -->
                <!-- ############ -->
                <h2 class="subTitle mt-5">حلقه for…of :</h2>
                <p class="text-justify textPara">
                    برخلاف حلقه <code dir="ltr">for…in</code> که تنها برای <span class="engliSpan">object</span> ها در جاوااسکریپت کارایی دارد، این حلقه را می&zwnj;توان در خصوص تمامی متغیرهای قابل پیمایش (<a href="https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Iteration_protocols" target="_blank">iterable objects</a>) مانند <span class="farsiSpan">آرایه&zwnj;ها</span> (Arrays) یا <span class="farsiSpan">مجموعه&zwnj;ها</span> (Sets) در جاوااسکریپت به کار برد. شکل کلی استفاده از آن، که تقریباً مشابه حلقه <code dir="ltr">for…in</code> است، در ادامه آورده شده است:
                </p>

                <!-- images related to codes -->
                <div class="col-md-10 mt-5 mx-auto code-img">
                    <img class="img-fluid img-thumbnail" src="./imgFolder/for-of-syntax.png" alt="تصویر مربوط به ساختار کلی حلقه for…of">
                </div>

                <p class="text-justify textPara mt-5">
                    در اینجا با ذکر دو مثال چگونگی استفاده از آن را مشاهده خواهید کرد. ابتدا این حلقه را در خصوص آرایه&zwnj;ها به کار می&zwnj;بریم:
                </p>

                <!-- showing code imgs in tabs -->
                <BaseTab
                    :head-tabs = "tabDatas6"
                    @indexSend = "loadTabContent6($event)"
                >
                    <template>
                        <div class="col-md-10 mt-5 mx-auto code-img">
                            <img class="img-fluid img-thumbnail" :src="require(`./imgFolder/${tabDatas6[activeTab6].data.imgName}`)" :alt="`${tabDatas6[activeTab6].data.altText}`">
                        </div>
                    </template>
                </BaseTab>

                <p class="text-justify textPara mt-5">
                    همان&zwnj;طور که ملاحظه می&zwnj;کنید، در این حلقه مقادیر اعضای آرایه به نمایش در می&zwnj;آیند. در مثال دوم به پیمایش یک مجموعه (<span class="engliSpan">set</span>) در جاوااسکریپت می&zwnj;پردازیم:
                </p>

                <!-- showing code imgs in tabs -->
                <BaseTab
                    :head-tabs = "tabDatas7"
                    @indexSend = "loadTabContent7($event)"
                >
                    <template>
                        <div class="col-md-10 mt-5 mx-auto code-img">
                            <img class="img-fluid img-thumbnail" :src="require(`./imgFolder/${tabDatas7[activeTab7].data.imgName}`)" :alt="`${tabDatas7[activeTab7].data.altText}`">
                        </div>
                    </template>
                </BaseTab>

                <p class="text-justify textPara mt-5">
                    در خروجی فوق مشاهده می&zwnj;کنید که تنها <span class="farsiSpan">5</span> عضو به نمایش در می&zwnj;آیند. در حالی&zwnj;که در کد نوشته شده <span class="farsiSpan">7</span> عضو برای آرایه مورد نظر ذکر کرده بودیم. علت این امر در آن است که دستور <code dir="ltr">new Set()</code> باعث ایجاد مجموعه در جاوااسکریپت می&zwnj;شود. به بیان ساده&zwnj;تر تنها اعضای <strong>غیرتکراری</strong> را در خود نگه می&zwnj;دارد. لذا از میان اعداد <span class="farsiSpan">4</span> و <span class="farsiSpan">5</span> که هر یک دو بار تکرار شده&zwnj;اند، تنها <span class="farsiSpan">یکی</span> از هر کدام در خروجی نهایی ذخیره می&zwnj;شود.
                </p>

                <!-- ############ -->
                <!-- part six -->
                <!-- ############ -->
                <h2 class="subTitle mt-5">حلقه while :</h2>
                <p class="text-justify textPara">
                    حلقه <span class="engliSpan">while</span> بر اساس شرطی که برای آن تبیین می&zwnj;شود، دستور یا دستورهای مشخصی را تکرار می&zwnj;کند. یعنی اگر شرط ذکر شده برقرار باشد (به اصطلاح true باشد) دستورهای داخل حلقه اجرا می&zwnj;شوند و این عمل تا جایی ادامه می&zwnj;یابد که نهایتاً شرط از حالت true <span class="farsiSpan">خارج</span> گردد. صورت کلی این حلقه به شکل زیر است:
                </p>

                <!-- images related to codes -->
                <div class="col-md-10 mt-5 mx-auto code-img">
                    <img class="img-fluid img-thumbnail" src="./imgFolder/while-syntax.png" alt="تصویر مربوط به ساختار کلی حلقه while">
                </div>

                <p class="text-justify textPara mt-5">
                    برای مثال فرض کنید که عدد رندمی را بین صفر و یک تولید نماییم و بخواهیم که دستورهای حلقه اگر این عدد کوچک&zwnj;تر از <span class="engliSpan">0.75</span> بود، اجرا شوند. همچنین در پایان هر بار اجرای حلقه دوباره این عدد رندم را تولید کنیم. در چنین حالتی تعداد تکرار حلقه ما از ابتدا مشخص نخواهد بود و دستورهای درون آن تا هنگامی که اعداد تولید شده کمتر از <span class="engliSpan">0.75</span> باشند، اجرا می&zwnj;گردند. در نقطه مقابل حتی ممکن است که همان عدد اول تولید شده بزرگتر از <span class="engliSpan">0.75</span> باشد و بنابراین اصلاً دستورهای درون حلقه اجرا <span class="farsiSpan">نشوند</span>!
                </p>

                <!-- showing code imgs in tabs -->
                <BaseTab
                    :head-tabs = "tabDatas8"
                    @indexSend = "loadTabContent8($event)"
                >
                    <template>
                        <div class="col-md-10 mt-5 mx-auto code-img">
                            <img class="img-fluid img-thumbnail" :src="require(`./imgFolder/${tabDatas8[activeTab8].data.imgName}`)" :alt="`${tabDatas8[activeTab8].data.altText}`">
                        </div>
                    </template>
                </BaseTab>

                <p class="text-justify textPara mt-5">
                    در خروجی فوق دو دفعه صفحه مرورگر را <span class="engliSpan">refresh</span> کرده&zwnj;ایم. در دفعه نخست حلقه مورد نظر دو بار تکرار شده و در دفعه دوم تنها یک بار. با توجه به این نکته که تعداد تکرار حلقه <span class="engliSpan">while</span> بستگی به برقرار بودن یا نبودن شرط آن دارد، باید دقت شود که از شرط&zwnj;هایی که <strong>همواره برقرار هستند</strong>، اجتناب شود. مثلاً اگر در مثال فوق شرط کوچک&zwnj;تر از <span class="engliSpan">0.75</span> را تغییر داده و شرط کوچک&zwnj;تر بودن از عدد <span class="engliSpan">1</span> را جایگزین آن نماییم، آنگاه حلقه ما بی&zwnj;نهایت بار تکرار می&zwnj;شود!
                </p>

                <!-- ############ -->
                <!-- part seven -->
                <!-- ############ -->
                <h2 class="subTitle mt-5">حلقه do…while :</h2>
                <p class="text-justify textPara">
                    این حلقه نیز مشابه حلقه <span class="engliSpan">while</span> تا زمانی که شرط ذکر شده برای آن برقرار باشد، به تکرار اجرای دستور یا دستورهای تعریف&zwnj;شده می&zwnj;پردازد. صورت کلی این حلقه به شکل زیر است:
                </p>

                <!-- images related to codes -->
                <div class="col-md-10 mt-5 mx-auto code-img">
                    <img class="img-fluid img-thumbnail" src="./imgFolder/do-while-syntax.png" alt="تصویر مربوط به ساختار کلی حلقه do…while">
                </div>

                <p class="text-justify textPara mt-5">
                    برای بهتر مشخص شدن مسئله به ذکر مثال با این حلقه می&zwnj;پردازیم. فرض کنید که یک مقدار اولیه به متغیر <span class="engliSpan">initialNum</span> داده باشیم و در هر بار تکرار حلقه مقدار آن را کاهش دهیم. اگر بخواهیم که دستورهای حلقه تا زمانی که مقدار این متغیر بزرگ&zwnj;تر از صفر است اجرا شوند، می&zwnj;توانیم به طریق زیر عمل کنیم:
                </p>

                <!-- showing code imgs in tabs -->
                <BaseTab
                    :head-tabs = "tabDatas9"
                    @indexSend = "loadTabContent9($event)"
                >
                    <template>
                        <div class="col-md-10 mt-5 mx-auto code-img">
                            <img class="img-fluid img-thumbnail" :src="require(`./imgFolder/${tabDatas9[activeTab9].data.imgName}`)" :alt="`${tabDatas9[activeTab9].data.altText}`">
                        </div>
                    </template>
                </BaseTab>

                <p class="text-justify textPara mt-5">
                    تفاوت اصلی این حلقه با حلقه while در آن است که دستورهای مذکور بدون توجه به برقرار بودن یا نبودن شرط حلقه، <span class="farsiSpan">حداقل یک بار</span> اجرا می&zwnj;شوند. مثلاً فرض کنید در مثال قبل مقدار اولیه متغیر <span class="engliSpan">initialNum</span> را برابر <span class="engliSpan" dir="ltr">-1</span> قرار دهیم. در این حالت با وجود آنکه شرط حلقه برقرار <strong>نیست</strong>، دستورهای حلقه یک بار اجرا می&zwnj;شوند:
                </p>

                <!-- showing code imgs in tabs -->
                <BaseTab
                    :head-tabs = "tabDatas10"
                    @indexSend = "loadTabContent10($event)"
                >
                    <template>
                        <div class="col-md-10 mt-5 mx-auto code-img">
                            <img class="img-fluid img-thumbnail" :src="require(`./imgFolder/${tabDatas10[activeTab10].data.imgName}`)" :alt="`${tabDatas10[activeTab10].data.altText}`">
                        </div>
                    </template>
                </BaseTab>

                <!-- ############ -->
                <!-- part eight -->
                <!-- ############ -->
                <h2 class="subTitle mt-5">استفاده از label :</h2>
                <p class="text-justify textPara">
                    در زبان جاوااسکریپت می&zwnj;توانیم بخشی از کد خود را با یک نام دلخواه به حالت نشان&zwnj;شده در آوریم. به اصطلاح گفته می&zwnj;شود که آنها را <span class="engliSpan">label</span> کرده&zwnj;ایم و چنین دستورهایی را <span class="engliSpan">labeled statements</span> می&zwnj;نامند. صورت کلی انجام این کار به شکل زیر است:
                </p>

                <!-- images related to codes -->
                <div class="col-md-10 mt-5 mx-auto code-img">
                    <img class="img-fluid img-thumbnail" src="./imgFolder/label-syntax.png" alt="تصویر مربوط به ساختار کلی استفاده از label">
                </div>

                <p class="text-justify textPara mt-5">
                    به عبارت ساده&zwnj;تر تنها کافی است یک نام دلخواه را قبل از علامت <span class="engliSpan">:</span> قرار داده و بعد از این علامت نیز دستور جاوااسکریپت خود را بیان کنیم (مثلاً یک حلقه for را بعد از این علامت قرار دهیم). به کمک این مفهوم می&zwnj;توانیم حلقه&zwnj;های کد خود را بهتر مدیریت کنیم و تکرار آنها و چگونگی پایان یافتن آنها را تغییر دهیم. برای روشن شدن موضوع به مثالی مشابه آنچه در بخش استفاده از دستور <code dir="ltr">break;</code> ذکر شد، می&zwnj;پردازیم. ابتدا کد زیر و خروجی مرتبط با آن را مشاهده کنید:
                </p>

                <!-- showing code imgs in tabs -->
                <BaseTab
                    :head-tabs = "tabDatas11"
                    @indexSend = "loadTabContent11($event)"
                >
                    <template>
                        <div class="col-md-10 mt-5 mx-auto code-img">
                            <img class="img-fluid img-thumbnail" :src="require(`./imgFolder/${tabDatas11[activeTab11].data.imgName}`)" :alt="`${tabDatas11[activeTab11].data.altText}`">
                        </div>
                    </template>
                </BaseTab>

                <p class="text-justify textPara mt-5">
                    در خط <span class="farsiSpan">19</span> کد فوق از دستور <code dir="ltr">break labeledFor;</code> استفاده شده است. این دستور بیان می&zwnj;کند که وقتی کد ما به این خط برسد (یعنی مقدار <code>i</code> برابر 4 شده باشد)، عبارت <span class="engliSpan">label</span> شده باید متوقف شود و دیگر اجرا نشود. در مثال حاضر عبارت label شده همان حلقه for خارجی است. لذا هنگامی که مقدار <code>i</code> برابر 4 می&zwnj;شود، هر دو حلقه <strong>به صورت همزمان</strong> متوقف می&zwnj;شوند. بنابراین خروجی انتهایی ما دارای <code>j</code> برابر <span class="engliSpan">0</span> و <code>i</code> برابر <span class="engliSpan">4</span> است. برای بهتر متوجه شدن تأثیر label کافی است که به جای دستور <code dir="ltr">break labeledFor;</code> از یک دستور <code dir="ltr">break;</code> مشابه کد زیر استفاده کنیم:
                </p>

                <!-- showing code imgs in tabs -->
                <BaseTab
                    :head-tabs = "tabDatas12"
                    @indexSend = "loadTabContent12($event)"
                >
                    <template>
                        <div class="col-md-10 mt-5 mx-auto code-img">
                            <img class="img-fluid img-thumbnail" :src="require(`./imgFolder/${tabDatas12[activeTab12].data.imgName}`)" :alt="`${tabDatas12[activeTab12].data.altText}`">
                        </div>
                    </template>
                </BaseTab>

                <p class="text-justify textPara mt-5">
                    در خروجی فوق ملاحظه می&zwnj;کنید که طبق حالت معمول دستور <code dir="ltr">break;</code> وقتی که مقدار <code>i</code> به 4 برسد، <span class="farsiSpan">تنها</span> حلقه for داخلی متوقف می&zwnj;شود و حلقه خارجی به تکرار خود <span class="farsiSpan">ادامه</span> می&zwnj;دهد. بنابراین در پایان مقدار <code>j</code> به عدد 2 رسیده و در هر بار تکرار حلقه خارجی نیز مقدار <code>i</code> از عدد 4 تجاوز نمی&zwnj;کند.
                </p>

                <!-- ############ -->
                <!-- part nine -->
                <!-- ############ -->
                <h2 class="subTitle mt-5">استفاده از continue :</h2>
                <p class="text-justify textPara">
                    از جمله دستورهای دیگری که می&zwnj;توان در ساختار حلقه&zwnj;ها از آن بهره برد، دستور <code dir="ltr">continue;</code> است. فرض کنید که آرایه&zwnj;ای از کلمات داشته باشید و بخواهید تنها کلماتی را که <strong>بیشتر از</strong> 4 کاراکتر دارند، از میان آنها جدا کرده و نمایش دهید. مسلماً در چنین حالتی اگر از دستور <code dir="ltr">break;</code> در ساختار حلقه استفاده شود، هنگامی که کد ما به <span class="farsiSpan">اولین</span> کلمه&zwnj;ای که کمتر از 4 کاراکتر دارد، برسد از حلقه خارج شده و دیگر به بررسی سایر کلمات موجود در آرایه نمی&zwnj;پردازد. در چنین شرایطی یک راه حل مناسب و معقول استفاده از دستور <code dir="ltr">continue;</code> است. این دستور می&zwnj;تواند هر بار که به کلمه&zwnj;ای با طول 4 کاراکتر یا کمتر رسید، <strong>فقط برای همان دور</strong> ما را از حلقه خارج کند و دور بعدی را آغاز کند. به عنوان مثال به کد زیر و خروجی حاصل از آن توجه کنید:
                </p>

                <!-- showing code imgs in tabs -->
                <BaseTab
                    :head-tabs = "tabDatas13"
                    @indexSend = "loadTabContent13($event)"
                >
                    <template>
                        <div class="col-md-10 mt-5 mx-auto code-img">
                            <img class="img-fluid img-thumbnail" :src="require(`./imgFolder/${tabDatas13[activeTab13].data.imgName}`)" :alt="`${tabDatas13[activeTab13].data.altText}`">
                        </div>
                    </template>
                </BaseTab>

                <p class="text-justify textPara mt-5">
                    همان&zwnj;گونه که از خروجی فوق مشخص است، با وجود آنکه آرایه <span class="engliSpan">myCars</span> در این مثال 8 عضو دارد، اما <span class="farsiSpan">تنها</span> 5 عدد از آنها در صفحه مرورگر به نمایش در آمده&zwnj;اند. دلیل این مسئله آن است که هر بار طول کلمه موجود در آرایه برابر با یا کوچکتر از 4 باشد، ادامه دستورهای موجود در حلقه اجرا <span class="farsiSpan">نمی&zwnj;شوند</span> و حلقه به سمت تکرار بعدی (کلمه بعدی) هدایت می&zwnj;شود.
                </p>

                <!-- ############ -->
                <!-- conclusion part -->
                <!-- ############ -->
                <h2 class="subTitle pb-3 mt-5">جمع بندی: </h2>
                <blockquote class="blockquote conclu">
                    <p class="text-justify textPara pt-3">
                        در این مقاله به بررسی انواع مختلف حلقه&zwnj;ها و دستورهایی که در ساختارهای تکرار شونده در زبان جاوااسکریپت کاربرد دارند، پرداختیم. مسلماً در برنامه&zwnj;نویسی به هر زبانی نیاز است که دستور یا دستورهایی به تعداد دفعات معین تکرار شوند تا به نتیجه مطلوب برسیم. در زبان جاوااسکریپت نیز انواع حلقه&zwnj;ها چه برای شرایطی که تعداد تکرار از قبل برای ما مشخص باشد (مانند حلقه <span class="engliSpan">for</span>) و چه برای شرایطی که تعداد تکرار از ابتدا برای ما نامعین است (مانند حلقه <span class="engliSpan">while</span>) تعریف شده&zwnj;اند. امیدواریم که با یادگیری این دستورها و ساختارهای مرتبط با هر یک بتوانید کدهای روان&zwnj;تر و کاربردی&zwnj;تری را در پروژه&zwnj;های خود پیاده&zwnj;سازی نمایید.
                    </p>
                </blockquote>

            </div>



            <!-- ways number 1 of loading imgs  -->
            <!--
            <img :src='`${this.publicPath}articles/${this.$route.params.id}/first.png`' alt="تصویر نمونه">
-->

                <!-- ways number 2 of loading imgs  -->
                <!--
               <img src='./imgFolder/img1.png' alt="تصویر نمونه">
               -->
        </div>
</template>

<script>
import BaseTab from '../../BaseTab.vue';

export default {
                name: "BaseArticle",
                components: {
                    BaseTab,
                },
                data() {
                    return {
                        activeTab: 0,
                        activeTab2: 0,
                        activeTab3: 0,
                        activeTab4: 0,
                        activeTab5: 0,
                        activeTab6: 0,
                        activeTab7: 0,
                        activeTab8: 0,
                        activeTab9: 0,
                        activeTab10: 0,
                        activeTab11: 0,
                        activeTab12: 0,
                        activeTab13: 0,

                        tabDatas: [
                            {
                                tabHeders: "HTML",
                                data:{
                                    imgName: "html-template.png",
                                    altText: "تصویر مربوط به فایل HTML که در طول مقاله از آن استفاده می شود"
                                }
                            },

                            {
                                tabHeders: "CSS",
                                data:{
                                    imgName: "css-general.png",
                                    altText: "تصویر مربوط به فایل CSS که در طول مقاله از آن استفاده می شود"
                                }
                            },

                            {
                                tabHeders: "JS",
                                data:{
                                    imgName: "javascript-template.png",
                                    altText: "تصویر مربوط به ساختار کلی فایل JS که در طول مقاله از آن استفاده می شود"
                                }
                            }
                        ],   // end of tabDatas

                        tabDatas2: [
                            {
                                tabHeders: "JS",
                                data:{
                                    imgName: "for-loop-example.png",
                                    altText: "تصویر مربوط به مثال استفاده از حلقه for"
                                }
                            },

                            {
                                tabHeders: "Result",
                                data:{
                                    imgName: "for-loop-result.gif",
                                    altText: "تصویر مربوط به خروجی مثال استفاده از حلقه for"
                                }
                            }
                        ], // end of tabDatas2

                        tabDatas3: [
                            {
                                tabHeders: "JS",
                                data:{
                                    imgName: "break-example.png",
                                    altText: "تصویر مربوط به مثال استفاده از دستور break"
                                }
                            },

                            {
                                tabHeders: "Result",
                                data:{
                                    imgName: "break-result.gif",
                                    altText: "تصویر مربوط به خروجی مثال استفاده از دستور break"
                                }
                            }
                        ], // end of tabDatas3

                        tabDatas4: [
                            {
                                tabHeders: "JS",
                                data:{
                                    imgName: "break-example-outer.png",
                                    altText: "تصویر مربوط به مثال استفاده از دستور break در حلقه های تو در تو"
                                }
                            },

                            {
                                tabHeders: "Result",
                                data:{
                                    imgName: "break-example-outer-result.gif",
                                    altText: "تصویر مربوط به خروجی مثال استفاده از دستور break در حلقه های تو در تو"
                                }
                            }
                        ],   // end of tabDatas4

                        tabDatas5: [
                            {
                                tabHeders: "JS",
                                data:{
                                    imgName: "for-in-example.png",
                                    altText: "تصویر مربوط به مثال استفاده از حلقه for…in"
                                }
                            },

                            {
                                tabHeders: "Result",
                                data:{
                                    imgName: "for-in-result.gif",
                                    altText: "تصویر مربوط به خروجی مثال استفاده از حلقه for…in"
                                }
                            }
                        ], // end of tabDatas5

                        tabDatas6: [
                            {
                                tabHeders: "JS",
                                data:{
                                    imgName: "for-of-array.png",
                                    altText: "تصویر مربوط به مثال استفاده از حلقه for…of در مورد آرایه ها"
                                }
                            },

                            {
                                tabHeders: "Result",
                                data:{
                                    imgName: "for-of-array-result.gif",
                                    altText: "تصویر مربوط به خروجی مثال استفاده از حلقه for…of در مورد آرایه ها"
                                }
                            }
                        ], // end of tabDatas6

                        tabDatas7: [
                            {
                                tabHeders: "JS",
                                data:{
                                    imgName: "for-of-set.png",
                                    altText: "تصویر مربوط به مثال استفاده از حلقه for…of در مورد مجموعه ها"
                                }
                            },

                            {
                                tabHeders: "Result",
                                data:{
                                    imgName: "for-of-set-result.gif",
                                    altText: "تصویر مربوط به خروجی مثال استفاده از حلقه for…of در مورد مجموعه ها"
                                }
                            }
                        ], // end of tabDatas7

                        tabDatas8: [
                            {
                                tabHeders: "JS",
                                data:{
                                    imgName: "while-example.png",
                                    altText: "تصویر مربوط به مثال استفاده از حلقه while"
                                }
                            },

                            {
                                tabHeders: "Result",
                                data:{
                                    imgName: "while-example-result.gif",
                                    altText: "تصویر مربوط به خروجی مثال استفاده از حلقه while"
                                }
                            }
                        ], // end of tabDatas8

                        tabDatas9: [
                            {
                                tabHeders: "JS",
                                data:{
                                    imgName: "do-while-example.png",
                                    altText: "تصویر مربوط به مثال استفاده از حلقه do…while"
                                }
                            },

                            {
                                tabHeders: "Result",
                                data:{
                                    imgName: "do-while-result.gif",
                                    altText: "تصویر مربوط به خروجی مثال استفاده از حلقه do…while"
                                }
                            }
                        ], // end of tabDatas9

                        tabDatas10: [
                            {
                                tabHeders: "JS",
                                data:{
                                    imgName: "do-while-false.png",
                                    altText: "تصویر مربوط به مثال استفاده از حلقه do…while در حالت برقرار نبودن شرط حلقه"
                                }
                            },

                            {
                                tabHeders: "Result",
                                data:{
                                    imgName: "do-while-false-result.gif",
                                    altText: "تصویر مربوط به خروجی مثال استفاده از حلقه do…while در حالت برقرار نبودن شرط حلقه"
                                }
                            }
                        ], // end of tabDatas10

                        tabDatas11: [
                            {
                                tabHeders: "JS",
                                data:{
                                    imgName: "with-label-example.png",
                                    altText: "تصویر مربوط به مثال استفاده از label"
                                }
                            },

                            {
                                tabHeders: "Result",
                                data:{
                                    imgName: "with-label-result.gif",
                                    altText: "تصویر مربوط به خروجی مثال استفاده از label"
                                }
                            }
                        ], // end of tabDatas11

                        tabDatas12: [
                            {
                                tabHeders: "JS",
                                data:{
                                    imgName: "without-label-example.png",
                                    altText: "تصویر مربوط به مثال عدم استفاده از label"
                                }
                            },

                            {
                                tabHeders: "Result",
                                data:{
                                    imgName: "withot-label-result.gif",
                                    altText: "تصویر مربوط به خروجی مثال عدم استفاده از label"
                                }
                            }
                        ], // end of tabDatas12

                        tabDatas13: [
                            {
                                tabHeders: "JS",
                                data:{
                                    imgName: "continue-example.png",
                                    altText: "تصویر مربوط به مثال استفاده از دستور continue"
                                }
                            },

                            {
                                tabHeders: "Result",
                                data:{
                                    imgName: "continue-result.gif",
                                    altText: "تصویر مربوط به خروجی مثال استفاده از دستور continue"
                                }
                            }
                        ] // end of tabDatas13

                    }
                }, // end of data

                methods: {
                    loadTabContent: function(indexNow) {
                        this.activeTab = indexNow;
                    },

                    loadTabContent2: function(indexNow2) {
                        this.activeTab2 = indexNow2;
                    },

                    loadTabContent3: function(indexNow3) {
                        this.activeTab3 = indexNow3;
                    },

                    loadTabContent4: function(indexNow4) {
                        this.activeTab4 = indexNow4;
                    },

                    loadTabContent5: function(indexNow5) {
                        this.activeTab5 = indexNow5;
                    },

                    loadTabContent6: function(indexNow6) {
                        this.activeTab6 = indexNow6;
                    },

                    loadTabContent7: function(indexNow7) {
                        this.activeTab7 = indexNow7;
                    },

                    loadTabContent8: function(indexNow8) {
                        this.activeTab8 = indexNow8;
                    },

                    loadTabContent9: function(indexNow9) {
                        this.activeTab9 = indexNow9;
                    },

                    loadTabContent10: function(indexNow10) {
                        this.activeTab10 = indexNow10;
                    },

                    loadTabContent11: function(indexNow11) {
                        this.activeTab11 = indexNow11;
                    },

                    loadTabContent12: function(indexNow12) {
                        this.activeTab12 = indexNow12;
                    },

                    loadTabContent13: function(indexNow13) {
                        this.activeTab13 = indexNow13;
                    }
                } // end of methods

        }
</script>

<style scoped></style>
