<template>
<!-- this is the texts and contents of "article number 9" of our database. for more info and list of all options available for each article please see this file:
H:/web-codes/websites/portfolio-folder/design-and-assets/sample article/BaseArticle.vue  -->
        <div class="col-md-10 mx-auto">

            <div class="text-right mt-5">
                <!-- ############ -->
                <!-- intro text -->
                <!-- ############ -->
                <h2 class="subTitle">مقدمه: </h2>
                <blockquote class="blockquote">
                    <p class="text-justify textPara pb-5">
                        انیمیشن&zwnj;ها امروزه در دنیای وب به وفور یافت می&zwnj;شوند. شاید کمتر وب&zwnj;سایتی را دیده باشید که از این قابلیت به کلی بی&zwnj;بهره باشد. از استایل&zwnj;های ساده هنگام حرکت موس کاربر بر روی یک المان تا انیمیشن&zwnj;های گسترده در تصاویر و اسلایدرهای موجود در سایت&zwnj;ها، همگی از کاربری&zwnj;های این ابزار طراحی در دنیای وب هستند. CSS به عنوان زبانی که قابلیت طراحی و دادن استایل&zwnj;های مختلف به صفحات وب را در اختیار ما می&zwnj;گذارد، در میان توسعه&zwnj;دهندگان شناخته شده است. در میان ویژگی&zwnj;هایی که در CSS برای تعریف کردن استایل استفاده می&zwnj;شوند، شاید یکی از جذاب&zwnj;ترین و البته گسترده&zwnj;ترین آنها، <span class="farsiSpan">خاصیت انیمیشن</span> باشد. در این مقاله قصد داریم تا شما را با این خاصیت و نحوه استفاده از آن در پروژه&zwnj;های طراحی وب&zwnj;سایت بیشتر آشنا کنیم.
                    </p>
                </blockquote>

                <!-- ############ -->
                <!-- part one -->
                <!-- ############ -->
                <h2 class="subTitle mt-5">ویژگی&zwnj;های مرتبط با انیمیشن:</h2>
                <p class="text-justify textPara">
                    برای استفاده از خاصیت انیمیشن در فایل&zwnj;های CSS باید از ویژگی&zwnj;های مرتبط با آن استفاده نمود. این ویژگی&zwnj;ها به CSS کمک می&zwnj;کنند تا انیمیشن مورد نظر و چگونگی انجام شدن آن را <span class="farsiSpan">شناسایی</span> کند و در صفحه مرورگر به نمایش درآورد. در جدول زیر این ویژگی&zwnj;ها به طور خلاصه تعریف شده&zwnj;اند:
                </p>

                <BaseTable :ltrProp="false" styleProp="style-type1" :dataTableProp="tableData" widthMin="800px" captionText="جدول مربوط به ویژگی های مرتبط با انیمیشن در CSS"></BaseTable>

                <p class="text-justify textPara mt-5">
                    برخی از ویژگی&zwnj;های ذکر شده در جدول فوق مانند <span class="engliSpan">animation-name</span> و <span class="engliSpan">animation-duration</span> <span class="farsiSpan">حتماً</span> باید در تعریف یک انیمیشن، مشخص شده باشند. چرا که در غیر این صورت انیمیشن اجرا نخواهد شد. اما برخی ویژگی&zwnj;های دیگر مقادیر پیش فرض و تعریف&zwnj;شده&zwnj;ای دارند که اگر توسط توسعه&zwnj;دهنده، درون کد تعریف <span class="farsiSpan">نشوند</span> هم مشکلی رخ نخواهد داد.
                </p>

                <p class="text-justify textPara">
                    شاید توضیحات کلی که در این جدول ارائه شده است، کمی گنگ به نظر آید. لذا در ادامه سعی می&zwnj;کنیم با ذکر مثال علاوه بر آشنایی بیشتر با ویژگی&zwnj;های انیمیشن، ابهامات موجود را نیز برطرف نماییم.
                </p>

                <!-- ############ -->
                <!-- part two -->
                <!-- ############ -->
                <h2 class="subTitle mt-5">مثال کاربردی: دادن انیمیشن به یک فلش</h2>
                <p class="text-justify textPara">
                    در این مثال قصد داریم که ویژگی&zwnj;های ذکر شده در جدول قبل را به صورت عملی در کد خود پیاده&zwnj;سازی کنیم. فرض کنید که در وب&zwnj;سایت خود برای اینکه بخواهید نظر مخاطبان سایت را بیشتر به ثبت نام در سایت جلب کنید، از یک فلش، که حالت انیمیشن دارد، استفاده نموده&zwnj;اید.
                </p>

                <!-- imgs (png,jpg or gif) that are not related to codes -->
                <div class="col-md-10 mt-5 mx-auto">
                    <img class="img-fluid mx-auto d-block img-thumbnail shadow-lg" src="./imgFolder/animation1.gif" alt="تصویر مربوط به نمایش خروجی انیمیشن در مورد فلش">
                </div>

                <p class="text-justify textPara mt-5">
                    برای ایجاد این فلش از یک کتابخانه مخصوص آیکون به نام <a href="https://fontawesome.com/" target="_blank">font awesome</a> استفاده شده است. با اضافه کردن لینک آن به قسمت <code>&lt;head&gt;</code> سایت خود، می&zwnj;توانید از مجموعه آیکون&zwnj;های آن که در قالب تگ <code>&lt;i&gt;</code> ارائه می&zwnj;شوند، بهره ببرید. اما هدف ما در اینجا دادن انیمیشن به این آیکون و بررسی ویژگی&zwnj;های مرتبط با آن است. همان&zwnj;طور که گفته شد برای تعریف بازه زمانی انیمیشن (در واقع تعریف اصل انیمیشن)، در فایل CSS از دستور <span class="engliSpan" dir="ltr">@keyframes</span> به شکل زیر استفاده می&zwnj;کنیم:
                </p>

                <div class="col-md-10 mt-5 mx-auto code-img">
                    <img class="img-fluid img-thumbnail" src="./imgFolder/keyframes.png" alt="تصویر مربوط به چگونگی استفاده از دستور keyframes">
                </div>

                <p class="text-justify textPara mt-5">
                    در این دستور <span class="engliSpan">fadeRight</span> نام دلخواهی است که به انیمیشن مورد نظر داده می&zwnj;شود. مقادیر <span class="engliSpan" dir="ltr">0%</span> و <span class="engliSpan" dir="ltr">100%</span> که در کد مشاهده می&zwnj;شود، در حقیقت بازه&zwnj;های زمانی است که برای انیمیشن در نظر گرفته می&zwnj;شود و می&zwnj;توان هر مقدار دیگری بین <span class="engliSpan">0</span> تا <span class="engliSpan">100</span> را نیز به آن اضافه کرد. مفهوم این کد به زبان ساده آن است که وقتی در <span class="engliSpan" dir="ltr">0%</span> زمان انیمیشن قرار داریم، ویژگی&zwnj;های ذکر شده باید لحاظ شود (مثلا <span class="engliSpan">opacity</span> باید برابر صفر باشد) و وقتی که به <span class="engliSpan" dir="ltr">100%</span> زمان انیمیشن می&zwnj;رسیم، این ویژگی&zwnj;ها باید به <span class="farsiSpan">تدریج</span> به مقدار جدید تعریف شده رسیده باشند (مثلا <span class="engliSpan">opacity</span> به مقدار یک رسیده باشد).
                </p>

                <p class="text-justify textPara">
                    اما چنانچه تنها همین کد را به فایل CSS اضافه کنیم، هیچ تغییری در خروجی حاصل نخواهد شد. چون باید بعد از تعریف انیمیشن، تعیین کنیم که <span class="farsiSpan">کدام</span> المان (تگ HTML) قرار است دارای این انیمیشن باشد. برای این کار از سایر ویژگی&zwnj;های انیمیشن استفاده می&zwnj;شود. در اینجا انیمیشن <span class="engliSpan">fadeRight</span> برای تگ <code>&lt;i&gt;</code> که در فایل HTML موجود است، به شکل زیر تعریف شده است:
                </p>

                <!-- showing code imgs in tabs -->
                <BaseTab
                    :head-tabs = "tabDatas"
                    @indexSend = "loadTabContent($event)"
                >
                    <template>
                        <div class="col-md-10 mt-5 mx-auto code-img">
                            <img class="img-fluid img-thumbnail" :src="require(`./imgFolder/${tabDatas[activeTab].data.imgName}`)" :alt="`${tabDatas[activeTab].data.altText}`">
                        </div>
                    </template>

                </BaseTab>

                <!-- begin subtitles in indented paragraphs -->
                <div class="indentPara">
                    <!-- ############ -->
                    <!-- part two section 1 -->
                    <!-- ############ -->
                    <h3 class="subTitle2 mt-5">تغییر تعداد تکرار انیمیشن:</h3>
                    <p class="text-justify textPara">
                        همان&zwnj;طور که قبلا نیز ذکر شد، برخی از ویژگی&zwnj;های انیمیشن مانند <span class="engliSpan">animation-name</span> و <span class="engliSpan">animation-duration</span> باید حتما در کد CSS قید شوند تا انیمیشن مورد نظر اجرا شود. ویژگی دیگری که در مثال ذکر شده به کار رفته است، <span class="engliSpan">animation-iteration-count</span> نام دارد که با قرار دادن مقدار <span class="engliSpan">infinite</span> برای آن، انیمیشن مربوطه بینهایت بار تکرار می&zwnj;شود. چنانچه مقدار آن را برابر <strong>یک</strong> تنظیم کنیم (که در حالت پیش&zwnj;فرض نیز همین مقدار را دارد) انیمیشن ما فقط یک بار اجرا خواهد شد. همچنین می&zwnj;توانید هر عدد دیگری را نیز برای تعداد تکرار انیمیشن تعریف کنید.
                    </p>

                    <!-- showing code imgs in tabs -->
                    <BaseTab
                        :head-tabs = "tabDatas2"
                        @indexSend = "loadTabContent2($event)"
                    >
                        <template>
                            <div class="col-md-10 mt-5 mx-auto code-img">
                                <img class="img-fluid img-thumbnail" :src="require(`./imgFolder/${tabDatas2[activeTab2].data.imgName}`)" :alt="`${tabDatas2[activeTab2].data.altText}`">
                            </div>
                        </template>

                    </BaseTab>
                    <!-- ############ -->
                    <!-- part two section 2 -->
                    <!-- ############ -->
                    <h3 class="subTitle2 mt-5">تغییر استایل&zwnj;های المان بعد از انیمیشن:</h3>
                    <p class="text-justify textPara">
                        در شکل قبل مشاهده شد که بعد از انجام انیمیشن، استایل&zwnj;هایی که به صورت پیش&zwnj;فرض برای المان <code>&lt;i&gt;</code> تعریف شده بود، اعمال می&zwnj;شوند. یعنی از نظر موقعیت، رنگ و opacity به حالت <span class="farsiSpan">قبل</span> از اجرای انیمیشن باز می&zwnj;گردد. ویژگی <span class="engliSpan">animation-fill-mode</span> به ما کمک می&zwnj;کند که در خصوص تغییر کردن یا نکردن استایل&zwnj;ها بعد از انیمیشن کدنویسی انجام دهیم. این ویژگی چهار مقدار <span class="engliSpan" dir="ltr">none</span>، <span class="engliSpan" dir="ltr">forwards</span>، <span class="engliSpan">backwards</span> و <span class="engliSpan">both</span> را قبول می&zwnj;کند. برای مثال چنانچه مقدار آن را برابر <span class="engliSpan">forwards</span> تنظیم کنیم، باعث می&zwnj;شود استایل&zwnj;هایی که المان مربوطه در پایان اجرای انیمیشن گرفته است، در آن باقی بماند.
                    </p>

                    <!-- showing code imgs in tabs -->
                    <BaseTab
                        :head-tabs = "tabDatas3"
                        @indexSend = "loadTabContent3($event)"
                    >
                        <template>
                            <div class="col-md-10 mt-5 mx-auto code-img">
                                <img class="img-fluid img-thumbnail" :src="require(`./imgFolder/${tabDatas3[activeTab3].data.imgName}`)" :alt="`${tabDatas3[activeTab3].data.altText}`">
                            </div>
                        </template>

                    </BaseTab>

                    <p class="text-justify textPara mt-5">
                        در اینجا برای بهتر مشخص شدن تغییر رنگ، رنگ المان را در ابتدای انیمیشن سفید و در انتهای آن تقریبا بنفش تنظیم کردیم. همچنین مدت زمان انیمیشن را نیز <span class="farsiSpan">افزایش</span> داده&zwnj;ایم تا تغییر استایل&zwnj;ها بهتر مشخص شود. این در حالی است که اگر مقدار پارامتر animation-fill-mode را برابر <span class="engliSpan">backwards</span> تنظیم نماییم، بعد از پایان انیمیشن استایل&zwnj;های المان مورد نظر به حالت پیش&zwnj;فرضی که از قبل تنظیم شده، باز می&zwnj;گردد:
                    </p>

                    <!-- showing code imgs in tabs -->
                    <BaseTab
                        :head-tabs = "tabDatas4"
                        @indexSend = "loadTabContent4($event)"
                    >
                        <template>
                            <div class="col-md-10 mt-5 mx-auto code-img">
                                <img class="img-fluid img-thumbnail" :src="require(`./imgFolder/${tabDatas4[activeTab4].data.imgName}`)" :alt="`${tabDatas4[activeTab4].data.altText}`">
                            </div>
                        </template>

                    </BaseTab>

                    <p class="text-justify textPara mt-5">
                        در شکل فوق برای بهتر مشخص شدن مسئله یک رنگ روشن به خود استایل&zwnj;های فلش مورد نظر اضافه کرده&zwnj;ایم تا مشخص شود که بعد از پایان اجرای انیمیشن، رنگ فلش به <span class="farsiSpan">حالت اولیه</span> خود باز می&zwnj;گردد.
                    </p>
                    <!-- ############ -->
                    <!-- part two section 3 -->
                    <!-- ############ -->
                    <h3 class="subTitle2 mt-5">تعیین جهت نمایش انیمیشن:</h3>
                    <p class="text-justify textPara">
                        از جمله ویژگی&zwnj;های دیگری که در بحث انیمیشن مطرح است، ویژگی <span class="engliSpan">animation-direction</span> است. این ویژگی مقادیر <span class="engliSpan" dir="ltr">normal</span>، <span class="engliSpan" dir="ltr">reverse</span>، <span class="engliSpan">alternate</span> و <span class="engliSpan">alternate-reverse</span> را قبول می&zwnj;کند. مقدار <span class="engliSpan">normal</span> (که مقدار پیش&zwnj;فرض این ویژگی است) به معنای آن است که انیمیشن در جهت تعریف شده اجرا می&zwnj;شود (از <span class="engliSpan" dir="ltr">0%</span> به سمت <span class="engliSpan" dir="ltr">100%</span>). اما چنانچه مقدار آن را برابر <span class="engliSpan">reverse</span> تعریف کنیم، جهت انجام انیمیشن معکوس می&zwnj;شود (از <span class="engliSpan" dir="ltr">100%</span> به سمت <span class="engliSpan" dir="ltr">0%</span>).
                    </p>

                    <!-- showing code imgs in tabs -->
                    <BaseTab
                        :head-tabs = "tabDatas5"
                        @indexSend = "loadTabContent5($event)"
                    >
                        <template>
                            <div class="col-md-10 mt-5 mx-auto code-img">
                                <img class="img-fluid img-thumbnail" :src="require(`./imgFolder/${tabDatas5[activeTab5].data.imgName}`)" :alt="`${tabDatas5[activeTab5].data.altText}`">
                            </div>
                        </template>

                    </BaseTab>

                    <p class="text-justify textPara mt-5">
                        در این حالت، همان&zwnj;طور که ملاحظه می&zwnj;کنید، فلش از سمت راست به سمت چپ حرکت کرده و جهت تغییر رنگ آن نیز عوض شده است (از بنفش به سمت سفید). حال چنانچه مقدار این ویژگی را برابر <span class="engliSpan">alternate</span> تعریف کنیم، خواهیم دید که یک بار در جهت <span class="engliSpan">0</span> به <span class="engliSpan">100</span> و بار دیگر در جهت <span class="engliSpan">100</span> به <span class="engliSpan">0</span> انیمیشن مربوطه را اجرا می&zwnj;کند.
                    </p>

                    <!-- showing code imgs in tabs -->
                    <BaseTab
                        :head-tabs = "tabDatas6"
                        @indexSend = "loadTabContent6($event)"
                    >
                        <template>
                            <div class="col-md-10 mt-5 mx-auto code-img">
                                <img class="img-fluid img-thumbnail" :src="require(`./imgFolder/${tabDatas6[activeTab6].data.imgName}`)" :alt="`${tabDatas6[activeTab6].data.altText}`">
                            </div>
                        </template>

                    </BaseTab>
                    <!-- ############ -->
                    <!-- part two section 4 -->
                    <!-- ############ -->
                    <h3 class="subTitle2 mt-5">چگونگی متوقف کردن انیمیشن در حال اجرا:</h3>
                    <p class="text-justify textPara">
                        ویژگی دیگری که در این زمینه باید بررسی شود، ویژگی <span class="engliSpan">animation-play-state</span> است. این ویژگی در صورتی که برابر با <span class="engliSpan">paused</span> مقداردهی شود، باعث توقف انیمیشن می&zwnj;شود. برای مثال فرض کنید که در اینجا بخواهیم وقتی کاربر موس را بر روی دکمه می&zwnj;برد، انیمیشن مربوط به فلش <strong>متوقف</strong> شود و وقتی موس را از روی دکمه بر می&zwnj;دارد، انیمیشن ادامه یابد. برای چنین هدفی باید کد خود را در فایل CSS به شکل زیر تعریف کنیم:
                    </p>

                    <!-- showing code imgs in tabs -->
                    <BaseTab
                        :head-tabs = "tabDatas7"
                        @indexSend = "loadTabContent7($event)"
                    >
                        <template>
                            <div class="col-md-10 mt-5 mx-auto code-img">
                                <img class="img-fluid img-thumbnail" :src="require(`./imgFolder/${tabDatas7[activeTab7].data.imgName}`)" :alt="`${tabDatas7[activeTab7].data.altText}`">
                            </div>
                        </template>

                    </BaseTab>
                    <!-- ############ -->
                    <!-- part two section 5 -->
                    <!-- ############ -->
                    <h3 class="subTitle2 mt-5">تغییر سرعت اجرای انیمیشن:</h3>
                    <p class="text-justify textPara">
                        به عنوان آخرین موردی که در این مقاله به آن می&zwnj;پردازیم، ویژگی <span class="engliSpan">animation-timing-function</span> را برای دو فلشی که در این مثال وجود دارند، اعمال می&zwnj;کنیم. این ویژگی بیان کننده <span class="farsiSpan">سرعت حرکت المان</span> در حین اجرای انیمیشن است. مقادیری که برای آن می&zwnj;توان در نظر گرفت برابر <span class="engliSpan" dir="ltr">ease</span>، <span class="engliSpan" dir="ltr">linear</span>، <span class="engliSpan" dir="ltr">ease-in</span>، <span class="engliSpan" dir="ltr">ease-out</span>، <span class="engliSpan">ease-in-out</span> و <span class="engliSpan">cubic-bezier(n,n,n,n)</span> هستند. به عنوان مثال مقدار <span class="engliSpan">linear</span> بیان می&zwnj;کند که انیمیشن با سرعتی یکسان و خطی از ابتدا تا انتها اجرا شود. در حالی که مقدار <span class="engliSpan">ease-in</span> بیان کننده آن است که انیمیشن به آرامی آغاز شود. یعنی در ابتدای اجرای انیمیشن، حرکت آن <span class="farsiSpan">کندتر</span> باشد. در اینجا برای مقایسه این دو مقدار را به فلش&zwnj;های اول و دوم داده&zwnj;ایم که خروجی آن را می&zwnj;توانید مشاهده کنید:
                    </p>

                    <!-- showing code imgs in tabs -->
                    <BaseTab
                        :head-tabs = "tabDatas8"
                        @indexSend = "loadTabContent8($event)"
                    >
                        <template>
                            <div class="col-md-10 mt-5 mx-auto code-img">
                                <img class="img-fluid img-thumbnail" :src="require(`./imgFolder/${tabDatas8[activeTab8].data.imgName}`)" :alt="`${tabDatas8[activeTab8].data.altText}`">
                            </div>
                        </template>

                    </BaseTab>

                </div>
                <!-- end of subtitles in indented paragraphs -->

                <!-- ############ -->
                <!-- conclusion part -->
                <!-- ############ -->
                <h2 class="subTitle pb-3 mt-5">جمع بندی: </h2>
                <blockquote class="blockquote conclu">
                    <p class="text-justify textPara pt-3">
                        در این مقاله در ابتدا به صورت فشرده و خلاصه تمامی ویژگی&zwnj;هایی را که در ساختار ایجاد انیمیشن به کمک CSS به کار گرفته می&zwnj;شوند، بیان نموده و تعریف کردیم. پس از آن برای درک بهتر این ویژگی&zwnj;ها به ذکر مثالی در این زمینه پرداخته و با تغییر هر یک از این موارد به اثر و کاربرد آن پی بردیم. در پایان یادآور می&zwnj;شویم که گرچه به&zwnj;کارگیری انیمیشن در طراحی سایت امری <strong>جذاب</strong> و گاهی <strong>لازم</strong> به نظر می&zwnj;آید، اما باید توجه داشت که از استفاده <span class="farsiSpan">بیش از اندازه</span> این ویژگی، به خصوص در مواردی که باعث شلوغی و به هم&zwnj;ریختگی طراحی می&zwnj;شود، اجتناب شود تا مخاطبان سایت تجربه لذت&zwnj;بخشی از بازدید سایت شما داشته باشند.
                    </p>
                </blockquote>

            </div>



            <!-- ways number 1 of loading imgs  -->
            <!--
            <img :src='`${this.publicPath}articles/${this.$route.params.id}/first.png`' alt="تصویر نمونه">
-->

                <!-- ways number 2 of loading imgs  -->
                <!--
               <img src='./imgFolder/img1.png' alt="تصویر نمونه">
               -->
        </div>
</template>

<script>
import BaseTab from '../../BaseTab.vue';
import BaseTable from '../../BaseTable.vue';

export default {
                name: "BaseArticle",
                components: {
                    BaseTab,
                    BaseTable
                },
                data() {
                    return {
                        activeTab: 0,
                        activeTab2: 0,
                        activeTab3: 0,
                        activeTab4: 0,
                        activeTab5: 0,
                        activeTab6: 0,
                        activeTab7: 0,
                        activeTab8: 0,

                        tableData: [
                            /* --------------- */
                            /* data for row 1 that is for "thead" of table */
                            /* --------------- */
                            {
                                column1: "ردیف",
                                column2: "نام ویژگی",
                                column3: "تعریف ویژگی"
                            },
                            /* --------------- */
                            /* data for row 2 */
                            /* --------------- */
                            {
                                column1: "1",
                                column2: "<span dir = ltr>@keyframes</span>",
                                column3: "این ویژگی زمان&zwnj;بندی ضروری برای انیمیشن را در اختیار فایل CSS قرار می&zwnj;دهد که در چه زمانی چه اتفاقی در انیمیشن باید رخ دهد."
                            },
                            /* --------------- */
                            /* data for row 3 */
                            /* --------------- */
                            {
                                column1: "2",
                                column2: "animation",
                                column3: "حالت کوتاه شده (shorthand) خاصیت انیمیشن است که می&zwnj;تواند تمامی ویژگی&zwnj;های دیگر را در خود جای دهد."
                            },
                            /* --------------- */
                            /* data for row 4 */
                            /* --------------- */
                            {
                                column1: "3",
                                column2: "animation-delay",
                                column3: "مقدار زمانی که برای به تأخیر انداختن شروع انیمیشن تنظیم می&zwnj;شود."
                            },
                            /* --------------- */
                            /* data for row 5 */
                            /* --------------- */
                            {
                                column1: "4",
                                column2: "animation-duration",
                                column3: "مدت زمان کل انیمیشن از نقطه آغاز تا پایان آن توسط این ویژگی تعریف می&zwnj;شود."
                            },
                            /* --------------- */
                            /* data for row 6 */
                            /* --------------- */
                            {
                                column1: "5",
                                column2: "animation-direction",
                                column3: "جهت انجام گرفتن انیمیشن را تعریف می&zwnj;کند که آیا از نقطه آغاز به سمت پایان برود یا بالعکس یا ترکیبی از هر دو."
                            },
                            /* --------------- */
                            /* data for row 7 */
                            /* --------------- */
                            {
                                column1: "6",
                                column2: "animation-fill-mode",
                                column3: "استایل المان گیرنده انیمیشن را در شرایطی که انیمیشن اجرا نمی&zwnj;شود (قبل از شروع انیمیشن یا بعد از اتمام آن)، تعریف می&zwnj;کند. "
                            },
                            /* --------------- */
                            /* data for row 8 */
                            /* --------------- */
                            {
                                column1: "7",
                                column2: "animation-iteration-count",
                                column3: "تعداد دفعاتی را که قرار است انیمیشن مورد نظر اجرا شود، تعریف می&zwnj;کند."
                            },
                            /* --------------- */
                            /* data for row 9 */
                            /* --------------- */
                            {
                                column1: "8",
                                column2: "animation-name",
                                column3: "نامی که به انیمیشن مورد نظر داده می&zwnj;شود تا بتوان از آن در فرآیند کدنویسی استفاده نمود."
                            },
                            /* --------------- */
                            /* data for row 10 */
                            /* --------------- */
                            {
                                column1: "9",
                                column2: "animation-play-state",
                                column3: "این ویژگی تعیین می&zwnj;کند که آیا انیمیشن در حالت اجرا باشد یا توقف کند."
                            },
                            /* --------------- */
                            /* data for row 11 */
                            /* --------------- */
                            {
                                column1: "10",
                                column2: "animation-timing-function",
                                column3: "این ویژگی چگونگی سرعت انیمیشن (speed curve) را در حین فرآیند اجرا تبیین می&zwnj;کند. "
                            },
                            /* --------------- */
                            /* data for width of each column */
                            /* --------------- */
                            {
                                column1: "10%",
                                column2: "25%",
                                column3: "65%",
                            }
                        ], // end of tableData

                        tabDatas: [
                            {
                                tabHeders: "HTML",
                                data:{
                                    imgName: "iTag-html.png",
                                    altText: "تصویر مربوط به کد HTML مرتبط با انیمیشن fadeRight"
                                }
                            },

                            {
                                tabHeders: "CSS",
                                data:{
                                    imgName: "iTag-css.png",
                                    altText: "تصویر مربوط به کد CSS مرتبط با انیمیشن fadeRight"
                                }
                            }
                        ],   // end of tabDatas

                        tabDatas2: [
                            {
                                tabHeders: "CSS",
                                data:{
                                    imgName: "iteration1-img.png",
                                    altText: "تصویر مربوط به کد CSS مرتبط با تغییر تعداد تکرار"
                                }
                            },

                            {
                                tabHeders: "Result",
                                data:{
                                    imgName: "iteration1.gif",
                                    altText: "تصویر مربوط به خروجی تغییر تعداد تکرار در انیمیشن"
                                }
                            }
                        ], // end of tabDatas2

                        tabDatas3: [
                            {
                                tabHeders: "CSS",
                                data:{
                                    imgName: "fill-forward.png",
                                    altText: "تصویر کد CSS مربوط به استفاده از ویژگی animation-fill-mode"
                                }
                            },

                            {
                                tabHeders: "Result",
                                data:{
                                    imgName: "fill-forwards.gif",
                                    altText: "تصویر مربوط به خروجی استفاده از ویژگی animation-fill-mode"
                                }
                            }
                        ], // end of tabDatas3

                        tabDatas4: [
                            {
                                tabHeders: "CSS",
                                data:{
                                    imgName: "fill-backwards.png",
                                    altText: "تصویر کد CSS مربوط به استفاده از ویژگی animation-fill-mode"
                                }
                            },

                            {
                                tabHeders: "Result",
                                data:{
                                    imgName: "fill-backwards.gif",
                                    altText: "تصویر مربوط به خروجی استفاده از ویژگی animation-fill-mode"
                                }
                            }
                        ],   // end of tabDatas4

                        tabDatas5: [
                            {
                                tabHeders: "CSS",
                                data:{
                                    imgName: "reverse.png",
                                    altText: "تصویر کد CSS مربوط به استفاده از ویژگی animation-direction"
                                }
                            },

                            {
                                tabHeders: "Result",
                                data:{
                                    imgName: "reverse.gif",
                                    altText: "تصویر مربوط به خروجی استفاده از ویژگی animation-direction"
                                }
                            }
                        ],   // end of tabDatas5

                        tabDatas6: [
                            {
                                tabHeders: "CSS",
                                data:{
                                    imgName: "alternate.png",
                                    altText: "تصویر کد CSS مربوط به استفاده از ویژگی animation-direction"
                                }
                            },

                            {
                                tabHeders: "Result",
                                data:{
                                    imgName: "alternate.gif",
                                    altText: "تصویر مربوط به خروجی استفاده از ویژگی animation-direction"
                                }
                            }
                        ],   // end of tabDatas6

                        tabDatas7: [
                            {
                                tabHeders: "CSS",
                                data:{
                                    imgName: "paused.png",
                                    altText: "تصویر کد CSS مربوط به استفاده از ویژگی animation-play-state"
                                }
                            },

                            {
                                tabHeders: "Result",
                                data:{
                                    imgName: "paused.gif",
                                    altText: "تصویر مربوط به خروجی استفاده از ویژگی animation-play-state"
                                }
                            }
                        ],   // end of tabDatas7

                        tabDatas8: [
                            {
                                tabHeders: "CSS",
                                data:{
                                    imgName: "timing-function.png",
                                    altText: "تصویر کد CSS مربوط به استفاده از ویژگی animation-timing-function"
                                }
                            },

                            {
                                tabHeders: "Result",
                                data:{
                                    imgName: "animation-timing-function.gif",
                                    altText: "تصویر مربوط به خروجی استفاده از ویژگی animation-timing-function"
                                }
                            }
                        ]   // end of tabDatas8

                    }
                }, // end of data

                methods: {
                    loadTabContent: function(indexNow) {
                        this.activeTab = indexNow;
                    },

                    loadTabContent2: function(indexNow2) {
                        this.activeTab2 = indexNow2;
                    },

                    loadTabContent3: function(indexNow3) {
                        this.activeTab3 = indexNow3;
                    },

                    loadTabContent4: function(indexNow4) {
                        this.activeTab4 = indexNow4;
                    },

                    loadTabContent5: function(indexNow5) {
                        this.activeTab5 = indexNow5;
                    },

                    loadTabContent6: function(indexNow6) {
                        this.activeTab6 = indexNow6;
                    },

                    loadTabContent7: function(indexNow7) {
                        this.activeTab7 = indexNow7;
                    },

                    loadTabContent8: function(indexNow8) {
                        this.activeTab8 = indexNow8;
                    }
                } // end of methods

        }
</script>

<style scoped></style>
