<template>
    <!-- This is the component that divides sections on the page. You can set the icon and title for that with the help of "dividerTitle" prop.  -->
  <div class="container-fluid">
    <div class="row align-items-center mt-5">
      <div class="col-md-2">
        <div class="iconPar d-flex justify-content-between align-items-center">
          <!-- load icons dynamically from parent component -->
          <i :class="dividerTitle.icon"></i>
          <i :class="dividerTitle.icon"></i>
          <i :class="dividerTitle.icon"></i>
        </div>
      </div>

      <div class="col-md-8 mx-auto text-center">
        <!-- load title dynamically from parent component -->
          <div class="title">
            <h1>{{ dividerTitle.title }}</h1>
            <hr class="gradient-line" />
          </div>

      </div>

      <div class="col-md-2">
        <div class="iconPar d-flex justify-content-between align-items-center">
          <i :class="dividerTitle.icon"></i>
          <i :class="dividerTitle.icon"></i>
          <i :class="dividerTitle.icon"></i>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
    export default {
        name: "DividerIcon",
        props: {
            dividerTitle: Object
        }
    }
</script>

<style scoped src="../assets/assets-CSS/divider.css"></style>
