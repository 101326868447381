<template>
<!-- this is the texts and contents of "article number 5" of our database. for more info and list of all options available for each article please see this file:
H:/web-codes/websites/portfolio-folder/design-and-assets/sample article/BaseArticle.vue  -->
        <div class="col-md-10 mx-auto">

            <div class="text-right mt-5">

                <!-- intro text -->
                <h2 class="subTitle">مقدمه: </h2>
                <blockquote class="blockquote">
                    <p class="text-justify textPara pb-5">
                        در این مقاله قصد داریم که نحوه ایجاد یک پایگاه داده در MySQL و ایجاد جدول&zwnj;ها در آن را بررسی نماییم. پایگاه داده یکی از قسمت&zwnj;های مهم اغلب وب سایت&zwnj;ها است که اطلاعات سایت در آن ذخیره می&zwnj;شود و اهمیت آن در فرآیند توسعه وب سایت بر کسی پوشیده نیست. سیستم&zwnj;های مدیریت پایگاه داده (DBMS) ابزارهایی هستند که فرآیند مدیریت کردن پایگاه داده را برای ما راحت تر کرده  و امکان وارد کردن یا استخراج اطلاعات را میسر می&zwnj;کنند. یکی از این سیستم&zwnj;ها MySQL نام دارد. در این مقاله به بررسی کدهایی که امکان ارتباط با این سیستم را فراهم می کنند و به کمک آنها می&zwnj;توان یک پایگاه داده ایجاد نمود، می&zwnj;پردازیم. همچنین <a target="_blank" href="https://dev.mysql.com/doc/"> وب سایت مرجع MySQL </a> نیز منبع معتبری برای انواع آموزش های مرتبط با این سیستم است.
                        </p>
                </blockquote>

                <!-- paragraphs -->
                <h2 class="subTitle mt-5">ابزارهای ضروری و پیش نیاز: </h2>
                <p class="text-justify textPara">معمولا توسعه&zwnj;دهندگان وب&zwnj;سایت کدهای مورد نظر خود را ابتدا بر روی سیستم&zwnj;های شخصی پیاده سازی کرده و بعد از اطمینان از آماده&zwnj;بودن وب&zwnj;سایت، آن را به صورت آنلاین بارگذاری می&zwnj;کنند (به اصطلاح وب سایت را <span class="engliSpan">host</span> یا <span class="engliSpan">deploy</span> می&zwnj;کنند). وقتی بر روی کامپیوتر شخصی خود کار می&zwnj;کنید، برای اجرا نمودن کدهای مرتبط با قسمت back-end سایت (مانند پایگاه داده و ...) نیاز به ابزارهایی دارید که محیط سرورهای آنلاین را برای محیط کامپیوتر شما شبیه&zwnj;سازی می&zwnj;کنند. یکی از این ابزارها <a href="https://www.wampserver.com/en/" target="_blank">WAMPSERVER</a> است که اصطلاحا برای ما محیط <span class = "engliSpan">local-host</span> ایجاد می&zwnj;کند. در این مقاله به چگونگی نصب و راه&zwnj;اندازی آن اشاره نمی&zwnj;شود. اما اجرای چنین نرم افزارهایی برای کار کردن با پایگاه&zwnj;های داده و یا برخی زبان&zwnj;های برنامه&zwnj;نویسی back-end در محیط کامپیوتر شخصی افراد ضروری است.</p>
                <p class="text-justify textPara">
                    نرم افزار دیگری که برای نوشتن <span class = "engliSpan">query</span> ها (درخواست&zwnj;ها و کدهایی که به پایگاه داده می&zwnj;فهمانند که چه رفتاری، اعم از حذف یا اضافه کردن داده، با داده&zwnj;های ما داشته باشد) و ارتباط برقرار کردن با پایگاه داده ضروری است، نرم افزار <span class = "engliSpan">MySQL command-line</span> است که برای دانلود و نصب آن می&zwnj;توانید به <a href="https://dev.mysql.com/downloads/windows/installer/8.0.html" target="_blank"> وب سایت مربوط به آن </a>مراجعه نمایید و از طریق MySQL-installer به نصب آن بپردازید.
                </p>

                <!-- paragraphs -->
                <h2 class="subTitle mt-5">آغاز کار با MySQL و ایجاد پایگاه داده:</h2>
                <p class="text-justify textPara">
                    پس از نصب و آماده&zwnj;سازی نرم افزارهای مورد نیاز، با اجرای WAMPSERVER و استفاده از نرم&zwnj;افزار MySQL command-line که محیطی شبیه به <span class="engliSpan">Command-Prompt</span> خود ویندوز دارد، می&zwnj;توانیم اولین پایگاه داده خود را در MySQL ایجاد کنیم. با استفاده از دستور <strong>show databases</strong> می&zwnj;توانید پایگاه های داده&zwnj;ای را که قبلا ایجاد شده&zwnj;اند، مشاهده نمایید. لازم به ذکر است که در MySQL command-line و اکثر نرم&zwnj;افزارهای دیگری که برای نوشتن query های مربوط به MySQL استفاده می&zwnj;شوند، باید در انتهای دستورات خود از علامت <strong><span class="engliSpan">;</span></strong> (نقطه ویرگول) استفاده کنید. در غیر این صورت MySQL فرض را بر آن می&zwnj;گذارد که دستور query شما همچنان ادامه دارد و آن را اجرا نخواهد کرد. برای مثال خروجی دستور فوق در پایگاه داده موجود در اینجا به شکل زیر خواهد بود:
                </p>



                <!-- simple code images -->
                <div class="col-md-10 mt-5 mx-auto code-img">
                    <img class="img-fluid img-thumbnail" src="./imgFolder/code1.png" alt="تصویر مربوط به دستور show databases">
                </div>

                <!-- paragraphs -->
                <p class="text-justify textPara mt-5">
                    اما برای ایجاد یک پایگاه داده جدید از دستور <span class="engliSpan">CREATE DATABASE</span> به شکل زیر استفاده می&zwnj;شود:
                </p>

                <!-- simple code images -->
                <div class="col-md-10 mt-5 mx-auto code-img">
                    <img class="img-fluid img-thumbnail" src="./imgFolder/code2.png" alt="تصویر مربوط به دستور create database">
                </div>

                <p class="text-justify textPara mt-5">
                    در این دستور <span class="engliSpan">tutorial_database</span> یک نام دلخواه است که شما می&zwnj;توانید متناسب با داده&zwnj;هایی که قرار است در پایگاه داده ذخیره کنید، آن را تعیین نمایید. همچنین باید ذکر نمود که MySQL command-line به حروف کوچک و بزرگ انگلیسی حساس <span class="farsiSpan">نیست</span>، اما معمول است که برای راحتی و خوانا بودن کدها، از حروف بزرگ برای کلمات کلیدی دستورات استفاده شود. لذا در این دستور کلمات CREATE و DATABASE با حروف بزرگ نوشته شده و نام دلخواهی که قرار است به پایگاه داده نسبت داده شود، با حروف کوچک نوشته شده است.
                </p>

                <p class="text-justify textPara">
                    چنانچه بعد از اجرای دستور فوق بار دیگر دستور show databases را اجرا نمایید، مشاهده خواهید کرد که <span class="engliSpan">tutorial_database</span> به مجموعه پایگاه های داده موجود اضافه شده است:
                </p>

                <!-- simple code images -->
                <div class="col-md-10 mt-5 mx-auto code-img">
                    <img class="img-fluid img-thumbnail" src="./imgFolder/code3.png" alt="تصویر مربوط به اجرای مجدد دستور show databases">
                </div>

                <!-- paragraphs -->
                <h2 class="subTitle mt-5">ایجاد نخستین جدول:</h2>
                <p class="text-justify textPara">
                    بعد از ایجاد پایگاه داده نوبت به ایجاد جداول درون آن می&zwnj;رسد. برای ایجاد یک جدول درون پایگاه داده از دستور <span class="engliSpan">CREATE TABLE</span> استفاده می&zwnj;شود، اما قبل از به کارگیری این دستور باید مشخص کنیم که در <span class="farsiSpan">کدام</span> پایگاه داده قرار است جدول را ایجاد کنیم. لذا به وسیله دستور <span class="engliSpan">USE</span> ابتدا نام پایگاه داده مورد نظر را تعیین می&zwnj;کنیم:
                </p>

                <!-- simple code images -->
                <div class="col-md-10 mt-5 mx-auto code-img">
                    <img class="img-fluid img-thumbnail" src="./imgFolder/code4.png" alt="تصویر مربوط به دستور use">
                </div>

                <!-- paragraphs -->
                <p class="text-justify textPara mt-5">
                    اکنون می&zwnj;توانیم به کمک دستور CREATE TABLE جدول مورد نظر را ایجاد کنیم:
                </p>

                <!-- simple code images -->
                <div class="col-md-10 mt-5 mx-auto code-img">
                    <img class="img-fluid img-thumbnail" src="./imgFolder/code5.png" alt="تصویر مربوط به دستور create table">
                </div>

                <p class = "text-justify textPara mt-5">
                    ساختار این دستور به این شکل است که بعد از کلمات کلیدی <span class="engliSpan">CREATE</span> و <span class="engliSpan">TABLE</span> ابتدا یک نام دلخواه برای جدول مورد نظر تعیین می&zwnj;کنیم. در این مثال نام جدول را <strong>cars</strong> در نظر گرفته&zwnj;ایم. سپس در داخل پرانتز نام ستون های جدول و نوع داده&zwnj;ای را که برای آن ستون می خواهیم در نظر بگیریم، تعریف می&zwnj;کنیم. برای مثال نوع داده&zwnj;ای که در ستون <span class="engliSpan">car_id</span> تعریف شده است، INT (به معنای INTEGER و عدد صحیح) است. در این مقاله قصد نداریم که در خصوص <strong>انواع داده</strong> در MySQL صحبت کنیم، ولی به همین اندازه بسنده می&zwnj;کنیم که ساختار دستور CREATE TABLE با تعریف ستون&zwnj;های جدول و نوع داده و قیودی که برای هر ستون تعریف می&zwnj;شود، شکل می&zwnj;گیرد. برای مثال در دستور فوق کلمه کلیدی PRIMARY KEY نوعی قید (constraint) محسوب می شود که بیان کننده آن است که ستون <span class="engliSpan">car_id</span> به عنوان ستونی است که مقادیر آن نباید شبیه یکدیگر باشند (تکراری باشند) و همچنین این ستون باید حتما مقدار داشته باشد (نمی&zwnj;تواند NULL یا تهی باشد). برای آنکه از مشخصات ستون&zwnj;های تعریف شده درون یک جدول آگاهی بهتری بدست آوریم، می&zwnj;توانیم از دستور <span class="engliSpan">describe</span> استفاده نماییم:
                </p>

                <!-- simple code images -->
                <div class="col-md-10 mt-5 mx-auto code-img">
                    <img class="img-fluid img-thumbnail" src="./imgFolder/code6.png" alt="تصویر مربوط به دستور describe ">
                </div>

                <!-- paragraphs -->
                <h2 class="subTitle mt-5">وارد کردن سطر به جدول: </h2>
                <p class="text-justify textPara">
                    در این بخش از مقاله به نحوه وارد کردن داده به یک جدول می&zwnj;پردازیم. برای وارد کردن داده به جدول از دستور <span class="engliSpan">INSERT INTO</span> استفاده می&zwnj;شود. برای مثال فرض کنید که بخواهیم یک خودرو از کارخانه BMW به قیمت 35900 دلار و سال تولید 2021 را به جدول ساخته شده در بخش قبلی اضافه کنیم. برای این کار دستور زیر را اجرا می&zwnj;کنیم:
                </p>

                <!-- simple code images -->
                <div class="col-md-10 mt-5 mx-auto code-img">
                    <img class="img-fluid img-thumbnail" src="./imgFolder/code7.png" alt="تصویر مربوط به دستور insert into">
                </div>

                <!-- paragraphs -->
                <p class="text-justify textPara mt-5">
                    سپس به کمک دستور <span class="engliSpan">SELECT</span> می&zwnj;توانیم سطر اضافه شده را مشاهده کنیم:
                </p>

                <!-- simple code images -->
                <div class="col-md-10 mt-5 mx-auto code-img">
                    <img class="img-fluid img-thumbnail" src="./imgFolder/code8.png" alt="تصویر مربوط به دستور select">
                </div>

                <!-- paragraphs -->
                <p class="text-justify textPara mt-5">
                    نکته&zwnj;ای که در دستور INSERT وجود دارد آن است که برای وارد کردن داده&zwnj;هایی که از نوع کاراکتر یا به عبارتی String هستند (مانند ستون&zwnj;هایی که از نوع <span class="engliSpan">VARCHAR</span> تعریف می&zwnj;شوند)، باید مقادیر آنها را درون علامت <span class="farsiSpan">کوتیشن</span> (<span class="engliSpan" dir="ltr">“BMW”</span>) قرار بدهیم ولی برای ستون&zwnj;هایی که از نوع عددی هستند، چنین کاری ضرورت ندارد.
                </p>


                <!-- conclusion part -->
                <h2 class="subTitle pb-3 mt-5">جمع بندی: </h2>
                <blockquote class="blockquote conclu">
                    <p class="text-justify textPara pt-3">
                        در این مقاله به مفاهیم مقدماتی و اقدامات اولیه برای ایجاد یک پایگاه داده در MySQL اشاره شد. همچنین در ادامه با ساختن یک جدول و اضافه کردن یک سطر به آن تا حدودی با نحوه ارتباط برقرار کردن با پایگاه داده ایجاد شده، آشنا شدیم. چنانچه بخواهید در خصوص هر یک از دستورات ذکر شده در این مقاله اطلاعات کامل&zwnj;تری بدست آورید، می توانید به وب سایت&zwnj;های مرجعی که دستورات MySQL در آنها توضیح داده شده است، مراجعه نمایید که از جمله آنها می&zwnj;توان سایت <a href="https://www.w3schools.com/sql/default.asp" target="_blank" class="engleText">w3schools</a> را نام برد.
                    </p>

                </blockquote>

            </div>



            <!-- ways number 1 of loading imgs  -->
            <!--
            <img :src='`${this.publicPath}articles/${this.$route.params.id}/first.png`' alt="تصویر نمونه">
-->

                <!-- ways number 2 of loading imgs  -->
                <!--
               <img src='./imgFolder/img1.png' alt="تصویر نمونه">
               -->
        </div>
</template>

<script>

export default {
                name: "BaseArticle",
        }
</script>

<style scoped></style>
