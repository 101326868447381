<template>
<!-- this is the texts and contents of "article number 4" of our database. for more info and list of all options available for each article please see this file:
H:/web-codes/websites/portfolio-folder/design-and-assets/sample article/BaseArticle.vue  -->
        <div class="col-md-10 mx-auto">

            <div class="text-right mt-5">
                <!-- ************* -->
                <!-- intro text -->
                <!-- ************* -->
                <h2 class="subTitle">مقدمه: </h2>
                <blockquote class="blockquote">
                    <p class="text-justify textPara pb-5">
                        <a href="https://getbootstrap.com/docs/5.0/getting-started/introduction/" target="_blank">بوتسترپ</a> به عنوان یکی از محبوب&zwnj;ترین فریم&zwnj;ورک&zwnj;های CSS، امروزه در میان توسعه&zwnj;دهندگان وب&zwnj;سایت طرفداران و استفاده کنندگان بیشماری دارد. این فریم&zwnj;ورک به خصوص برای ساخت وب&zwnj;سایت&zwnj;های با قابلیت واکنش&zwnj;گرا (<span class="engliSpan">Responsive</span>) می&zwnj;تواند کمک شایانی به برنامه&zwnj;نویس&zwnj;ها در حوزه وب بدهد. همان&zwnj;گونه که می&zwnj;دانیم نسخه جدید بوتسترپ (<span class="engliSpan">bootstrap-5</span> یا به اختصار <span class="engliSpan">BS5</span>) در ماه ژوئن سال 2020 منتشر شده و اکنون در دسترس است. در این مقاله سعی کرده&zwnj;ایم به مهمترین تفاوت&zwnj;هایی که این نسخه با نسخه شماره 4 (<span class="engliSpan">bootstrap-4</span> یا به اختصار <span class="engliSpan">BS4</span>) دارد، بپردازیم.
                    </p>
                </blockquote>

                <!-- ************* -->
                <!-- part one -->
                <!-- ************* -->
                <h2 class="subTitle mt-5">تفاوت اول: حذف jQuery</h2>
                <p class="text-justify textPara">
                    در نسخه شماره 5 بوتسترپ دیگر نیازی به اضافه کردن jQuery به پروژه خود <span class="farsiSpan">ندارید</span>! این مسئله به معنای آن نیست که نسخه 5 نیازی به جاوا&zwnj;اسکریپت ندارد. بلکه قابلیت&zwnj;های مرتبط با جاوا&zwnj;اسکریپت (مانند کار با <span class="engliSpan">dropdown</span> و ...) از طریق خود فایل <span class="engliSpan">bootstrap.js</span> به اجرا در می&zwnj;آید.
                </p>

                <!-- simple code images -->
                <div class="col-md-10 mt-5 mx-auto code-img">
                    <img class="img-fluid img-thumbnail" src="./imgFolder/bs5-starter-template.png" alt="تصویر مربوط به نمایش قالب اولیه یک فایل HTML بدون اضافه کردن jQuery برای استفاده از بوتسترپ ">
                </div>


                <p class="text-justify textPara mt-5">
                    این مسئله به خصوص برای توسعه&zwnj;دهندگانی که نمی&zwnj;خواهند از jQuery در پروژه&zwnj;های خود استفاده نمایند، به عنوان مثال می&zwnj;خواهند از سایر فریم&zwnj;ورک&zwnj;های جاوا&zwnj;اسکریپت مانند <span class="engliSpan">Vue</span> یا <span class="engliSpan">React</span> استفاده نمایند، دارای اهمیت است.
                </p>

                <!-- ************* -->
                <!-- part two -->
                <!-- ************* -->
                <h2 class="subTitle engleMatn mt-5">تفاوت دوم: عدم پشتیبانی از <span class="engleMatn">IE10</span> و <span class="engleMatn">IE11</span></h2>
                <p class="text-justify textPara">
                    مرورگر <span class="engliSpan">internet explorer</span> همواره یکی از چالش&zwnj;ها برای توسعه&zwnj;دهندگان وب&zwnj;سایت بوده است. قابلیت&zwnj;های پایین این مرورگر در پشتیبانی از ویژگی&zwnj;های جدید و مدرن <span class="engliSpan">CSS</span> و <span class="engliSpan">JS</span> (مانند عدم پشتیبانی از <span class="engliSpan">JS ES6 standards</span>) باعث می&zwnj;شود که توسعه&zwnj;دهندگان نتوانند کدهای خود را به خوبی روی این مرورگر به اجرا در آورند. با توجه به سهم اندک این مرورگر در بازار (مقداری حدود <span class="engliSpan" dir="ltr">3%</span> برای استفاده&zwnj;کنندگان از این مرورگر تخمین زده شده است) بوتسترپ تصمیم به <strong>عدم پشتیبانی</strong> از این مرورگر در نسخه شماره 5 خود گرفته است.
                </p>

                <!-- imgs (png,jpg or gif) that are not related to codes -->
                <div class="col-md-10 mt-5 mx-auto">
                    <img class="img-fluid mx-auto d-block img-thumbnail shadow-lg" src="./imgFolder/explorer920.png" alt="تصویر مربوط به عدم  پشتیبانی از internet explorer ">
                </div>

                <!-- ************* -->
                <!-- part three -->
                <!-- ************* -->
                <h2 class="subTitle mt-5">تفاوت سوم: بهبود سیستم grid</h2>
                <p class="text-justify textPara">
                    در بوتسترپ نسخه 4 برای مبحث چیدمان المان&zwnj;ها در صفحه وب (به اصطلاح <span class="engliSpan">layout</span> و <span class="engliSpan">grid</span>) از ساختار flex استفاده شده است. در بوتسترپ نسخه 5 نیز ویژگی&zwnj;های کلی این سیستم grid حفظ شده است. اما برخی قابلیت&zwnj;های بیشتر نیز به آن اضافه شده که از آن جمله می&zwnj;توان به موارد زیر اشاره نمود:
                </p>

                <p class="text-justify textPara">
                <strong class="strongFirst"><u>اضافه شدن <span dir="ltr">xxl</span> </u> : </strong>
                در سیستم grid بوتسترپ 5 این <span class="engliSpan">break-point</span> برای رزولوشن&zwnj;های بالای <span class="engliSpan">1400px</span> استفاده می&zwnj;شود. عملکرد این ویژگی را در کد زیر مشاهده می&zwnj;کنید (به ابعاد صفحه مرورگر در بالای تصویر توجه نمایید):
                </p>

                <!-- showing code imgs in tabs -->
                <BaseTab
                    :head-tabs = "tabDatas"
                    @indexSend = "loadTabContent($event)"
                >
                    <template>
                        <div class="col-md-10 mt-5 mx-auto code-img">
                            <img class="img-fluid img-thumbnail" :src="require(`./imgFolder/${tabDatas[activeTab].data.imgName}`)" :alt="`${tabDatas[activeTab].data.altText}`">
                        </div>
                    </template>
                </BaseTab>

                <p class="text-justify textPara mt-5">
                <strong class="strongFirst"><u>استفاده از <span dir="ltr">gutters</span> </u> : </strong>
                فضاهای خالی بین ستون&zwnj;ها (<span class="engliSpan">gutters</span>) در نسخه شماره 5 به وسیله کلاس&zwnj;های <span class="engliSpan" dir="ltr">g&#42;</span> قابل مدیریت کردن شده است. در ادامه می&zwnj;توانید تأثیر اضافه کردن کلاس <span class="engliSpan">gx-5</span> به یک المان دارای کلاس <span class="engliSpan">row</span> را مشاهده کنید:
                </p>

                <strong class="strongFirst d-block text-center mt-5 border-top border-right border-left">&#10042; استفاده از ویژگی gutters &#10042;</strong>

                <!-- showing code imgs in tabs -->
                <BaseTab
                    :head-tabs = "tabDatas2"
                    @indexSend = "loadTabContent2($event)"
                >
                    <template>
                        <div class="col-md-10 mt-5 mx-auto code-img">
                            <img class="img-fluid img-thumbnail" :src="require(`./imgFolder/${tabDatas2[activeTab2].data.imgName}`)" :alt="`${tabDatas2[activeTab2].data.altText}`">
                        </div>
                    </template>
                </BaseTab>

                <strong class="strongFirst d-block text-center mt-5 border-top border-right border-left">&#10042; عدم استفاده از ویژگی gutters  &#10042;</strong>

                <!-- showing code imgs in tabs -->
                <BaseTab
                    :head-tabs = "tabDatas3"
                    @indexSend = "loadTabContent3($event)"
                >
                    <template>
                        <div class="col-md-10 mt-5 mx-auto code-img">
                            <img class="img-fluid img-thumbnail" :src="require(`./imgFolder/${tabDatas3[activeTab3].data.imgName}`)" :alt="`${tabDatas3[activeTab3].data.altText}`">
                        </div>
                    </template>
                </BaseTab>

                <p class="text-justify textPara mt-5">
                <strong class="strongFirst"><u>حذف <span dir="ltr">position: relative</span> </u> : </strong>
                از جمله دیگر تغییراتی که در نسخه شماره 5 این فریم&zwnj;ورک رخ داده این است که ستون&zwnj;های تعریف شده به کمک بوتسترپ (المان&zwnj;های دارای کلاس <span class="engliSpan" dir="ltr">col&#42;</span> مانند <span class="engliSpan">col-md-4</span>) دیگر به صورت پیش&zwnj;فرض دارای استایل <code dir="ltr">position: relative</code> نیستند.
                </p>

                <!-- ************* -->
                <!-- part four -->
                <!-- ************* -->
                <h2 class="subTitle mt-5">تفاوت چهارم: وجود یک سری آیکون مخصوص در <span class="engleMatn" style="letter-spacing: .3rem;">BS5</span></h2>

                <p class="text-justify textPara">
                    در بوتسترپ نسخه 4 زمانی که می&zwnj;خواستیم از آیکون&zwnj;ها برای وب&zwnj;سایت خودمان استفاده کنیم، ناچار بودیم که به کتابخانه&zwnj;های خارج از بوتسترپ مانند <a href="https://fontawesome.com" target="_blank">font awesome</a> روی بیاوریم. ولی در نسخه شماره 5 بوتسترپ مجموعه&zwnj;ای از آیکون&zwnj;ها طراحی شده است که می&zwnj;توانید آنها را از بخش <a href="https://icons.getbootstrap.com/#install" target="_blank">آیکون&zwnj;های خود سایت بوتسترپ</a> انتخاب کرده و استفاده نمایید. یکی از راه&zwnj;های استفاده آن اضافه کردن <span class="engliSpan">CDN</span> مربوطه به بالای فایل <span class="engliSpan">HTML</span> مورد نظر و به&zwnj;کارگیری کلاس مرتبط با هر آیکون در المان&zwnj;های HTML است:
                </p>

                <!-- showing code imgs in tabs -->
                <BaseTab
                    :head-tabs = "tabDatas4"
                    @indexSend = "loadTabContent4($event)"
                >
                    <template>
                        <div class="col-md-10 mt-5 mx-auto code-img">
                            <img class="img-fluid img-thumbnail" :src="require(`./imgFolder/${tabDatas4[activeTab4].data.imgName}`)" :alt="`${tabDatas4[activeTab4].data.altText}`">
                        </div>
                    </template>
                </BaseTab>

                <!-- ************* -->
                <!-- part five -->
                <!-- ************* -->
                <h2 class="subTitle mt-5">تفاوت پنجم: ایجاد قابلیت RTL</h2>

                <p class="text-justify textPara">
                    قابلیت RTL (<span class="engliSpan" dir="ltr">Right To Left</span>) برای طراحی وب&zwnj;سایت به زبان&zwnj;هایی مانند فارسی و عربی بسیار ضروری و مهم است. در نسخه شماره 5 بوتسترپ، فایل <span class="engliSpan">CSS</span> مخصوص توسط خود سازندگان این فریم&zwnj;ورک برای اعمال و پیاده&zwnj;سازی این قابلیت طراحی شده است. برای به&zwnj;کارگیری این قابلیت در فایل خود، باید به جای استفاده از نسخه CSS خود بوتسترپ از نسخه CSS مربوط به RTL استفاده شود. همچنین در تگ <code>&lt;html&gt;</code> نیز از دو شاخصه <code>&rdquo;dir=&ldquo;rtl</code> و <code>&rdquo;lang=&ldquo;fa</code> (در صورتی که سایت به زبان فارسی باشد) استفاده شود.
                </p>

                <!-- simple code images -->
                <div class="col-md-10 mt-5 mx-auto code-img">
                    <img class="img-fluid img-thumbnail" src="./imgFolder/RTL-support.png" alt="تصویر مربوط به چگونگی استفاده از قابلیت RTL در بوتسترپ نسخه 5 ">
                </div>

                <p class="text-justify textPara mt-5">
                    همچنین می&zwnj;توانید با مراجعه به <a href="https://getbootstrap.com/docs/5.0/getting-started/rtl/" target="_blank">صفحه مرتبط با RTL</a> در سایت بوتسترپ، اطلاعات بیشتری در این زمینه بدست آورید.
                </p>

                <!-- ************* -->
                <!-- part six -->
                <!-- ************* -->
                <h2 class="subTitle mt-5">تفاوت ششم: ابعاد فونت واکنش&zwnj;گرا</h2>
                <p class="text-justify textPara">
                    فونت واکنش&zwnj;گرا که به آن RFS (<span dir="ltr" class="engliSpan">Responsive Font Size</span>) گفته می&zwnj;شود، از دیگر وجوه تمایز بین بوتسترپ نسخه 5 و نسخه 4 است. منظور از ابعاد فونت واکنش&zwnj;گرا آن است که وقتی صفحه وب <span class="farsiSpan">تغییر</span> می&zwnj;کند (مثلاً از حالت کامپیوتر به حالت موبایل)، ابعاد فونت استفاده شده در آن نیز <strong>عوض می&zwnj;شود</strong>. این کار به کمک <span class="engliSpan">SASS</span> در بوتسترپ نسخه 5 عملی می&zwnj;شود. برای اطلاعات کامل&zwnj;تر می&zwnj;توانید به <a href="https://getbootstrap.com/docs/5.0/getting-started/rfs/" target="_blank">صفحه توضیحات آن</a> در سایت بوتسترپ مراجعه نمایید. در تصاویر زیر می&zwnj;توانید به مقایسه متن و تغییرات آن با توجه به این ویژگی بپردازید:
                </p>

                <!-- showing code imgs in tabs -->
                <BaseTab
                    :head-tabs = "tabDatas5"
                    @indexSend = "loadTabContent5($event)"
                >
                    <template>
                        <div class="col-md-10 mt-5 mx-auto code-img">
                            <img class="img-fluid img-thumbnail" :src="require(`./imgFolder/${tabDatas5[activeTab5].data.imgName}`)" :alt="`${tabDatas5[activeTab5].data.altText}`">
                        </div>
                    </template>
                </BaseTab>

                <p class="text-justify textPara mt-5">
                    در تصاویر فوق چنانچه دقت نمایید مشاهده خواهید کرد که ابعاد فونت در حالت استفاده از <span class="engliSpan">RFS</span> در رزولوشن <span class="engliSpan">622px</span> نسبت به حالت عدم استفاده از آن <span class="farsiSpan">کوچک&zwnj;تر</span> است. به عبارت دیگر با به&zwnj;کارگیری این ویژگی در فایل SCSS خود، با کوچک&zwnj;تر نمودن ابعاد صفحه مرورگر، ابعاد فونت نیز <strong>کاهش می&zwnj;یابد</strong>.
                </p>

                <!-- showing code imgs in tabs -->
                <BaseTab
                    :head-tabs = "tabDatas6"
                    @indexSend = "loadTabContent6($event)"
                >
                    <template>
                        <div class="col-md-10 mt-5 mx-auto code-img">
                            <img class="img-fluid img-thumbnail" :src="require(`./imgFolder/${tabDatas6[activeTab6].data.imgName}`)" :alt="`${tabDatas6[activeTab6].data.altText}`">
                        </div>
                    </template>
                </BaseTab>

                <!-- ************* -->
                <!-- part seven -->
                <!-- ************* -->
                <h2 class="subTitle mt-5">تفاوت هفتم: ویژگی&zwnj;های شخصی&zwnj;سازی&zwnj;شده CSS</h2>
                <p class="text-justify textPara">
                    ویژگی&zwnj;های شخصی&zwnj;سازی&zwnj;شده CSS (<span class="engliSpan" dir="ltr">CSS custom properties</span>) همان ویژگی&zwnj;هایی هستند که می&zwnj;توانید در قالب متغیرها در کد CSS خود تعریف کنید. به عنوان مثال چنانچه داشته باشیم:
                </p>

                <!-- simple code images -->
                <div class="col-md-10 mt-5 mx-auto code-img">
                    <img class="img-fluid img-thumbnail" src="./imgFolder/custom-css-example.png" alt="تصویر مربوط به CSS Custom Properties  ">
                </div>

                <p class="text-justify textPara mt-5">
                    بدان معناست که ویژگی <code dir="ltr">--colorMe</code> از این پس در فایل CSS شما شناخته شده است و می&zwnj;توانید با تابع <code dir="ltr">var()</code> مقدار آن را در قسمت&zwnj;های مختلف کد خود استفاده کنید. در بوتسترپ نسخه 4 نیز از این دست ویژگی&zwnj;های CSS وجود داشت، ولی استفاده از آنها محدود به <span class="engliSpan" dir="ltr">:root</span> می&zwnj;شد. در نسخه شماره 5 می&zwnj;توانید از این ویژگی&zwnj;های تعریف شده توسط بوتسترپ برای سایر المان&zwnj;ها هم بهره ببرید. به عنوان مثال برای یک تگ <code>&lt;a&gt;</code> می&zwnj;توانیم با اضافه کردن کد زیر، رنگ آن را تغییر دهیم:
                </p>

                <!-- showing code imgs in tabs -->
                <BaseTab
                    :head-tabs = "tabDatas7"
                    @indexSend = "loadTabContent7($event)"
                >
                    <template>
                        <div class="col-md-10 mt-5 mx-auto code-img">
                            <img class="img-fluid img-thumbnail" :src="require(`./imgFolder/${tabDatas7[activeTab7].data.imgName}`)" :alt="`${tabDatas7[activeTab7].data.altText}`">
                        </div>
                    </template>
                </BaseTab>

                <p class="text-justify textPara mt-5">
                    در کد فوق ویژگی&zwnj;های <code dir="ltr">--bs-red</code> و <code dir="ltr">--bs-orange</code> توسط خود بوتسترپ تعریف شده&zwnj;اند و نیازی <span class="farsiSpan">نیست</span> که خودمان آنها را در <span class="engliSpan" dir="ltr">:root</span> تعریف کنیم.
                </p>

                <!-- ************* -->
                <!-- conclusion part -->
                <!-- ************* -->
                <h2 class="subTitle pb-3 mt-5">جمع بندی: </h2>
                <blockquote class="blockquote conclu">
                    <p class="text-justify textPara pt-3">
                        در این مقاله به بررسی برخی تفاوت&zwnj;های بین بوتسترپ نسخه 4 و نسخه 5 پرداختیم. در واقع بوتسترپ 5 به عنوان جدیدترین نسخه این فریم&zwnj;ورک مطرح، قابلیت&zwnj;های خاص و تازه&zwnj;ای به همراه دارد که در این مقاله سعی شد به برخی از این قابلیت&zwnj;ها اشاره شود. مسلما مطالعه <a href="https://getbootstrap.com/docs/5.0/getting-started/introduction/" target="_blank">سایت رسمی بوتسترپ</a> می&zwnj;تواند به درک هر چه بیشتر قابلیت&zwnj;ها و امکانات آن کمک کند.
                    </p>
                </blockquote>

            </div>



            <!-- ways number 1 of loading imgs  -->
            <!--
            <img :src='`${this.publicPath}articles/${this.$route.params.id}/first.png`' alt="تصویر نمونه">
-->

                <!-- ways number 2 of loading imgs  -->
                <!--
               <img src='./imgFolder/img1.png' alt="تصویر نمونه">
               -->
        </div>
</template>

<script>
import BaseTab from '../../BaseTab.vue';

export default {
                name: "BaseArticle",
                components: {
                    BaseTab,
                },
                data() {
                    return {
                        activeTab: 0,
                        activeTab2: 0,
                        activeTab3: 0,
                        activeTab4: 0,
                        activeTab5: 0,
                        activeTab6: 0,
                        activeTab7: 0,

                        tabDatas: [
                            {
                                tabHeders: "HTML",
                                data:{
                                    imgName: "grid-xxl.png",
                                    altText: "تصویر مربوط به استفاده از xxl "
                                }
                            },

                            {
                                tabHeders: "Result",
                                data:{
                                    imgName: "grid-xxl.gif",
                                    altText: "تصویر مربوط به خروجی استفاده از xxl "
                                }
                            }
                        ], // end of tabDatas

                        tabDatas2: [
                            {
                                tabHeders: "HTML",
                                data:{
                                    imgName: "with-gutter.png",
                                    altText: "تصویر مربوط به کد HTML مرتبط با استفاده از ویژگی gutters"
                                }
                            },

                            {
                                tabHeders: "Result",
                                data:{
                                    imgName: "with-gutter-out.png",
                                    altText: "تصویر مربوط به خروجی کد HTML مرتبط با استفاده از ویژگی gutters"
                                }
                            }
                        ], // end of tabDatas2

                        tabDatas3: [
                            {
                                tabHeders: "HTML",
                                data:{
                                    imgName: "without-gutter.png",
                                    altText: "تصویر مربوط به کد HTML مرتبط با عدم استفاده از ویژگی gutters"
                                }
                            },

                            {
                                tabHeders: "Result",
                                data:{
                                    imgName: "without-gutter-out.png",
                                    altText: "تصویر مربوط به خروجی کد HTML مرتبط با عدم استفاده از ویژگی gutters"
                                }
                            }
                        ], // end of tabDatas3

                        tabDatas4: [
                            {
                                tabHeders: "HTML",
                                data:{
                                    imgName: "iconsCode.png",
                                    altText: "تصویر مربوط به کد HTML استفاده از آیکون های خاص بوتسترپ 5"
                                }
                            },

                            {
                                tabHeders: "Result",
                                data:{
                                    imgName: "iconsOut.png",
                                    altText: "تصویر مربوط به خروجی کد HTML استفاده از آیکون های خاص بوتسترپ 5"
                                }
                            }
                        ], // end of tabDatas4

                        tabDatas5: [
                            {
                                tabHeders: "With RFS",
                                data:{
                                    imgName: "withRFS-622.png",
                                    altText: "تصویر مربوط به استفاده از قابلیت RFS در بوتسترپ نسخه 5"
                                }
                            },

                            {
                                tabHeders: "Without RFS",
                                data:{
                                    imgName: "withoutRFS-622.png",
                                    altText: "تصویر مربوط به عدم استفاده از قابلیت RFS در بوتسترپ نسخه 5"
                                }
                            }
                        ], // end of tabDatas5

                        tabDatas6: [
                            {
                                tabHeders: "HTML",
                                data:{
                                    imgName: "RFS-HTML.png",
                                    altText: "تصویر مربوط به کد HTML استفاده از قابلیت RFS"
                                }
                            },

                            {
                                tabHeders: "SCSS",
                                data:{
                                    imgName: "RFS-SCSS.png",
                                    altText: "تصویر مربوط به کد SCSS استفاده از قابلیت RFS"
                                }
                            },

                            {
                                tabHeders: "Compiled CSS",
                                data:{
                                    imgName: "RFS-compiled-css.png",
                                    altText: "تصویر مربوط به کد CSS استفاده از قابلیت RFS"
                                }
                            }
                        ], // end of tabDatas6

                        tabDatas7: [
                            {
                                tabHeders: "CSS",
                                data:{
                                    imgName: "bs-custom-css.png",
                                    altText: "تصویر مربوط به ویژگی های شخصی سازی شده خود بوتسترپ  "
                                }
                            },

                            {
                                tabHeders: "Result",
                                data:{
                                    imgName: "custom-css.gif",
                                    altText: "تصویر مربوط به خروجی استفاده از ویژگی های شخصی سازی شده خود بوتسترپ  "
                                }
                            }
                        ], // end of tabDatas7

                    }
                }, // end of data

                methods: {
                    loadTabContent: function(indexNow) {
                        this.activeTab = indexNow;
                    },

                    loadTabContent2: function(indexNow2) {
                        this.activeTab2 = indexNow2;
                    },

                    loadTabContent3: function(indexNow3) {
                        this.activeTab3 = indexNow3;
                    },

                    loadTabContent4: function(indexNow4) {
                        this.activeTab4 = indexNow4;
                    },

                    loadTabContent5: function(indexNow5) {
                        this.activeTab5 = indexNow5;
                    },

                    loadTabContent6: function(indexNow6) {
                        this.activeTab6 = indexNow6;
                    },

                    loadTabContent7: function(indexNow7) {
                        this.activeTab7 = indexNow7;
                    }
                } // end of methods

        }
</script>

<style scoped></style>
