<template>
<!-- This page (view) is called when the user clicks to read any article in "Articles.vue" page. Then this page loads some common data that each article has from database, and finally it loads "BaseArticle.vue" component related to that special article with the help of "this.$options.components[componentName]" code at "created" hook. -->
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12">
                <div v-if="showFlag1">
                    <section class="sectionMain my-5 px-1 px-sm-5 py-3">

                    <!-- /////////////////////// -->
                    <!-- dynamic data for each article -->
                    <!-- /////////////////////// -->

                    <!-- titles -->
                    <div class="border-bottom mb-3">
                        <div class="w-100 d-flex justify-content-center justify-content-md-start align-items-center p-3 mb-2">
                            <h1 class="titleH1 text-center text-md-right">{{ articleData.title }}</h1>
                        </div>
                        <hr class="w-25 mx-auto">
                        <div class="w-100 justify-content-center justify-content-md-end align-items-center p-3 mb-2">
                            <h1 class="titleH1_eng text-center text-md-left">{{ articleData.english_title }}</h1>
                        </div>
                    </div>

                    <!-- tags and dates and level -->
                    <div class="jumbotron d-flex flex-wrap justify-content-around align-items-center">
                        <div class="text-center mx-sm-1 mx-lg-auto mt-lg-auto mt-3">
                            <p>
                            <span class="d-block d-md-inline">آخرین به روز رسانی:</span>
                            <span class="badge p-2 mt-2 mx-1"> {{ datePart }} </span>
                            </p>
                        </div>
                        <hr class="w-100 d-md-none">
                        <div class="text-center mx-sm-1 mx-lg-auto mt-lg-auto mt-3">
                            <p>
                            <span class="d-block d-md-inline">برچسب ها:</span>
                            <span :key="tag" v-for="tag in tagData" class="badge mt-2 p-2 mx-1"> {{ tag }} </span>
                            </p>
                        </div>
                        <hr class="w-100 d-md-none">
                        <div class="text-center mx-sm-1 mx-lg-auto mt-lg-auto mt-3">
                            <p>
                            <span class="d-block d-md-inline">سطح مقاله:</span>
                            <span class="badge p-2 mt-2 mx-1"> {{ articleData.level_desc }} </span>
                            </p>
                        </div>
                    </div>

                    <!-- head img -->
                    <div class="col-md-10 mx-auto img-head-par">
                        <img class="img-fluid img-head mx-auto d-block" :src='require(`../components/articles/${this.$route.params.id}/imgFolder/first.png`)' alt="تصویر نمایشی مربوط به ابتدای مقاله">
                    </div>

                    <!-- /////////////////////// -->
                    <!-- texts and other info specially related to each article -->
                    <!-- /////////////////////// -->
                    <BaseArticle></BaseArticle>

                    <!-- /////////////////////// -->
                    <!-- other parts in the footer of each article -->
                    <!-- /////////////////////// -->

                    <!-- like or dislike part -->
                    <DividerLine
                        :size = "10"
                        reso = "md"
                        v-if = "showLike"
                    >
                    </DividerLine>
                    <TheLike v-if = "showLike"></TheLike>

                    </section>
                    <!-- banner advertise -->
                    <!-- If you want to advertise for other companies you can set "show-add" prop to "true" and put related data to "BaseAdvertise" component. -->
                    <BaseAdvertise :show-add = "false"></BaseAdvertise>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
/* importing components needed for this page */
/* ----------------------------- */
import TheLike from '../components/TheLike.vue';
import BaseAdvertise from '../components/BaseAdvertise.vue'
import DividerLine from '../components/DividerLine.vue'
        export default {
                name: "ArticlesId",
                /* ----------------------------- */
                components: {
                    TheLike,
                    BaseAdvertise,
                    DividerLine
                },
                /* ----------------------------- */
                data() {
                    return {
                        articleData: null, /* The data related to each article comes here. */
                        /* ----------------------------- */
                        tagData: [], /* The tags related to each articles come here. */
                        /* ----------------------------- */
                        showFlag1: false, /* This data becomes true when the data related to each article loaded successfully from back-end. */
                        /* ----------------------------- */
                        showLike: true, /* This is used to appear or disappear "TheLike" component according to this issue that the user voted before or not.  */
                        /* ----------------------------- */
                        idThis: this.$route.params.id /* This is the id of each article that must be called to show related info. */
                    }
                },
                /* ----------------------------- */
                props: ['id'], /* this props is actually the dynamic id of page. */
                /* ----------------------------- */
                computed: {

                    datePart: function () {
                      let tempoDate = this.articleData.update_date.split('-');
                      let dayPart = tempoDate[2].substr(0, 2);
                      let dateArti = new Date(tempoDate[0], tempoDate[1], dayPart);
                      let year = dateArti.getFullYear();
                      let month = dateArti.getMonth();
                      let day = dateArti.getDate();

                      return `${year}-${month}-${day}`;
                    }
                },
                /* ----------------------------- */
                methods: {
                    //  for fetching data and linking to backend
                    /* ---------------------- */
                    getData: function() {
                        let idArticle = this.$route.params.id
                        const myInit = {
                             method: 'POST',
                             mode: 'cors',
                             cache: 'default',
                             headers: {
                                'Content-Type': 'application/json;charset=utf-8'
                             },
                             body: JSON.stringify({
                                 dataArti: [idArticle]
                             })
                        };
                        // sending request to back-end
                        fetch(this.$backUrl + "database/article/" + idArticle, myInit)
                             .then((response) => {
                                 if (!response.ok) {
                                     throw new Error(`HTTP error! status: ${response.status}`);
                                 }
                                 return response.json();
                             })
                             .then(result => {
                                 // getting articles data
                                 this.articleData = result;
                                 // getting tags data
                                 this.tagFetch(this.$route.params.id);
                             });
                    },
                    //  for getting tags related to each article
                    /* ---------------------- */
                    tagFetch: function(idParam) {
                        const myInit = {
                             method: 'POST',
                             mode: 'cors',
                             cache: 'default',
                             headers: {
                                'Content-Type': 'application/json;charset=utf-8'
                             },
                        };
                        // sending request to back-end
                        fetch(this.$backUrl + "database/tags/" + idParam, myInit)
                             .then((response) => {
                                 if (!response.ok) {
                                     throw new Error(`HTTP error! status: ${response.status}`);
                                 }
                                 return response.json();
                             })
                             .then(result => {
                                 this.tagData = result;
                                 this.showFlag1 = true;
                             });
                    },
                    //  for finding that showLike must be set to false or not
                    /* ---------------------- */
                    showLikes: function() {
                        let localPara = localStorage.getItem("likes" + this.idThis);
                        if( localPara ) {
                            this.showLike = false;
                        }
                    }
                },
                /* ----------------------------- */
                created ()  {
                        /* loading components dynamically */
                        let componentName = "BaseArticle";

                        try {
                          let fileName = require('../components/articles/' + this.$route.params.id + '/' + componentName + '.vue');
                          this.$options.components[componentName] = () => import('../components/articles/' + this.$route.params.id + '/' + componentName + '.vue');
                          /* getting data of this article from backend */
                          this.getData();
                        } catch (e) {
                          this.$router.push({ name: 'PageNote' });
                        }


                },
                /* ----------------------------- */
                mounted() {
                    this.showLikes();
                }
        }
</script>

<style src="../assets/assets-CSS/articlesId.css"></style>
