import Vue from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import VueCookies from 'vue-cookies';
import VueMeta from 'vue-meta';
Vue.use(VueMeta);
Vue.use(VueCookies);


// window.$ = require('jquery');
// window.JQuery = require('jquery');
import 'jquery/src/jquery';
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

// import 'bootstrap/dist/js/bootstrap.min.js';
import 'animate.css';

/* global variables */
/* http://localhost:3500/ */
Vue.prototype.$frontUrl = process.env.VUE_APP_FRONT_URL;
Vue.prototype.$backUrl = process.env.VUE_APP_BACK_URL;
Vue.prototype.$bankUrl = process.env.VUE_APP_BANK_URL;

Vue.config.productionTip = false;

new Vue({
  router,
  render: (h) => h(App),
}).$mount('#app');
