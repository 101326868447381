<template>
   <div class="container-fluid">
       <div class="row">
          <div class="col-md-10 mx-auto p-4">
           <h1 class="border text-center">صفحه کمک مالی به سایت</h1>
          </div>
          <div class="col-md-11 mx-auto p-3" id="body-donate">
           <p class="text-center">
               سایت ما یک سایت با خدمات رایگان است. در صورت تمایل و رضایت از مطالب سایت می&zwnj;توانید از طریق فرم زیر کمک&zwnj;های مالی خود را به حساب سایت واریز کنید.
           </p>
           <div class="alert alert-danger text-center" role="alert" v-if="alertShow">
              {{ alertText }}
           </div>
           <div class="card">
               <div class="card-body">
                   <form class="text-center">
                      <div class="form-row">
                        <div class="col-md-6">
                            <label for="email">آدرس ایمیل:</label>
                            <input v-model.trim="emailData" type="email" class="form-control form-control-lg" id="email" aria-describedby="emailHelp" name="email">
                            <small id="emailHelp" class="form-text pt-2">ذکر آدرس ایمیل اختیاری است.</small>
                        </div>
                        
                        <hr class="d-md-none">
                        
                        <div class="col-md-6">
                            <label for="mablag">مبلغ مورد نظر:</label>
                            <input v-model.number.trim="donateRial" type="number" class="form-control form-control-lg" id="mablag" aria-describedby="mablagHelp" name="mablag">
                            <small id="mablagHelp" class="form-text pt-2">تنها عدد مبلغ مورد نظر خود را به ریال وارد کنید.</small>
                        </div>
                        
                        <div class="col-md-6 mx-auto my-5">
                            <button @click.prevent="checkInputs" class="btn">اتصال به درگاه بانکی</button>
                        </div>
                      </div>
                   </form>
               </div>
           </div>
          </div>
       </div>
   </div>
    
</template>

<script>
    export default {
        metaInfo: {
            title: "کمک مالی",
        },
        data () {
            return {
                donateRial: null,
                emailData: null,
                alertShow: false,
                alertText: ""
            }
        },
        methods:{
            checkInputs: function() {
                if (this.donateRial != null && this.donateRial > 50000) {
                    console.log(this.donateRial);
                    if (this.emailData != null && this.emailData.length > 0) {
                        let myEmail = this.emailData.toLowerCase().match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
                        console.log(myEmail);
                        if (myEmail == null) {
                            this.alertShow = true;
                            this.alertText = "فرمت ایمیل وارد شده صحیح نمی باشد."
                        } else {
                            this.alertShow = false;
                            this.alertText = ""
                            /* doing real operations */
                            this.bankOperations();
                        }
                    } else {
                        this.alertShow = false;
                        this.alertText = ""
                        /* doing real operations */
                        this.bankOperations();
                    }
                } else {
                    this.alertShow = true;
                    this.alertText = "مبلغ وارد شده باید از مقدار 50000 ریال بیشتر باشد."
                }
                
            },
            bankOperations: function() {
                console.log(typeof this.donateRial);
                this.donateRial = Math.round(this.donateRial);
                let data = {
                             amount: this.donateRial,
                             email: this.emailData
                            }
                var redirect = this.$bankUrl + "formData";
                
                this.postData(redirect, data);
                
//                window.location = redirect + '/?amountData=' + data.amount + '&emailData=' + data.email;
                // $.redirectPost(redirect, {amountData: data.amount, emailData: data.email});
                
//                fetch(this.$bankUrl + "formData",
//                  {
//                    method: "POST",
//                    mode: 'cors',
//                    cache: 'default',
//                    headers: {
//                            'Content-Type': 'application/json;charset=utf-8'
//                    },
//                    body: JSON.stringify(data),
//                  })
//                  .then(response => console.log(response))
//                  .catch(error => console.log(error))
            },
            postData: function(path, params, method='post') {

              // The rest of this code assumes you are not using a library.
              // It can be made less verbose if you use one.
              const form = document.createElement('form');
              form.method = method;
              form.action = path;

              for (const key in params) {
                if (params.hasOwnProperty(key)) {
                  const hiddenField = document.createElement('input');
                  hiddenField.type = 'hidden';
                  hiddenField.name = key;
                  hiddenField.value = params[key];

                  form.appendChild(hiddenField);
                }
              }

              document.body.appendChild(form);
              form.submit();
            }
        }
    }
</script>

<style scoped src="../assets/assets-CSS/donateMe.css"></style>