<template>
<!-- This is the component for showing data that needs table format in our articles. It has several props that help show data and make appropriate styles for it.

/* --------------- */
-- for calling that in a parent component: --
/* --------------- */
<BaseTable :ltrProp="false" styleProp="style-type1" :dataTableProp="tableData" widthMin="900px" captionText="جدول مربوط به محل قرارگیری شرکت&zwnj;ها"></BaseTable>

Where "tableData" that comes to "dataTableProp" prop is and array of objects that has a format similar to this:

tableData: [
    /* --------------- */
    /* data for row 1 that is for "thead" of table */
    /* --------------- */
    {
        column1: "ردیف",
        column2: "مورد اول",
        column3: "مورد دوم",
        column4: "مورد سوم"
    },
    /* --------------- */
    /* data for row 2 */
    /* --------------- */
    {
        column1: "1",
        column2: "یک وب سایت تک صفحه که با موضوع هتل طراحی شده است. در این وب سایت از تکنولوژی های بخش فرانت مانند JavaScript، CSS، HTML و Bootstrap استفاده شده و سایت به صورت responsive طراحی شده است. ",
        column3: "برنامه نویسی شئ محور نوعی مدل برنامه نویسی است که پایه و اساس آن بر مبنای اشیاء (Objects) بنا نهاده شده است. در این مدل داده ها و توابع برنامه نویسی در خود شئ ذخیره می شوند.",
        column4: "از آنجایی که  زبان&zwnj;های سمت سرور برای برقراری ارتباط بین سرور و بازدیدکننده سایت (client) طراحی می&zwnj;شوند، آشنایی با ابزارها و مسائلی که در این ارتباط نقش آفرینی می&zwnj;کنند، می&zwnj;تواند مفید باشد. از جمله این مفاهیم درخواست&zwnj;های HTTP هستند (<span class = engliSpan>HTTP Requests</span>) که با متودهایی مانند GE"
    },
    /* --------------- */
    /* data for row 3 */
    /* --------------- */
    {
        column1: "2",
        column2: "CSS یکی از زبانهای front-end است که برای طراحی صفحات وب و استایل دادن به آنها به کار می رود. این زبان اغلب به کمک زبان HTML مورد استفاده قرار می گیرد.",
        column3: "بوتسترپ یکی از فریم ورک های front-end است که به کمک آن می توان صفحات وب را به صورت responsive طراحی کرد. قابلیت های فراوان این فریم ورک آنرا بسیار پرکاربرد نموده است",
        column4: "کتابخانه jQuery یکی از کتابخانه های <span class = 'farsiSpan'>مرتبط</span> با جاوا اسکریپت است. این کتابخانه در مواردی  نظیر DOM Manipulation و Animation به راحت تر استفاده کردن از جاوا اسکریپت کمک شایانی کرده است."
    },
    /* --------------- */
    /* data for width of each column */
    /* --------------- */
    {
        column1: "5%",
        column2: "33%",
        column3: "31%",
        column4: "31%"
    }
] // end of tableData

/* --------------- */
Notice: The first and last items of this array are related to "thead" row data and "width" of each column respectively. Yuo can insert as many as rows between these two items to fill "tbody" data. Also notice that all objects of this array must have same number of "property-values". For example here all of them have 4 columns. All properties must be started with "column" keyword.
/* --------------- */
-->
<div class="col-md-10 mx-auto mt-5">
    <div class="responsiveTable">
        <table
            :data-style="styleProp"
            class="table table-striped"
            :dir="ltrProp ? 'ltr' : 'rtl'"
            :style="{ minWidth: widthMin }"
        >
            <caption v-html = "captionText" class="text-center mt-2">جدول مربوط به پارامترهای مورد استفاده در ویو - فرمولیت</caption>
            <thead>
                <tr>
                    <th
                        v-for = "(value, name, index) in theadData"
                        :key = "index"
                        scope="col"
                        class="px-md-4 px-2"
                        :style="{ width: columnWidths[name] }"
                    >
                      {{ value }}
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr
                    v-for = "(item, index) in tbodyData"
                    :key = "index"
                >
                    <th
                        scope="row"
                        class="px-md-4 px-2"
                        v-html = "item.column1"
                    ></th>
                    <td
                        class="px-md-4 px-2"
                        v-for="n in numberColumns-1"
                        v-html = "item['column'+(n+1)+'']"
                    ></td>
                </tr>
            </tbody>
        </table>
    </div>
</div>
</template>

<script>
        export default {
                name: "BaseTable",
                props: {
                    ltrProp : {
                        type: Boolean,
                        required: true
                    },
                    styleProp: {
                        type: String,
                        default: "default-style"
                    },
                    captionText: {
                        type: String,
                        required: true
                    },
                    dataTableProp: {
                        type: Array,
                        required: true
                    },
                    widthMin: {
                        type: String,
                        required: true
                    }
                },// end of props

                computed: {
                    theadData: function() {
                        let theadRow = this.dataTableProp[0];
                        return theadRow;
                    },
                    numberColumns: function() {
                        let theadRow = this.dataTableProp[0];
                        let count = 0;
                        for (let properties in theadRow) {
                            count = count + 1
                        }
                        return count;
                    },
                    columnWidths: function() {
                        let widthRow = this.dataTableProp[this.dataTableProp.length - 1];
                        return widthRow;
                    },
                    tbodyData: function() {
                        let tbodyRow = [];
                        for ( let i=1; i<(this.dataTableProp.length - 1); i++ ) {
                            tbodyRow.push(this.dataTableProp[i]);
                        }
                        return tbodyRow;
                    }
                } // end of computed
        }
</script>

<style scoped src="../assets/assets-CSS/baseTable.css"></style>
