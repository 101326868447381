<template>
  <!-- This is the base button used in the site. You can define some option on it, for example 3 different color mode is acceptable:
Default: without setting prop, color1: setting "color1Prop" prop or color2: setting "color2Prop".  -->

  <!-- first we define that if the button is a linked type or not -->
  <div class="btn1Par" v-if="linked === 'true'">
    <a v-on:click="$emit('routing')" :href="linkRef" :target="targetProp" :type="typeProp" class="btn btn-lg" :class="{ color1: color1acc, color2: color2acc }">
      {{ text }}
    </a>
  </div>

  <div class="btn1Par" v-else>
    <button v-on:click="$emit('routing')" :type="typeProp" class="btn btn-lg" :class="{ color1: color1acc, color2: color2acc }">
      {{ text }}
    </button>
  </div>
</template>

<script>
    export default {
        name: "BaseButton",
        data() {
            return {
                color1acc: this.color1Prop,
                color2acc: this.color2Prop
            }
        },
        props: {
            text: String,
            linked: String,
            color1Prop: Boolean,
            color2Prop: Boolean,
            linkRef: String, /* this is used for url addres if the button is linked. */
            typeProp: String, /* This is used to define the type of button. For example a "submit button" or ... */
            targetProp: String
        }
    }
</script>

<style scoped src="../assets/assets-CSS/buttonLarge.css"></style>
