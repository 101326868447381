<template>
    <!-- This is the pagination component that is responsible for showing articles in, for example "Articles.vue" to use this component you need to specify two parameters (props) from the parent component.
--------------------------------------
1- countPage: this is for the number of all pages that exists.
2- pageShows: this is the number of pages that is shown in the view of pagination. For example, if it is set to 4 that means the pages 1, 2, 3, 4 and 5 are shown and if the user clicks on page 5 then the pages 2, 3, 4, 5 and 6 are shown and continue to reach the last page.
--------------------------------------
In addition to that two parameters this component emits an event called "changePage". This event, in fact returns the number of active pages to the parent component to render the contents in that component. -->
        <div class="col-md-10 mx-auto parentPag my-3">

            <nav class="mt-2">
                <ul class="pagination justify-content-center align-items-center">

                    <li class="page-item">
                        <span class="page-link mx-sm-1 text-center" @click="firstClick">صفحه نخست</span>
                    </li>

                    <li v-for="num in maxArr" class="page-item" @click=" defineActive($event);">
                        <span class="page-link mx-1"> {{ num }} </span>
                    </li>

                    <li class="page-item">
                        <span class="page-link mx-sm-1 text-center" @click="lastClick">آخرین صفحه</span>
                    </li>

                </ul>
            </nav>

        </div>
</template>

<script>

        export default {
                name: "BasePagination",
                data() {
                    return {
                        activeNum: 1, /* this is an integer value that defines which page in pagination is active */
                        minData: 1,
                    }
                }, // end of data

                computed: {
                     maxData() {
                         /* this is the end page indexe in the pagination, and is set according to "this.minData" */
                         return this.minData + this.numShow;
                     },

                     /* --------------------------- */
                     /* --------------------------- */

                     allPages() {
                         return this.countPage;
                     },

                     /* --------------------------- */
                     /* --------------------------- */

                     numShow() {
                         return parseInt(this.pageShows) + 1 <= parseInt(this.countPage) ? parseInt(this.pageShows) : parseInt(this.countPage) - 1;
                     },

                     /* --------------------------- */
                     /* --------------------------- */

                     maxArr() {
                        /* build the array according to "this.maxData" for rendering "li"s of pagination */
                        let startVar = this.minData;
                        let endVar = this.maxData;
                        let maxArrVar = [];
                        for ( let ij = startVar; ij<=endVar; ij++) {
                            maxArrVar.push(ij);
                        }
                        return maxArrVar;
                    }
                },

                props: {
                    pageShows: Number,
                    countPage: Number
                },

                methods: {
                    givingActive: function(numberPage, minPage) {
                        /* This function gives the active class to one of the pages according to activeNum data, also it defines the initial value of activeNum */
                        sessionStorage.setItem("activePage", numberPage);
                        sessionStorage.setItem("minPagInd", minPage);
                        this.activeNum = parseInt( sessionStorage.getItem("activePage") );
                        this.minData = parseInt( sessionStorage.getItem("minPagInd") );
                        let allLi = document.querySelectorAll(".page-item");
                        for (let ii = 0; ii < allLi.length; ii++) {

                            if ( allLi[ii].classList.value.includes("active") ) {
                                allLi[ii].classList.remove("active");
                                break;
                            }

                        } // end of for
                        allLi[this.activeNum].classList.add("active");
                        let pageNow = parseInt(this.activeNum - 1 + this.minData);
                        this.sendPage(pageNow);
                    },

                    /* ----------------------------  */
                    /* ----------------------------  */

                    sendPage: function(pageSend) {
                        /* This is the function that sends the active page to the parent component for rendering the data related to that. */
                        this.$emit('changePage', pageSend)
                    },

                    /* ----------------------------  */
                    /* ----------------------------  */

                    firstClick: function() {
                        /* This method is used when user click on the "first-page" button in pagination. */
                        this.activeNum = 1;
                        this.minData = 1;
                        this.givingActive(this.activeNum, this.minData);

                    },

                    /* ----------------------------  */
                    /* ----------------------------  */

                    lastClick: function() {
                        /* This method is used when user click on the "last-page" button in pagination. */
                        this.activeNum = this.numShow + 1;
                        this.minData = parseInt(this.allPages) - this.numShow;
                        this.givingActive(this.activeNum, this.minData);
                    },

                    /* ----------------------------  */
                    /* ----------------------------  */

                    defineActive: function(evt) {
                        /* This method defines the active page styles according to the click of the user. */
                        let textClick = evt.target.innerText;
                        let classArr = evt.target.parentElement.classList;
                        console.log(classArr);
                        /* first we must declare that the clicked page is active "now" or not. */
                        if(!classArr.value.includes("active") ) {

                            let indexFinal = textClick - this.minData + 1;
                            this.activeNum = parseInt(indexFinal);

                            if (this.activeNum == this.numShow + 1 && parseInt(textClick) != this.allPages ) {
                                this.activeShow(textClick);
                                this.activeNum = parseInt(indexFinal) - 1;
                                this.givingActive(this.activeNum, this.minData);
                            } else if( (this.activeNum === 1) && (this.minData !== 1) ) {
                                this.activeShow(textClick);
                                this.activeNum = parseInt(indexFinal) + 1;
                                this.givingActive(this.activeNum, this.minData);
                            } else {
                                this.givingActive(this.activeNum, this.minData);
                            }

                        } // end of if

                    },

                    /* ----------------------------  */
                    /* ----------------------------  */

                    activeShow: function(indexClick) {
                        /* This method is responsible for showing the number of "page-links" according to the one that user clicks. */
                        if (this.allPages >= this.maxData) {

                            let act3minus = parseInt(indexClick - this.minData);

                            if ( ( act3minus > 3 ) && ( ( parseInt(indexClick) + 1 ) <= this.allPages ) ) {
                                this.minData++;
                            }

                            if ( act3minus == 0 ) {
                                this.minData--;
                            }

                        }

                    }

                }, // end of methods

                updated() {
                    /* We use the exact functionality of mounted part. So that the component responses to changes in communication with its parent component. */
                    if ( !sessionStorage.getItem("activePage") ) {
                        this.givingActive(1, 1);
                    } else {
                        this.givingActive( parseInt( sessionStorage.getItem("activePage") ), parseInt( sessionStorage.getItem("minPagInd") ) );
                    }

                }, // end of updated

                mounted() {
                    if ( !sessionStorage.getItem("activePage") ) {
                        this.givingActive(1, 1);
                    } else {
                        this.givingActive( parseInt( sessionStorage.getItem("activePage") ), parseInt( sessionStorage.getItem("minPagInd") ) );
                    }


                } // end of mounted

        }
</script>

<style scoped src="../assets/assets-CSS/BasePagination.css"></style>
