<template>
<!-- This page (view) is for showing 404 page, when the user set a url that does not exist in our site.  -->
  <div class="container-fluid my-5">
      <div class="row">
          <div id="pageNot" class="col-md-10 mx-auto">
              <div class="d-flex my-3 justify-content-center align-items-center">
                  <span class="px-4">4</span>
                  <i class="far fa-exclamation-circle"></i>
                  <span class="px-4">4</span>
              </div>
              <div class="text-center my-3" id="textNot">
                  <p class="py-5">صفحه مورد نظر یافت نشد!</p>
              </div>
              <div class="text-center linkDiv">
                  <router-link class="p-3 my-3" :to="{name: 'Articles'}">
                      بازگشت به صفحه اصلی سایت
                  </router-link>
              </div>
          </div>
      </div>
  </div>
</template>

<script>
export default {
  metaInfo: {
    title: "404",
  },
  name: 'PageNote',
};
</script>

<style scoped src="../assets/assets-CSS/notFound.css"></style>
