<template>
<!-- This component is used to show download resume button and a text related to that. Because of its special feature that loads some HTML content with its method we must use "module" styles for that. -->
  <div class="container-fluid btnPar mt-5 p-5">
    <div class="khat"></div>
    <div class="row">
      <div class="col-md-6 d-flex flex-column justify-content-between align-items-center">
        <p>دانلود فایل رزومه در قالب pdf </p>
        <p>آخرین به روز رسانی: 1401/03/29 </p>
      </div>

      <div class="col-md-6 d-flex justify-content-center align-items-center">

        <a download href="../files/resume3.pdf" @mouseenter="[hover = true, hoverEff()]" @mouseleave="hover = false" :class="{[$style.classDiv]: hover}" class="button px-4 py-2" style="text-align: left">دانلود فایل</a>

      </div>
    </div>
  </div>
</template>

<script>
    export default {
        name: "OneButton",
        data() {
            return {
                hover: false,
            }
        }, // end of data

        methods: {
            hoverEff: function () {
                /* This function gives the special style to the button on "hover". */
                document.querySelectorAll('.button').forEach(button => button.innerHTML = '<div><span>' + button.textContent.trim().split('').join('</span><span>') + '</span></div>');
            }, // end of hoverEff function

        }, // end of methods

    }
</script>

<style scoped src="../assets/assets-CSS/buttons.css"></style>

<style module>


  .classDiv {
    --shadow-active: 0 3px 1px rgba(0, 0, 0, .2);
    --shadow2: 2px 3px 2px rgba(0, 0, 0, .4);
  }

  .classDiv div span:nth-child(1) {
    --d: 0.05s;
  }
  .classDiv div span:nth-child(2) {
    --d: 0.1s;
  }
  .classDiv div span:nth-child(3) {
    --d: 0.15s;
  }
  .classDiv div span:nth-child(4) {
    --d: 0.2s;
  }
  .classDiv div span:nth-child(5) {
    --d: 0.25s;
  }
  .classDiv div span:nth-child(6) {
    --d: 0.3s;
  }
  .classDiv div span:nth-child(7) {
    --d: 0.35s;
  }
  .classDiv div span:nth-child(8) {
    --d: 0.4s;
  }
  .classDiv div span:nth-child(9) {
    --d: 0.45s;
  }
  .classDiv div span:nth-child(10) {
    --d: 0.5s;
  }
  .classDiv div span:nth-child(11) {
    --d: 0.55s;
  }

  .classDiv div span {
    animation: move 2s linear var(--d);
    animation-iteration-count: 1;
  }



@keyframes move {
    0% {
      color: var(--secondary2);
      text-shadow: var(--shadow2);
    }
    40% {
      color: var(--accent3);
      text-shadow: var(--shadow-active);
    }
}


</style>
