<template>
    <!-- This is the simple divider that may be used on different parts of the site. You can set the width of that according to 2 props on it that define in which resolution, which width is ok. For example col-md-10 or col-sm-8. -->
    <div
        class="mx-auto parentDiv"
        :class="`col-${this.reso}-${this.size}`"
    >
            <div class=divMe0></div>
            <div class=divMe></div>
            <div class=divMe0></div>
    </div>
</template>

<script>
        export default {
                name: "DividerLine",
                props: {
                    reso: String,
                    size: Number
                }
        }
</script>

<style scoped src="../assets/assets-CSS/divider3.css"></style>
