<template>
<!-- This is the card component of the site that is used in "Samples.vue" to show services. It is using "vue slots" to get data from the parent component  -->
    <div class="col-lg-3 col-md-6 my-4 my-lg-auto cardPare">
        <section class="collAll shadow px-2">
            <!-- head title of card -->
                <div class="d-flex flex-row align-items-stretch justify-content-around mb-2 pt-4">

                    <h2>
                         <slot name="header"></slot>
                    </h2>

                    <slot name="iconCode"></slot>
                </div>

                <section class="textDivPar d-flex flex-column justify-content-around">
            <!-- body text of card -->
                <div class="text-right textDiv">
                    <p>
                        <slot name="tozih"></slot>
                    </p>
                </div>

                <div class="listItems px-1">
            <!-- listed item of card -->
                    <ul class="text-right">

                        <li class="mb-3">
                            <span class="ml-2">
                                    <i class="fal fa-check-circle"></i>
                            </span>

                            <span>
                                <slot name="span1"></slot>
                            </span>

                        </li>

                        <li class="mb-3">
                            <span class="ml-2">
                                    <i class="fal fa-check-circle"></i>
                            </span>

                            <span>
                                <slot name="span2"></slot>
                            </span>

                        </li>

                        <li class="mb-3">
                            <span class="ml-2">
                                    <i class="fal fa-check-circle"></i>
                            </span>

                            <span>
                                <slot name="span3"></slot>
                            </span>

                        </li>

                    </ul>
                </div>

                </section>
        </section>
    </div>
</template>

<script>
        export default {
                name: "BaseCard",
        }
</script>

<style scoped src="../assets/assets-CSS/cardService.css"></style>