<template>
<!-- this is the texts and contents of "article number 5" of our database. for more info and list of all options available for each article please see this file:
H:/web-codes/websites/portfolio-folder/design-and-assets/sample article/BaseArticle.vue  -->
        <div class="col-md-10 mx-auto">

            <div class="text-right mt-5">

                <!-- intro text -->
                <h2 class="subTitle">مقدمه: </h2>
                <blockquote class="blockquote">
                    <p class="text-justify textPara pb-5">
                        در میان المان&zwnj;های از پیش طراحی شده <a href="https://getbootstrap.com/docs/3.4/" target="_blank">بوتسترپ</a>، شاید <a href="https://getbootstrap.com/docs/3.4/components/#panels" target="_blank">پنل</a> یکی از پرکاربردترین&zwnj;ها باشد. در این مقاله قصد داریم آموزشی در ساختن یک ساختار پنل بوتسترپ ارائه کنیم و سپس به شخصی&zwnj;سازی&zwnj;های لازم بپردازیم. به این معنا که پس از ساخت پنل به کمک کلاس&zwnj;های خاص <span class="farsiSpan">بوتسترپ</span>، به وسیله <span class="engliSpan">CSS</span> و استایل&zwnj;هایی که خودمان تعریف می&zwnj;کنیم رنگ&zwnj;بندی و طراحی مناسب و دلخواه خود را ایجاد نماییم.
                    </p>
                </blockquote>

                <!-- paragraphs -->
                <h2 class="subTitle mt-5">پنل بوتسترپ چیست؟</h2>
                <p class="text-justify textPara">
                    همان&zwnj;طور که می&zwnj;دانیم یکی از کامپوننت&zwnj;ها در نسخه شماره 3 بوتسترپ <span class="engliSpan">panels</span> (پنل&zwnj;ها) است. پنل&zwnj;ها به زبان ساده ابزاری قدرتمند برای طراحی اجزای وب&zwnj;سایت&zwnj;ها هستند که می&zwnj;توان از آنها برای ساختن وب&zwnj;سایت&zwnj;های با موضوعات مختلف استفاده نمود. مثلاً در وب&zwnj;سایت&zwnj;های تجاری می&zwnj;توان خدمات سایت را در قالب یک سری پنل در کنار یکدیگر به مخاطبان سایت ارائه کرد. در واقع پنل&zwnj;ها به ما این امکان را می&zwnj;دهند که بتوانیم در <strong> یک فضای با ابعاد مشخص و منظم </strong> به تولید محتوای بخش خاصی از سایت بپردازیم. در اینجا قصد داریم به کمک بوتسترپ به ایجاد چنین ساختاری پرداخته و سپس به کمک <span class="engliSpan">CSS</span> طراحی آن را به حالت دلخواه (شخصی&zwnj;سازی) تبدیل کنیم.
                </p>

                <!-- paragraphs -->
                <h2 class="subTitle mt-5">آماده&zwnj;سازی اولیه:</h2>
                <p class="text-justify textPara">
                    طبق ساختار بوتسترپ برای ایجاد پنل می&zwnj;توان از کلاس&zwnj;های <span class="engliSpan">panel</span> و <span class="engliSpan">panel-default</span> استفاده کرد. از آنجایی که در این مقاله قصد داریم 4 عدد از این پنل&zwnj;ها را در کنار یکدیگر قرار بدهیم، باید از ساختار <a href="https://getbootstrap.com/docs/3.4/css/#grid" target="_blank">گریدبندی</a> بوتسترپ هم کمک بگیریم. لذا پس از اضافه نمودن لینک&zwnj;های مرتبط با بوتسترپ (bootstrap.css و bootstrap.js و ...) و همچنین لینک&zwnj;های مورد نظر برای فونتها (که در اینجا از <a href="https://fonts.google.com" target="_blank">google fonts</a> استفاده شده است) به فایل <span class="engliSpan">HTML</span> خود، می&zwnj;توانیم به ساختار اولیه زیر دست یابیم. در این کد برای ایجاد grid مناسب از کلاس&zwnj;های <span class="engliSpan">col-lg-3</span> و <span class="engliSpan">col-sm-6</span> استفاده شده است.
                </p>

                <!-- simple code images -->
                <div class="col-md-10 mt-5 mx-auto code-img">
                    <img class="img-fluid img-thumbnail" src="./imgFolder/code1.png" alt="تصویر مربوط به کد HTML جهت ایجاد گریدبندی مناسب برای پنل ها">
                </div>

                <!-- paragraphs -->
                <p class="text-justify textPara mt-5">
                    در داخل هریک از این گریدها از کلاس&zwnj;های <span class="engliSpan">panel-heading</span> و <span class="engliSpan">panel-body</span> برای اضافه کردن محتویات مربوط به پنل&zwnj;ها استفاده شده است.
                </p>

                <!-- simple code images -->
                <div class="col-md-10 mt-5 mx-auto code-img">
                    <img class="img-fluid img-thumbnail" src="./imgFolder/code3.png" alt="تصویر مربوط به طرز قرارگیری کلاس های مرتبط با پنل در کد HTML">
                </div>

                <!-- paragraphs -->
                <h2 class="subTitle mt-5">اضافه کردن محتویات:</h2>
                <p class="text-justify textPara">
                    مسلما تا به اینجا اگر کد نوشته شده را در صفحه مرورگر به نمایش بگذاریم، چیز خاصی جز باکس&zwnj;های خالی مشاهده نمی&zwnj;شود. لذا باید برای اضافه کردن محتویات مناسب اقدام شود. ما در اینجا <strong>فقط</strong> محتویات یکی از پنل&zwnj;ها را اضافه کرده و توضیح می&zwnj;دهیم. سه پنل دیگر مشابه همین روند را طی خواهند نمود و تنها تفاوت آنها در متون و عکس&zwnj;هایی است که جایگزین می&zwnj;شوند. در ادامه برای عنوان پنل در داخل panel-heading از یک تگ <code> &lt;h1&gt;</code> استفاده شده و در قسمت <span class="engliSpan">panel-body</span> هم از تگ&zwnj;های HTML مانند <code> &lt;img&gt;</code> و ... بهره برده&zwnj;ایم.
                </p>

                <!-- showing code imgs in tabs -->
                <BaseTab
                    :head-tabs = "tabDatas"
                    @indexSend = "loadTabContent($event)"
                >
                    <template>
                        <div class="col-md-10 mt-5 mx-auto code-img">
                            <img class="img-fluid img-thumbnail" :src="require(`./imgFolder/${tabDatas[activeTab].data.imgName}`)" :alt="`${tabDatas[activeTab].data.altText}`">
                        </div>
                    </template>

                </BaseTab>

                <!-- paragraphs -->
                <p class="text-justify textPara mt-5">
                    در اینجا مشاهده می&zwnj;شود که محتویات پنل به خوبی جایگزین شده&zwnj;اند. چنانچه به کد نوشته شده دقت شود می&zwnj;توان فهمید که تنها کلاس&zwnj;های بوتسترپ در آن قرار گرفته&zwnj;اند. به عنوان مثال کلاس&zwnj;های <span class="engliSpan">img-responsive</span> و <span class="engliSpan">img-circle</span> باعث می&zwnj;شوند که تصاویر استفاده شده در پنل&zwnj;ها <span class="farsiSpan">رسپانسیو</span> عمل کرده و حالت دایره&zwnj;ای هم به خود بگیرند. همچنین لازم به توضیح است که از خط 42 تا حدود 52 از <strong>5</strong> تگ <code>&lt;span&gt;</code> با کلاس <span class="engliSpan">glyphicon</span> استفاده شده است که در اینجا به اختصار فقط یک مورد به نمایش در آمده است. گرچه استفاده از کلاس&zwnj;های بوتسترپ به تنهایی شمای کلی مناسبی به پنل مورد نظر می&zwnj;دهد، ولی معمولا طراحان وب&zwnj;سایت موظف هستند که طراحی منحصر به فرد و مرتبط با رنگ&zwnj;بندی و استایل سایت مد نظر خودشان را پیاده&zwnj;سازی کنند. لذا معمولا نیاز است که این طرح اولیه بوتسترپ به شکل دلخواه تغییر داده شود که به آن شخصی سازی کردن (<span class="engliSpan">customize</span>) گفته می&zwnj;شود.
                </p>

                <!-- paragraphs -->
                <h2 class="subTitle mt-5">شخصی&zwnj;سازی کردن پنل ساخته&zwnj;شده: </h2>
                <p class="text-justify textPara">
                    در ادامه برای ایجاد طرح خاص&zwnj;تر و رنگ&zwnj;بندی مورد نظر از یک فایل CSS که توسط تگ <code>&lt;link&gt;</code> به بالای فایل HTML مرتبط شده است، استفاده می&zwnj;شود. برای تغییر در المان&zwnj;هایی که کلاس&zwnj;های بوتسترپ را دارند، گاهی اوقات لازم است که خود کلاس&zwnj;های بوتسترپ را در فایل CSS وارد کرده و تغییرات لازم را به آنها اعمال کنیم. نمونه&zwnj;ای از این موارد را در خطوط <strong>47</strong> و <strong>52</strong> فایل CSS این پنل می&zwnj;توان مشاهده کرد:
                </p>

                <!-- simple code images -->
                <div class="col-md-10 mt-5 mx-auto code-img">
                    <img class="img-fluid img-thumbnail" src="./imgFolder/code4.png" alt="تصویر مربوط به کد CSS برای شخصی سازی کردن استایل ها">
                </div>

                <!-- paragraphs -->
                <p class="text-justify textPara mt-5">
                    به طور کلی در این فایل CSS از کلاس&zwnj;های مختلفی برای تغییر رنگ و فونت و ... المان&zwnj;های HTML که در ساختار پنل استفاده شده&zwnj;اند، سود برده&zwnj;ایم. با اضافه کردن این کلاس&zwnj;ها به المان&zwnj;های مورد نظر ساختار کد HTML ما برای هریک از پنل&zwnj;ها به شکل زیر تغییر خواهد نمود. در واقع این فرآیند (<strong>تعریف کلاس&zwnj;های جدید</strong> و اضافه کردن آنها به المان&zwnj;های دارای کلاس بوتسترپ) یکی دیگر از روش&zwnj;های <strong>تغییر</strong> استایل المان&zwnj;های بوتسترپی است. به این ترتیب به شکل ابتدایی مقاله که مربوط به 4 پنل شخصی سازی شده بوتسترپ در کنار یکدیگر است، دست پیدا می&zwnj;کنیم.
                </p>

                <BaseTab
                    :head-tabs = "tabDatas2"
                    @indexSend = "loadTabContent2($event)"
                >
                    <template>
                        <div class="col-md-10 mt-5 mx-auto code-img">
                            <img class="img-fluid img-thumbnail" :src="require(`./imgFolder/${tabDatas2[activeTab2].data.imgName}`)" :alt="`${tabDatas2[activeTab2].data.altText}`">
                        </div>
                    </template>

                </BaseTab>


                <!-- conclusion part -->
                <h2 class="subTitle pb-3 mt-5">جمع بندی: </h2>
                <blockquote class="blockquote conclu">
                    <p class="text-justify textPara pt-3">
                        در این مقاله سعی شد که روش شخصی&zwnj;سازی کردن یک المان بوتسترپ (پنل) آموزش داده شود. گرچه در اینجا ما فقط بر روی پنل بوتسترپ تمرین داشتیم، اما این متود بر روی تمامی کامپوننت&zwnj;های بوتسترپ قابل اجرا است. آنچه به عنوان خلاصه و نتیجه این مقاله می&zwnj;توان ذکر نمود آن است که به طور کلی برای تغییر دادن ظاهر المان&zwnj;های بوتسترپ و اضافه کردن استایل&zwnj;های شخصی به آن می&zwnj;توان یکی از حالت&zwnj;های زیر را در نظر گرفت:
                    </p>

                    <BaseList :listData = "listText"></BaseList>

                    <p class="text-justify textPara">
                        همچنین می&zwnj;توان از ابزارهای مرورگرهای امروزی که با کلیک راست کردن و انتخاب گزینه <span class="engliSpan">inspect</span> در اختیار توسعه&zwnj;دهندگان وب قرار دارد، برای شناسایی اینکه چه تغییراتی باید برای کامپوننت مد نظر صورت گیرد، کمک گرفت.
                    </p>
                </blockquote>

            </div>



            <!-- ways number 1 of loading imgs  -->
            <!--
            <img :src='`${this.publicPath}articles/${this.$route.params.id}/first.png`' alt="تصویر نمونه">
-->

                <!-- ways number 2 of loading imgs  -->
                <!--
               <img src='./imgFolder/img1.png' alt="تصویر نمونه">
               -->
        </div>
</template>

<script>
import BaseTab from '../../BaseTab.vue';
import BaseList from '../../BaseList.vue';

export default {
                name: "BaseArticle",
                components: {
                    BaseTab,
                    BaseList
                },
                data() {
                    return {
                        activeTab: 0,
                        activeTab2: 0,
                        tabDatas: [
                            {
                                tabHeders: "HTML",
                                data:{
                                    imgName: "code2.png",
                                    altText: "تصویر مربوط به کد HTML اضافه کردن محتویات پنل"
                                }
                            },

                            {
                                tabHeders: "Result",
                                data:{
                                    imgName: "result1.png",
                                    altText: "تصویر مربوط به خروجی محتویات پنل ها بدون اعمال شخصی سازی"
                                }
                            }

                        ],

                        tabDatas2: [
                            {
                                tabHeders: "HTML",
                                data:{
                                    imgName: "code5.png",
                                    altText: "تصویر مربوط به اضافه کردن کلاس های CSS به فایل HTML برای شخصی سازی کردن ظاهر محتویات پنل ها"
                                }
                            },

                            {
                                tabHeders: "Result",
                                data:{
                                    imgName: "first-for-result.png",
                                    altText: "تصویر مربوط به خروجی نهایی شخصی سازی شده محتویات پنل ها"
                                }
                            }

                        ],


                        listText: [
                            /* this is the data used for BaseList.vue. the "strongText" data is optional and may be present in some list and not in other lists. */
                            {
                                icon: "fad fa-angle-left",
                                liText: "اضافه کردن کلاس&zwnj;ها یا id های خودمان به المان مورد نظر و تغییر استایل به وسیله آن"
                            },

                            {
                                icon: "fad fa-angle-left",
                                liText: "استفاده از خود کلاس&zwnj;های بوتسترپ و اعمال تغییرات دلخواه بر روی آن"
                            }

                        ],  // end of liText

                    }
                }, // end of data

                methods: {
                    loadTabContent: function(indexNow) {
                        this.activeTab = indexNow;
                    },

                    loadTabContent2: function(indexNow2) {
                        this.activeTab2 = indexNow2;
                    }
                } // end of methods

        }
</script>

<style scoped></style>
