<template>
<!-- This is the simple divider that uses "CSS styles" to show some decorative divider for separating parts of the site. -->
<div class="container-fluid">
  <div class="row dividerPar mx-auto my-5">
    <hr class="divider col-lg-10 col-8 mx-auto">
  </div>
</div>
</template>

<script>
    export default {
        name: "DividerCircle"
    }
</script>

<style scoped src="../assets/assets-CSS/divider2.css"></style>
