<template>
<!-- This component is responsible for search functionality in "Articles.vue" view. It searches according to text input and find the result from the database. Then it sends that data to the parent component to show on the page. -->
    <div class="col-md-10 mx-auto my-5">

        <div class="form-row align-items-center">
            <div class="col-md-12">
            <label class="sr-only" for="searchInp">search bar</label>
            <div class="input-group my-2 listInp">

                <div class="input-group-append">
                    <button class="btn" @click="sendData">
                        <i class="fas fa-search fa-3x"></i>
                    </button>
                </div>

                <input
                    @blur = "emptyCheck"
                    @keypress="checkEnter"
                    v-model="searchTermInput"
                    type="text"
                    class="form-control"
                    id="searchInp"
                    placeholder="جستجو در مقالات"
                >

            </div>
            </div>
        </div>

    </div>
</template>

<script>
export default {
        name: "BaseSearch",

        data() {
            return {
                searchTermInput: "",
                outSearch: []
            }
        }, // end of data

        props: {
            srtProp: String,
            searchItem: String
        }, // end of props

        computed: {

            sortComp() {
                return this.srtProp;
            },

            searchTerm: {
                /*
                This computed data use setter and getter to communicate with parent component. Its default value is "searchTermInput" data from "input" tag, but in cummunication with parent component, that component may set other values to this computed data via "searchItem" prop. */
                // getter
                get: function () {
                  return this.searchTermInput;
                },
                // setter
                set: function (newValue) {
                  this.searchTermInput = newValue;
                }

            }

        }, // end of computed

        methods: {
            sendData: function() {
                /* This function is called when the user clicks the search icon or press "enter" key in the input search. */
                if( this.searchTerm.trim().length > 1 ) {

                    let sentencAll = this.searchTerm.trim();
                    let sentenceSplit = sentencAll.split(" ");
                    let arrStr = [];
                    sentenceSplit.forEach(function (item, index) {
                        if(item.trim().length > 1) {
                            arrStr.push(item);
                        }
                    } );

                    if (sentencAll === arrStr[0]) {
                        /* this means that the user inputs only one word and there is no need to search about the words of the input sentence */
                        this.databaseCall(sentencAll)
                    } else {
                        this.databaseCall(sentencAll, arrStr);
                    }


                }

            },

            /* ---------------------- */

            checkEnter: function(evt) {
                /* This function detects when the user press "enter" key. */
                let keyCode = evt.code || evt.key;
                if (keyCode == "Enter" || keyCode == "NumpadEnter"){
                    // Enter pressed
                    this.sendData();
                }
            },

            /* ---------------------- */

            databaseCall: function (strSearch, searchArr = []) {
                 /* This function is responsible for sending data to the back-end and getting the result of search to send to parent component. */
                 let sortCol = this.sortComp;
                 let arrFinal = [strSearch];
                 if ( searchArr.length > 0 ) {
                     searchArr.forEach(function (item2, index2) {
                         arrFinal.push(item2);
                     });
                 }

                 const myInit = {
                     method: 'POST',
                     mode: 'cors',
                     cache: 'default',
                     headers: {
                        'Content-Type': 'application/json;charset=utf-8'
                     },
                     body: JSON.stringify({
                         finalSend: [arrFinal, sortCol]
                     })
                 };
                 fetch(this.$backUrl + "search", myInit)
                     .then((response) => {
                         /* response here is the response of express.js backend server and we must "return" it.  */
                         if (!response.ok) {
                             throw new Error(`HTTP error! status: ${response.status}`);
                         }
                         return response.json();
                     })
                     .then(result => {
                         this.outSearch = result;
                         // Sending data to other methods for sending finally to the parent component.
                         this.sortOut(this.outSearch);
                     });
             },

             /* ---------------------- */

             sortOut: function(idDupli) {
                 /* This method is responsible to delete the duplicate ids from the list and then sends them to "idFinal" method to use in parent component. */

                    let uniqueIds = idDupli.filter((item5, index5) => {
                        return idDupli.indexOf(item5) === index5;
                    });

                    this.idFinal(uniqueIds);

             },

             /* ---------------------- */

             idFinal: function(idList) {
                        /* This is the function that sends ids of searched articles to the parent component for rendering the data related to that. */
                        this.$emit('searchDone', [idList, this.searchTerm]);
             },

             /* ---------------------- */

             emptyCheck: function() {
                        /* This is the function that sends ids of searched articles to the parent component for rendering the data related to that. This method actually works on blur and mostly for refreshing result if the user cleared search input. */
                        this.$emit('blurFunc', this.searchTerm.length);
             },

        }, // end of methods

        mounted() {
            this.searchTerm = this.searchItem;
        },

}
</script>

<style scoped src="../assets/assets-CSS/BaseSearch.css"></style>