<template>
<!-- this is the texts and contents of "article number 7" of our database. for more info and list of all options available for each article please see this file:
H:/web-codes/websites/portfolio-folder/design-and-assets/sample article/BaseArticle.vue  -->
        <div class="col-md-10 mx-auto">

            <div class="text-right mt-5">

                <!-- intro text -->
                <h2 class="subTitle">مقدمه: </h2>
                <blockquote class="blockquote">
                    <p class="text-justify textPara pb-5">
                        دنیای برنامه&zwnj;نویسی پر از زبان&zwnj;های گوناگون و با کاربردهای متفاوت است. شاید شما نیز نام زبان برنامه&zwnj;نویسی <strong>پی&zwnj;اچ&zwnj;پی</strong> (PHP) را شنیده باشید. این زبان یکی از زبان&zwnj;های پرکاربرد در زمینه توسعه و ساخت بخش سمت سرور (back-end) وب سایت&zwnj;ها محسوب می&zwnj;شود. در این مقاله ضمن <span class="farsiSpan">معرفی مختصر</span> زبان برنامه&zwnj;نویسی پی&zwnj;اچ&zwnj;پی، قصد داریم که در خصوص مزایای استفاده از آن و همچنین تکنولوژی&zwnj;ها و مهارت&zwnj;هایی که یک توسعه&zwnj;دهنده زبان پی&zwnj;اچ&zwnj;پی نیاز دارد، صحبت کنیم.
                    </p>
                </blockquote>

                <!-- paragraphs -->
                <h2 class="subTitle mt-5">پی&zwnj;اچ&zwnj;پی چیست؟</h2>
                <p class="text-justify textPara">
                    طبق تعریف <a href = "https://www.php.net/manual/en/intro-whatis.php" target="_blank">سایت پی&zwnj;اچ&zwnj;پی</a> می&zwnj;توان این زبان را این&zwnj;گونه تعریف کرد:
                </p>

                <blockquote class="blockquote mainPart pr-3">
                    پی&zwnj;اچ&zwnj;پی یک زبان برنامه&zwnj;نویسی متن باز و چندکاره با اهداف عام است که به طور خاص برای توسعه وب&zwnj;سایت مناسب است و قابلیت استفاده در درون ساختار HTML را دارد.
                </blockquote>

                <p class="text-justify textPara">
                    به بیان ساده پی&zwnj;اچ&zwnj;پی یک زبان برنامه&zwnj;نویسی سمت سرور است که به کمک آن می&zwnj;توان کارهایی مانند تبادل داده، ثبت نام کاربران در وب&zwnj;سایت&zwnj;ها و مسائل مربوط به بارگذاری دینامیک محتوای وب&zwnj;سایت از طریق سرور و ... را به انجام رساند.
                </p>

                <!-- paragraphs -->
                <h2 class="subTitle mt-5">مزایای زبان برنامه&zwnj;نویسی پی&zwnj;اچ&zwnj;پی:</h2>
                <p class="text-justify textPara">
                    در اینجا به برخی مزیت&zwnj;های این زبان برنامه&zwnj;نویسی اشاره می&zwnj;کنیم که می&zwnj;تواند در تصمیم&zwnj;گیری شما برای یادگیری آن مؤثر باشد:
                </p>

                <BaseList :listData = "listText"></BaseList>

                <h2 class="subTitle mt-5">منابع یادگیری پی&zwnj;اچ&zwnj;پی:</h2>
                <p class="text-justify textPara">
                    از آنجا که در این مقاله علاوه بر معرفی زبان برنامه&zwnj;نویسی پی&zwnj;اچ&zwnj;پی، قصد داریم که در خصوص <span class="farsiSpan">یادگیری</span> آن نیز به خوانندگان محترم کمک کرده باشیم، در این بخش به معرفی برخی منابع یادگیری (وب&zwnj;سایت&zwnj;ها و ...) پرداخته&zwnj;ایم:
                </p>
                <!--
                <BaseTable :ltrProp="false" styleProp="style-type1" :dataTableProp="tableData" widthMin="900px" captionText="جدول مربوط به محل قرارگیری شرکت&zwnj;ها"></BaseTable>
                -->

                <BaseList :listData = "listText3"></BaseList>

                <!-- paragraphs -->
                <h2 class="subTitle mt-5">مهارت&zwnj;ها و تکنولوژی&zwnj;های مورد نیاز:</h2>
                <p class="text-justify textPara">
                    در این بخش به طور کلی به برخی مهارت&zwnj;ها و تکنولوژی&zwnj;هایی که یک توسعه&zwnj;دهنده پی&zwnj;اچ&zwnj;پی به آنها نیاز دارد، اشاره می&zwnj;شود. در واقع با یادگیری و تمرین کردن این موارد می&zwnj;توانید به عنوان یک توسعه&zwnj;دهنده در زبان پی&zwnj;اچ&zwnj;پی تخصص پیدا کنید. لازم به ذکر است که تمامی این موارد با فرض آشنایی فرد با مقدمات و مفاهیم اولیه و ساختار زبان پی&zwnj;اچ&zwnj;پی مطرح می&zwnj;شوند و در واقع به عنوان گزینه&zwnj;های <strong>بعدی</strong> در مسیر یادگیری این زبان مطرح هستند. همچنین در لیست ارائه&zwnj;شده در اینجا اولویت خاصی مدنظر <span class="farsiSpan">نبوده</span> و این موارد صرفا جهت اطلاع مخاطبان ذکر می&zwnj;شوند (ملاک خاصی در ترتیب موارد ذکر شده در نظر گرفته نشده است):
                </p>

                <BaseList :listData = "listText2"></BaseList>

                <!-- conclusion part -->
                <h2 class="subTitle pb-3 mt-5">جمع بندی: </h2>
                <blockquote class="blockquote conclu">
                    <p class="text-justify textPara pt-3">
                        در این مقاله تلاش ما بر آن بود که با زبان پی&zwnj;اچ&zwnj;پی، مزیت&zwnj;های آن و ابزارهای لازم برای به کارگیری آن آشنا شوید. در هر صورت دنیای وب و برنامه&zwnj;نویسی، دنیای گسترده&zwnj;ای است و کسانی که در این زمینه وارد می&zwnj;شوند، معمولا <strong>به تدریج</strong> این مهارت&zwnj;ها را می&zwnj;آموزند. پس چنانچه در نگاه اول حجم عظیمی از ابزارها و مهارت&zwnj;ها (برای یادگیری) پیش روی شما است، باید تلاش نمایید که آنها را گام به گام و به تدریج آموخته و با تمرین آنها، <span class="farsiSpan">تسلط</span> خود را در هر یک افزایش دهید.
                    </p>
                </blockquote>

            </div>



            <!-- ways number 1 of loading imgs  -->
            <!--
            <img :src='`${this.publicPath}articles/${this.$route.params.id}/first.png`' alt="تصویر نمونه">
-->

                <!-- ways number 2 of loading imgs  -->
                <!--
               <img src='./imgFolder/img1.png' alt="تصویر نمونه">
               -->
        </div>
</template>

<script>
import BaseList from '../../BaseList.vue';
import BaseTable from '../../BaseTable.vue';

export default {
                name: "BaseArticle",
                components: {
                    BaseList,
                    BaseTable
                },
                data() {
                    return {

                        listText: [
                            /* this is the data used for BaseList.vue. the "strongText" data is optional and may be present in some list and not in other lists. */
                            {
                                icon: "fad fa-angle-left",
                                strongText: "متن&zwnj;باز بودن",
                                liText: "پی&zwnj;اچ&zwnj;پی یک زبان متن&zwnj;باز (<span class = engliSpan>open source</span>) است. به این معنا که برای استفاده از آن نیاز به صرف هزینه یا خرید لایسنس و ... نداریم. این مزیت به توسعه&zwnj;دهندگان وب&zwnj;سایت کمک می&zwnj;کند تا بتوانند آسانتر به کار با این زبان برنامه&zwnj;نویسی بپردازند."
                            },

                            {
                                icon: "fad fa-angle-left",
                                strongText: "مستقل از سیستم عامل",
                                liText: "زبان پی&zwnj;اچ&zwnj;پی توسط <span class = farsiSpan >تمامی</span> سیستم عامل&zwnj;ها (ویندوز، لینوکس، یونیکس و ...) قابل پشتیبانی است. این زبان و فریم&zwnj;ورک&zwnj;های آن به راحتی در هر نوع سیستم عاملی اجرا شده و لذا برای توسعه وب&zwnj;سایت&zwnj;ها و نرم&zwnj;افزارها مشکلی از این جهت ندارند."
                            },

                            {
                                icon: "fad fa-angle-left",
                                strongText: "سرعت بالا",
                                liText: "در مقایسه با سایر زبان&zwnj;های برنامه&zwnj;نویسی، این زبان شاید سریع&zwnj;ترین آنها باشد. به صورتی که وب&zwnj;سایت&zwnj;های ساخته شده با آن در شرایط <strong>پایین بودن</strong> سرعت اینترنت و یا نرخ تبادلات داده، باز هم به خوبی بارگذاری می&zwnj;شوند. این در حالی است که وب&zwnj;سایت&zwnj;های ساخته شده با سایر زبان&zwnj;ها، عمدتا به دلیل زمانی که نیاز دارند تا با پایگاه داده <strong>ارتباط</strong> برقرار کنند، از چنین مزیتی برخوردار نمی&zwnj;باشند."
                            },

                            {
                                icon: "fad fa-angle-left",
                                strongText: "امنیت مناسب",
                                liText: "این زبان دارای فریم&zwnj;ورک&zwnj;هایی است که به وب&zwnj;سایت&zwnj;های ساخته شده با آنها کمک شایانی در زمینه مصونیت از خطرهای امنیتی می&zwnj;کنند. خطرهایی که عمدتا در بخش <strong>تبادلات داده&zwnj;ها</strong> ممکن است اتفاق بیفتد، از طریق این فریم&zwnj;ورک&zwnj;ها به شکل مناسبی مدیریت می&zwnj;شوند."
                            }

                        ],  // end of liText

                        listText3: [
                            /* this is the data used for BaseList.vue. the "strongText" data is optional and may be present in some list and not in other lists. */
                            {
                                icon: "fad fa-arrow-alt-left",
                                strongText: "وب&zwnj;سایت رسمی پی&zwnj;اچ&zwnj;پی",
                                liText: "شاید برای یادگیری هر زبان برنامه&zwnj;نویسی، <a href= https://www.php.net/manual/en/index.php  target=_blank>سایت اصلی</a> و پشتیبانی کننده آن زبان یکی از بهترین منابع یادگیری باشد. پی&zwnj;اچ&zwnj;پی نیز از این قاعده مستثنی نیست. در این سایت، علاوه بر مباحث مقدماتی، مباحث پیشرفته&zwnj;تر و به طور کلی هر آنچه را که در ارتباط با این زبان باشد، احتمالا بتوانید یاد بگیرید."
                            },

                            {
                                icon: "fad fa-arrow-alt-left",
                                strongText: "سایت codecademy",
                                liText: " <a href= https://www.codecademy.com/learn/learn-php target=_blank>این سایت</a> که در واقع می&zwnj;توان آن را به عنوان یک ابزار یادگیری در <span class = farsiSpan>بسیاری</span> از مباحث برنامه&zwnj;نویسی لقب داد، محیطی را فراهم می&zwnj;کند که علاوه بر یادگیری و مطالعه مطالب می&zwnj;توانید به کدهای دوره مربوطه نیز دسترسی داشته باشید و در واقع مسائل برنامه&zwnj;نویسی را به صورت عملی بیاموزید."
                            },

                            {
                                icon: "fad fa-arrow-alt-left",
                                strongText: "سایت learn-php",
                                liText: "شاید برای تازه&zwnj;واردها در دنیای زبان&zwnj;های برنامه&zwnj;نویسی یادگیری برخی زبان&zwnj;ها مانند پی&zwnj;اچ&zwnj;پی، که نیاز به <span class = farsiSpan>آماده&zwnj;سازی&zwnj;های اولیه</span> در سیستم کامپیوتر افراد دارند، کمی دشوارتر باشد. <a href= https://www.learn-php.org target=_blank>این سایت</a> با ایجاد یک محیط تعاملی نیاز چنین اشخاصی را تا حدودی برطرف نموده است. در این سایت پس از مطالعه مطالب هر بخش، می&zwnj;توانید در محیط قرار داده شده در داخل خود سایت به تمرین مباحث بپردازید و نیاز به نصب یا دانلود نرم&zwnj;افزار خاصی <span class = farsiSpan>نخواهید</span> داشت."
                            }

                        ],  // end of liText3

                        listText2: [

                            {
                                icon: "fad fa-check-double",
                                strongText: "معماری MVC",
                                liText: "کلمه MVC مخفف سه عبارت <span class = engliSpan>Model</span> و <span class = engliSpan>View</span> و <span class = engliSpan>Control</span> است و در واقع نوعی طراحی وب&zwnj;سایت بر اساس این سه بخش است. از آنجایی که برخی فریم&zwnj;ورک&zwnj;های مرتبط با پی&zwnj;اچ&zwnj;پی از این معماری استفاده می&zwnj;کنند، یادگیری و آشنایی با مفاهیم آن و اینکه چرا و چگونه باید از آن استفاده شود، می&zwnj;تواند حائز اهمیت باشد."
                            },

                            {
                                icon: "fad fa-check-double",
                                strongText: "آشنایی با پایگاه های داده و MySQL",
                                liText: "از آنجایی که پی&zwnj;اچ&zwnj;پی یک زبان برنامه&zwnj;نویسی سمت سرور است و یکی از مسئولیت&zwnj;های بخش سرور سایت بارگذاری و تبادل داده&zwnj;ها می&zwnj;باشد، آشنایی با مفاهیم پایگاه&zwnj;های داده و عملیات&zwnj;های لازم برای تبادل داده ضروری به نظر می&zwnj;رسد. از میان پایگاه&zwnj;های داده، پایگاه داده <span class = engliSpan>MySQL</span> به طور خاص بیشتر در این زبان مطرح می&zwnj;شود، هرچند که امروزه پایگاه&zwnj;های داده NoSQL نیز بسیار حائز اهمیت هستند."
                            },

                            {
                                icon: "fad fa-check-double",
                                strongText: "آشنایی با فریم&zwnj;ورک&zwnj;های پی&zwnj;اچ&zwnj;پی",
                                liText: "به طور کلی امروزه فریم&zwnj;ورک&zwnj;ها در دنیای وب، نقش مهمی دارند. چرا که فرآیند کدنویسی و ساختار و سازماندهی یک پروژه تولید وب سایت را راحت&zwnj;تر و منظم&zwnj;تر می&zwnj;کنند. لذا معمولا در کنار زبان&zwnj;های برنامه نویسی فریم&zwnj;ورک&zwnj;های آنها نیز بسیار مطرح هستند. از جمله فریم&zwnj;ورک&zwnj;های مطرح در زبان پی&zwnj;اچ&zwnj;پی می&zwnj;توان به <a href = https://laravel.com target=_blank>Laravel</a> ،<a href = https://codeigniter.com target=_blank>Codeigniter</a> ،<a href = https://symfony.com target=_blank>Symfony</a> و <a href = https://cakephp.org target=_blank>CakePHP</a> اشاره نمود که متخصص شدن در آنها برای توسعه&zwnj;دهندگان این زبان ضروری به نظر می&zwnj;رسد."
                            },

                            {
                                icon: "fad fa-check-double",
                                strongText: "ساختار OOP",
                                liText: "کلمه OOP مخفف <span class = engliSpan>Object Oriented Programming</span> یا همان برنامه&zwnj;نویسی شئ محور است. در واقع OOP یک متود برنامه&zwnj;نویسی است که بر اساس اشیاء (<span class = engliSpan>Object</span> ها) در زبان&zwnj;های برنامه&zwnj;نویسی بنیان&zwnj;گذاری شده است. این روش برنامه&zwnj;نویسی تنها در زبان پی&zwnj;اچ&zwnj;پی کارایی ندارد و در سایر زبان&zwnj;ها نیز به کار می&zwnj;رود. آشنایی با اصول و نحوه کدنویسی OOP از جمله مهارت&zwnj;های یک توسعه&zwnj;دهنده پی&zwnj;اچ&zwnj;پی محسوب می&zwnj;شود، چرا که اساسا کار با برخی فریم&zwnj;ورک&zwnj;های پی&zwnj;اچ&zwnj;پی مستلزم یادگیری چنین روشی است."
                            },

                            {
                                icon: "fad fa-check-double",
                                strongText: "آشنایی با Linux",
                                liText: "در واقع آشنا بودن با ساختار سیستم عامل Linux برای یک توسعه&zwnj;دهنده پی&zwnj;اچ&zwnj;پی مزیت محسوب می&zwnj;شود و حتی ممکن است در برخی موارد ضروری باشد. مواردی که در آنها از دستورات خط فرمان (<span class = engliSpan>Command Line</span>) استفاده می&zwnj;شود، از جمله این موارد هستند. همچنین نکته دیگری که در این زمینه حائز اهمیت است، آن است که در اکثر سرورها از سیستم&zwnj;های مبتنی بر Linux استفاده می&zwnj;شود و لذا آشنایی با آن برای یک توسعه&zwnj;دهنده پی&zwnj;اچ&zwnj;پی مناسب خواهد بود."
                            },

                            {
                                icon: "fad fa-check-double",
                                strongText: "آشنایی با API و درخواست&zwnj;های HTTP",
                                liText: "از آنجایی که  زبان&zwnj;های سمت سرور برای برقراری ارتباط بین سرور و بازدیدکننده سایت (client) طراحی می&zwnj;شوند، آشنایی با ابزارها و مسائلی که در این ارتباط نقش آفرینی می&zwnj;کنند، می&zwnj;تواند مفید باشد. از جمله این مفاهیم درخواست&zwnj;های HTTP هستند (<span class = engliSpan>HTTP Requests</span>) که با متودهایی مانند GET و POST و ... باعث برقراری ارتباط بین کاربر و سرور می&zwnj;شوند. همچنین آشنایی با APIها (<span class = engliSpan >Application Programming Interface</span>) به عنوان ابزاری که به نوعی درخواست&zwnj;ها را منتقل می&zwnj;کنند و پیام&zwnj;های سرور را بر می&zwnj;گردانند، می&zwnj;تواند کمک&zwnj;کننده یک توسعه&zwnj;دهنده پی&zwnj;اچ&zwnj;پی باشد."
                            }

                        ],  // end of liText2

                        tableData: [
                            /* data for row 1 that is for "thead" of table */
                            {
                                column1: "ردیف",
                                column2: "مورد اول",
                                column3: "مورد دوم",
                                column4: "مورد سوم"
                            },
                            /* data for row 2 */
                            {
                                column1: "1",
                                column2: "یک وب سایت تک صفحه که با موضوع هتل طراحی شده است. در این وب سایت از تکنولوژی های بخش فرانت مانند JavaScript، CSS، HTML و Bootstrap استفاده شده و سایت به صورت responsive طراحی شده است. ",
                                column3: "برنامه نویسی شئ محور نوعی مدل برنامه نویسی است که پایه و اساس آن بر مبنای اشیاء (Objects) بنا نهاده شده است. در این مدل داده ها و توابع برنامه نویسی در خود شئ ذخیره می شوند.",
                                column4: "از آنجایی که  زبان&zwnj;های سمت سرور برای برقراری ارتباط بین سرور و بازدیدکننده سایت (client) طراحی می&zwnj;شوند، آشنایی با ابزارها و مسائلی که در این ارتباط نقش آفرینی می&zwnj;کنند، می&zwnj;تواند مفید باشد. از جمله این مفاهیم درخواست&zwnj;های HTTP هستند (<span class = engliSpan>HTTP Requests</span>) که با متودهایی مانند GE"
                            },
                            /* data for row 3 */
                            {
                                column1: "2",
                                column2: "CSS یکی از زبانهای front-end است که برای طراحی صفحات وب و استایل دادن به آنها به کار می رود. این زبان اغلب به کمک زبان HTML مورد استفاده قرار می گیرد.",
                                column3: "بوتسترپ یکی از فریم ورک های front-end است که به کمک آن می توان صفحات وب را به صورت responsive طراحی کرد. قابلیت های فراوان این فریم ورک آنرا بسیار پرکاربرد نموده است",
                                column4: "کتابخانه jQuery یکی از کتابخانه های <span class = 'farsiSpan'>مرتبط</span> با جاوا اسکریپت است. این کتابخانه در مواردی  نظیر DOM Manipulation و Animation به راحت تر استفاده کردن از جاوا اسکریپت کمک شایانی کرده است."
                            },
                            /* data for width of each column */
                            {
                                column1: "5%",
                                column2: "33%",
                                column3: "31%",
                                column4: "31%"
                            }
                        ] // end of tableData
                    }
                }, // end of data


        }
</script>

<style scoped></style>
