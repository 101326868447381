<template>
<!-- this is the texts and contents of "article number 15" of our database. for more info and list of all options available for each article please see this file:
H:/web-codes/websites/portfolio-folder/design-and-assets/sample article/BaseArticle.vue  -->
        <div class="col-md-10 mx-auto">
            <div class="text-right mt-5">
                <!-- **************** -->
                <!-- intro text -->
                <!-- **************** -->
                <h2 class="subTitle">مقدمه: </h2>
                <blockquote class="blockquote">
                    <p class="text-justify textPara pb-5">
                        احتمالا شما نیز نام <a href="https://router.vuejs.org/" target="_blank">ویو – روتر</a> (vue-router) را شنیده&zwnj;اید. یکی از ابزارهای مطرح که توسعه&zwnj;دهندگان <a href="https://vuejs.org" target="_blank">ویو – جی&zwnj;اس</a> از آن استفاده می&zwnj;کنند. این ابزار به شما کمک می&zwnj;کند تا بتوانید صفحات سایت خود را بهتر با یکدیگر مرتبط سازید. به عبارت ساده&zwnj;تر ویو – روتر ابزاری است که کار <span class="farsiSpan">مسیریابی</span> (navigation) در سایت را بهتر مدیریت می&zwnj;کند و زمانی که کاربران سایت بخواهند از صفحه&zwnj;ای به صفحه دیگر منتقل شوند، فرآیندهای ضروری توسط این ابزار انجام می&zwnj;گیرد. امتیاز مثبت آن نسبت به تگ <code>&lt;a&gt;</code> در آن است که این ابزار بدون بارگذاری مجدد صفحات وب، فرآیند مسیریابی (حرکت از یک صفحه به صفحه دیگر) را امکان&zwnj;پذیر می&zwnj;کند که البته قابلیت&zwnj;های خود فریم ورک ویو - جی&zwnj;اس نیز به این مسئله کمک می&zwnj;کند. در این مقاله قصد داریم تا شما را با نحوه استفاده از ویو – روتر و برخی مفاهیم اولیه آن آشنا سازیم.
                    </p>
                </blockquote>

                <!-- **************** -->
                <!-- part one -->
                <!-- **************** -->
                <h2 class="subTitle mt-5">استفاده از ویو – روتر در یک پروژه ویو – جی&zwnj;اس:</h2>
                <p class="text-justify textPara">
                    گام اول در استفاده از ویو – روتر اضافه کردن و شناساندن آن به یک پروژه ویو - جی&zwnj;اس است. در اینجا دو حالت ممکن است رخ دهد:
                </p>

                <!-- begin subtitles in indented paragraphs -->
                <div class="indentPara">
                    <!-- **************** -->
                    <!-- part one section 1 -->
                    <!-- **************** -->
                    <h3 class="subTitle2 mt-4">حالت اول: استفاده از ویو – روتر از همان ابتدای شروع پروژه</h3>
                    <p class="text-justify textPara">
                        در این حالت توسعه&zwnj;دهنده سایت از همان آغاز تصمیم خود را گرفته است که از ویو – روتر در پروژه ویو – جی&zwnj;اس خود استفاده کند. لذا برای استفاده از آن و شناساندن آن به پروژه، تنها کافی است که در سؤالاتی که در هنگام آغاز پروژه پرسیده می&zwnj;شود، گزینه <span class="engliSpan">Yes</span> را برای ویو – روتر انتخاب کند:
                    </p>

                    <!-- imgs (png,jpg or gif) that are not related to codes -->
                    <div class="col-md-10 mt-5 mx-auto">
                        <img class="img-fluid mx-auto d-block img-thumbnail shadow-lg" src="./imgFolder/yes-router.png" alt="تصویر مربوط به انتخاب ویو – روتر در سوالات ابتدایی ایجاد یک پروژه ویو – جی اس">
                    </div>

                    <p class="text-justify textPara mt-5">
                        در تصویر فوق ملاحظه می&zwnj;کنید که با دستور <code>npm init vue@latest</code> می&zwnj;توان یک پروژه ویو - جی&zwnj;اس را آغاز کرد. بعد از اجرای این دستور معمولا یک سری سؤالات پرسیده می&zwnj;شود که مربوط به ابزارهایی است که شما ممکن است تمایل داشته باشید تا از آنها در پروژه استفاده کنید. ممکن است در هنگامی که شما این مقاله را مطالعه می&zwnj;کنید، سؤالات <span class="farsiSpan">متفاوتی</span> نسبت به شکل فوق از شما پرسیده شود، اما معمولا در خصوص استفاده از ویو – روتر از شما سؤال می&zwnj;شود. در صورتی که گزینه Yes را انتخاب کنید، پس از اجرای فرآیند ایجاد پروژه ویو - جی&zwnj;اس، تنظیمات لازم برای اینکه بتوانید از ویو – روتر در پروژه استفاده نمایید به صورت <span class="farsiSpan">خودکار</span> صورت پذیرفته است.
                    </p>

                    <h3 class="subTitle2 mt-4">حالت دوم: اضافه کردن ویو – روتر به پروژه ایجاد شده در گذشته</h3>
                    <p class="text-justify textPara">
                        در این حالت فرض ما بر آن است که شما یک پروژه ویو – جی&zwnj;اس را از قبل شروع کرده&zwnj;اید ولی در زمان شروع، تمایلی به استفاده از ویو – روتر نداشته&zwnj;اید. اکنون می&zwnj;خواهید آن را به پروژه خود اضافه کنید تا از قابلیت&zwnj;های آن در پروژه استفاده نمایید. در این حالت ابتدا باید در محیط خط فرمان به آدرس محل فایل&zwnj;های پروژه خود رفته و دستور زیر را اجرا نمایید:
                    </p>

                    <div class="col-md-10 mt-5 mx-auto">
                        <img class="img-fluid mx-auto d-block img-thumbnail shadow-lg" src="./imgFolder/router-install.png" alt="تصویر مربوط به دستور نصب ویو – روتر در یک پروژه ویو – جی اس">
                    </div>

                    <p class="text-justify textPara mt-5">
                        لازم به ذکر است که ما در این مقاله از نسخه <span class="farsiSpan">شماره 3</span> فریم&zwnj;ورک ویو - جی&zwnj;اس استفاده می&zwnj;کنیم و بنابراین نسخه <span class="farsiSpan">شماره 4</span> ویو – روتر را که با آن سازگاری دارد، نصب کرده&zwnj;ایم. شما می&zwnj;توانید نسخه سازگار با پروژه خود را نصب کنید. پس از اجرای دستور فوق، فایل&zwnj;های مربوط به ویو – روتر بر روی پروژه شما نصب می&zwnj;شود. حال باید ویو – روتر به پروژه <strong>شناسانده</strong> شود. در این مقاله ما از روش <a href="https://vuejs.org/guide/scaling-up/sfc.html" target="_blank">single-file-component</a> در فریم&zwnj;ورک ویو – جی&zwnj;اس استفاده کرده&zwnj;ایم. در این روش معمولا پوشه&zwnj;ای به نام <span class="engliSpan">src</span> در ساختار پروژه وجود دارد. به داخل آن پوشه رفته و پوشه جدیدی به نام <span class="engliSpan">router</span> در آن ایجاد نمایید. درون این پوشه فایل <span class="engliSpan">index.js</span> را ایجاد کرده و محتویات زیر را در آن وارد نمایید:
                    </p>

                    <div class="col-md-10 mt-5 mx-auto code-img">
                        <img class="img-fluid img-thumbnail" src="./imgFolder/router-index-js.png" alt="تصویر مربوط به فایل index.js درون پوشه router">
                    </div>

                    <p class="text-justify textPara mt-5">
                        پس از انجام این کار کافی است در فایل <span class="engliSpan">main.js</span> که درون پوشه <span class="engliSpan">src</span> قرار گرفته است، این فایل را <span class="engliSpan">import</span> نموده و از طریق دستور <code dir="ltr">use()</code> به ویو - جی&zwnj;اس اجازه استفاده از آن را بدهید:
                    </p>

                    <div class="col-md-10 mt-5 mx-auto code-img">
                        <img class="img-fluid img-thumbnail" src="./imgFolder/import-router.png" alt="تصویر مربوط به import کردن فایل index.js در فایل main.js">
                    </div>

                    <p class="text-justify textPara mt-5">
                        حال می&zwnj;توانید از قابلیت&zwnj;های ویو – روتر که در بخش&zwnj;های بعدی مقاله ذکر می&zwnj;شود، در پروژه خود استفاده نمایید.
                    </p>

                </div>
                <!-- end of subtitles in indented paragraphs -->

                <!-- **************** -->
                <!-- part two -->
                <!-- **************** -->
                <h2 class="subTitle mt-5">تعریف کردن صفحات سایت به وسیله ویو - روتر:</h2>
                <p class="text-justify textPara">
                    بعد از دانلود و نصب ویو – روتر در گام نخست، در ادامه باید صفحات سایت را به آن معرفی کنیم. در اینجا فرض می&zwnj;کنیم که قرار است سه صفحه در سایت خود داشته باشیم. این صفحات را <span class="engliSpan">about</span> ، <span class="engliSpan">home</span> و <span class="engliSpan">pricing</span> نام&zwnj;گذاری می&zwnj;کنیم. برای معرفی این صفحات به سایت، باید به فایل <span class="engliSpan">index.js</span> درون پوشه src/router در ساختار پروژه ویو -جی&zwnj;اس رفته و تغییرات زیر را در آن اعمال کنیم:
                </p>

                <div class="col-md-10 mt-5 mx-auto code-img">
                    <img class="img-fluid img-thumbnail" src="./imgFolder/change-router-index.png" alt="تصویر مربوط به تغییرات اعمال شده در فایل index.js">
                </div>

                <p class="text-justify textPara mt-5">
                    در حقیقت کار اصلی که در اینجا انجام شده است، آن است که سه کامپوننت را از پوشه views به داخل این فایل <span class="engliSpan">import</span> نموده&zwnj;ایم. سپس در داخل آرایه&zwnj;ای به نام <span class="engliSpan">routes</span> از طریق سه <span class="engliSpan">object</span> ویژگی&zwnj;های این سه صفحه را مشخص کرده&zwnj;ایم. یعنی برای هر یک path و name تعریف کرده&zwnj;ایم که از آنها برای اجرای قابلیت&zwnj;های ویو – روتر کمک گرفته می&zwnj;شود. حال برای اینکه در اصل این سه صفحه وجود خارجی داشته باشند، باید آنها را در داخل پوشه src/views بسازیم و ایجاد نماییم. برای این منظور سه فایل به نام&zwnj;های <span class="engliSpan">AboutView.vue</span> ، <span class="engliSpan">HomeView.vue</span> و <span class="engliSpan">PricingView.vue</span> در داخل پوشه مذکور خلق می&zwnj;کنیم که محتویات هر کدام به قرار زیر است:
                </p>

                <BaseTab
                    :head-tabs = "tabDatas"
                    @indexSend = "loadTabContent($event)"
                >
                    <template>
                        <div class="col-md-10 mt-5 mx-auto code-img">
                            <img class="img-fluid img-thumbnail" :src="require(`./imgFolder/${tabDatas[activeTab].data.imgName}`)" :alt="`${tabDatas[activeTab].data.altText}`">
                        </div>
                    </template>

                </BaseTab>

                <!-- **************** -->
                <!-- part three -->
                <!-- **************** -->
                <h2 class="subTitle mt-5">ایجاد لینک صفحات و استفاده از کامپوننت&zwnj;های ویو – روتر:</h2>
                <p class="text-justify textPara">
                    بعد از آن که پروژه ما ویو – روتر را شناخت و صفحات مورد نظر نیز تعریف و ایجاد شدند، نوبت به استفاده از قابلیت&zwnj;ها و کامپوننت&zwnj;های خاص ویو – روتر برای نمایش صفحات می&zwnj;رسد. به بیان دیگر می&zwnj;خواهیم یک <span class="engliSpan">navigation-bar</span> (مجموعه لینک&zwnj;های صفحات سایت که به کاربر در مسیریابی و یافتن صفحه مورد نظر کمک می&zwnj;کند) مشابه اکثر سایت&zwnj;ها برای سایت خود ایجاد کنیم که کاربر بتواند با کلیک بر روی لینک هر صفحه به محتویات آن دست یابد. برای این منظور ویو – روتر از دو کامپوننت ساخته شده در خودش به نام&zwnj;های <code>&lt;router-link&gt;</code> و <code>&lt;router-view&gt;</code> سود می&zwnj;برد. در واقع این دو کامپوننت را می&zwnj;توانید مشابه سایر تگ&zwnj;های <span class="engliSpan">HTML</span> در قسمت <code>&lt;template&gt;</code> کدهای خود به کار ببرید. برای ایجاد یک navigation-bar ساده به فایل <span class="engliSpan">App.vue</span> در سایت خود رفته و کد زیر را وارد نمایید:
                </p>

                <BaseTab
                    :head-tabs = "tabDatas2"
                    @indexSend = "loadTabContent2($event)"
                >
                    <template>
                        <div class="col-md-10 mt-5 mx-auto code-img">
                            <img class="img-fluid img-thumbnail" :src="require(`./imgFolder/${tabDatas2[activeTab2].data.imgName}`)" :alt="`${tabDatas2[activeTab2].data.altText}`">
                        </div>
                    </template>

                </BaseTab>

                <p class="text-justify textPara mt-5">
                    همان&zwnj;طور که از کد فوق می&zwnj;توان دریافت از دو کامپوننت <code>&lt;router-link&gt;</code> و <code>&lt;router-view&gt;</code> <span class="farsiSpan">به ترتیب</span> برای ایجاد navigation-bar سایت و نمایش محتوای هر صفحه استفاده شده است. چنانچه به کامپوننت <code>&lt;router-link&gt;</code> توجه کنید، متوجه می&zwnj;شوید که دارای یک شاخصه (attribute) به نام <code>&rdquo;to&ldquo;</code> است که نقشی مشابه با شاخصه <code>&rdquo;href&ldquo;</code> در تگ <code>&lt;a&gt;</code> دارد. یعنی به ویو – روتر می&zwnj;فهماند که با کلیک کردن بر روی هر لینک، به کدام صفحه سایت باید منتقل شود.
                </p>

                <p class="text-justify textPara">
                    نکته دیگر در خصوص کدهای فوق را می&zwnj;توان در فایل <span class="engliSpan">CSS</span> مشاهده کرد. در این فایل از یک کلاس به نام <span class="engliSpan">router-link-exact-active</span> استفاده شده است. کاربرد این کلاس در آن است که به کمک آن می&zwnj;توانید استایل&zwnj;های مورد نظر برای لینک صفحه&zwnj;ای را که در حالت مشاهده (<span class="engliSpan">active</span>) قرار دارد، تعیین کنید. مثلاً در اینجا از تغییر رنگ متن لینک و همچنین اضافه نمودن underline (خط در زیر لینک) برای مشخص کردن لینک فعال (در حال مشاهده) استفاده شده است. این کلاس از کلاس&zwnj;های <strong>تعریف شده و خاص</strong> ویو – روتر است که بر خلاف سایر کلاس&zwnj;های CSS نیاز به این که شما آن را در کد بخش <code>&lt;template&gt;</code> برای تگ خاصی وارد کنید، ندارد و به صورت خودکار شناسایی می&zwnj;شود.
                </p>

                <!-- imgs (png,jpg or gif) that are not related to codes -->
                <div class="col-md-10 mt-5 mx-auto">
                    <img class="img-fluid mx-auto d-block img-thumbnail shadow-lg" src="./imgFolder/router-active.gif" alt="تصویر مربوط به خروجی نمایش صفحه فعال پس از انتخاب کاربر">
                </div>


                <!-- **************** -->
                <!-- part four -->
                <!-- **************** -->
                <h2 class="subTitle mt-5">مدیریت کردن صفحات تکراری به صورت دینامیک:</h2>

                <!-- begin subtitles in indented paragraphs -->
                <div class="indentPara">
                    <!-- **************** -->
                    <!-- part four section 1 -->
                    <!-- **************** -->
                    <h3 class="subTitle2 mt-4">ایجاد کامپوننت دینامیک:</h3>
                    <p class="text-justify textPara">
                        تا اینجا در خصوص عملکرد <code>&lt;router-link&gt;</code> و <code>&lt;router-view&gt;</code> مطالبی را آموختیم و توانستیم یک navigation-bar ساده برای سایت خود بسازیم. حال فرض کنید سایتی که قرار است طراحی کنیم، خدمات متنوعی به کاربران ارائه می&zwnj;کند و ما می&zwnj;خواهیم انواع خدمات سایت را در navigation-bar قرار دهیم تا کاربران در نگاه اول متوجه آنها بشوند. مسلما در چنین حالت&zwnj;هایی از منوهای به اصطلاح <span class="engliSpan">dropdown</span> در navigation-bar سایت&zwnj;ها استفاده می&zwnj;شود. یعنی یکی از گزینه&zwnj;های موجود (لینک&zwnj;ها) به صورتی طراحی می&zwnj;شود که با حرکت موس کاربر بر روی آن، گزینه&zwnj;های جدید از پایین آن <span class="farsiSpan">ظاهر می&zwnj;شوند</span> و کاربر با کلیک بر روی آنها به صفحه مورد نظر ارجاع داده می&zwnj;شود. اگر بخواهیم خروجی کار را در ابتدا نمایش دهیم، قرار یک navigation-bar مشابه شکل زیر داشته باشیم:
                    </p>

                    <!-- imgs (png,jpg or gif) that are not related to codes -->
                    <div class="col-md-10 mt-5 mx-auto">
                        <img class="img-fluid mx-auto d-block img-thumbnail shadow-lg" src="./imgFolder/nav-dropdown.gif" alt="تصویر مربوط به خروجی منوی dropdown">
                    </div>


                    <p class="text-justify textPara mt-5">
                        مسلما قرار <span class="farsiSpan">نیست</span> که به ازای هر یک از خدمات یک صفحه جدید (یا به اصطلاح یک view) در پوشه view مربوط به پروژه ایجاد کنیم. بلکه قرار است که تنها یک view ایجاد نماییم که بتواند به عنوان <span class="farsiSpan">چارچوب و قالب</span>، تمامی صفحات مربوط به خدمات سایت را نمایش دهد. چرا که در حالت کلی نیز معمولا در اکثر وب&zwnj;سایت&zwnj;ها می&zwnj;توان مشاهده کرد که خدمات مختلف سایت (یا سایر صفحاتی که در مورد یک موضوع مشترک ساخته می&zwnj;شوند) قالب کلی یکسانی دارند (المان&zwnj;ها و طرز قرارگیری و طراحی آنها مشابه یکدیگر است) و تنها <span class="farsiSpan">محتویات</span> هر صفحه متناسب با آن خدمت خاص، تغییر میکند. به چنین حالتی در ویو – روتر نمایش دینامیک صفحات (<span class="engliSpan">Dynamic Route Matching</span>) گفته می&zwnj;شود. برای انجام چنین کاری ابتدا در فایل src/router/index.js کامپوننت مربوط به آن را معرفی می&zwnj;کنیم:
                    </p>

                    <div class="col-md-10 mt-5 mx-auto code-img">
                        <img class="img-fluid img-thumbnail" src="./imgFolder/dynamic.png" alt="تصویر مربوط به اعمال تغییرات در فایل index.js برای معرفی کامپوننت دینامیک">
                    </div>

                    <p class="text-justify textPara mt-5">
                        سپس به پوشه <span class="engliSpan">src/views</span> رفته و کامپوننت (یا همان <span class="engliSpan">view</span>) با نام <span class="engliSpan">ServiceView.vue</span> را به شکل زیر ایجاد می&zwnj;کنیم:
                    </p>

                    <div class="col-md-10 mt-5 mx-auto code-img">
                        <img class="img-fluid img-thumbnail" src="./imgFolder/ServiceView.png" alt="تصویر مربوط به کامپوننت ServiceView.vue">
                    </div>

                    <p class="text-justify textPara mt-5">
                        آنچه از مجموعه دو کد فوق مشخص است، این است که برای دینامیک کردن یک صفحه کافی است که در انتهای ویژگی <span class="engliSpan">path</span> مربوط به آن در فایل index.js از علامت <span class="engliSpan">:</span> استفاده شود و پس از آن نام پارامتر دینامیک مربوطه را قرار دهیم. ما در اینجا این پارامتر را <span class="engliSpan">id</span> نام نهاده&zwnj;ایم (<span class="engliSpan" dir="ltr">:id</span>)، اما نام&zwnj;گذاری آن دلخواه بوده و شما می&zwnj;توانید از هر نام دیگری نیز استفاده کنید. در نهایت این پارامتر با نام قرار داده شده توسط ویو – روتر قابل شناسایی خواهد بود و می&zwnj;توانید از آن در سایر کامپوننت&zwnj;ها استفاده کنید.
                    </p>

                    <!-- **************** -->
                    <!-- part four section 2 -->
                    <!-- **************** -->
                    <h3 class="subTitle2 mt-4">استفاده از ویژگی&zwnj;های ویو – روتر در حالت دینامیک:</h3>
                    <p class="text-justify textPara">
                        در بخش قبل مشاهده کردید که با اضافه کردن پارامتر id یک صفحه دینامیک ایجاد کردیم. اگر به کد مربوط به کامپوننت <span class="engliSpan">views/ServiceView.vue</span> که در بخش قبل ذکر شد، دقت کرده باشید، متوجه شده&zwnj;اید که در قسمت  <code>&lt;template&gt;</code>  مربوط به آن از عبارت <code dir="ltr">$route.params.id</code> استفاده شده است. این عبارت در حقیقت id (یا هر نام دیگری که شما تعریف کرده باشید) متناظر با این صفحه را که می&zwnj;تواند به صورت دینامیک در هر قسمت از ساختار سایت شما تعریف شود یا تغییر داده شود، در صفحه مرورگر نمایش می&zwnj;دهد. برای فهم بهتر موضوع به <span class="engliSpan">navigation-bar</span> که در فایل App.vue در قسمت&zwnj;های قبلی ایجاد کرده&zwnj;ایم، مراجعه کرده و تغییرات زیر را در آن اعمال می&zwnj;کنیم:
                    </p>

                    <BaseTab
                        :head-tabs = "tabDatas3"
                        @indexSend = "loadTabContent3($event)"
                    >
                        <template>
                            <div class="col-md-10 mt-5 mx-auto code-img">
                                <img class="img-fluid img-thumbnail" :src="require(`./imgFolder/${tabDatas3[activeTab3].data.imgName}`)" :alt="`${tabDatas3[activeTab3].data.altText}`">
                            </div>
                        </template>

                    </BaseTab>

                    <p class="text-justify textPara mt-5">
                        همان&zwnj;گونه که در کد فوق مشخص است با اضافه نمودن یک تگ <code>&lt;div&gt;</code> با کلاس dropdown به قسمت <code>&lt;template&gt;</code> کد خود و قرار دادن سه <code>&lt;router-link&gt;</code> در درون آن، در اصل منوی خدمات سایت را ایجاد کرده&zwnj;ایم. آنچه برای فهم مسئله مدیریت کردن دینامیکی صفحات توسط ویو – روتر در اینجا باید بدانیم، آن است که در شاخصه to که در هر <code>&lt;router-link&gt;</code> به کار رفته است، از آدرس&zwnj;هایی مشابه <code dir="ltr">/services/number</code> استفاده شده که به جای <span class="engliSpan">number</span> اعداد مختلفی قرار داده شده است. شما می&zwnj;توانید حتی متن نیز به جای آن قرار دهید. ویو – روتر این قسمت بعد از /services/ را در حقیقت به عنوان همان پارامتر دینامیک (که در بخش قبل <span class="engliSpan">id</span> نام&zwnj;گذاری شد) شناسایی می&zwnj;کند. بنابراین تمامی این آدرس&zwnj;ها به همان صفحه <span class="engliSpan">ServiceView.vue</span> ارجاع داده می&zwnj;شوند. اما تفاوت آنها در id مربوط به هر کدام است که باعث می&zwnj;شود صفحات از یکدیگر تمیز داده شوند.
                    </p>

                    <p class="text-justify textPara">
                        به این ترتیب در حالیکه ما تنها <span class="farsiSpan">یک</span> کامپوننت (یا view) به نام <span class="engliSpan">ServiceView.vue</span> ایجاد کرده&zwnj;ایم، می&zwnj;توانیم محتوای تعداد خدمات <span class="farsiSpan">بیشماری</span> را به کمک آن نمایش دهیم. چنانچه چنین قابلیتی در ویو – روتر لحاظ نشده بود، باید برای هر یک از خدمات یک view جدید به ساختار وب&zwnj;سایت خود اضافه می&zwnj;کردیم.
                    </p>

                </div>
                <!-- end subtitles in indented paragraphs -->

                <!-- **************** -->
                <!-- part five -->
                <!-- **************** -->
                <h2 class="subTitle mt-5">استفاده از ویو – روتر در قسمت <code>&lt;script&gt;</code> :</h2>
                <p class="text-justify textPara">
                    اگر به آنچه در بخش قبل ایجاد کرده&zwnj;ایم دقت بیشتری نمایید، متوجه خواهید شد که زمانی که بر روی صفحه هر یک از خدمات (مثلا لینک مربوط به <span class="engliSpan">Service A</span> در منوی <span class="engliSpan">dropdown</span>) کلیک می&zwnj;کنیم، بعد از انتقال به آن صفحه خود متن <span class="engliSpan">Services</span> که در منوی اصلی قرار دارد، به حالت active در <span class="farsiSpan">نمی&zwnj;آید</span>. به عبارت ساده&zwnj;تر تغییر رنگ نداده و حالت underline به خود نمی&zwnj;گیرد. علت این مسئله کاملا واضح است.
                </p>

                <p class="text-justify textPara">
                    دلیل آن است که کلاس <span class="engliSpan">router-link-exact-active</span> تنها بر روی کامپوننت&zwnj;های <code>&lt;router-link&gt;</code> به صورت خودکار عمل می&zwnj;کند. اگر می&zwnj;خواهیم که این استایل&zwnj;ها بر روی سایر المان&zwnj;های اپلیکیشن یا وب&zwnj;سایت (مانند تگ <code>&lt;button&gt;</code> با کلاس <span class="engliSpan">buttonDrop</span> در اینجا) نیز اعمال شوند، باید خودمان آن کلاس را به تگ مربوطه اضافه کنیم. لذا باید در قسمت <code>&lt;script&gt;</code> مربوط به فایل App.vue کدهای جاوا&zwnj;اسکریپت لازم و ضروری برای این مسئله را ایجاد نماییم. برای آن که بتوانیم به تگ <code>&lt;button&gt;</code> مورد نظر در قسمت <code>&lt;script&gt;</code> دسترسی داشته باشیم، از شاخصه <code>&rdquo;ref&ldquo;</code> (که از <span class="engliSpan">attribute</span> های مخصوص فریم&zwnj;ورک ویو – جی&zwnj;اس است) در این تگ سود می&zwnj;بریم.
                </p>

                <p class="text-justify textPara">
                    سپس در قسمت <code>&lt;script&gt;</code> با import کردن <span class="engliSpan">useRoute</span> از ویو – روتر می&zwnj;توانیم به ویژگی&zwnj;های مورد نظر خود از این ابزار دسترسی داشته باشیم. همچنین برای این که بتوانیم تغییرات مربوط به لینک&zwnj;های خدمات (لینک&zwnj;های دینامیک) را بررسی کنیم، یعنی متوجه شویم که چه زمانی کاربر بر روی لینک&zwnj;های خدمات سایت کلیک کرده و چه زمانی در سایر صفحات سایت قرار دارد، از ویژگی <span class="engliSpan">watch</span> مربوط به فریم&zwnj;ورک ویو - جی&zwnj;اس کمک گرفته&zwnj;ایم. در مجموع بخش&zwnj;های <code dir="ltr">&lt;script&gt;</code> ، <code>&lt;template&gt;</code> و تغییرات مربوط به بخش <code>&lt;style&gt;</code> فایل <span class="engliSpan">App.vue</span> برای عملی شدن هدف فوق را می&zwnj;توانید در زیر مشاهده نمایید:
                </p>

                <BaseTab
                    :head-tabs = "tabDatas4"
                    @indexSend = "loadTabContent4($event)"
                >
                    <template>
                        <div class="col-md-10 mt-5 mx-auto code-img">
                            <img class="img-fluid img-thumbnail" :src="require(`./imgFolder/${tabDatas4[activeTab4].data.imgName}`)" :alt="`${tabDatas4[activeTab4].data.altText}`">
                        </div>
                    </template>

                </BaseTab>

                <!-- **************** -->
                <!-- part six -->
                <!-- **************** -->
                <h2 class="subTitle mt-5">ایجاد صفحات تو-در-تو به کمک ویو – روتر:</h2>
                <p class="text-justify textPara">
                    یکی دیگر از قابلیت&zwnj;هایی که در ویو – روتر طراحی شده است، قابلیت ایجاد صفحات تو-در-تو (<span class="engliSpan">nested routes</span>) است. این قابلیت به شما کمک می&zwnj;کند تا طراحی بهتری برای صفحات سایت خود داشته باشید. در مثال ذکر شده بخش&zwnj;های قبلی مقاله از صفحات مربوط به خدمات سایت نام برده شد. حال فرض کنید که سایت ما برای <strong>هر یک</strong> از خدمات سه سطح <span class="engliSpan">professional</span> ، <span class="engliSpan">free</span> و <span class="engliSpan">high performance</span> را ارائه دهد که محتویات هر یک با دیگری <span class="farsiSpan">متفاوت</span> است. مسلما چنین محتویاتی تنها در صفحات مربوط به خدمات سایت (مانند Service A و ...) باید قرار داده شوند و ارتباطی به صفحاتی چون Pricing یا About ندارند. در اینجا است که کاربرد صفحات تو-در-تو در ویو – روتر مشخص می&zwnj;شود. به عبارت ساده&zwnj;تر قرار است که در هر یک از صفحات خدمات سایت، لینک&zwnj;هایی برای سطوح خدمات مربوطه قرار دهیم که با کلیک بر روی آنها محتویات هر سطح در <span class="farsiSpan">داخل همان صفحه</span> خدمات (در بخش دلخواهی از صفحه مرورگر که با توجه به طراحی سایت نسبت به آن تصمیم&zwnj;گیری می&zwnj;شود) به نمایش در آید. در زیر می&zwnj;توانید خروجی نهایی مورد نظر را مشاهده کنید:
                </p>

                <!-- imgs (png,jpg or gif) that are not related to codes -->
                <div class="col-md-10 mt-5 mx-auto">
                    <img class="img-fluid mx-auto d-block img-thumbnail shadow-lg" src="./imgFolder/nested-final.gif" alt="تصویر مربوط به خروجی نهایی صفحات تو-در-تو">
                </div>

                <p class="text-justify textPara mt-5">
                    همان&zwnj;طور که مشخص است محتویات مربوط به هر سطح از خدمات در ناحیه پایین سمت راست صفحات مربوط به خدمات سایت به نمایش در می&zwnj;آید و این محتویات و حتی طراحی آنها می&zwnj;تواند به کلی با یکدیگر <span class="farsiSpan">متفاوت</span> باشد. برای رسیدن به چنین هدفی و ایجاد صفحات تو-در-تو در ابتدا لازم است تغییراتی را در فایل <span class="engliSpan">index.js</span> موجود در پوشه <span class="engliSpan">src/router</span> ایجاد کنیم. در زیر تغییرات صورت گرفته را مشاهده می&zwnj;کنید:
                </p>

                <div class="col-md-10 mt-5 mx-auto code-img">
                    <img class="img-fluid img-thumbnail" src="./imgFolder/nested-index.png" alt="تصویر مربوط به تغییرات اعمال شده در فایل index.js">
                </div>

                <p class="text-justify textPara mt-5">
                    در کد فوق مشخص است که تغییرات اعمال شده یکی در بخش <span class="engliSpan">import</span> کردن سه کامپوننت (view) جدید است و دیگری که در حقیقت باعث <span class="farsiSpan">عملکرد</span> صفحات تو-در-تو می&zwnj;شود، اضافه کردن ویژگی <span class="engliSpan">children</span> به صفحه services است. این ویژگی در اصل به ویو – روتر می&zwnj;فهماند که این صفحه دارای فرزندانی است. یعنی دارای صفحاتی است که باید در داخل خود این صفحه نمایش داده شوند و این می&zwnj;شود همان قابلیت صفحات تو-در-تو.
                </p>

                <p class="text-justify textPara">
                    بعد از آن در گام بعدی باید کامپوننت&zwnj;هایی را که در فایل <span class="engliSpan">index.js</span> معرفی کرده&zwnj;ایم در حقیقت ایجاد کنیم. لذا به داخل پوشه src/views رفته و سه فایل با نام&zwnj;های <span class="engliSpan">ServiceViewProfessional.vue</span> ، <span class="engliSpan">ServiceViewFree.vue</span> و <span class="engliSpan">ServiceViewHigh.vue</span> را ایجاد می&zwnj;کنیم. شما می&zwnj;توانید هرگونه محتوای دلخواهی را به این فایل&zwnj;ها وارد نمایید، ولی آنچه ما به این صفحات وارد کردیم به شکل زیر است:
                </p>

                <BaseTab
                    :head-tabs = "tabDatas5"
                    @indexSend = "loadTabContent5($event)"
                >
                    <template>
                        <div class="col-md-10 mt-5 mx-auto code-img">
                            <img class="img-fluid img-thumbnail" :src="require(`./imgFolder/${tabDatas5[activeTab5].data.imgName}`)" :alt="`${tabDatas5[activeTab5].data.altText}`">
                        </div>
                    </template>

                </BaseTab>

                <p class="text-justify textPara mt-5">
                    در گام آخر باید تغییرات لازم را در فایل <span class="engliSpan">ServiceView.vue</span>، که مسئول نمایش صفحات خدمات سایت به صورت دینامیک (بخش قبل) است، اعمال کنیم تا بتوانیم محتویات این صفحات جدید را در داخل آن به نمایش درآوریم.
                </p>

                <BaseTab
                    :head-tabs = "tabDatas6"
                    @indexSend = "loadTabContent6($event)"
                >
                    <template>
                        <div class="col-md-10 mt-5 mx-auto code-img">
                            <img class="img-fluid img-thumbnail" :src="require(`./imgFolder/${tabDatas6[activeTab6].data.imgName}`)" :alt="`${tabDatas6[activeTab6].data.altText}`">
                        </div>
                    </template>

                </BaseTab>

                <p class="text-justify textPara mt-5">
                    در واقع آنچه برای نمایش صفحات جدید در این صفحه لازم است، قرار دادن <code>&lt;router-link&gt;</code> و <code>&lt;router-view&gt;</code> درون بخش <code>&lt;template&gt;</code>  مربوط به این کامپوننت است. در خصوص تگ&zwnj;های <code>&lt;router-link&gt;</code> به نحوه آدرس&zwnj;دهی دقت کنید. کلمات <span dir="ltr">free</span> ، <span dir="ltr">professional</span> و <span dir="ltr">high</span> که در شاخصه <code>&rdquo;to&ldquo;</code> مربوط به این تگ&zwnj;ها بعد از پارامتر id مربوطه قرار داده شده&zwnj;اند، همان <span class="engliSpan">path</span> هایی هستند که در فایل index.js در قسمت ویژگی <span class="engliSpan">children</span> تعریف کرده بودیم. همچنین توجه داشته باشید که اضافه کردن <code>&lt;router-view&gt;</code> در این فایل <span class="farsiSpan">ضروری</span> است و این تگ ارتباطی با <code>&lt;router-view&gt;</code> تعریف شده در فایل <span class="engliSpan">App.vue</span> ندارد. در واقع به کمک این تگ ما به ویو – روتر می&zwnj;فهمانیم که محتویات هر یک از صفحات تو-در-تو را در کدام بخش از طراحی سایت ما به نمایش بگذارد.
                </p>

                <p class="text-justify textPara">
                    سایر کدهایی که در قسمت <code>&lt;script&gt;</code> مشاهده می&zwnj;کنید عمدتاً برای مدیریت کردن نمایش اطلاعات موجود نوشته شده&zwnj;اند. به این صورت که به کمک ویژگی <span class="engliSpan">watch</span>، مشابه آنچه در فایل App.vue قبلا انجام شده بود، این بار به پایش <span class="engliSpan">router.currentRoute.value.fullPath</span> پرداخته&zwnj;ایم. این متغیر آدرس <span class="engliSpan">URL</span> را که در بالای صفحه مرورگر وجود دارد، در اختیار ما می&zwnj;گذارد و هر زمان که در صفحه خدمات سایت این آدرس تغییر کند (مثلا کاربر روی لینک مربوط به Free Services کلیک کند یا از صفحه خدمات اول به صفحه خدمات دوم برود)، این ویژگی باعث تغییر وضعیت متغیر <span class="engliSpan">slData</span> می&zwnj;شود و به این ترتیب محتویات صفحه در قسمت <code>&lt;template&gt;</code> بر اساس آن تنظیم می&zwnj;شود (به کمک ویژگی v-if که در کد به کار رفته است). در مجموع کدهای این بخش خیلی ارتباطی به مبحث صفحات تو-در-تو در ویو – روتر <span class="farsiSpan">ندارند</span>، هرچند که از ویژگی&zwnj;های دیگر ویو – روتر در آنها استفاده شده است.
                </p>

                <!-- **************** -->
                <!-- conclusion part -->
                <!-- **************** -->
                <h2 class="subTitle pb-3 mt-5">جمع بندی: </h2>
                <blockquote class="blockquote conclu">

                    <p class="text-justify textPara pt-3">
                        در این مقاله سعی ما بر آن بود تا مخاطبان سایت را با یکی از ابزارهای رایج و قدرتمند در راستای مدیریت نمودن صفحات وب&zwnj;سایت&zwnj;های ساخته شده با فریم&zwnj;ورک ویو - جی&zwnj;اس به نام <strong>ویو – روتر</strong> آشنا کنیم. ابتدا نحوه اضافه کردن آن به یک پروژه ویو - جی&zwnj;اس را بررسی نمودیم و در ادامه به نحوه به&zwnj;کارگیری آن و مدیریت کردن صفحات مشابه به صورت دینامیک و اتوماتیک پرداخته شد. در پایان نیز اشاره&zwnj;ای به استفاده از این ابزار در ساخت صفحات تو-در-تو در وب&zwnj;سایت&zwnj;ها داشتیم. لازم به ذکر است که این ابزار دارای ویژگی&zwnj;های دیگری نیز هست که در این مقاله به آنها نپرداختیم. اما علاقه&zwnj;مندان می&zwnj;توانند با مراجعه با <a href="https://router.vuejs.org/" target="_blank">سایت مرجع</a> مربوط به این ابزار به یادگیری مباحث بیشتر بپردازند.
                    </p>

                </blockquote>

            </div>



            <!-- ways number 1 of loading imgs  -->
            <!--
            <img :src='`${this.publicPath}articles/${this.$route.params.id}/first.png`' alt="تصویر نمونه">
-->

                <!-- ways number 2 of loading imgs  -->
                <!--
               <img src='./imgFolder/img1.png' alt="تصویر نمونه">
               -->
        </div>
</template>

<script>
import BaseTab from '../../BaseTab.vue';

export default {
                name: "BaseArticle",
                components: {
                    BaseTab
                },
                data() {
                    return {
                        activeTab: 0,
                        activeTab2: 0,
                        activeTab3: 0,
                        activeTab4: 0,
                        activeTab5: 0,
                        activeTab6: 0,

                        tabDatas: [
                            {
                                tabHeders: "HomeView",
                                data:{
                                    imgName: "HomeView.png",
                                    altText: "تصویر مربوط به کامپوننت HomeView.vue"
                                }
                            },

                            {
                                tabHeders: "AboutView",
                                data:{
                                    imgName: "AboutView.png",
                                    altText: "تصویر مربوط به کامپوننت AboutView.vue"
                                }
                            },

                            {
                                tabHeders: "PricingView",
                                data:{
                                    imgName: "PricingView.png",
                                    altText: "تصویر مربوط به کامپوننت PricingView.vue"
                                }
                            }

                        ], // end of tabDatas

                        tabDatas2: [
                            {
                                tabHeders: "App.vue",
                                data:{
                                    imgName: "navigation.png",
                                    altText: "تصویر مربوط به فایل App.vue برای ایجاد navigation-bar"
                                }
                            },

                            {
                                tabHeders: "base.css",
                                data:{
                                    imgName: "base-css.png",
                                    altText: "تصویر مربوط به فایل CSS استفاده شده"
                                }
                            },

                            {
                                tabHeders: "Result",
                                data:{
                                    imgName: "navigation-browser.png",
                                    altText: "تصویر مربوط به خروجی navigation-bar ایجاد شده"
                                }
                            }

                        ], // end of tabDatas2

                        tabDatas3: [
                            {
                                tabHeders: "App.vue",
                                data:{
                                    imgName: "App-new.png",
                                    altText: "تصویر مربوط به فایل App.vue برای استفاده از کامپوننت دینامیک"
                                }
                            },

                            {
                                tabHeders: "base.css",
                                data:{
                                    imgName: "base-css-new.png",
                                    altText: "تصویر مربوط به فایل CSS استفاده شده"
                                }
                            },

                            {
                                tabHeders: "Result",
                                data:{
                                    imgName: "router-browser-dynamic.gif",
                                    altText: "تصویر مربوط به خروجی کامپوننت دینامیک"
                                }
                            }

                        ], // end of tabDatas3

                        tabDatas4: [
                            {
                                tabHeders: "template",
                                data:{
                                    imgName: "buttonDrop-template.png",
                                    altText: "تصویر مربوط به بخش template فایل App.vue"
                                }
                            },

                            {
                                tabHeders: "style",
                                data:{
                                    imgName: "buttonDrop-styles.png",
                                    altText: "تصویر مربوط به بخش style فایل App.vue"
                                }
                            },

                            {
                                tabHeders: "script",
                                data:{
                                    imgName: "buttonDrop-scripts.png",
                                    altText: "تصویر مربوط به بخش script فایل App.vue"
                                }
                            },

                            {
                                tabHeders: "result",
                                data:{
                                    imgName: "buttonDrop.gif",
                                    altText: "تصویر مربوط به خروجی تغییرات اعمال شده برای ایجاد استایل های لینک فعال در خصوص صفحه خدمات"
                                }
                            }

                        ], // end of tabDatas4

                        tabDatas5: [
                            {
                                tabHeders: "ServiceViewFree",
                                data:{
                                    imgName: "ServiceFree.png",
                                    altText: "تصویر مربوط به محتویات کامپوننت ServiceViewFree.vue"
                                }
                            },

                            {
                                tabHeders: "ServiceViewProfessional",
                                data:{
                                    imgName: "ServiceProfessional.png",
                                    altText: "تصویر مربوط به محتویات کامپوننت ServiceViewProfessional.vue"
                                }
                            },

                            {
                                tabHeders: "ServiceViewHigh",
                                data:{
                                    imgName: "ServiceHigh.png",
                                    altText: "تصویر مربوط به محتویات کامپوننت ServiceViewHigh.vue"
                                }
                            }

                        ], // end of tabDatas5

                        tabDatas6: [
                            {
                                tabHeders: "template",
                                data:{
                                    imgName: "newTemplateService.png",
                                    altText: "تصویر مربوط به بخش template فایل ServiceView.vue"
                                }
                            },

                            {
                                tabHeders: "style",
                                data:{
                                    imgName: "newStyleService.png",
                                    altText: "تصویر مربوط به بخش style فایل ServiceView.vue"
                                }
                            },

                            {
                                tabHeders: "script",
                                data:{
                                    imgName: "newScriptService.png",
                                    altText: "تصویر مربوط به بخش script فایل ServiceView.vue"
                                }
                            },

                            {
                                tabHeders: "result",
                                data:{
                                    imgName: "nested-final.gif",
                                    altText: "تصویر مربوط به خروجی تغییرات اعمال شده برای ایجاد صفحات تو-در-تو"
                                }
                            }

                        ] // end of tabDatas6

                    }
                }, // end of data

                methods: {
                    loadTabContent: function(indexNow) {
                        this.activeTab = indexNow;
                    },

                    loadTabContent2: function(indexNow2) {
                        this.activeTab2 = indexNow2;
                    },

                    loadTabContent3: function(indexNow3) {
                        this.activeTab3 = indexNow3;
                    },

                    loadTabContent4: function(indexNow4) {
                        this.activeTab4 = indexNow4;
                    },

                    loadTabContent5: function(indexNow5) {
                        this.activeTab5 = indexNow5;
                    },

                    loadTabContent6: function(indexNow6) {
                        this.activeTab6 = indexNow6;
                    }
                }

        }
</script>

<style scoped></style>
