<template>
<!-- This is the page to show information and contact form for users, it contains some input and a textarea for the user to send data to our database.  -->
  <div class="container-fluid">
    <div class="row">
      <!-- text of the contact page -->
      <div class="col-md-8 mr-auto ml-auto mt-5 text-center">
        <p class="firstPara px-md-2 px-4 pb-2">شما بازدیدکنندگان محترم سایت می توانید با ارسال نظرات و پیشنهادات خود بنده را در بهبود کیفیت سایت یاری دهید. همچنین چنانچه درخواست کاری (ساخت وب سایت و ...) داشته باشید نیز می توانید از طریق همین بخش یا سایر راه های ارتباطی ذکر شده پیام خود را ارسال کرده و با اینجانب در ارتباط باشید.</p>
      </div>
    </div>

    <!-- divider part -->
    <DividerCircle></DividerCircle>

    <!-- form for users messages -->
    <div class="row">
      <div class="col-md-12 mainPar mb-5">
        <!-- alert message -->
        <section class="alertPar" v-if="alertShow">
          <div
              v-if="alertData"
              class="col-md-8 mx-auto alert alertSuc alert-dismissible text-center fade show"
              role="alert"
          >
           {{ alertText }}
            <button type="button" class="close" data-dismiss="alert" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>

          <div
              v-else
              class="col-md-8 mx-auto alert alertDan alert-dismissible text-center fade show"
              role="alert"
          >
            {{ alertText }}
            <button type="button" class="close" data-dismiss="alert" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
        </section>

        <form novalidate class="row pl-md-5" :action="this.$backUrl + 'sql'" method="post">
          <BaseInput
              :data-valid="validAfter['name']"
              class-size="col-md-6 my-4"
              inp-type="text"
              label-text="نام:"
              name-inp="name"
              :propVal="nameValue"
          >
          </BaseInput>

          <BaseInput
              :data-valid="validAfter['family']"
              class-size="col-md-6 my-4"
              inp-type="text"
              label-text="نام خانوادگی: "
              name-inp="family"
              :propVal="famiValue"
          >
          </BaseInput>

          <BaseInput
              :data-valid="validAfter['email']"
              class-size="col-md-6 my-4"
              inp-type="email"
              label-text="آدرس ایمیل: "
              name-inp="email"
              :propVal="emailValue"
          >
          </BaseInput>

          <BaseInput
              :data-valid="validAfter['subject']"
              class-size="col-md-6 my-4"
              inp-type="text"
              :propVal="subValue"
              label-text="موضوع پیام: "
              name-inp="subject"
          >
          </BaseInput>

          <BaseTextArea
              :data-valid="validAfter['textId']"
              :prop-val="textCok"
              name-inp="textId"
          >
          </BaseTextArea>

          <div class="col-md-4 my-4 d-flex align-items-center justify-content-center">
            <BaseButton
                type-prop="submit"
                color1-prop
                linked="false"
                text="ارسال پیام"
                name="subBtn"
            >
            </BaseButton>
          </div>

        </form>

      </div>
    </div>
  </div>
</template>

<script>
/* importing components needed for this page */
/* ----------------------------- */
import BaseInput from "../components/BaseInput";
import BaseTextArea from "../components/BaseTextArea";
import DividerCircle from "../components/DividerCircle";
import BaseButton from "../components/BaseButton";
    export default {
        metaInfo: {
           title: "تماس با من",
        },
        name: "Contact",
        /* ----------------------------- */
        data() {
            return {
                /* Data related to the values of inputs */
                nameValue: "",
                famiValue: "",
                emailValue: "",
                subValue: "",
                textCok: "",
                /* ------------------------------------- */
                queryStr: [], /* This data accept the query strings from url to show some validation message. */
                alertText: '',
                alertShow: false,
                alertData: false,
                validAfter: {
                    "name": "valid",
                    "family": "valid",
                    "email": "valid",
                    "subject": "valid",
                    "textId": "valid"
                }
            }
        },
        /* ----------------------------- */
        components: {
            BaseTextArea,
            BaseInput,
            DividerCircle,
            BaseButton
        },
        /* ----------------------------- */
        methods: {

            cokkFunc: function () {
                /* This method checks that if there is any "cookies" on the page or not. Then it gets the values of "cookies" (if exists) and put them on inputs for better "User Experience" */
              if( this.$cookies.isKey("reqBody") ) {
                this.textCok = this.$cookies.get("reqBody").textId;
                this.subValue = this.$cookies.get("reqBody").subject;
                this.emailValue = this.$cookies.get("reqBody").email;
                this.nameValue = this.$cookies.get("reqBody").name;
                this.famiValue = this.$cookies.get("reqBody").family;
              } // end of if
            },
            // end of "cokkFunc" ---------------------
            queryResult: function () {
                /* This method uses query string of the url that comes from "back-end" of site to show some validation messages for inputs. */
                if ( Object.keys(this.$route.query).length > 0 ) {
                    let routeProp = Object.keys(this.$route.query)[0];
                    let valueProp = this.$route.query[routeProp];
                    this.queryStr = [routeProp, valueProp];
                    let inpErr = $('[name='+ this.queryStr[0] +']');
                    this.validAfter[ this.queryStr[0] ] = "inValid";
                    inpErr.parent().append("<small>"+ this.queryStr[1] +"</small>");
                    if (this.queryStr[0] == "textId") {
                        inpErr.parent().children("small").css({
                            "position": "absolute",
                            "bottom": "-20%",
                            "right": "20%",
                            "font-size": "1.2rem"
                        });
                    } else {
                        inpErr.parent().children("small").css({
                            "position": "absolute",
                            "bottom": "-120%",
                            "right": "20%",
                            "font-size": "1.2rem"
                        });
                    }

                    inpErr.focus();
                } // end of if
            },
            // end of "queryResult" ---------------------
            modalActiv: function () {
                /* This method uses query string of the url that comes from "back-end" of site to show some alert messages according to success or fail of process (it depends on this fact that the user input validation was passed or not). */
                if ( Object.keys(this.$route.query).length > 0 ) {
                    let keyParam = Object.keys(this.$route.query)[0];
                    let messVal = this.$route.query[keyParam];

                    if (keyParam === "mess") {
                        this.alertShow = true;
                        this.alertText = messVal;
                    } else {
                        this.alertShow = false;
                        this.alertText = '';
                    }

                    if (messVal === "نظر شما با موفقیت ثبت شد.") {
                        this.alertData = true;
                    } else {
                        this.alertData = false;
                    }
                }
            }
            // end of "modalActiv" ---------------------

        },
        /* ----------------------------- */
        mounted() {
            this.cokkFunc();
            this.queryResult();
            this.modalActiv();
        }

    }
</script>

<style scoped src="../assets/assets-CSS/contact.css"></style>
