<template>
<!-- This is the textarea component for showing "textarea HTML tag" in the site. It supports both "valid" and "invalid" styles, but the validation rules must be coded in parent component or the back-end of site. -->
  <div v-if="dataValid === 'valid'" class="col-md-8 my-4 p-sm-4 py-4 d-flex align-items-center justify-content-around flex-column">
    <label class="labelText" :for="nameInp">متن پیام: </label>
    <textarea :name="nameInp" :id="nameInp" :class = "{'w-75': widthCompute}" class="taCusto form-control" rows="5" :value="valueInp3">
    </textarea>
  </div>

  <div v-else class="col-md-8 my-4 p-sm-4 py-4 d-flex align-items-center justify-content-around flex-column invalidText">
    <label class="labelText" :for="nameInp">متن پیام: </label>
    <textarea :name="nameInp" :id="nameInp" :class = "{'w-75': widthCompute}" class="taCusto form-control w-75" rows="5" :value="valueInp3">
    </textarea>
  </div>
</template>

<script>
    export default {
        name: "BaseTextArea",
        props: {
            dataValid: String,
            propVal: String,
            nameInp: String
        },
        computed: {
            valueInp3() {
                return this.propVal;
            },

            widthCompute() {
                if (window.innerWidth > 575.5) {
                    return true;
                } else {
                    return false;
                }
            }
        }
    }
</script>

<style scoped src="../assets/assets-CSS/textCompo.css"></style>
