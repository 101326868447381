<template>
    <!-- This is the component that is used for advertising on the site. This component is shown only if you manually set "showAdd" prop in the parent component. Until now it is not optimized to use everywhere on the site and it is strongly recommended to use it only at the end of "ArticlesId.vue" view  -->
<div v-if = "showAdd" class="parentAdd row justify-content-around">

    <div class="col-md-6 col-sm-8 col-10 mx-auto" :class="`col-${resolusion}-${size}`" v-for = "(item, index) in advers" :key = "index">
        <div @mouseleave = "shadowFunc" @mouseenter = "shadowFunc" class="jumbotron shadow p-3" :class="`class${size}`">
            <h1 :class = "{changeFont: fontSmall}" class="text-center">{{ item.titleWord }}</h1>
            <p :class = "{changeFont: fontSmall}" class="lead text-center px-2 my-3 d-flex align-items-center justify-content-center">
                {{ item.text }}
            </p>
            <hr class="my-2">
            <div class="d-flex flex-column flex-sm-row align-items-center justify-content-around parentImg">

                <a :class = "{changeFont: fontSmall}" target="_blank" class="btn d-block mx-auto my-3 my-sm-auto" :href="item.linkAdd" role="button">اطلاعات بیشتر در مورد آگهی</a>

                <div class="w-50 p-2">
                    <img :class = "[sizeLarge]" class="img-fluid rounded d-block mx-auto" :src="item.logoAdress" alt="تصویر آگهی">
                </div>
            </div>
        </div>
    </div>

</div>
</template>

<script>
        export default {
                name: "BaseAdvertise",
                data() {
                        return {
                            advers: [
/* Until now (2021/9/24) the data of adds must be defined here and not in parent component or... and also the number of data of adds must be between 1 to 4. you can set random algorithms in the future if the number of ads is a lot.  */
                                {
                                    /* the logo or image that the company sends to its add must be about 250px */
                                   logoAdress: require("../assets/img/imgAdds/logo1.png"),
                                   /* link of the url of the website that must be advertised */
                                   linkAdd: "https://renatello.com/bind-img-src-vue-js/",
                                   /* some exciting words to notice the use of the site to the ad */
                                   titleWord: "حراج زمستانی پاتن جامه",
                                   /* some texts that show the message of ads. the length of this text must be between 36 to 58 for responsiveness. */
                                   text: "آخرین فرصت برای دریافت 50 درصد تخفیف زمستانی که دیگر در تاریخ رخ نخواهد داد و باید بشتابید تا به آن دسترسی پیدا نمایید. به امید دیدار شما در نمایشگاه دادبی در محل نمایشگاه های دائمی",
                                },

                                /*
                                {
                                    logoAdress: require("../assets/img/imgAdds/logo2.svg"),
                                    linkAdd: "https://www.digikala.com",
                                    titleWord: "فرصت محدود خرید ",
                                    text: "آخرین فرصت برای دریافت 50 درصد تخفیف زمستانی که دیگر در تاریخ رخ نخواهد داد و باید بشتابید تا به آن دسترسی پیدا نمایید. به امید دیدار شما در نمایشگاه دادبی در محل نمایشگاه های دائمی نخواهد داد و باید بشتابید تا به آن دسترسی پیدا نمایید. نخواهد داد و باید بشتابید تا به آن دسترسی پیدا نمایید",
                                },

                                {
                                    logoAdress: require("../assets/img/imgAdds/logo3.jpg"),
                                    linkAdd: "https://www.smashingmagazine.com/2010/04/getting-started-banner-advertisements/",
                                    titleWord: "تخفیف ویژه فروشگاه",
                                    text: "آخرین فرصت برای دریافت 50 درصد تخفیف زمستانی که دیگر در تاریخ رخ نخواهد داد و باید بشتابید تا به آن دسترسی پیدا نمایید. به امید دیدار شما در نمایشگاه دادبی در محل نمایشگاه های دائمی نخواهد داد و باید بشتابید تا به آن دسترسی پیدا نمایید",
                                },

                                {
                                    logoAdress: require("../assets/img/imgAdds/logo3.jpg"),
                                    linkAdd: "https://www.smashingmagazine.com/2010/04/getting-started-banner-advertisements/",
                                    titleWord: "حراج زمستانی پاتن جامه",
                                    text: "آخرین فرصت برای دریافت 50 درصد تخفیف زمستانی که دیگر در تاریخ رخ نخواهد داد و باید بشتابید تا به آن دسترسی پیدا نمایید. به امید دیدار شما در نمایشگاه دادبی در محل نمایشگاه های دائمی",
                                }
                                */

                            ],
                        }
                },

                computed: {
                    size() {
                        /* Calculate the size of "col-md-${size}" according to the data length.. */
                        let sizeTemp = 12 / this.advers.length;
                        if (sizeTemp == 12) {
                            sizeTemp = 6;
                        }
                        return sizeTemp;
                    },

                    resolusion() {
                        if ( this.size < 4 ) {
                            return "xl";
                        } else {
                            return "lg";
                        }
                    },

                    sizeLarge() {
                        /* This is used to set the "width" of "img" tag according to the number of ads automatically. */
                        if ( this.size > 4 ) {
                            if (window.innerWidth > 576) {
                                return "w-50"
                            } else {
                                return "w-75"
                            }

                        } else {
                            return "w-75"
                        }
                    },

                    fontSmall() {
                        /* This is used to change the font-size of some tags (with the help of CSS) smaller if the number of data is more. */
                        if ( this.size >= 4 ) {
                            return false;
                        } else {
                            return true;
                        }
                    }
                },

                props: {
                    showAdd: Boolean, /* This prop is used to allow the entire component to show or not. */
                },

                methods: {
                    shadowFunc: function(eve) {
                        /* This method is called when the user enters or leaves an ad to show or disappear some styles like "animated h1 tag" and "box-shadow". */
                        let h1Tag = eve.target.querySelector("h1");

                        if ( eve.type == "mouseleave" ) {
                           eve.target.classList.remove("shadow-lg");
                           eve.target.classList.add("shadow");
                           h1Tag.classList.remove("animationH1");
                        }

                        if ( eve.type == "mouseenter" ) {
                            eve.target.classList.remove("shadow");
                            eve.target.classList.add("shadow-lg");
                            h1Tag.classList.add("animationH1");
                        }

                    }
                }
        }
</script>

<style scoped src="../assets/assets-CSS/baseAdvertise.css"></style>
