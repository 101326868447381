import Vue from 'vue';
import VueRouter from 'vue-router';
import articles from '../views/Articles';
import articlesId from '../views/ArticlesId';
import about from '../views/About';
import contact from '../views/Contact';
import samples from '../views/Samples';
import DonateMe from '../views/DonateMe';
import notFound from '../views/PageNote';

Vue.use(VueRouter);
/* main routes of the site  */
const routes = [
  {
    path: '/',
    name: 'Articles',
    component: articles,
    props: true
  },
  {
    path: '/articles/:id',
    name: 'ArticlesId',
    // component: () => import(/* webpackChunkName: "articlesId" */'../views/ArticlesId'),
    component: articlesId,
    props: true
  },
  {
    path: '/about',
    name: 'About',
    // component: () => import(/* webpackChunkName: "about" */'../views/About'),
    component: about,
    props: true
  },
  {
    path: '/samples',
    name: 'Samples',
    // component: () => import(/* webpackChunkName: "samples" */'../views/Samples'),
    component: samples,
    props: true
  },
  {
    path: '/contact',
    name: 'Contact',
    // component: () => import(/* webpackChunkName: "contact" */'../views/Contact'),
    component: contact,
    props: true
  },
  {
    path: '/donateMe',
    name: 'DonateMe',
    component: DonateMe,
    props: true
  },
  {
    path: '/404',
    alias: '*',
    name: 'PageNote',
    component: notFound,
    props: true
  }
];

const router = new VueRouter({
//  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
